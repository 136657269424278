export default {
  login: 'Log In',
  Status_Login: 'Status Login',
  Translation: 'Translation',
  Translation_Output: 'Translation Output',
  Registration: 'Registration',
  Password_Info:
    'Password must be at least 8 characters long with an uppercase letter and a punctuation mark.',
  Reset_password: 'Reset password',
  New_Password: 'New Password',
  About: 'About',
  Start: 'Start',
  Logout: 'Logout',
  Profile: 'Profile',
  Info: 'Info',
  Settings: 'Settings',
  Archive: 'Archive',
  Default_Input: 'General Input',
  Default_Output: 'General Output',
  Voice_settings: 'Voice settings',
  Contrast: 'Contrast',
  Avatar_settings: 'Avatar settings',
  Languages_Settings: 'Languages Settings',
  Upload_Video: 'Upload video',
  Choose: 'Choose',
  Select_Output_Method: 'Select output method',
  Select_Output_Language: 'Select output language',
  Select_Input_Language: 'Select input language',
  Submit: 'Submit',
  Preview_Video_Upload: 'Uploaded video file will be present here',
  Input_Method: 'Input Method',
  Spoken_Language: 'Spoken Language',
  Spoken_Language_Settings: 'Spoken Language Settings',
  Write: 'Write',
  Record_speech: 'Record speech',
  Record_content: 'Record content',
  Video_input: 'Video input',
  Record_Video: 'Record Video',
  Output_Method: 'Output Method',
  Language: 'Language',
  Back: 'Back',
  All: 'All',
  Speech: 'Speech',
  Text: 'Text',
  Please_enter_valid_email: 'Please enter valid email',
  Email_Address_is_Required: 'Email address is required',
  Password_is_required: 'Password is required',
  Welcome_to: 'Welcome to',
  E_Mail: 'E-Mail',
  Email_is_not_correct: 'Email is not correct.',
  Password: 'Password',
  Password_Repeat: 'Password repeat',
  Password_is_not_correct: 'Password is not correct.',
  Email_or_password_is_not_correct: 'Email or password is incorrect',
  Username_empty: 'Username must not be empty.',
  Email_empty: 'E-mail must not be empty.',
  Email_exists: 'E-mail already exists.',
  Password_empty: 'Password must not be empty.',
  Password_not_match: 'Password does not match.',
  Password_security_rules: 'Password does not match the security rules.',
  No_data_available: 'No data available',
  MAIN_TEXT:
    'EASIER is a Horizon 2020 project that aims to design, develop, and validate a complete multilingual machine translation system that will act as a framework for barrier-free communication among deaf and hearing individuals, as well as provide a platform to support sign language content creation.',
  SUB_TEXT:
    'The project foresees a strong involvement of end-users in design, development, and evaluation of the EASIER ecosystem, where users of various European sign and spoken languages will be able to use their preferred language to interact with each other.',
  FOUNDED_TEXT:
    'EASIER project has been funded by the EU’s Horizon 2020 programme under Grant Agreement number 101016982.',
  Current_password: 'Current password',
  New_password: 'New password',
  New_password_is_invalid: 'New password is invalid.',
  code: 'Code',
  code_invalid: 'Code is invalid.',
  mailbox_check: 'Please check your email inbox.',
  Save: 'Save',
  Name: 'Name',
  ID: 'ID',
  Delete_account: 'Delete account',
  Username_is_Required: 'Username is Required',
  Username: 'Username',
  Username_is_invalid: 'Username is invalid.',
  Email_is_invalid: 'Email is invalid',
  Password_is_invalid: 'Password is invalid',
  Back_to_Log_In: 'Back to Log In',
  Background: 'Background',
  Clothing: 'Clothing',
  Gender: 'Gender',
  Dark_Mode: 'Dark Mode',
  Text_size: 'Text size',
  Camera: 'Signing',
  Adapt_intonation: ' Adapt intonation',
  Voice_style: 'Voice style',
  Language_Level: 'Language level',
  Play_Audio: 'Click on the icon to play the recorded audio file',
  Accent: 'Accent',
  Voice: 'Voice',
  Recording: 'Recording',
  Logged_in_as: 'Logged in as:',
  START: 'START',
  Use_favorite_settings: 'Use favorite settings',
  Failure: 'Failure',
  No_default_input_and_output:
    'No default input and output has been set. Go to the settings menu at first.',
  Go_to_settings: 'Go to settings',
  OK: 'Ok',
  Status: 'Status',
  Signing_in: 'Signing in ....',
  Translation_in_progress: 'Translation in progress ....',
  Input: 'Input',
  Recording_time: 'Recording time',
  Delete: 'Delete',
  Copy: 'Copy',
  Share: 'Share',
  NEXT: 'NEXT',
  Activate_your_camera: 'Activate your camera for recording a video',
  Activate_camera: 'Activate camera',
  Select_video_file: 'Select a video file from stored on your device',
  Open_video_file: 'Open video file',
  Video_recording_element: 'Video recording element',
  Choose_Language: 'Choose Language',
  Tap_and_write: 'Tap and write',
  Clear_text: 'Clear text?',
  NO: 'NO',
  YES: 'YES',
  Clear: 'Clear',
  Paste: 'Paste',
  TRANSLATE: 'TRANSLATE',
  Output: 'Output',
  Audio_Input: 'Audio Input',
  Text_Input: ' Text Input',
  Camera_Input: 'Camera Input',
  Audio_Output: 'Audio Output',
  Text_Output: ' Text Output',
  Avatar_Output: 'Avatar_Output',
  Audio_Play: 'Audio player',
  Camera_Play: 'Video player',
  BACK_TO_START_MENU: 'BACK TO START MENU',
  Avatar_Language_Unavailable:
    'Avatar is currently not available in that language.',
  English: 'English',
  German: 'German',
  French: 'French',
  Italian: 'Italian',
  Greek: 'Greek',
  Dutch: 'Dutch',
  Suizze_German: 'Swiss-German',
  Record_Text: 'Recording in progress',
  video_one: 'Click on the record icon to activate recording',
  video_two: 'Click on the white button left from the timer for start and stop',
  New_translation: 'New translation',
  Appearance: 'Appearance',
  Session_Old: 'Session are old. Please log in again.',
  Unable_Service_Message:
    'A problem has occurred or the service is currently unavailable.',
  no_archives: 'No archives available',
  Archive_Detail: 'Archive Detail',
  Delete_all: 'Delete all',
  Delete_all_archive: 'Do you want to delete all archives?',
  Delete_one_archive: 'Do you want to delete this archive?',
  Delete_user_account: 'Do you want to delete your account?',
  Log_title: 'Log',
  Affect_Fusion: 'Affect Fusion Result',
  Intermediate_translation: 'Intermediate translation',
  Translation_input_from_text: 'Translation input from text',
  Translation_input_from_speech: 'Translation input from speech',
  Translation_input_from_sign_video: 'Translation input from sign video',
  Translation_output_to_text: 'Translation output to text',
  Translation_output_to_speech: 'Translation output to speech',
  Translation_output_to_avatar: 'Translation output to avatar',
  Privacy: 'Privacy Policy',
  Privacy_Title: 'By registering, you accept the',
  Privacy_Text: 'Privacy Policy',
  Privacy_is_required: 'Privacy is required',
  Introduction: 'Introduction',
  Introduction_One:
    'EASIER is a Horizon 2020 project that aims to design, develop and validate a complete multilingual machine translation system that provides a framework for accessible communication between deaf and hearing people, as well as a platform to support content creation in sign language.',
  Introduction_Two:
    'The project relies on strong end-user involvement in the design, development and evaluation of the EASIER platform, where users of different European sign and spoken languages will be able to use their preferred language to interact with each other.',
  Introduction_Three:
    'The EASIER project is funded by the EU Horizon 2020 programme under grant agreement number 101016982.',
  Privacy_Overview: 'Data protection at a glance',
  General_Information: 'General information',
  General_Information_One:
    'The following notes provide a simple overview of what happens to your personal data when you use this app. Personal data is any data that can be used to identify you personally. For detailed information on the subject of data protection.',
  General_Information_Two:
    'Our data protection declaration listed below this text.',
  Data_Collection: 'Data collection on this app',
  Data_Collection_One: 'Who is responsible for data collection on this app?',
  Data_Collection_Two:
    'The data processing in this app is carried out by the app provider. You can find their contact details in the "Notice about the responsible party" section of this privacy policy.',
  Getting_Data: 'How do we collect your data?',
  Getting_Data_One:
    'On the one hand, your data is collected by you providing it to us. This may, for example, be data that you enter during registration or use.',
  Getting_Data_Two:
    'Other data is collected automatically or after your consent when using the app through your browser by our IT systems. This is primarily technical data (e.g. internet browser, operating system or time of app access). This data is collected automatically as soon as you use the app.',
  Using_Data: 'What do we use your data for?',
  Using_Data_One:
    'Data is collected to ensure error-free provision of the app. Login data is used to make your messages and their translation accessible only to you. The messages you enter or record are processed for translation and are only accessible to you in the archive.',
  Delete_Data: 'Deletion of data',
  Delete_Data_One:
    'The data processed in the system will be deleted at the end of the EASIER project. The deletion will take place no later than July 2024.',
  Delete_Data_Two:
    'You can delete your data in the app yourself at any time before this date.',
  Delete_Data_Three:
    '- In the menu item Archive you can delete individual or all messages.',
  Delete_Data_Four:
    '- In the menu item Profile you can delete your account with all data.',
  Rights_Data: 'What rights do you have regarding your data?',
  Rights_Data_One:
    'You have the right at any time to receive information free of charge about the origin, recipient and purpose of your stored personal data. You also have a right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right to request the restriction of the processing of your personal data under certain circumstances. Furthermore, you have the right to lodge a complaint with the competent supervisory authority.',
  Rights_Data_Two:
    'You can contact us at any time about this and other questions on the subject of data protection.',
  Hosting: 'Hosting and partner systems',
  Hosting_One:
    "The following providers are used on the basis of Art. 6 Para. 1 lit. f DSGVO. We have a legitimate interest in the most reliable presentation and function of our app. If a corresponding consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a DSGVO and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information in the user's terminal device (e.g. device fingerprinting) as defined by the TTDSG. The consent can be revoked at any time.",
  Hetzner: 'Hetzner',
  Hetzner_One: 'We host the app with the following provider:',
  Hetzner_Two:
    'The provider is Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (hereinafter Hetzner).',
  Hetzner_Three:
    "For details, please refer to Hetzner's privacy policy: https://www.hetzner.com/de/rechtliches/datenschutz.",
  AWS: 'Amazon Web Services',
  AWS_One:
    'We host components for video analysis and machine translation with the following provider:',
  AWS_Two:
    'Service provider is the American company Amazon Web Services, Inc, 410 Terry Avenue North, Seattle WA 98109, USA.',
  AWS_Three: 'https://aws.amazon.com/de/compliance/data-privacy/',
  Process_Order: 'Order processing',
  Process_Order_One:
    'We have concluded an order processing agreement (AVV) for the use of the above-mentioned services with the providers. This is a contract required by data protection law, which ensures that the latter only processes the personal data of our app users in accordance with our instructions and in compliance with the DSGVO.',
  General_Notes: 'General notes and mandatory information',
  Data_Protection: 'Data protection',
  Data_Protection_One:
    'The operators of this app take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this privacy policy.',
  Data_Protection_Two:
    'When you use this app, various personal data are collected. Personal data is data that can be used to identify you personally. This Privacy Policy explains what data we collect and what we use it for. It also explains how this is done and for what purpose.',
  Data_Protection_Three:
    'We would like to point out that data transmission on the Internet (e.g. when communicating by e-mail) can have security gaps. Complete protection of data against access by third parties is not possible.',
  Responsible_Body: 'Note on the responsible body',
  Responsible_Body_One:
    'The responsible party for data processing in this app is:',
  Responsible_Body_Two:
    'The controller is the natural or legal person who alone or jointly with others determines the purposes and means of the processing of personal data (e.g. names, e-mail addresses, etc.).',
  Phone: 'Phone',
  Email: 'E-mail',
  City: 'Cologne',
  Storage_Period: 'Storage period',
  Storage_Period_One:
    'Unless a more specific storage period has been specified within this privacy policy, your personal data will remain with us until the purpose for processing the data no longer applies. If you assert a legitimate request for deletion or revoke consent for data processing, your data will be deleted unless we have other legally permissible reasons for storing your personal data (e.g. retention periods under tax or commercial law); in the latter case, deletion will take place after these reasons cease to apply.',
  Storage_Period_Two: 'See also "Deletion of data" in section 1.',
  General_Information_Legal:
    'General information on the legal basis for data processing in this app',
  General_Information_Legal_One:
    'If you have consented to data processing, we process your personal data on the basis of Art. 6 (1) lit. a DSGVO or Art. 9 (2) lit. a DSGVO if special categories of data are processed pursuant to Art. 9 (1) DSGVO. In the event of express consent to the transfer of personal data to third countries, data processing is also carried out on the basis of Art. 49 (1) a DSGVO. If you have consented to the storage of cookies or to the access to information in your terminal device (e.g. via device fingerprinting), the data processing is additionally carried out on the basis of Section 25 (1) TTDSG. This consent can be revoked at any time. If your data is required for the performance of a contract or for the implementation of pre-contractual measures, we process your data on the basis of Art. 6 para. 1 lit. b DSGVO. Furthermore, if your data is required for the fulfilment of a legal obligation, we process it on the basis of Art. 6 para. 1 lit. c DSGVO. Furthermore, the data processing may be carried out on the basis of our legitimate interest according to Art. 6 para. 1 lit. f DSGVO. Information on the relevant legal basis in each individual case is provided in the following paragraphs of this data protection declaration.',
  Revocation_Data: 'Revocation of your consent to data processing',
  Revocation_Data_One:
    'Many data processing operations are only possible with your express consent. You can revoke consent you have already given at any time. The legality of the data processing carried out until the revocation remains unaffected by the revocation.',
  Rights_Collection_Data:
    'Right to object to the collection of data (Art. 21 GDPR)',
  Rights_Collection_Data_One:
    'IF THE DATA PROCESSING IS BASED ON ART. 6 ABS. 1 LIT. E OR F DSGVO, YOU HAVE THE RIGHT TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA AT ANY TIME FOR REASONS ARISING FROM YOUR PARTICULAR SITUATION. THE RESPECTIVE LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND IN THIS DATA PROTECTION DECLARATION. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR PERSONAL DATA UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING WHICH OVERRIDE YOUR INTERESTS, RIGHTS AND FREEDOMS, OR THE PROCESSING IS FOR THE PURPOSE OF ASSERTING, EXERCISING OR DEFENDING LEGAL CLAIMS (OBJECTION UNDER ARTICLE 21(1) DSGVO).',
  Right_Of_Appeal: 'Right of appeal to the competent supervisory authority',
  Right_Of_Appeal_One:
    'In the event of breaches of the GDPR, data subjects shall have a right of appeal to a supervisory authority, in particular in the Member State of their habitual residence, their place of work or the place of the alleged breach. The right of appeal is without prejudice to any other administrative or judicial remedy.',
  Right_Of_Portability: 'Right to data portability',
  Right_Of_Portability_One:
    'You have the right to have data that we process automatically on the basis of your consent or in performance of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another responsible party, this will only be done insofar as it is technically feasible.',
  Information_Correction_Deletion: 'Information, correction and deletion',
  Information_Correction_Deletion_One:
    'Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, its origin and recipient and the purpose of the data processing and, if applicable, a right to correction or deletion of this data. You can contact us at any time for this purpose and for further questions on the subject of personal data.',
  Information_Correction_Deletion_Two:
    'You can delete your data in the app yourself at any time, see "Deletion of data" in section 2.',
  Right_Restriction_Processing: 'Right to restriction of processing',
  Right_Restriction_Processing_One:
    'You have the right to request the restriction of the processing of your personal data. To do this, you can contact us at any time. The right to restriction of processing exists in the following cases:',
  Right_Restriction_Processing_List_One:
    '- If you dispute the accuracy of your personal data stored by us, we usually need time to check this. For the duration of the verification, you have the right to request the restriction of the processing of your personal data.',
  Right_Restriction_Processing_List_Two:
    '- If the processing of your personal data happened/is happening unlawfully, you can request the restriction of data processing instead of erasure.',
  Right_Restriction_Processing_List_Three:
    '- If we no longer need your personal data, but you need it to exercise, defend or assert legal claims, you have the right to request restriction of the processing of your personal data instead of erasure.',
  Right_Restriction_Processing_Two:
    'If you have lodged an objection pursuant to Art. 21 (1) DSGVO, a balancing of your interests and ours must be carried out. As long as it has not yet been determined whose interests prevail, you have the right to demand the restriction of the processing of your personal data.',
  Right_Restriction_Processing_Three:
    'If you have restricted the processing of your personal data, this data may - apart from being stored - only be processed with your consent or for the assertion, exercise or defence of legal claims or for the protection of the rights of another natural or legal person or for reasons of an important public interest of the European Union or a Member State.',
  SSL_TLS: 'SSL or TLS encryption',
  SSL_TLS_One:
    'For security reasons and to protect the transmission of confidential content, such as orders or enquiries that you send to us as the site operator, this site uses SSL or TLS encryption. You can recognise an encrypted connection by the fact that the address line of the browser changes from "http://" to "https://" and by the lock symbol in your browser line.',
  SSL_TLS_Two:
    'If SSL or TLS encryption is activated, the data you transmit to us cannot be read by third parties.',
  Data_Collection_App: 'Data collection in this app',
  Cookies: 'Cookies',
  Cookies_One:
    'Our internet pages use so-called "cookies". Cookies are small data packets and do not cause any damage to your end device. They are stored either temporarily for the duration of a session (session cookies) or permanently (permanent cookies) on your end device. Session cookies are automatically deleted at the end of your visit. Permanent cookies (e.g. app settings) remain stored on your end device until you delete them yourself or until they are automatically deleted by your web browser.',
  Cookies_Two:
    'Cookies can originate from us (first-party cookies) or from third-party companies (so-called third-party cookies). Third-party cookies are not used.',
  Cookies_Three:
    'The cookies are technically necessary for the use of the app in order to guarantee the security of a session and to ensure the function of the app. An evaluation of user behaviour does not take place.',
  Cookies_Four:
    'Cookies that are necessary to carry out the electronic communication process, to provide certain functions that you have requested (necessary cookies), are stored on the basis of Art. 6 (1) lit. f DSGVO, unless another legal basis is specified. The app operator has a legitimate interest in storing necessary cookies for the technically error-free provision of its services. Insofar as consent to the storage of cookies and comparable recognition technologies has been requested, processing is carried out exclusively on the basis of this consent (Art. 6 Para. 1 lit. a DSGVO and § 25 Para. 1. TTDSG); consent can be revoked at any time.',
  Cookies_Five:
    'You can delete your data in the app yourself at any time, see "Deletion of data" in section 2.',
  Cookies_Six:
    'You can set your browser so that you are informed about the setting of cookies and only allow cookies in individual cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic deletion of cookies when closing the browser. If cookies are deactivated, the functionality of this app may be limited. You can find out which cookies and services are used in this app in this privacy policy.',
  Enquiry: 'Enquiry by e-mail, telephone or fax',
  Enquiry_One:
    'If you contact us by e-mail, telephone or fax, your enquiry including all resulting personal data (name, enquiry) will be stored and processed by us for the purpose of processing your request. We do not pass on this data without your consent.',
  Enquiry_Two:
    'The processing of this data is based on Art. 6 (1) lit. b DSGVO if your request is related to the performance of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the enquiries addressed to us (Art. 6 para. 1 lit. f DSGVO) or on your consent (Art. 6 para. 1 lit. a DSGVO) if this has been requested; the consent can be revoked at any time.',
  Enquiry_Three:
    'The data you send us via contact requests will remain with us until you request us to delete it, revoke your consent to store it or the purpose for storing the data no longer applies (e.g. after the end of the research project). Mandatory legal provisions - in particular legal retention periods - remain unaffected.',
  Plugins_Tools: 'Plugins and tools',
  Google_Fonts: 'Google Fonts',
  Google_Fonts_One:
    'This site uses so-called Google Fonts, which are provided by Google, for the uniform display of fonts. When you call up a page, your browser loads the required fonts into its browser cache in order to display texts and fonts correctly.',
  Google_Fonts_Two:
    "For this purpose, the browser you are using must connect to Google's servers. This informs Google that this app has been accessed via your IP address. The use of Google Fonts is based on Art. 6 para. 1 lit. f DSGVO. The app operator has a legitimate interest in the uniform presentation of the typeface on its app. Insofar as a corresponding consent has been requested, the processing is carried out exclusively on the basis of Art. 6 para. 1 lit. a DSGVO and § 25 para. 1 TTDSG, insofar as the consent includes the storage of cookies or access to information in the user's terminal device (e.g. device fingerprinting) within the meaning of the TTDSG. The consent can be revoked at any time.",
  Google_Fonts_Three:
    'If your browser does not support Google Fonts, a standard font is used by your computer.',
  Google_Fonts_Four:
    "You can find more information about Google Fonts at https://developers.google.com/fonts/faq and in Google's privacy policy: https://policies.google.com/privacy?hl=de.",
  Google_Fonts_Five:
    'The company is certified under the EU-US Data Privacy Framework (DPF). The DPF is an agreement between the European Union and the USA, which is intended to ensure compliance with European data protection standards for data processing in the USA. Any company certified under the DPF undertakes to comply with these data protection standards. For more information, please contact the provider at the following link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active',
};
