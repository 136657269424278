export default {
  login: 'Se connecter',
  Status_Login: 'Statut de connexion',
  Translation: 'Traduction',
  Translation_Output: 'Résultat de la traduction',
  Registration: 'Inscription',
  Password_Info:
    'Le mot de passe doit comporter au moins 8 caractères avec une lettre majuscule et un signe de ponctuation.',
  Reset_password: 'Réinitialiser le mot de passe',
  New_Password: 'Nouveau mot de passe',
  About: 'À propos de',
  Start: 'Démarrer',
  Logout: 'Déconnexion',
  Profile: 'Profil',
  Info: 'Info',
  Settings: 'Paramètres',
  Archive: 'Archives',
  Default_Input: 'Entrée générale',
  Default_Output: 'Sortie générale',
  Voice_settings: 'Paramètres vocaux',
  Contrast: 'Contraste',
  Avatar_settings: 'Paramètres Avatar',
  Languages_Settings: 'Paramètres de langues',
  Upload_Video: 'Télécharger la vidéo',
  Choose: 'Choisir',
  Select_Output_Method: 'Sélectionner la méthode de sortie',
  Select_Output_Language: 'Sélectionner la langue de sortie',
  Select_Input_Language: "Sélectionner la langue d'entrée",
  Submit: 'Soumettre',
  Preview_Video_Upload: 'Le fichier vidéo téléchargé sera présent ici',
  Input_Method: 'Mode de saisie',
  Spoken_Language: 'Langue de traduction',
  Spoken_Language_Settings: 'Paramètres de la langue parlée',
  Write: 'Écrire',
  Record_speech: 'Enregistrer la voix',
  Record_content: 'Enregistrer le contenu',
  Video_input: 'Entrée vidéo',
  Record_Video: 'Enregistrer la vidéo',
  Output_Method: 'Mode de sortie',
  Language: 'Langue',
  Back: 'Retour',
  All: 'Tout',
  Speech: 'Voix',
  Text: 'Texte',
  Please_enter_valid_email: 'Veuillez entrer une adresse e-mail valide',
  Email_Address_is_Required: "L'adresse e-mail est requise",
  Password_is_required: 'Le mot de passe est requis',
  Welcome_to: 'Bienvenue sur',
  E_Mail: 'Courriel',
  Email_is_not_correct: "L'adresse électronique n'est pas correcte.",
  Password: 'Mot de passe',
  Password_Repeat: 'Répétition du mot de passe',
  Password_is_not_correct: "Le mot de passe n'est pas correct.",
  Email_or_password_is_not_correct: "L'email ou le mot de passe est incorrect",
  Username_empty: "Le nom d'utilisateur ne doit pas être vide.",
  Email_empty: "L'adresse e-mail ne doit pas être vide.",
  Email_exists: "L'e-mail existe déjà.",
  Password_empty: 'Le mot de passe ne doit pas être vide.',
  Password_not_match: 'Le mot de passe ne correspond pas.',
  No_data_available: 'Pas de données disponibles',
  Password_security_rules:
    'Le mot de passe ne correspond pas aux règles de sécurité.',
  MAIN_TEXT:
    "EASIER est un projet Horizon 2020 qui vise à concevoir, développer et valider un système complet de traduction automatique multilingue qui servira de cadre à une communication sans barrière entre les personnes sourdes et les personnes entendantes, ainsi qu'à fournir une plateforme pour soutenir la création de contenu en langue des signes.",
  SUB_TEXT:
    "Le projet prévoit une forte implication des utilisateurs finaux dans la conception, le développement et l'évaluation de l'écosystème EASIER, où les utilisateurs de diverses langues des signes et langues parlées européennes pourront utiliser leur langue préférée pour interagir les uns avec les autres.",
  FOUNDED_TEXT:
    " Le projet EASIER a été financé par le programme Horizon 2020 de l'UE sous le numéro de convention de subvention 101016982.",
  Current_password: 'Mot de passe actuel',
  New_password: 'Nouveau mot de passe',
  New_password_is_invalid: "Le nouveau mot de passe n'est pas valide.",
  code: 'Code',
  code_invalid: "Le code n'est pas valide.",
  mailbox_check: 'Veuillez consulter votre boîte aux lettres électronique.',
  Save: 'Sauvegarder',
  Name: 'Nom',
  ID: 'ID',
  Delete_account: 'Supprimer le compte',
  Username_is_Required: "Le nom d'utilisateur est obligatoire",
  Username: "Nom d'utilisateur",
  Username_is_invalid: "Le nom d'utilisateur n'est pas valide.",
  Email_is_invalid: "L'email n'est pas valide",
  Password_is_invalid: "Le mot de passe n'est pas valide",
  Back_to_Log_In: 'Retour à Connexion',
  Background: "Fond d'écran",
  Clothing: 'Vêtements',
  Gender: 'Genre',
  Dark_Mode: 'Mode foncé',
  Text_size: 'Taille du texte',
  Camera: 'Signe',
  Adapt_intonation: "Adapter l'intonation",
  Voice_style: 'Style vocal',
  Language_Level: 'Niveau de langue',
  Play_Audio: "Cliquez sur l'icône pour lire le fichier audio enregistré.",
  Accent: 'Accentuer',
  Voice: 'Voix',
  Recording: 'Enregistrement',
  Logged_in_as: 'Connecté en tant que :',
  START: 'START',
  Use_favorite_settings: 'Utiliser les paramètres favoris',
  Failure: 'Échec',
  No_default_input_and_output:
    "Aucune entrée et sortie par défaut n'a été définie. Allez d'abord dans le menu des paramètres.",
  Go_to_settings: 'Aller aux paramètres',
  OK: 'Ok',
  Status: 'Statut',
  Signing_in: 'Ouverture de session ....',
  Translation_in_progress: 'Traduction en cours ....',
  Input: 'Entrée',
  Recording_time: "Durée d'enregistrement",
  Delete: 'Supprimer',
  Copy: 'Copier',
  Share: 'Partager',
  NEXT: 'SUIVAN',
  Activate_your_camera: 'Activez votre caméra pour enregistrer une vidéo',
  Activate_camera: 'Activer la caméra',
  Select_video_file: 'Sélectionnez un fichier vidéo stocké sur votre appareil',
  Open_video_file: 'Ouvrir le fichier vidéo',
  Video_recording_element: "Élément d'enregistrement vidéo",
  Choose_Language: 'Choisissez la langue',
  Tap_and_write: 'Taper et écrire',
  Clear_text: 'Supprimer le texte ?',
  NO: 'NON',
  YES: 'OUI',
  Clear: 'Effacer',
  Paste: 'Coller',
  TRANSLATE: 'TRADUIRE',
  Output: 'Sortie',
  Audio_Input: 'Entrée audio',
  Text_Input: 'Entrée texte',
  Camera_Input: 'Entrée caméra',
  Audio_Output: 'Sortie audio',
  Text_Output: 'Sortie texte',
  Avatar_Output: 'Sortie Avatar',
  Audio_Play: 'Lecteur audio',
  Camera_Play: 'Lecteur vidéo',
  BACK_TO_START_MENU: 'RETOUR AU MENU DE DÉPART',
  Avatar_Language_Unavailable:
    "Avatar n'est actuellement pas disponible dans cette langue.",
  English: 'Anglais',
  German: 'Allemand',
  French: 'Français',
  Italian: 'Italienne',
  Greek: 'Grec',
  Dutch: 'Néerlandais',
  Suizze_German: 'Suisse-Allemand',
  Record_Text: 'Enregistrement en cours',
  video_one:
    "Cliquez sur l'icône d'enregistrement pour activer l'enregistrement",
  video_two:
    'Cliquez sur le bouton blanc à gauche du minuteur pour démarrer et arrêter',
  New_translation: 'Nouvelle traduction',
  Appearance: 'Apparence',
  Session_Old: 'La session est ancienne. Veuillez vous reconnecter.',
  Unable_Service_Message:
    "Un problème est survenu ou le service n'est actuellement pas disponible.",
  no_archives: "Pas d'archives disponibles",
  Archive_Detail: "Détail de l'archive",
  Delete_all: 'Supprimer tout',
  Delete_all_archive: 'Voulez-vous supprimer toutes les archives ?',
  Delete_one_archive: 'Voulez-vous supprimer cette archive ?',
  Delete_user_account: 'Voulez-vous supprimer votre compte ?',
  Log_title: 'Journal',
  Affect_Fusion: 'Affect Fusion Résultat',
  Intermediate_translation: 'Traduction intermédiaire',
  Translation_input_from_text: 'Entrée de la traduction à partir du texte',
  Translation_input_from_speech: 'Entrée de traduction à partir de la parole',
  Translation_input_from_sign_video:
    "Entrée de traduction à partir d'une vidéo de signes",
  Translation_output_to_text: 'Sortie de la traduction vers le texte',
  Translation_output_to_speech: 'Sortie de la traduction vers la parole',
  Translation_output_to_avatar: "Sortie de la traduction vers l'avatar",
  Privacy: 'Déclaration de protection des données',
  Privacy_Title: 'En vous inscrivant, vous acceptez la',
  Privacy_Text: 'Déclaration de protection des données',
  Privacy_is_required: 'Le respect de la vie privée est nécessaire',
  Introduction: 'Introduction',
  Introduction_One:
    "EASIER est un projet Horizon 2020 qui vise à concevoir, développer et valider un système complet de traduction automatique multilingue fournissant un cadre pour la communication accessible entre sourds et entendants, ainsi qu'une plateforme pour soutenir la création de contenu en langue des signes.",
  Introduction_Two:
    "Le projet s'appuie sur une forte implication des utilisateurs finaux dans la conception, le développement et l'évaluation de la plateforme EASIER, qui permettra aux utilisateurs de différentes langues des signes et langues parlées européennes d'utiliser leur langue préférée pour interagir entre eux.",
  Introduction_Three:
    "Le projet EASIER est financé par le programme Horizon 2020 de l'UE sous le numéro de convention de subvention 101016982.",
  Privacy_Overview: 'Aperçu de la protection des données',
  General_Information: 'Remarques générales',
  General_Information_One:
    'Les remarques suivantes donnent un aperçu simple de ce qui se passe avec vos données personnelles lorsque vous utilisez cette application. Les données personnelles sont toutes les données qui permettent de vous identifier personnellement. Vous trouverez des informations détaillées sur le thème de la protection des données.',
  General_Information_Two:
    'Vous trouverez des informations complémentaires dans notre déclaration de protection des données figurant sous ce texte.',
  Data_Collection: 'Saisie des données sur cette app',
  Data_Collection_One:
    'Qui est responsable de la collecte des données sur cette application ?',
  Data_Collection_Two:
    "Le traitement des données dans cette app est effectué par le fournisseur de l'app. Vous trouverez ses coordonnées dans la section 'Informations sur le responsable du traitement' de la présente déclaration de protection des données.",
  Getting_Data: 'Comment collectons-nous vos données ?',
  Getting_Data_One:
    "Vos données sont collectées, d'une part, lorsque vous nous les communiquez. Il peut s'agir par exemple de données que vous saisissez lors de l'enregistrement ou de l'utilisation.",
  Getting_Data_Two:
    "D'autres données sont collectées automatiquement par nos systèmes informatiques ou après votre accord lors de l'utilisation de l'application par votre navigateur. Il s'agit principalement de données techniques (par exemple, le navigateur Internet, le système d'exploitation ou l'heure de la consultation de l'application). La saisie de ces données s'effectue automatiquement dès que vous utilisez l'application.",
  Using_Data: 'À quoi servent vos données ?',
  Using_Data_One:
    "Les données sont collectées afin de garantir une mise à disposition sans erreur de l'application. Les données de connexion sont utilisées pour rendre vos messages et leur traduction accessibles uniquement à vous. Les messages que vous avez saisis ou enregistrés sont traités pour la traduction et ne sont accessibles qu'à vous dans les archives.",
  Delete_Data: 'Suppression des données',
  Delete_Data_One:
    'Les données traitées dans le système seront supprimées à la fin du projet EASIER. La suppression aura lieu au plus tard en juillet 2024.',
  Delete_Data_Two:
    "Vous pouvez vous-même supprimer vos données dans l'application à tout moment avant cette date.",
  Delete_Data_Three:
    "- Dans l'option de menu Archives, vous pouvez supprimer des messages individuels ou tous les messages.",
  Delete_Data_Four:
    '- Dans le point de menu Profil, vous pouvez supprimer votre compte avec toutes les données.',
  Rights_Data: 'Quels sont vos droits concernant vos données ?',
  Rights_Data_One:
    "Vous avez à tout moment le droit d'obtenir gratuitement des informations sur l'origine, le destinataire et la finalité de vos données personnelles enregistrées. Vous avez également le droit de demander la rectification ou la suppression de ces données. Si vous avez donné votre consentement au traitement des données, vous pouvez à tout moment révoquer ce consentement pour l'avenir. Vous avez également le droit de demander la limitation du traitement de vos données personnelles dans certaines circonstances. En outre, vous disposez d'un droit de recours auprès de l'autorité de surveillance compétente.",
  Rights_Data_Two:
    'Vous pouvez nous contacter à tout moment à ce sujet ou pour toute autre question relative à la protection des données.',
  Hosting: 'Hébergement et systèmes partenaires',
  Hosting_One:
    "L'utilisation des fournisseurs suivants est basée sur l'article 6, paragraphe 1, point f du RGPD. Nous avons un intérêt légitime à ce que notre application soit représentée et fonctionne de la manière la plus fiable possible. Dans la mesure où un consentement correspondant a été demandé, le traitement s'effectue exclusivement sur la base de l'art. 6, al. 1, let. a du RGPD et de l'art. 25, al. 1 de la loi sur la protection des données (TTDSG), dans la mesure où le consentement comprend le stockage de cookies ou l'accès à des informations dans le terminal de l'utilisateur (par ex. Device-Fingerprinting) au sens de la TTDSG. Le consentement est révocable à tout moment.",
  Hetzner: 'Hetzner',
  Hetzner_One: "Nous hébergeons l'application chez le fournisseur suivant :",
  Hetzner_Two:
    'Le fournisseur est Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (ci-après Hetzner).',
  Hetzner_Three:
    'Pour plus de détails, veuillez consulter la déclaration de protection des données de Hetzner : https://www.hetzner.com/de/rechtliches/datenschutz.',
  AWS: 'Amazon Web Services',
  AWS_One:
    "Nous hébergeons les composants d'analyse vidéo et de traduction automatique chez le fournisseur suivant :",
  AWS_Two:
    "Le fournisseur de services est l'entreprise américaine Amazon Web Services, Inc, 410 Terry Avenue North, Seattle WA 98109, USA.",
  AWS_Three: 'https://aws.amazon.com/de/compliance/data-privacy/',
  Process_Order: 'Traitement des commandes',
  Process_Order_One:
    "Nous avons conclu un contrat de traitement des commandes (CTP) avec les fournisseurs pour l'utilisation des services susmentionnés. Il s'agit d'un contrat prescrit par la législation sur la protection des données, qui garantit que ce dernier traite les données personnelles des utilisateurs de notre application uniquement selon nos instructions et dans le respect du RGPD.",
  General_Notes: 'Informations générales et obligatoires',
  Data_Protection: 'Protection des données',
  Data_Protection_One:
    "Les exploitants de cette application prennent très au sérieux la protection de vos données personnelles. Nous traitons vos données personnelles de manière confidentielle et conformément aux dispositions légales en matière de protection des données ainsi qu'à la présente déclaration de protection des données.",
  Data_Protection_Two:
    'Lorsque vous utilisez cette application, différentes données personnelles sont collectées. Les données personnelles sont des données qui permettent de vous identifier personnellement. La présente déclaration de confidentialité explique quelles données nous collectons et à quelles fins nous les utilisons. Elle explique également comment et dans quel but nous le faisons.',
  Data_Protection_Three:
    "Nous attirons votre attention sur le fait que la transmission de données sur Internet (par ex. lors de la communication par e-mail) peut présenter des failles de sécurité. Une protection sans faille des données contre l'accès par des tiers n'est pas possible.",
  Responsible_Body: 'Remarque sur le service responsable',
  Responsible_Body_One:
    'Le service responsable du traitement des données dans cette application est :',
  Responsible_Body_Two:
    "Le responsable du traitement est la personne physique ou morale qui, seule ou conjointement avec d'autres, détermine les finalités et les moyens du traitement des données à caractère personnel (par ex. noms, adresses e-mail ou autres).",
  Phone: 'Téléphone',
  Email: 'Courrier électronique',
  City: 'Cologne',
  Storage_Period: 'Durée de conservation',
  Storage_Period_One:
    "Dans la mesure où aucune durée de stockage spécifique n'a été mentionnée dans la présente déclaration de protection des données, vos données à caractère personnel restent chez nous jusqu'à ce que la finalité du traitement des données soit supprimée. Si vous faites valoir une demande légitime d'effacement ou si vous retirez votre consentement au traitement des données, vos données seront effacées, à moins que nous n'ayons d'autres raisons légalement valables de conserver vos données personnelles (par exemple, des délais de conservation fiscaux ou commerciaux) ; dans ce dernier cas, l'effacement aura lieu après la disparition de ces raisons.",
  Storage_Period_Two:
    'Voir également "Suppression des données" à la section 1.',
  General_Information_Legal:
    'Remarques générales sur les bases juridiques du traitement des données dans cette application',
  General_Information_Legal_One:
    "Si vous avez donné votre consentement au traitement des données, nous traitons vos données personnelles sur la base de l'article 6, paragraphe 1, point a) du RGPD ou de l'article 9, paragraphe 2, point a) du RGPD, dans la mesure où des catégories particulières de données sont traitées conformément à l'article 9, paragraphe 1 du RGPD. En cas de consentement explicite au transfert de données à caractère personnel vers des pays tiers, le traitement des données s'effectue en outre sur la base de l'article 49, paragraphe 1, point a) du RGPD. Si vous avez consenti à l'enregistrement de cookies ou à l'accès à des informations dans votre terminal (par exemple via l'empreinte digitale de l'appareil), le traitement des données s'effectue en outre sur la base de l'article 25, paragraphe 1, de la TTDSG. Ce consentement est révocable à tout moment. Si vos données sont nécessaires à l'exécution d'un contrat ou à la mise en œuvre de mesures précontractuelles, nous traitons vos données sur la base de l'article 6, paragraphe 1, point b), du RGPD. En outre, nous traitons vos données si elles sont nécessaires pour remplir une obligation légale sur la base de l'article 6, paragraphe 1, point c) du RGPD. Le traitement des données peut également être effectué sur la base de notre intérêt légitime conformément à l'article 6, paragraphe 1, point f), du RGPD. Les paragraphes suivants de la présente déclaration de protection des données informent sur les bases juridiques applicables dans chaque cas.",
  Revocation_Data: 'Révocation de votre consentement au traitement des données',
  Revocation_Data_One:
    "De nombreuses opérations de traitement des données ne sont possibles qu'avec votre consentement explicite. Vous pouvez révoquer à tout moment un consentement déjà donné. La légalité du traitement des données effectué jusqu'à la révocation n'est pas affectée par la révocation.",
  Rights_Collection_Data:
    "Droit d'opposition à la collecte de données (art. 21 RGPD)",
  Rights_Collection_Data_One:
    "SI LE TRAITEMENT DES DONNÉES EST EFFECTUÉ SUR LA BASE DE L'ART. 6 ABS. 1 LIT. E OU F DU RGPD, VOUS AVEZ LE DROIT DE VOUS OPPOSER À TOUT MOMENT, POUR DES RAISONS TENANT À VOTRE SITUATION PARTICULIÈRE, AU TRAITEMENT DE VOS DONNÉES PERSONNELLES. VOUS TROUVEREZ DANS LA PRÉSENTE DÉCLARATION DE PROTECTION DES DONNÉES LES BASES JURIDIQUES RESPECTIVES SUR LESQUELLES REPOSE UN TRAITEMENT. SI VOUS VOUS Y OPPOSEZ, NOUS NE TRAITERONS PLUS VOS DONNÉES PERSONNELLES CONCERNÉES, À MOINS QUE NOUS PUISSIONS DÉMONTRER DES RAISONS IMPÉRIEUSES ET LÉGITIMES POUR LE TRAITEMENT QUI PRÉVALENT SUR VOS INTÉRÊTS, VOS DROITS ET VOS LIBERTÉS, OU QUE LE TRAITEMENT SERVE À FAIRE VALOIR, EXERCER OU DÉFENDRE DES DROITS LÉGAUX (OPPOSITION À L'ARTICLE 21, PARAGRAPHE 1, DU DSGVO).",
  Right_Of_Appeal:
    "Droit de recours auprès de l'autorité de surveillance compétente",
  Right_Of_Appeal_One:
    "En cas de violation du RGPD, les personnes concernées disposent d'un droit de recours auprès d'une autorité de surveillance, notamment dans l'État membre de leur résidence habituelle, de leur lieu de travail ou du lieu de la violation présumée. Ce droit de recours s'exerce sans préjudice de tout autre recours administratif ou judiciaire.",
  Right_Of_Portability: 'Droit à la portabilité des données',
  Right_Of_Portability_One:
    "Vous avez le droit de vous faire remettre ou de faire remettre à un tiers, dans un format courant et lisible par machine, les données que nous traitons de manière automatisée sur la base de votre consentement ou en exécution d'un contrat. Si vous demandez la transmission directe des données à un autre responsable, cela ne se fera que dans la mesure où cela est techniquement possible.",
  Information_Correction_Deletion: 'Renseignement, rectification et effacement',
  Information_Correction_Deletion_One:
    "Dans le cadre des dispositions légales en vigueur, vous avez à tout moment le droit d'obtenir gratuitement des informations sur vos données personnelles enregistrées, leur origine et leur destinataire ainsi que sur la finalité du traitement des données et, le cas échéant, un droit de rectification ou de suppression de ces données. Vous pouvez nous contacter à tout moment à ce sujet ainsi que pour toute autre question relative aux données personnelles.",
  Information_Correction_Deletion_Two:
    "Vous pouvez à tout moment supprimer vous-même vos données dans l'application, voir 'Suppression des données' dans la section 2.",
  Right_Restriction_Processing: 'Droit à la limitation du traitement',
  Right_Restriction_Processing_One:
    'Vous avez le droit de demander la limitation du traitement de vos données personnelles. Pour ce faire, vous pouvez nous contacter à tout moment. Le droit à la limitation du traitement existe dans les cas suivants :',
  Right_Restriction_Processing_List_One:
    "- Si vous contestez l'exactitude de vos données à caractère personnel enregistrées chez nous, nous avons généralement besoin de temps pour le vérifier. Pendant la durée de la vérification, vous avez le droit d'exiger la limitation du traitement de vos données à caractère personnel.",
  Right_Restriction_Processing_List_Two:
    "- Si le traitement de vos données à caractère personnel s'est produit/se produit de manière illicite, vous pouvez demander la limitation du traitement des données au lieu de leur suppression.",
  Right_Restriction_Processing_List_Three:
    "- Si nous n'avons plus besoin de vos données à caractère personnel, mais que vous en avez besoin pour exercer, défendre ou faire valoir des droits en justice, vous avez le droit de demander la limitation du traitement de vos données à caractère personnel au lieu de leur effacement.",
  Right_Restriction_Processing_Two:
    "Si vous avez fait opposition conformément à l'article 21, paragraphe 1, du RGPD, une mise en balance de vos intérêts et des nôtres doit être effectuée. Tant qu'il n'a pas été déterminé quels intérêts prévalent, vous avez le droit de demander la limitation du traitement de vos données à caractère personnel.",
  Right_Restriction_Processing_Three:
    "Si vous avez limité le traitement de vos données à caractère personnel, ces données - à l'exception de leur stockage - ne peuvent être traitées qu'avec votre consentement ou pour la constatation, l'exercice ou la défense de droits en justice ou pour la protection des droits d'une autre personne physique ou morale, ou encore pour un motif d'intérêt public important de l'Union européenne ou d'un État membre.",
  SSL_TLS: 'Cryptage SSL ou TLS',
  SSL_TLS_One:
    "Pour des raisons de sécurité et pour protéger la transmission de contenus confidentiels, tels que les commandes ou les demandes que vous nous envoyez en tant qu'exploitant du site, ce site utilise un cryptage SSL ou TLS. Vous reconnaissez une connexion cryptée au fait que la ligne d'adresse du navigateur passe de 'http://' à 'https://' et au symbole du cadenas dans la ligne de votre navigateur.",
  SSL_TLS_Two:
    'Si le cryptage SSL ou TLS est activé, les données que vous nous transmettez ne peuvent pas être lues par des tiers.',
  Data_Collection_App: 'Collecte de données dans cette application',
  Cookies: 'Cookies',
  Cookies_One:
    "Nos pages Internet utilisent ce que l'on appelle des 'cookies'. Les cookies sont de petits paquets de données qui ne causent aucun dommage à votre terminal. Ils sont enregistrés sur votre terminal soit temporairement pour la durée d'une session (cookies de session), soit durablement (cookies permanents). Les cookies de session sont automatiquement supprimés à la fin de votre visite. Les cookies permanents (par ex. paramètres de l'application) restent enregistrés sur votre terminal jusqu'à ce que vous les supprimiez vous-même ou qu'une suppression automatique soit effectuée par votre navigateur web.",
  Cookies_Two:
    "Les cookies peuvent provenir de nous (cookies de première partie) ou d'entreprises tierces (cookies dits de troisième partie). Les cookies tiers ne sont pas utilisés.",
  Cookies_Three:
    "Les cookies sont techniquement nécessaires à l'utilisation de l'application pour garantir la sécurité d'une session et assurer le bon fonctionnement de l'application. Une évaluation du comportement de l'utilisateur n'a pas lieu.",
  Cookies_Four:
    "Les cookies nécessaires à l'exécution du processus de communication électronique, à la mise à disposition de certaines fonctions que vous souhaitez (cookies nécessaires), sont enregistrés sur la base de l'article 6, paragraphe 1, point f du RGPD, sauf si une autre base juridique est indiquée. L'exploitant de l'application a un intérêt légitime à l'enregistrement des cookies nécessaires à la mise à disposition de ses services sans erreur technique. Si un consentement a été demandé pour l'enregistrement de cookies et de technologies de reconnaissance comparables, le traitement est effectué exclusivement sur la base de ce consentement (art. 6, al. 1, let. a RGPD et § 25, al. 1 TTDSG) ; le consentement peut être révoqué à tout moment.",
  Cookies_Five:
    "Vous pouvez à tout moment supprimer vous-même vos données dans l'application, voir 'Suppression des données' dans la section 2.",
  Cookies_Six:
    "Vous pouvez configurer votre navigateur de manière à être informé de l'installation de cookies et à n'autoriser les cookies qu'au cas par cas, à exclure l'acceptation de cookies dans certains cas ou de manière générale, et à activer la suppression automatique des cookies à la fermeture du navigateur. En cas de désactivation des cookies, la fonctionnalité de cette application peut être limitée. Pour savoir quels cookies et services sont utilisés dans cette application, veuillez consulter cette déclaration de protection des données.",
  Enquiry: 'Demande par e-mail, téléphone ou télécopie',
  Enquiry_One:
    'Si vous nous contactez par e-mail, téléphone ou télécopie, votre demande, y compris toutes les données personnelles qui en découlent (nom, demande), sera enregistrée et traitée par nos soins dans le but de traiter votre demande. Nous ne transmettons pas ces données sans votre consentement.',
  Enquiry_Two:
    "Le traitement de ces données est basé sur l'article 6, paragraphe 1, point b), du RGPD, dans la mesure où votre demande est liée à l'exécution d'un contrat ou est nécessaire à l'exécution de mesures précontractuelles. Dans tous les autres cas, le traitement repose sur notre intérêt légitime à traiter efficacement les demandes qui nous sont adressées (article 6, paragraphe 1, point f) du RGPD) ou sur votre consentement (article 6, paragraphe 1, point a) du RGPD) dans la mesure où celui-ci a été demandé ; le consentement peut être retiré à tout moment.",
  Enquiry_Three:
    "Les données que vous nous avez envoyées par le biais de demandes de contact restent chez nous jusqu'à ce que vous nous demandiez de les effacer, que vous révoquiez votre consentement à leur stockage ou que la finalité du stockage des données devienne caduque (par ex. après la fin du projet de recherche). Les dispositions légales contraignantes - en particulier les délais de conservation légaux - ne sont pas affectées.",
  Plugins_Tools: 'Plugins et outils',
  Google_Fonts: 'Google Fonts',
  Google_Fonts_One:
    "Ce site utilise ce que l'on appelle les Google Fonts, mis à disposition par Google, pour l'affichage uniforme des polices de caractères. Lorsque vous consultez une page, votre navigateur charge les polices nécessaires dans le cache de votre navigateur afin d'afficher correctement les textes et les polices.",
  Google_Fonts_Two:
    "Pour ce faire, le navigateur que vous utilisez doit se connecter aux serveurs de Google. Google est ainsi informé que cette application a été consultée via votre adresse IP. L'utilisation de Google Fonts est basée sur l'article 6, paragraphe 1, point f du RGPD. L'exploitant de l'application a un intérêt légitime à la représentation uniforme de la police de caractères sur son application. Si un consentement correspondant a été demandé, le traitement s'effectue exclusivement sur la base de l'art. 6, al. 1, let. a du RGPD et de l'art. 25, al. 1 de la TTDSG, dans la mesure où le consentement inclut le stockage de cookies ou l'accès à des informations dans le terminal de l'utilisateur (par ex. Device-Fingerprinting) au sens de la TTDSG. Le consentement est révocable à tout moment.",
  Google_Fonts_Three:
    'Si votre navigateur ne supporte pas Google Fonts, une police standard est utilisée par votre ordinateur.',
  Google_Fonts_Four:
    "Pour plus d'informations sur Google Fonts, veuillez consulter le site https://developers.google.com/fonts/faq et la déclaration de confidentialité de Google : https://policies.google.com/privacy?hl=de.",
  Google_Fonts_Five:
    "L'entreprise dispose d'une certification selon le \"EU-US Data Privacy Framework\" (DPF). Le DPF est un accord entre l'Union européenne et les États-Unis visant à garantir le respect des normes européennes en matière de protection des données lors du traitement des données aux États-Unis. Toute entreprise certifiée selon le DPF s'engage à respecter ces normes de protection des données. Pour plus d'informations à ce sujet, veuillez contacter le fournisseur en cliquant sur le lien suivant : https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active",
};
