/**
 * Default Output
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable,
  TouchableOpacity,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  SPEECH,
  TEXT,
  CAMERA,
  AVATAR,
  DEFAULT_OUTPUT_TYPE,
  DEFAULT_OUTPUT_LANGUAGE_TYPE,
  DEFAULT_INPUT_TYPE,
  DEFAULT_INPUT_LANGUAGE_TYPE,
  SELECT_INPUT_TYPE,
  SELECT_OUTPUT_TYPE,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_ITALIAN,
  LANGUAGE_LUXEMBOURG_FRENCH,
  LANGUAGE_LUXEMBOURG_GERMAN,
  LANGUAGE_DUTCH,
  LANGUAGE_SUIZZE_FRENCH,
  LANGUAGE_SUIZZE_ITALIAN,
  LANGUAGE_SUIZZE_GERMAN,
  LANGUAGE_BRITISH,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  SETTINGS_CHOICE,
  SETTINGS_NORMAL,
  SETTINGS_FAVORITE,
  AVATAR_CHOICE,
  AVATAR_NORMAL,
  AVATAR_FAVORITE,
  USERTOKEN_KEY,
  DEVELOPMENT,
  TEST,
} from '../../../constants/constants';
import {useTranslation} from 'react-i18next';
import axios from 'axios';

/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import IconArrow from './../../../assets/iconarrow.png';
import TranslateIcon from './../../../assets/illustrationheaderoutputmethod.png';
import TranslateIconDark from './../../../assets/illustrationheaderoutputmethoddark.png';
import RecordIconDark from './../../../assets/iconspeechdark.png';
import RecordIcon from './../../../assets/iconspeech.png';
import TextIcon from './../../../assets/icontext.png';
import TextIconDark from './../../../assets/icontextdark.png';
import AvatarIconDark from './../../../assets/iconavatardark.png';
import AvatarIcon from './../../../assets/iconavatar.png';
import EarthIcon from './../../../assets/illustrationheaderlanguage.png';
import EarthIconDark from './../../../assets/illustrationheaderlanguagedark.png';
import LanguageIcon from './../../../assets/iconlanguage.png';
import LanguageIconDark from './../../../assets/iconlanguagedark.png';
import FlagGermanIcon from './../../../assets/flaggerman.png';
import FlagDutchIcon from './../../../assets/flagdutch.png';
import FlagFrenchIcon from './../../../assets/flagfrench.png';
import FlagItalianIcon from './../../../assets/flagitaly.png';
import FlagGreeceIcon from './../../../assets/flaggreece.png';
import FlagGreatBritianIcon from './../../../assets/flaggreatbritain.png';
import FlagLuxembourgIcon from './../../../assets/flagluxembourg.png';
import FlagSuizzeIcon from './../../../assets/flagsuizze.png';

const DefaultOutput = ({navigation}): Node => {
  const [speech, setSpeech] = useState(false);
  const [text, setText] = useState(false);
  const [avatar, setAvatar] = useState(false);
  const [language, setLanguage] = useState('');
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [isFavorite, setIsFavorite] = useState(false);
  const [userDataAll, setUserDataAll] = useState({});
  const isFocused = useIsFocused();
  const {t} = useTranslation();

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    const settingsChoice = async () => {
      const settingsStatus = await AsyncStorage.getItem(SETTINGS_CHOICE);

      if (settingsStatus === SETTINGS_FAVORITE) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    };
    const userDataSettings = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      axios
        .get(TEST + 'user', {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(response => {
          if (response.status === 200) {
            console.log(response.data);
            setUserDataAll(response.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    };
    settingsChoice();
    checkDarkModeDefault();
    checkSizeDefault();
    userDataSettings();
  }, [isFocused]);
  const updateUserSettings = async (speechValue, textValue, avatarValue) => {
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

    let allUserData = JSON.parse(userDataAll.settings);
    if (speechValue === true) {
      allUserData.output_media_type = SPEECH;
    }
    if (textValue === true) {
      allUserData.output_media_type = TEXT;
    }
    if (avatarValue === true) {
      allUserData.output_media_type = AVATAR;
    }
    axios
      .post(
        TEST + 'user/settings',
        {
          settings: JSON.stringify(allUserData),
        },
        {
          headers: {
            //...form.getHeaders(),
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userKeyToken}`,
          },
        },
      )
      .then(res => {
        if (res.status === 200) {
          console.log(res.data);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  const setActiveDefaultOutput = async (
    speechValue,
    textValue,
    avatarValue,
  ) => {
    setSpeech(speechValue);
    setText(textValue);
    setAvatar(avatarValue);

    const inputDefaultType = await AsyncStorage.getItem(DEFAULT_INPUT_TYPE);
    const outputDefaultType = await AsyncStorage.getItem(DEFAULT_OUTPUT_TYPE);
    const inputSelectType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
    const outputSelectType = await AsyncStorage.getItem(SELECT_OUTPUT_TYPE);

    await AsyncStorage.setItem(
      DEFAULT_OUTPUT_TYPE,
      speechValue == true
        ? SPEECH
        : textValue == true
        ? TEXT
        : avatarValue == true
        ? AVATAR
        : '',
    );

    await AsyncStorage.setItem(
      SELECT_OUTPUT_TYPE,
      speechValue == true
        ? SPEECH
        : textValue == true
        ? TEXT
        : avatarValue == true
        ? AVATAR
        : '',
    );

    /*await AsyncStorage.setItem(
      DEFAULT_INPUT_TYPE,
      speechValue == true
        ? CAMERA
        : textValue == true
        ? CAMERA
        : avatarValue == true
        ? inputDefaultType == TEXT
          ? TEXT
          : SPEECH
        : '',
    );

    await AsyncStorage.setItem(
      SELECT_INPUT_TYPE,
      speechValue == true
        ? CAMERA
        : textValue == true
        ? CAMERA
        : avatarValue == true
        ? inputSelectType == TEXT
          ? TEXT
          : SPEECH
        : '',
    );*/
  };

  const setActiveDefaultLanguageOutput = async valueLanguage => {
    setLanguage(valueLanguage);
  };

  useEffect(() => {
    const outputTypeDefault = async () => {
      const defaultOutputType = await AsyncStorage.getItem(DEFAULT_OUTPUT_TYPE);
      defaultOutputType === SPEECH
        ? await setActiveDefaultOutput(true, false, false)
        : defaultOutputType === TEXT
        ? await setActiveDefaultOutput(false, true, false)
        : defaultOutputType === AVATAR
        ? await setActiveDefaultOutput(false, false, true)
        : await setActiveDefaultOutput(false, false, false);

      const defaultOutputLanguageType = await AsyncStorage.getItem(
        DEFAULT_OUTPUT_LANGUAGE_TYPE,
      );
      defaultOutputLanguageType === LANGUAGE_FRENCH
        ? setLanguage(LANGUAGE_FRENCH)
        : defaultOutputLanguageType === LANGUAGE_GERMAN
        ? setLanguage(LANGUAGE_GERMAN)
        : defaultOutputLanguageType === LANGUAGE_GREEK
        ? setLanguage(LANGUAGE_GREEK)
        : defaultOutputLanguageType === LANGUAGE_ITALIAN
        ? setLanguage(LANGUAGE_ITALIAN)
        : defaultOutputLanguageType === LANGUAGE_LUXEMBOURG_FRENCH
        ? setLanguage(LANGUAGE_LUXEMBOURG_FRENCH)
        : defaultOutputLanguageType === LANGUAGE_LUXEMBOURG_GERMAN
        ? setLanguage(LANGUAGE_LUXEMBOURG_GERMAN)
        : defaultOutputLanguageType === LANGUAGE_DUTCH
        ? setLanguage(LANGUAGE_DUTCH)
        : defaultOutputLanguageType === LANGUAGE_SUIZZE_FRENCH
        ? setLanguage(LANGUAGE_SUIZZE_FRENCH)
        : defaultOutputLanguageType === LANGUAGE_SUIZZE_ITALIAN
        ? setLanguage(LANGUAGE_SUIZZE_ITALIAN)
        : defaultOutputLanguageType === LANGUAGE_SUIZZE_GERMAN
        ? setLanguage(LANGUAGE_SUIZZE_GERMAN)
        : defaultOutputLanguageType === LANGUAGE_BRITISH
        ? setLanguage(LANGUAGE_BRITISH)
        : setLanguage('');
    };
    outputTypeDefault();
  }, [isFocused]);

  const toTitleCase = phrase => {
    return phrase
      .toLowerCase()
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('-');
  };
  const saveData = () => {
    navigation.navigate('Settings');
  };
  const saveDataNormal = async navigation => {
    if ((await AsyncStorage.getItem(DEFAULT_INPUT_TYPE)) === TEXT) {
      navigation.navigate('Input Text');
    } else if ((await AsyncStorage.getItem(DEFAULT_INPUT_TYPE)) === SPEECH) {
      navigation.navigate('Input Audio Content New');
    } else if ((await AsyncStorage.getItem(DEFAULT_INPUT_TYPE)) === CAMERA) {
      navigation.navigate('Input Camera Content');
    } else {
      navigation.navigate('Default Input');
    }
  };

  const avatarSettingsFavorite = async navigation => {
    await AsyncStorage.setItem(AVATAR_CHOICE, AVATAR_FAVORITE);
    await AsyncStorage.removeItem('archiveID');
    navigation.navigate('Avatar New');
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel={'Illustration - Icon Output'}>
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? TranslateIconDark
                    : TranslateIcon
                  : darkModeAvailable
                  ? 'illustrationheaderinputmethoddark'
                  : 'illustrationheaderinputmethod',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View
          style={
            darkModeAvailable
              ? styles.settingsBackgroundColorDark
              : styles.settingsBackgroundColor
          }
          accessible={true}>
          <View style={styles.additionalOption} accessible={true}>
            <TouchableOpacity
              style={
                speech
                  ? darkModeAvailable
                    ? styles.settingsBackgroundColorIconActiveDark
                    : styles.settingsBackgroundColorIconActive
                  : darkModeAvailable
                  ? styles.settingsBackgroundColorIconDark
                  : styles.settingsBackgroundColorIcon
              }
              onPress={() => {
                setActiveDefaultOutput(true, false, false);
                updateUserSettings(true, false, false);
              }}
              accessible={true}
              accessibilityLabel={t('Speech') + ' - ' + 'Icon'}>
              <Image
                width={Platform.OS == 'web' ? '75px' : 75}
                height={Platform.OS == 'web' ? '75px' : 75}
                source={{
                  uri:
                    Platform.OS == 'web'
                      ? darkModeAvailable
                        ? RecordIconDark
                        : RecordIcon
                      : darkModeAvailable
                      ? 'iconinputdark'
                      : 'iconinput',
                }}
                style={
                  darkModeAvailable
                    ? speech
                      ? styles.settingsIconStyleOtherDark
                      : styles.settingsIconStyle
                    : styles.settingsIconStyle
                }
                resizeMode="contain"
              />
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.settingsTextDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.settingsText,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Speech')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={
                text
                  ? darkModeAvailable
                    ? styles.settingsBackgroundColorIconActiveDark
                    : styles.settingsBackgroundColorIconActive
                  : darkModeAvailable
                  ? styles.settingsBackgroundColorIconDark
                  : styles.settingsBackgroundColorIcon
              }
              onPress={() => {
                setActiveDefaultOutput(false, true, false);
                updateUserSettings(false, true, false);
              }}
              accessible={true}
              accessibilityLabel={t('Text') + ' - ' + 'Icon'}>
              <Image
                width={Platform.OS == 'web' ? '75px' : 75}
                height={Platform.OS == 'web' ? '75px' : 75}
                source={{
                  uri:
                    Platform.OS == 'web'
                      ? darkModeAvailable
                        ? TextIconDark
                        : TextIcon
                      : darkModeAvailable
                      ? 'icontextdark'
                      : 'icontext',
                }}
                style={
                  darkModeAvailable
                    ? text
                      ? styles.settingsIconStyleOtherDark
                      : styles.settingsIconStyle
                    : styles.settingsIconStyle
                }
                resizeMode="contain"
              />
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.settingsTextDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.settingsText,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Text')}
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={
                avatar
                  ? darkModeAvailable
                    ? styles.settingsBackgroundColorIconActiveDark
                    : styles.settingsBackgroundColorIconActive
                  : darkModeAvailable
                  ? styles.settingsBackgroundColorIconDark
                  : styles.settingsBackgroundColorIcon
              }
              onPress={() => {
                setActiveDefaultOutput(false, false, true);
                updateUserSettings(false, false, true);
              }}
              accessible={true}
              accessibilityLabel={t('Avatar') + ' - ' + 'Icon'}>
              <Image
                width={Platform.OS == 'web' ? '75px' : 75}
                height={Platform.OS == 'web' ? '75px' : 75}
                source={{
                  uri:
                    Platform.OS == 'web'
                      ? darkModeAvailable
                        ? AvatarIconDark
                        : AvatarIcon
                      : darkModeAvailable
                      ? 'iconavatardark'
                      : 'iconavatar',
                }}
                style={
                  darkModeAvailable
                    ? avatar
                      ? styles.settingsIconStyleOtherDark
                      : styles.settingsIconStyle
                    : styles.settingsIconStyle
                }
                resizeMode="contain"
              />
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.settingsTextDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.settingsText,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Avatar')}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel={'Illustration - Icon language'}>
          <Image
            width={Platform.OS == 'web' ? '150px' : 150}
            height={Platform.OS == 'web' ? '150px' : 150}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? EarthIconDark
                    : EarthIcon
                  : darkModeAvailable
                  ? 'illustrationheaderlanguagedark'
                  : 'illustrationheaderlanguage',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View
          style={
            darkModeAvailable
              ? [
                  styles.settingsBackgroundColorDark,
                  contrastSizing === SMALLTEXT
                    ? styles.smallTextAll
                    : contrastSizing === NORMALTEXT
                    ? styles.normalTextAll
                    : contrastSizing === BIGTEXT
                    ? styles.bigTextAll
                    : styles.normalTextAll,
                ]
              : [
                  styles.settingsBackgroundColor,
                  contrastSizing === SMALLTEXT
                    ? styles.smallTextAll
                    : contrastSizing === NORMALTEXT
                    ? styles.normalTextAll
                    : contrastSizing === BIGTEXT
                    ? styles.bigTextAll
                    : styles.normalTextAll,
                ]
          }
          accessible={true}>
          <View
            style={
              language == ''
                ? styles.additionalOption
                : styles.additionalOptionActive
            }
            accessible={true}>
            <View
              style={
                language == ''
                  ? darkModeAvailable
                    ? styles.settingsBackgroundColorIconDark
                    : styles.settingsBackgroundColorIcon
                  : darkModeAvailable
                  ? styles.settingsBackgroundColorIconFlagDark
                  : styles.settingsBackgroundColorIconFlag
              }
              accessible={true}>
              <TouchableOpacity
                onPress={() => navigation.navigate('Languages Settings Output')}
                accessible={true}
                accessibilityLabel={
                  language == ''
                    ? t('Choose_Language')
                    : t(
                        language == 'british'
                          ? 'English'
                          : language == 'german'
                          ? 'German'
                          : language == 'french'
                          ? 'French'
                          : language == 'italian'
                          ? 'Italian'
                          : language == 'greek'
                          ? 'Greek'
                          : language == 'dutch'
                          ? 'Dutch'
                          : language == 'suizze-german'
                          ? 'Suizze_German'
                          : language == 'suizze-french'
                          ? 'French'
                          : language == 'suizze-italian'
                          ? 'Italian'
                          : language == 'luxembourg-french'
                          ? 'French'
                          : language == 'luxembourg-german'
                          ? 'German'
                          : '',
                      )
                }
                accessibilityHint="Language selection validation">
                <Image
                  width={Platform.OS == 'web' ? '75px' : 75}
                  height={Platform.OS == 'web' ? '75px' : 75}
                  source={{
                    uri:
                      language == LANGUAGE_GERMAN
                        ? Platform.OS == 'web'
                          ? FlagGermanIcon
                          : 'flaggerman'
                        : language == LANGUAGE_GREEK
                        ? Platform.OS == 'web'
                          ? FlagGreeceIcon
                          : 'flaggreece'
                        : language == LANGUAGE_FRENCH
                        ? Platform.OS == 'web'
                          ? FlagFrenchIcon
                          : 'flagfrench'
                        : language == LANGUAGE_ITALIAN
                        ? Platform.OS == 'web'
                          ? FlagItalianIcon
                          : 'flagitaly'
                        : language == LANGUAGE_LUXEMBOURG_FRENCH ||
                          language == LANGUAGE_LUXEMBOURG_GERMAN
                        ? Platform.OS == 'web'
                          ? FlagLuxembourgIcon
                          : 'flagluxembourg'
                        : language == LANGUAGE_DUTCH
                        ? Platform.OS == 'web'
                          ? FlagDutchIcon
                          : 'flagdutch'
                        : language == LANGUAGE_SUIZZE_FRENCH ||
                          language == LANGUAGE_SUIZZE_ITALIAN ||
                          language == LANGUAGE_SUIZZE_GERMAN
                        ? Platform.OS == 'web'
                          ? FlagSuizzeIcon
                          : 'flagsuizze'
                        : language == LANGUAGE_BRITISH
                        ? Platform.OS == 'web'
                          ? FlagGreatBritianIcon
                          : 'flaggreatbritain'
                        : Platform.OS == 'web'
                        ? darkModeAvailable
                          ? LanguageIconDark
                          : LanguageIcon
                        : darkModeAvailable
                        ? 'iconlanguagedark'
                        : 'iconlanguage',
                  }}
                  style={
                    language == ''
                      ? darkModeAvailable
                        ? [
                            styles.settingsIconStyle,
                            /*styles.settingsIconCustomStyle,*/
                          ]
                        : [
                            styles.settingsIconStyle,
                            /*styles.settingsIconCustomStyle,*/
                          ]
                      : darkModeAvailable
                      ? avatar
                        ? [
                            styles.settingsIconStyleDark,
                            styles.settingsIconCustomStyleActive,
                          ]
                        : [
                            styles.settingsIconStyleDark,
                            styles.settingsIconCustomStyleActiveOtherDark,
                          ]
                      : avatar
                      ? [
                          styles.settingsIconStyle,
                          styles.settingsIconCustomStyleActive,
                        ]
                      : language == 'suizze-german'
                      ? [
                          styles.settingsIconStyle,
                          styles.settingsIconCustomStyleActiveOther,
                        ]
                      : [
                          styles.settingsIconStyle,
                          styles.settingsIconCustomStyleActive,
                        ]
                  }
                  resizeMode="contain"
                />
                <Text
                  style={
                    language == ''
                      ? darkModeAvailable
                        ? [
                            styles.settingsTextDark,
                            styles.settingsTextActiveDarkAvatar,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.settingsText,
                            styles.settingsTextAdditional,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                      : !darkModeAvailable
                      ? [
                          styles.settingsText,
                          styles.settingsTextAdditional,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.settingsText,
                          styles.settingsTextActiveDarkAvatar,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {language == ''
                    ? t('Choose_Language')
                    : t(
                        language == 'british'
                          ? avatar
                            ? 'BSL'
                            : 'English'
                          : language == 'german'
                          ? avatar
                            ? 'DGS'
                            : 'German'
                          : language == 'french'
                          ? avatar
                            ? 'LSF'
                            : 'French'
                          : language == 'italian'
                          ? avatar
                            ? 'LIS'
                            : 'Italian'
                          : language == 'greek'
                          ? avatar
                            ? 'GSL'
                            : 'Greek'
                          : language == 'dutch'
                          ? avatar
                            ? 'NGT'
                            : 'Dutch'
                          : language == 'suizze-german'
                          ? avatar
                            ? 'DSGS'
                            : 'Suizze_German'
                          : language == 'suizze-french'
                          ? 'French'
                          : language == 'suizze-italian'
                          ? 'Italian'
                          : language == 'luxembourg-french'
                          ? 'French'
                          : language == 'luxembourg-german'
                          ? 'German'
                          : '',
                      )}
                </Text>
              </TouchableOpacity>
            </View>
            {avatar ? (
              <View
                style={
                  language == ''
                    ? darkModeAvailable
                      ? styles.settingsBackgroundColorIconDark
                      : styles.settingsBackgroundColorIcon
                    : darkModeAvailable
                    ? styles.settingsBackgroundColorIconFlagDark
                    : styles.settingsBackgroundColorIconFlag
                }
                accessible={true}>
                <TouchableOpacity
                  onPress={() => avatarSettingsFavorite(navigation)}
                  accessible={true}
                  accessibilityLabel={'Avatar settings'}
                  accessibilityHint="Avatar settings - Choose">
                  <Image
                    width={Platform.OS == 'web' ? '75px' : 75}
                    height={Platform.OS == 'web' ? '75px' : 75}
                    source={{
                      uri:
                        Platform.OS == 'web'
                          ? darkModeAvailable
                            ? AvatarIconDark
                            : AvatarIcon
                          : darkModeAvailable
                          ? 'iconavatardark'
                          : 'iconavatar',
                    }}
                    style={
                      language == ''
                        ? darkModeAvailable
                          ? [
                              styles.settingsIconStyle,
                              /*styles.settingsIconCustomStyle,*/
                            ]
                          : [
                              styles.settingsIconStyle,
                              /*styles.settingsIconCustomStyle,*/
                            ]
                        : darkModeAvailable
                        ? avatar
                          ? [
                              styles.settingsIconStyleDark,
                              styles.settingsIconStyleOtherDark,
                              styles.settingsIconCustomStyleActive,
                            ]
                          : [
                              styles.settingsIconStyleDark,
                              styles.settingsIconStyleOtherDark,
                              styles.settingsIconCustomStyleActiveOtherDark,
                            ]
                        : avatar
                        ? [
                            styles.settingsIconStyle,
                            styles.settingsIconCustomStyleActive,
                          ]
                        : [
                            styles.settingsIconStyle,
                            styles.settingsIconCustomStyleActive,
                          ]
                    }
                    resizeMode="contain"
                  />
                  <Text
                    style={
                      language == ''
                        ? darkModeAvailable
                          ? [
                              styles.settingsTextDark,
                              styles.settingsTextActiveDark,
                              contrastSizing === SMALLTEXT
                                ? styles.smallTextAll
                                : contrastSizing === NORMALTEXT
                                ? styles.normalTextAll
                                : contrastSizing === BIGTEXT
                                ? styles.bigTextAll
                                : styles.normalTextAll,
                            ]
                          : [
                              styles.settingsText,
                              styles.settingsTextAdditional,
                              contrastSizing === SMALLTEXT
                                ? styles.smallTextAll
                                : contrastSizing === NORMALTEXT
                                ? styles.normalTextAll
                                : contrastSizing === BIGTEXT
                                ? styles.bigTextAll
                                : styles.normalTextAll,
                            ]
                        : !darkModeAvailable
                        ? [
                            styles.settingsText,
                            styles.settingsTextAdditional,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.settingsText,
                            styles.settingsTextActiveDark,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                    }>
                    {t('Settings')}
                  </Text>
                </TouchableOpacity>
              </View>
            ) : (
              <></>
            )}
          </View>
        </View>
        {isFavorite ? (
          <View>
            <Pressable
              style={darkModeAvailable ? styles.buttonDark : styles.button}
              accessible={true}
              accessibilityLabel={t('Save')}
              accessibilityHint="Save default output validation"
              onPress={saveData}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.textButton,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Save')}
              </Text>
            </Pressable>
          </View>
        ) : (
          <View>
            <Pressable
              style={darkModeAvailable ? styles.buttonDark : styles.button}
              accessible={true}
              accessibilityLabel={t('Save')}
              accessibilityHint="Save default output validation and go back to input view"
              onPress={() => saveDataNormal(navigation)}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.textButton,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Save')}
              </Text>
            </Pressable>
          </View>
        )}
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  additionalOption: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '145px' : 145,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  additionalOptionActive: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '160px' : 160,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  settingsBackgroundColor: {
    backgroundColor: '#ECECEC',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //flex: 1,
    flexDirection: 'column',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  settingsBackgroundColorDark: {
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //flex: 1,
    flexDirection: 'column',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '30px' : 30,
    paddingLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  settingsText: {
    fontSize: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    color: '#000000',
  },
  settingsTextDark: {
    fontSize: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    color: '#ecb853',
  },
  settingsTextAdditional: {
    //width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  settingsTextActive: {
    //width: Platform.OS === 'web' ? '100px' : 100,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    backgroundColor: '#EDB854',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    padding: Platform.OS === 'web' ? '3.5px' : 3.5,
  },
  settingsTextActiveDark: {
    //width: Platform.OS === 'web' ? '100px' : 100,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //backgroundColor: '#EDB854',
    color: '#ecb853',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    padding: Platform.OS === 'web' ? '3.5px' : 3.5,
    borderWidth: 2,
    borderColor: '#ecb853',
  },
  settingsTextActiveDarkAvatar: {
    //width: Platform.OS === 'web' ? '100px' : 100,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '12px' : 12,
    //backgroundColor: '#EDB854',
    color: '#ecb853',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    padding: Platform.OS === 'web' ? '3.5px' : 3.5,
    borderWidth: 2,
    borderColor: '#ecb853',
  },
  settingsBackgroundColorIcon: {
    backgroundColor: '#BEBEBE',
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '75px' : 75,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorIconDark: {
    backgroundColor: '#000',
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '75px' : 75,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderWidth: 2,
    borderColor: '#ecb853',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorIconFlag: {
    backgroundColor: '#BEBEBE',
    width: Platform.OS === 'web' ? '100px' : 100,
    height: Platform.OS === 'web' ? '120px' : 120,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    paddingTop: Platform.OS === 'web' ? '5px' : 5,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorIconFlagDark: {
    backgroundColor: 'transparent',
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '75px' : 75,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '12px' : 12,
    //borderWidth: 2,
    //borderColor: '#ecb853',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorIconActive: {
    backgroundColor: '#EDB854',
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '75px' : 75,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorIconActiveDark: {
    borderColor: '#ecb853',
    borderWidth: 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '75px' : 75,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  logo: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '150px' : 150,
    height: Platform.OS === 'web' ? '150px' : 150,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '75px' : 75,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsIconStyleOtherDark: {
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '75px' : 75,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginTop: Platform.OS === 'web' ? '-8px' : -8,
  },
  settingsIconStyleDark: {
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '60px' : 60,
    //resizeMode: 'center',
    alignItems: 'center',
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: Platform.OS === 'web' ? '12px' : 12,
    //textAlign: 'center',
  },
  settingsIconCustomStyle: {
    marginLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  settingsIconCustomStyleActive: {
    marginLeft: Platform.OS === 'web' ? '4px' : 4,
  },
  settingsIconCustomStyleActiveOther: {
    marginLeft: Platform.OS === 'web' ? '12px' : 12,
  },
  settingsIconCustomStyleActiveOtherDark: {
    marginLeft: Platform.OS === 'web' ? '0px' : 0,
  },
  buttonMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#D05B5B',
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textButton: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textDark: {
    color: '#ecb853',
  },
});

export default DefaultOutput;
