import React, {useState, useEffect} from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  Dimensions,
} from 'react-native';
import {Dropdown} from 'react-native-element-dropdown';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {APP_LANGUAGE, CONTRAST_MODE_TYPE} from '../../constants/constants';
import {useTranslation} from 'react-i18next';
import {useIsFocused} from '@react-navigation/native';
import i18n from '../../localization/index';

/* Data filter */
const data = [
  {label: 'English', value: '1', code: 'en'},
  {label: 'Deutsch', value: '2', code: 'de'},
  {label: 'Français', value: '3', code: 'fr'},
  {label: 'Italiano', value: '4', code: 'it'},
  {label: 'Ελληνική', value: '5', code: 'el'},
  {label: 'Nederlands', value: '6', code: 'nl'},
  {label: 'Schweizer-Deutsch', value: '7', code: 'ch'},
];

/* Icons */
import IconArrow from './../../assets/iconarrow.png';
import FlagDE from './../../assets/flaggerman.png';
import FlagEN from './../../assets/flaggreatbritain.png';
import FlagFR from './../../assets/flagfrench.png';
import FlagIT from './../../assets/flagitaly.png';
import FlagGR from './../../assets/flaggreece.png';
import FlagDU from './../../assets/flagdutch.png';
import FlagCH from './../../assets/flagsuizze.png';

const DropdownComponent = ({style, color}) => {
  const [value, setValue] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));
  const {t} = useTranslation();
  const isFocused = useIsFocused();
  const renderLabel = () => {
    if (value || isFocus) {
      // return (
      //   <Text style={[styles.label, isFocus && {color: 'grey'}]}>
      //     {t('Language')}
      //   </Text>
      // );
      return null;
    }
    return null;
  };
  const dataTranslation = [
    {label: 'English', value: '1', code: 'en'},
    {label: 'Deutsch', value: '2', code: 'de'},
    {label: 'Français', value: '3', code: 'fr'},
    {label: 'Italiano', value: '4', code: 'it'},
    {label: 'Ελληνική', value: '5', code: 'el'},
    {label: 'Nederlands', value: '6', code: 'nl'},
    {label: 'Schweizer-Deutsch', value: '7', code: 'ch'},
  ];

  useEffect(() => {
    const intervalMode = setInterval(() => {
      const languageSelected = async () => {
        const getLanguageName = await AsyncStorage.getItem('APP_LANGUAGE');

        if (getLanguageName === '' || !getLanguageName) {
          setSelectedLanguage('en');
        } else if (getLanguageName === 'en') {
          setSelectedLanguage('en');
        } else if (getLanguageName === 'de') {
          setSelectedLanguage('de');
        } else if (getLanguageName === 'fr') {
          setSelectedLanguage('fr');
        } else if (getLanguageName === 'it') {
          setSelectedLanguage('it');
        } else if (getLanguageName === 'el') {
          setSelectedLanguage('el');
        } else if (getLanguageName === 'nl') {
          setSelectedLanguage('nl');
        } else {
          setSelectedLanguage('ch');
          //i18n.changeLanguage('ch');
        }
      };
      languageSelected();
    }, 1000);

    return () => {
      clearInterval(intervalMode);
    };
  }, [isFocused]);

  useEffect(() => {
    const getAppLanguage = async () => {
      const lang = await AsyncStorage.getItem(APP_LANGUAGE);
      console.log('App language: ' + lang);
      if (lang === undefined) {
        setValue(data[0]);
      } else {
        setValue(data.filter(item => item.code === lang)[0]);
        i18n.changeLanguage(lang);
      }
    };
    getAppLanguage();
  }, []);

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    checkDarkModeDefault();
  }, [isFocused]);

  const renderItem = item => {
    return (
      <View style={styles.item}>
        {item.value === '1' ? (
          <Image
            width={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            height={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            source={{
              uri: Platform.OS == 'web' ? FlagEN : 'flaggreatbritain',
            }}
            style={darkModeAvailable ? styles.logoDark : styles.logo}
            resizeMode="contain"
          />
        ) : item.value === '2' ? (
          <Image
            width={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            height={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            source={{
              uri: Platform.OS == 'web' ? FlagDE : 'flaggerman',
            }}
            style={darkModeAvailable ? styles.logoDark : styles.logo}
            resizeMode="contain"
          />
        ) : item.value === '3' ? (
          <Image
            width={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            height={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            source={{
              uri: Platform.OS == 'web' ? FlagFR : 'flagfrench',
            }}
            style={darkModeAvailable ? styles.logoDark : styles.logo}
            resizeMode="contain"
          />
        ) : item.value === '4' ? (
          <Image
            width={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            height={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            source={{
              uri: Platform.OS == 'web' ? FlagIT : 'flagitaly',
            }}
            style={darkModeAvailable ? styles.logoDark : styles.logo}
            resizeMode="contain"
          />
        ) : item.value === '5' ? (
          <Image
            width={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            height={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            source={{
              uri: Platform.OS == 'web' ? FlagGR : 'flaggreece',
            }}
            style={darkModeAvailable ? styles.logoDark : styles.logo}
            resizeMode="contain"
          />
        ) : item.value === '6' ? (
          <Image
            width={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            height={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            source={{
              uri: Platform.OS == 'web' ? FlagDU : 'flagdutch',
            }}
            style={darkModeAvailable ? styles.logoDark : styles.logo}
            resizeMode="contain"
          />
        ) : (
          <Image
            width={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            height={
              darkModeAvailable
                ? Platform.OS == 'web'
                  ? '20px'
                  : 20
                : Platform.OS == 'web'
                ? '20px'
                : 20
            }
            source={{
              uri: Platform.OS == 'web' ? FlagCH : 'flagsuizze',
            }}
            style={darkModeAvailable ? styles.logoDark : styles.logo}
            resizeMode="contain"
          />
        )}
        <Text style={styles.textItem}>{item.label}</Text>
      </View>
    );
  };

  return (
    <View
      style={dimensions.width > 991 ? styles.container : styles.containerSmall}
      accessible={true}>
      {renderLabel()}
      <Dropdown
        style={[
          darkModeAvailable ? styles.dropdownDark : styles.dropdown,
          isFocus && {borderColor: darkModeAvailable ? 'orange' : 'white'},
          style,
        ]}
        placeholderStyle={styles.placeholderStyle}
        selectedTextStyle={[
          styles.selectedTextStyle,
          {
            color:
              color === undefined
                ? darkModeAvailable
                  ? 'orange'
                  : 'white'
                : !darkModeAvailable
                ? 'black'
                : 'orange',
          },
        ]}
        inputSearchStyle={styles.inputSearchStyle}
        iconStyle={styles.iconStyle}
        data={dataTranslation}
        search={false}
        maxHeight={300}
        labelField="label"
        valueField="value"
        placeholder={!isFocus ? 'Select Language' : '...'}
        searchPlaceholder="Search..."
        value={value}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        onChange={item => {
          setValue(item.value);
          i18n.changeLanguage(item.code);
          AsyncStorage.setItem(APP_LANGUAGE, item.code);
          setIsFocus(false);
        }}
        renderItem={renderItem}
        renderLeftIcon={() => (
          <>
            <View style={styles.itemLanguage}>
              {selectedLanguage === '' || !selectedLanguage ? (
                <Image
                  width={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  height={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  source={{
                    uri: Platform.OS == 'web' ? FlagEN : 'flaggreatbritain',
                  }}
                  style={darkModeAvailable ? styles.logoDark : styles.logo}
                  resizeMode="contain"
                />
              ) : selectedLanguage === 'en' ? (
                <Image
                  width={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  height={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  source={{
                    uri: Platform.OS == 'web' ? FlagEN : 'flaggreatbritain',
                  }}
                  style={darkModeAvailable ? styles.logoDark : styles.logo}
                  resizeMode="contain"
                />
              ) : selectedLanguage === 'de' ? (
                <Image
                  width={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  height={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  source={{
                    uri: Platform.OS == 'web' ? FlagDE : 'flaggerman',
                  }}
                  style={darkModeAvailable ? styles.logoDark : styles.logo}
                  resizeMode="contain"
                />
              ) : selectedLanguage === 'fr' ? (
                <Image
                  width={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  height={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  source={{
                    uri: Platform.OS == 'web' ? FlagFR : 'flagfrench',
                  }}
                  style={darkModeAvailable ? styles.logoDark : styles.logo}
                  resizeMode="contain"
                />
              ) : selectedLanguage === 'it' ? (
                <Image
                  width={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  height={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  source={{
                    uri: Platform.OS == 'web' ? FlagIT : 'flagitaly',
                  }}
                  style={darkModeAvailable ? styles.logoDark : styles.logo}
                  resizeMode="contain"
                />
              ) : selectedLanguage === 'el' ? (
                <Image
                  width={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  height={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  source={{
                    uri: Platform.OS == 'web' ? FlagGR : 'flaggreece',
                  }}
                  style={darkModeAvailable ? styles.logoDark : styles.logo}
                  resizeMode="contain"
                />
              ) : selectedLanguage === 'nl' ? (
                <Image
                  width={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  height={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  source={{
                    uri: Platform.OS == 'web' ? FlagDU : 'flagdutch',
                  }}
                  style={darkModeAvailable ? styles.logoDark : styles.logo}
                  resizeMode="contain"
                />
              ) : (
                <Image
                  width={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  height={
                    darkModeAvailable
                      ? Platform.OS == 'web'
                        ? '20px'
                        : 20
                      : Platform.OS == 'web'
                      ? '20px'
                      : 20
                  }
                  source={{
                    uri: Platform.OS == 'web' ? FlagCH : 'flagsuizze',
                  }}
                  style={darkModeAvailable ? styles.logoDark : styles.logo}
                  resizeMode="contain"
                />
              )}
            </View>
          </>
        )}
      />
    </View>
  );
};

export default DropdownComponent;
const styles = StyleSheet.create({
  container: {
    width: 220,
  },
  containerSmall: {
    width: 180,
  },
  item: {
    flexDirection: 'row',
    padding: Platform.OS === 'web' ? '10px' : 10,
  },
  itemLanguage: {
    paddingRight: Platform.OS === 'web' ? '2px' : 2,
    paddingLeft: Platform.OS === 'web' ? '7px' : 7,
  },
  textItem: {
    marginTop: Platform.OS === 'web' ? '1.5px' : 1.5,
  },
  dropdown: {
    height: 40,
    borderColor: 'white',
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
    marginRight: 8,
  },
  dropdownDark: {
    height: 40,
    borderColor: '#ecb853',
    borderWidth: 0.5,
    borderRadius: 8,
    paddingHorizontal: 8,
    marginRight: 8,
  },
  icon: {
    marginRight: 5,
  },
  label: {
    position: 'absolute',
    backgroundColor: '#D05B5B',
    left: 22,
    top: 8,
    zIndex: 999,
    paddingHorizontal: 8,
    fontSize: 12,
    color: 'white',
  },
  placeholderStyle: {
    fontSize: 12,
  },
  selectedTextStyle: {
    fontSize: 14,
    color: 'white',
    textAlign: 'center',
    padding: 8,
  },
  iconStyle: {
    width: 20,
    height: 20,
  },
  inputSearchStyle: {
    height: 40,
    fontSize: 12,
  },
  logo: {
    width: Platform.OS === 'web' ? '20px' : 20,
    height: Platform.OS === 'web' ? '20px' : 20,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginRight: Platform.OS === 'web' ? '10px' : 10,
  },
  logoDark: {
    width: Platform.OS === 'web' ? '20px' : 20,
    height: Platform.OS === 'web' ? '20px' : 20,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginRight: Platform.OS === 'web' ? '10px' : 10,
  },
});
