/**
 * Home
 */

import React, {useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Button,
  Platform,
  Pressable,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useTranslation} from 'react-i18next';

const About = ({navigation}): Node => {
  const {t, i18n} = useTranslation();
  return (
    <ScrollView style={styles.mainBackgroundColor} accessible={true}>
      <View style={styles.mainMargin} accessible={true}>
        <Button
          title={t('Registration')}
          /*color={Platform.OS === 'web' ? '#ccc' : '#2296F3'}*/
          onPress={() => navigation.navigate('Registration')}
          style={styles.button}
          accessible={true}
          accessibilityLabel={t('Registration')}
          accessibilityHint="Registration validation"
        />
        <Button
          title="Back"
          onPress={() => navigation.goBack()}
          accessible={true}
          accessibilityLabel="Back"
          accessibilityHint="Navigate back"
        />
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  button: {
    backgroundColor: '#cc0000',
    color: '#fff',
  },
});

export default About;
