/**
 * Info
 */

import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Platform,
  Linking,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Logo from './../../assets/easierlogowithtext.png';
import LogoDark from './../../assets/easierlogowithtextdark.png';
import IconArrow from './../../assets/iconarrow.png';
import EUFlag from './../../assets/flageurope.png';
import {
  MAIN_TEXT,
  SUB_TEXT,
  FOUNDED_TEXT,
  LINK_TEXT,
  LINK_WEBSITE,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
} from '../../constants/constants';
import {useIsFocused} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';
const Info = ({navigation}): Node => {
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();
  const {t} = useTranslation();

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  const PrivacyData = async () => {
    await AsyncStorage.setItem('@PrivacyWay', 'Other');
    await navigation.navigate('Privacy');
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel="Logo of EASIER">
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? LogoDark
                    : Logo
                  : darkModeAvailable
                  ? 'easierlogowithtextdark'
                  : 'easierlogowithtext',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <ScrollView style={styles.userDataContainer} accessible={true}>
          <View
            style={styles.userDataInfo}
            accessible={true}
            accessibilityLabel={t('MAIN_TEXT')}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textInfoNormalDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textInfoNormal,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('MAIN_TEXT')}
            </Text>
          </View>
          <View
            style={styles.userDataInfo}
            accessible={true}
            accessibilityLabel={t('SUB_TEXT')}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textInfoNormalDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textInfoNormal,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('SUB_TEXT')}
            </Text>
          </View>
        </ScrollView>
        <View accessible={true} accessibilityLabel={t('LINK_TEXT')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textLinkLeftDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.textLinkLeft,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }
            onPress={PrivacyData}>
            {t('Privacy')}
          </Text>
        </View>
        <View accessible={true} accessibilityLabel={t('LINK_TEXT')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textLinkDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.textLink,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }
            onPress={() => {
              Linking.openURL(LINK_WEBSITE);
            }}>
            {LINK_TEXT}
          </Text>
        </View>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel="Logo of European flag">
          <Image
            width={Platform.OS == 'web' ? '50px' : 50}
            height={Platform.OS == 'web' ? '50px' : 50}
            source={{
              uri: Platform.OS == 'web' ? EUFlag : 'flageurope',
            }}
            style={styles.europeFlag}
            resizeMode="contain"
          />
        </View>
        <View accessible={true} accessibilityLabel={t('FOUNDED_TEXT')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textInfoDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.textInfo,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('FOUNDED_TEXT')}
          </Text>
        </View>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  textLink: {
    color: '#D05B5B',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
  },
  textLinkDark: {
    color: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
  },
  textLinkLeft: {
    color: '#D05B5B',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
  },
  textLinkLeftDark: {
    color: '#ecb853',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
  },
  textInfoNormal: {
    color: '#666666',
  },
  textInfoNormalDark: {
    color: '#ecb853',
  },
  textInfo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    color: '#666666',
  },
  textInfoDark: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    color: '#ecb853',
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  userDataContainer: {
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    marginBottom: Platform.OS === 'web' ? '30px' : 30,
    marginTop: Platform.OS === 'web' ? '-30px' : -30,
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
    paddingTop: Platform.OS === 'web' ? '20px' : 20,
    maxHeight: Platform.OS === 'web' ? '250px' : 250,
  },
  userDataInfo: {
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '30px' : 30,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  userDataTextMore: {
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    color: '#666666',
    fontSize: Platform.OS === 'web' ? '16px' : 16,
    fontWeight: '600',
  },
  equalLine: {
    flexDirection: 'row',
    opacity: Platform.OS === 'web' ? '1' : 1,
  },
  equalLineHidden: {
    flexDirection: 'row',
    opacity: Platform.OS === 'web' ? '0' : 0,
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  europeFlag: {
    width: Platform.OS === 'web' ? '50px' : 50,
    height: Platform.OS === 'web' ? '50px' : 50,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  backArrow: {
    width: Platform.OS === 'web' ? '20px' : 20,
    height: Platform.OS === 'web' ? '20px' : 20,
    transform: [{rotate: '180deg'}],
  },
  backText: {},
  button: {
    backgroundColor: '#cc0000',
    color: '#fff',
  },
  menuMainUser: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    top: Platform.OS === 'web' ? '0px' : 0,
    right: Platform.OS === 'web' ? '20px' : 20,
    position: 'absolute',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 3px 0px #000',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,
    elevation: 11,
    border: '1px solid #ccc',
    padding: Platform.OS === 'web' ? '20px' : 20,
  },
});

export default Info;
