export default {
  login: 'Inloggen',
  Status_Login: 'Status inloggen',
  Translation: 'Vertaling',
  Translation_Output: 'Output vertaling',
  Registration: 'Registratie',
  Password_Info:
    'Wachtwoord moet minstens 8 tekens lang zijn met een hoofdletter en een leesteken.',
  Reset_password: 'Wachtwoord resetten',
  New_Password: 'Nieuw wachtwoord',
  About: 'Over',
  Start: 'Start',
  Logout: 'Uitloggen',
  Profile: 'Profiel',
  Info: 'Info',
  Settings: 'Instellingen',
  Archive: 'Archief',
  Default_Input: 'Algemene ingang',
  Default_Output: 'Algemene uitgang',
  Voice_settings: 'Instellingen spraak',
  Contrast: 'Contrast',
  Avatar_settings: 'Instellingen avatar',
  Languages_Settings: 'Instellingen taal',
  Upload_Video: 'Video uploaden',
  Choose: 'Kies',
  Select_Output_Method: 'Selecteer uitvoermethode',
  Select_Output_Language: 'Selecteer uitvoertaal',
  Select_Input_Language: 'Selecteer invoertaal',
  Submit: 'Verzenden',
  Preview_Video_Upload: 'Het geüploade videobestand wordt hier weergegeven',
  Input_Method: 'Invoermethode',
  Spoken_Language: 'Gesproken taal',
  Spoken_Language_Settings: 'Instellingen gesproken taal',
  Write: 'Schrijven',
  Record_speech: 'Spraak opnemen',
  Record_content: 'Inhoud opnemen',
  Video_input: 'Video-invoer',
  Record_Video: 'Video opnemen',
  Output_Method: 'Uitvoermethode',
  Language: 'Taal',
  Back: 'Terug',
  All: 'Alle',
  Speech: 'Spraak',
  Text: 'Tekst',
  Please_enter_valid_email: 'Voer een geldig e-mailadres in',
  Email_Address_is_Required: 'E-mailadres is vereist',
  Password_is_required: 'Wachtwoord is vereist',
  Welcome_to: 'Welkom bij',
  E_Mail: 'E-mail',
  Email_is_not_correct: 'E-mail is niet correct',
  Password: 'Wachtwoord',
  Password_Repeat: 'Wachtwoord herhalen',
  Password_is_not_correct: 'Het wachtwoord is niet correct',
  Email_or_password_is_not_correct: 'Email of wachtwoord is onjuist',
  Username_empty: 'Gebruikersnaam mag niet leeg zijn.',
  Email_empty: 'E-mail mag niet leeg zijn.',
  Email_exists: 'E-mail bestaat al.',
  Password_empty: 'Wachtwoord mag niet leeg zijn.',
  Password_not_match: 'Wachtwoord komt niet overeen.',
  No_data_available: 'Geen gegevens beschikbaar',
  Password_security_rules:
    'Wachtwoord komt niet overeen met de beveiligingsregels.',
  MAIN_TEXT:
    'EASIER is een Horizon 2020-project dat zich richt op het ontwerpen, ontwikkelen en valideren van een compleet meertalig machinevertalingssysteem dat zal fungeren als een raamwerk voor barrièrevrije communicatie tussen doven en horenden, en tevens een platform zal bieden ter ondersteuning van het creëren van gebarentaalcontent.',
  SUB_TEXT:
    'Het project voorziet in een sterke betrokkenheid van eindgebruikers bij het ontwerp, de ontwikkeling en de evaluatie van het EASIER-ecosysteem, waar gebruikers van verschillende Europese gebarentalen en gesproken talen in staat zullen zijn om hun voorkeurstaal te gebruiken om met elkaar te communiceren.',
  FOUNDED_TEXT:
    'Het EASIER-project wordt gefinancierd door het Horizon 2020-programma van de EU onder subsidieovereenkomstnummer 101016982.',
  Current_password: 'Huidig wachtwoord',
  New_password: 'Nieuw wachtwoord',
  New_password_is_invalid: 'Het nieuwe wachtwoord is ongeldig',
  code: 'Code',
  code_invalid: 'Code is ongeldig.',
  mailbox_check: 'Controleer je inbox.',
  Save: 'Opslaan',
  Name: 'Naam',
  ID: 'ID',
  Delete_account: 'Account verwijderen',
  Username_is_Required: 'Gebruikersnaam is verplicht',
  Username: 'Gebruikersnaam',
  Username_is_invalid: 'Gebruikersnaam is ongeldig',
  Email_is_invalid: 'E-mail is ongeldig',
  Password_is_invalid: 'Wachtwoord is ongeldig',
  Back_to_Log_In: 'Terug naar Aanmelden',
  Background: 'Achtergrond',
  Clothing: 'Kleding',
  Gender: 'Geslacht',
  Dark_Mode: 'Donkere modus',
  Text_size: 'Tekstgrootte',
  Camera: 'Teken',
  Adapt_intonation: 'Aanpassen intonatie',
  Voice_style: 'Stemstijl',
  Language_Level: 'Taalniveau',
  Play_Audio:
    'Klik op het pictogram om het opgenomen audiobestand af te spelen',
  Accent: 'Accent',
  Voice: 'Stem',
  Recording: 'Opname',
  Logged_in_as: 'Ingelogd als:',
  START: 'START',
  Use_favorite_settings: 'Favoriete instellingen gebruiken',
  Failure: 'Storing',
  No_default_input_and_output:
    'Er is geen standaard in- en uitvoer ingesteld. Ga eerst naar het instellingenmenu.',
  Go_to_settings: 'Ga naar instellingen',
  OK: 'Ok',
  Status: 'Status',
  Signing_in: 'Aanmelden...',
  Translation_in_progress: 'Vertaling bezig...',
  Input: 'Invoer',
  Recording_time: 'Opname-tijd',
  Delete: 'Verwijderen',
  Copy: 'Kopiëren',
  Share: 'Delen',
  NEXT: 'VOLGENDE',
  Activate_your_camera: 'Activeer je camera voor het opnemen van een video',
  Activate_camera: 'Camera activeren',
  Select_video_file:
    'Selecteer een videobestand dat op je toestel is opgeslagen',
  Open_video_file: 'Videobestand openen',
  Video_recording_element: 'Video-opname-element',
  Choose_Language: 'Taal kiezen',
  Tap_and_write: 'Tikken en schrijven',
  Clear_text: 'Tekst verwijderen?',
  NO: 'NEE',
  YES: 'JA',
  Clear: 'Wissen',
  Paste: 'Plakken',
  TRANSLATE: 'VERTALEN',
  Output: 'Uitvoer',
  Audio_Input: 'Audio-invoer',
  Text_Input: 'Tekst-invoer',
  Camera_Input: 'Camera-invoer',
  Audio_Output: 'Audio-uitvoer',
  Text_Output: 'Tekstuitvoer',
  Avatar_Output: 'Avatar-uitvoer',
  Audio_Play: 'Audiospeler',
  Camera_Play: 'Videospeler',
  BACK_TO_START_MENU: 'TERUG NAAR STARTMENU',
  Avatar_Language_Unavailable:
    'Avatar is momenteel niet beschikbaar in die taal.',
  English: 'Engelsk',
  German: 'Tysk',
  French: 'Fransk',
  Italian: 'Italiensk',
  Greek: 'græsk',
  Dutch: 'hollandsk',
  Suizze_German: 'Zwitsers-Duits',
  Record_Text: 'Opname bezig',
  video_one: 'Klik op het opname-icoon om de opname te activeren',
  video_two: 'Klik op de witte knop links van de timer voor start en stop',
  New_translation: 'Nieuwe vertaling',
  Appearance: 'Verschijning',
  Session_Old: 'Sessie zijn oud. Log opnieuw in.',
  Unable_Service_Message:
    'Er heeft zich een probleem voorgedaan of de service is momenteel niet beschikbaar.',
  no_archives: 'Geen archieven beschikbaar',
  Archive_Detail: 'Archief Detail',
  Delete_all: 'Alles verwijderen',
  Delete_all_archive: 'Wil je alle archieven verwijderen?',
  Delete_one_archive: 'Wil je dit archief verwijderen?',
  Delete_user_account: 'Wil je je account verwijderen?',
  Log_title: 'Log',
  Affect_Fusion: 'Affect Fusion Resultaat',
  Intermediate_translation: 'Intermediaire vertaling',
  Translation_input_from_text: 'Vertaalinput van tekst',
  Translation_input_from_speech: 'Vertaalinput uit spraak',
  Translation_input_from_sign_video: 'Vertaalinput van gebarenvideo',
  Translation_output_to_text: 'Vertaaluitvoer naar tekst',
  Translation_output_to_speech: 'Vertaaluitvoer naar spraak',
  Translation_output_to_avatar: 'Vertaaluitvoer naar avatar',
  Privacy: 'Privacybeleid',
  Privacy_Title: 'Met de registratie accepteert u het',
  Privacy_Text: 'Privacybeleid',
  Privacy_is_required: 'Privacy is vereist',
  Introduction: 'Inleiding',
  Introduction_One:
    'EASIER is een Horizon 2020-project dat zich richt op het ontwerpen, ontwikkelen en valideren van een compleet meertalig machinevertaalsysteem dat een kader biedt voor toegankelijke communicatie tussen doven en horenden, evenals een platform ter ondersteuning van het creëren van inhoud in gebarentaal.',
  Introduction_Two:
    'Het project steunt op een sterke betrokkenheid van de eindgebruikers bij het ontwerp, de ontwikkeling en de evaluatie van het EASIER-platform, waar gebruikers van verschillende Europese gebarentalen en gesproken talen in staat zullen zijn om de taal van hun voorkeur te gebruiken om met elkaar te communiceren.',
  Introduction_Three:
    'Het EASIER-project wordt gefinancierd door het Horizon 2020-programma van de EU onder het subsidieovereenkomstnummer 101016982.',
  Privacy_Overview: 'Gegevensbescherming in één oogopslag',
  General_Information: 'Algemene informatie',
  General_Information_One:
    'De volgende opmerkingen geven een eenvoudig overzicht van wat er met uw persoonlijke gegevens gebeurt wanneer u deze app gebruikt. Persoonlijke gegevens zijn alle gegevens die kunnen worden gebruikt om u persoonlijk te identificeren. Voor gedetailleerde informatie over gegevensbescherming.',
  General_Information_Two:
    'Onze verklaring over gegevensbescherming onder deze tekst.',
  Data_Collection: 'Gegevensverzameling op deze app',
  Data_Collection_One:
    'Wie is verantwoordelijk voor het verzamelen van gegevens op deze app?',
  Data_Collection_Two:
    'De gegevensverwerking in deze app wordt uitgevoerd door de aanbieder van de app. Je vindt hun contactgegevens in het gedeelte "Kennisgeving over de verantwoordelijke partij" van dit privacybeleid.',
  Getting_Data: 'Hoe verzamelen we uw gegevens?',
  Getting_Data_One:
    'Enerzijds worden uw gegevens verzameld doordat u ze aan ons verstrekt. Dit kunnen bijvoorbeeld gegevens zijn die u invoert tijdens registratie of gebruik.',
  Getting_Data_Two:
    'Andere gegevens worden automatisch of na uw toestemming verzameld wanneer u de app gebruikt via uw browser door onze IT-systemen. Dit zijn voornamelijk technische gegevens (bijv. internetbrowser, besturingssysteem of tijdstip van toegang tot de app). Deze gegevens worden automatisch verzameld zodra u de app gebruikt.',
  Using_Data: 'Waar gebruiken we je gegevens voor?',
  Using_Data_One:
    'Gegevens worden verzameld om een foutloze levering van de app te garanderen. Inloggegevens worden gebruikt om je berichten en hun vertaling alleen voor jou toegankelijk te maken. De berichten die je invoert of opneemt worden verwerkt voor vertaling en zijn alleen voor jou toegankelijk in het archief.',
  Delete_Data: 'Verwijdering van gegevens',
  Delete_Data_One:
    'De in het systeem verwerkte gegevens worden na afloop van het EASIER-project verwijderd. De verwijdering zal uiterlijk in juli 2024 plaatsvinden.',
  Delete_Data_Two:
    'Je kunt je gegevens in de app zelf verwijderen op elk gewenst moment voor deze datum.',
  Delete_Data_Three:
    '- In het menu Archief kun je individuele of alle berichten verwijderen.',
  Delete_Data_Four:
    '- In het menuonderdeel Profiel kun je je account met alle gegevens verwijderen.',
  Rights_Data: 'Welke rechten hebt u met betrekking tot uw gegevens?',
  Rights_Data_One:
    'U hebt te allen tijde het recht om kosteloos informatie te ontvangen over de herkomst, de ontvanger en het doel van uw opgeslagen persoonlijke gegevens. U hebt ook het recht om correctie of verwijdering van deze gegevens aan te vragen. Als u toestemming hebt gegeven voor gegevensverwerking, kunt u deze toestemming te allen tijde intrekken voor de toekomst. U hebt ook het recht om onder bepaalde omstandigheden de beperking van de verwerking van uw persoonsgegevens te vragen. Bovendien hebt u het recht om een klacht in te dienen bij de bevoegde toezichthoudende autoriteit.',
  Rights_Data_Two:
    'U kunt te allen tijde contact met ons opnemen over deze en andere vragen met betrekking tot gegevensbescherming.',
  Hosting: 'Hosting- en partnersystemen',
  Hosting_One:
    'De volgende aanbieders worden gebruikt op basis van Art. 6 Para. 1 lit. f DSGVO. Wij hebben een gerechtvaardigd belang bij de meest betrouwbare presentatie en werking van onze app. Als er een overeenkomstige toestemming is gevraagd, wordt de verwerking uitsluitend uitgevoerd op basis van art. 6 lid 1 letter a DSGVO en § 25 lid 1 TTDSG, voor zover de toestemming de opslag van cookies of de toegang tot informatie op het eindapparaat van de gebruiker omvat (bijv. device fingerprinting) zoals gedefinieerd door de TTDSG. De toestemming kan op elk moment worden ingetrokken.',
  Hetzner: 'Hetzner',
  Hetzner_One: 'We hosten de app bij de volgende provider:',
  Hetzner_Two:
    'De provider is Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (hierna Hetzner).',
  Hetzner_Three:
    'Raadpleeg voor meer informatie het privacybeleid van Hetzner: https://www.hetzner.com/de/rechtliches/datenschutz.',
  AWS: 'Amazon Web Services',
  AWS_One:
    'We hosten componenten voor videoanalyse en automatische vertaling bij de volgende provider:',
  AWS_Two:
    'De serviceprovider is het Amerikaanse bedrijf Amazon Web Services, Inc, 410 Terry Avenue North, Seattle WA 98109, VS.',
  AWS_Three: 'https://aws.amazon.com/de/compliance/data-privacy/',
  Process_Order: 'Orderverwerking',
  Process_Order_One:
    'Voor het gebruik van de bovengenoemde diensten hebben we met de aanbieders een overeenkomst voor orderverwerking (AVV) gesloten. Dit is een contract dat vereist is door de wet op gegevensbescherming en dat garandeert dat deze laatste de persoonlijke gegevens van onze app-gebruikers alleen verwerkt in overeenstemming met onze instructies en in overeenstemming met de GDPR.',
  General_Notes: 'Algemene opmerkingen en verplichte informatie',
  Data_Protection: 'Gegevensbescherming',
  Data_Protection_One:
    'De beheerders van deze app nemen de bescherming van uw persoonlijke gegevens zeer serieus. Wij behandelen uw persoonlijke gegevens vertrouwelijk en in overeenstemming met de wettelijke voorschriften inzake gegevensbescherming en dit privacybeleid.',
  Data_Protection_Two:
    'Wanneer u deze app gebruikt, worden verschillende persoonlijke gegevens verzameld. Persoonlijke gegevens zijn gegevens die kunnen worden gebruikt om u persoonlijk te identificeren. Dit privacybeleid legt uit welke gegevens we verzamelen en waarvoor we ze gebruiken.Het legt ook uit hoe dit gebeurt en met welk doel.',
  Data_Protection_Three:
    'We willen erop wijzen dat gegevensoverdracht op het internet (bijvoorbeeld bij communicatie per e-mail) veiligheidsleemten kan vertonen. Volledige bescherming van gegevens tegen toegang door derden is niet mogelijk.',
  Responsible_Body: 'Opmerking over de verantwoordelijke',
  Responsible_Body_One:
    'De verantwoordelijke partij voor de gegevensverwerking in deze app is:',
  Responsible_Body_Two:
    'De voor de verwerking verantwoordelijke is de natuurlijke of rechtspersoon die alleen of samen met anderen het doel van en de middelen voor de verwerking van persoonsgegevens (bijv. namen, e-mailadressen, enz.) vaststelt.',
  Phone: 'Telefoon',
  Email: 'E-mail',
  City: 'Keulen',
  Storage_Period: 'Bewaartermijn',
  Storage_Period_One:
    'Tenzij binnen dit privacybeleid een specifiekere bewaartermijn is gespecificeerd, blijven uw persoonsgegevens bij ons totdat het doel voor de verwerking van de gegevens niet langer van toepassing is. Als u een gerechtvaardigd verzoek tot verwijdering indient of uw toestemming voor gegevensverwerking intrekt, worden uw gegevens verwijderd, tenzij we andere wettelijk toegestane redenen hebben om uw persoonsgegevens te bewaren (bijv. bewaartermijnen op grond van belasting- of handelswetgeving); in het laatste geval vindt verwijdering plaats nadat deze redenen niet langer van toepassing zijn.',
  Storage_Period_Two: 'Zie ook "Verwijdering van gegevens" in sectie 1.',
  General_Information_Legal:
    'Algemene informatie over de rechtsgrondslag voor gegevensverwerking in deze app',
  General_Information_Legal_One:
    'Als u toestemming hebt gegeven voor gegevensverwerking, verwerken wij uw persoonsgegevens op basis van art. 6 (1) lit. a DSGVO of art. 9 (2) lit. a DSGVO als bijzondere categorieën van gegevens worden verwerkt op grond van art. 9 (1) DSGVO. In het geval van uitdrukkelijke toestemming voor de overdracht van persoonsgegevens naar derde landen, wordt de gegevensverwerking ook uitgevoerd op basis van art. 49 (1) a DSGVO. Als u toestemming hebt gegeven voor de opslag van cookies of voor de toegang tot informatie op uw eindapparaat (bijv. via apparaatfingerprinting), wordt de gegevensverwerking bovendien uitgevoerd op basis van art. 25 (1) AVG. Deze toestemming kan te allen tijde worden ingetrokken. Als uw gegevens nodig zijn voor de uitvoering van een contract of voor de uitvoering van precontractuele maatregelen, verwerken wij uw gegevens op basis van art. 6 lid 1 lit. b DSGVO. Als uw gegevens bovendien nodig zijn om aan een wettelijke verplichting te voldoen, verwerken wij deze op basis van art. 6 lid 1 letter c DSGVO. Verder kan de gegevensverwerking worden uitgevoerd op basis van ons gerechtvaardigd belang volgens Art. 6 para. 1 lit. f DSGVO. Informatie over de relevante rechtsgrondslag in elk afzonderlijk geval wordt verstrekt in de volgende paragrafen van deze gegevensbeschermingsverklaring.',
  Revocation_Data: 'Intrekking van uw toestemming voor gegevensverwerking',
  Revocation_Data_One:
    'Veel gegevensverwerkingen zijn alleen mogelijk met uw uitdrukkelijke toestemming. U kunt uw reeds gegeven toestemming te allen tijde herroepen. De rechtmatigheid van de gegevensverwerking die is uitgevoerd tot de herroeping blijft onaangetast door de herroeping.',
  Rights_Collection_Data:
    'Recht om bezwaar te maken tegen gegevensverzameling (art. 21 DSGVO)',
  Rights_Collection_Data_One:
    'ALS DE GEGEVENSVERWERKING WORDT UITGEVOERD OP BASIS VAN ART. 6 ABS. 1 LIT. E OF F DSGVO, HEBT U HET RECHT OM TE ALLEN TIJDE BEZWAAR TE MAKEN TEGEN DE VERWERKING VAN UW PERSOONSGEGEVENS OM REDENEN DIE VOORTVLOEIEN UIT UW BIJZONDERE SITUATIE. DE RESPECTIEVE RECHTSGRONDSLAG WAAROP DE VERWERKING IS GEBASEERD, IS TE VINDEN IN DEZE GEGEVENSBESCHERMINGSVERKLARING. ALS U BEZWAAR MAAKT, ZULLEN WIJ UW PERSOONSGEGEVENS NIET LANGER VERWERKEN, TENZIJ WIJ DWINGENDE LEGITIEME GRONDEN VOOR DE VERWERKING KUNNEN AANTONEN DIE ZWAARDER WEGEN DAN UW BELANGEN, RECHTEN EN VRIJHEDEN, OF DE VERWERKING DIENT VOOR HET DOEN GELDEN, UITOEFENEN OF VERDEDIGEN VAN RECHTSVORDERINGEN (BEZWAAR OP GROND VAN ARTIKEL 21, LID 1, DSGVO).',
  Right_Of_Appeal:
    'Recht van beroep bij de bevoegde toezichthoudende autoriteit',
  Right_Of_Appeal_One:
    'In geval van inbreuken op de GDPR hebben betrokkenen het recht om in beroep te gaan bij een toezichthoudende autoriteit, met name in de lidstaat van hun gewone verblijfplaats, hun werkplek of de plaats van de vermeende inbreuk. Het recht van beroep doet geen afbreuk aan enig ander administratief of gerechtelijk rechtsmiddel.',
  Right_Of_Portability: 'Recht op gegevensoverdraagbaarheid',
  Right_Of_Portability_One:
    'U hebt het recht om gegevens die wij automatisch verwerken op basis van uw toestemming of ter uitvoering van een overeenkomst, in een gangbaar, machineleesbaar formaat aan u of aan een derde te laten overdragen. Als u verzoekt om rechtstreekse overdracht van de gegevens aan een andere verantwoordelijke partij, zal dit alleen worden gedaan voor zover dit technisch haalbaar is.',
  Information_Correction_Deletion: 'Informatie, correctie en verwijdering',
  Information_Correction_Deletion_One:
    'Binnen het kader van de toepasselijke wettelijke bepalingen hebt u te allen tijde recht op gratis informatie over uw opgeslagen persoonsgegevens, de herkomst en ontvanger ervan en het doel van de gegevensverwerking en, indien van toepassing, recht op correctie of verwijdering van deze gegevens. U kunt te allen tijde contact met ons opnemen over deze en andere vragen met betrekking tot persoonsgegevens.',
  Information_Correction_Deletion_Two:
    'U kunt uw gegevens in de app op elk gewenst moment zelf verwijderen, zie "Verwijderen van gegevens" in sectie 2.',
  Right_Restriction_Processing: 'Recht op beperking van de verwerking',
  Right_Restriction_Processing_One:
    'U hebt het recht om de beperking van de verwerking van uw persoonsgegevens aan te vragen. Hiervoor kunt u te allen tijde contact met ons opnemen. Het recht op beperking van de verwerking bestaat in de volgende gevallen:',
  Right_Restriction_Processing_List_One:
    '- Als u de juistheid van uw door ons opgeslagen persoonsgegevens betwist, hebben we meestal tijd nodig om dit te controleren. Voor de duur van de controle hebt u het recht om de beperking van de verwerking van uw persoonlijke gegevens aan te vragen.',
  Right_Restriction_Processing_List_Two:
    '- Als de verwerking van uw persoonlijke gegevens op onwettige wijze is/wordt uitgevoerd, kunt u verzoeken om beperking van de gegevensverwerking in plaats van verwijdering.',
  Right_Restriction_Processing_List_Three:
    '- Als we uw persoonlijke gegevens niet langer nodig hebben, maar u deze nodig hebt om juridische claims uit te oefenen, te verdedigen of te doen gelden, hebt u het recht om beperking van de verwerking van uw persoonlijke gegevens te vragen in plaats van verwijdering.',
  Right_Restriction_Processing_Two:
    'Als u bezwaar hebt gemaakt op grond van art. 21 (1) DSGVO, moet er een afweging worden gemaakt tussen uw belangen en de onze. Zolang nog niet is vastgesteld wiens belangen prevaleren, hebt u het recht om beperking van de verwerking van uw persoonsgegevens te eisen.',
  Right_Restriction_Processing_Three:
    'Als u de verwerking van uw persoonsgegevens hebt beperkt, mogen deze gegevens - afgezien van opslag - alleen worden verwerkt met uw toestemming of voor de vaststelling, uitoefening of verdediging van rechtsvorderingen of voor de bescherming van de rechten van een andere natuurlijke of rechtspersoon of om redenen van zwaarwegend algemeen belang van de Europese Unie of een lidstaat.',
  SSL_TLS: 'SSL- of TLS-codering',
  SSL_TLS_One:
    'Om veiligheidsredenen en ter bescherming van de overdracht van vertrouwelijke inhoud, zoals bestellingen of aanvragen die u naar ons als sitebeheerder stuurt, maakt deze site gebruik van SSL- of TLS-codering. U kunt een versleutelde verbinding herkennen aan het feit dat de adresregel van de browser verandert van "http://" in "https://" en aan het slotsymbool in uw browserregel.',
  SSL_TLS_Two:
    'Als SSL- of TLS-codering is geactiveerd, kunnen de gegevens die u naar ons verzendt niet door derden worden gelezen.',
  Data_Collection_App: 'Gegevensverzameling in deze app',
  Cookies: 'Cookies',
  Cookies_One:
    "Onze internetpagina's maken gebruik van zogenaamde 'cookies'. Cookies zijn kleine gegevenspakketjes die geen schade veroorzaken aan uw eindapparaat. Ze worden tijdelijk voor de duur van een sessie (sessiecookies) of permanent (permanente cookies) op je eindapparaat opgeslagen. Sessiecookies worden automatisch verwijderd aan het einde van uw bezoek. Permanente cookies (bijv. app-instellingen) blijven opgeslagen op je eindapparaat totdat je ze zelf verwijdert of totdat ze automatisch worden verwijderd door je webbrowser.",
  Cookies_Two:
    'Cookies kunnen van ons afkomstig zijn (first-party cookies) of van derde partijen (zogenaamde third-party cookies). Cookies van derden worden niet gebruikt.',
  Cookies_Three:
    'De cookies zijn technisch noodzakelijk voor het gebruik van de app om de veiligheid van een sessie te garanderen en de werking van de app te garanderen. Er vindt geen evaluatie van gebruikersgedrag plaats.',
  Cookies_Four:
    'Cookies die noodzakelijk zijn om het elektronische communicatieproces uit te voeren, om bepaalde door u gevraagde functies te leveren (noodzakelijke cookies), worden opgeslagen op basis van art. 6 (1) lit. f DSGVO, tenzij een andere wettelijke basis is gespecificeerd. De exploitant van de app heeft een gerechtvaardigd belang bij het opslaan van noodzakelijke cookies voor de technisch foutloze levering van zijn diensten. Voor zover toestemming is gevraagd voor de opslag van cookies en vergelijkbare herkenningstechnologieën, vindt verwerking uitsluitend plaats op basis van deze toestemming (art. 6 lid 1 letter a DSGVO en § 25 lid 1 TTDSG); toestemming kan te allen tijde worden ingetrokken.',
  Cookies_Five:
    'U kunt uw gegevens in de app te allen tijde zelf verwijderen, zie "Verwijderen van gegevens" in sectie 2.',
  Cookies_Six:
    'U kunt uw browser zo instellen dat u wordt geïnformeerd over het plaatsen van cookies en cookies alleen in individuele gevallen toestaan, de acceptatie van cookies voor bepaalde gevallen of in het algemeen uitsluiten en de automatische verwijdering van cookies bij het sluiten van de browser activeren. Als cookies worden uitgeschakeld, kan de functionaliteit van deze app beperkt zijn. In dit privacybeleid kunt u lezen welke cookies en diensten in deze app worden gebruikt.',
  Enquiry: 'Aanvraag per e-mail, telefoon of fax',
  Enquiry_One:
    'Als u per e-mail, telefoon of fax contact met ons opneemt, worden uw aanvraag en alle daaruit voortvloeiende persoonlijke gegevens (naam, aanvraag) door ons opgeslagen en verwerkt om uw aanvraag te verwerken. Wij geven deze gegevens niet door zonder uw toestemming.',
  Enquiry_Two:
    'De verwerking van deze gegevens is gebaseerd op art. 6 (1) lit. b DSGVO als uw verzoek verband houdt met de uitvoering van een contract of noodzakelijk is voor de uitvoering van precontractuele maatregelen. In alle andere gevallen is de verwerking gebaseerd op ons gerechtvaardigd belang in de effectieve verwerking van de aan ons gerichte aanvragen (art. 6 lid 1 lit. f DSGVO) of op uw toestemming (art. 6 lid 1 lit. a DSGVO) indien deze is aangevraagd; de toestemming kan te allen tijde worden ingetrokken.',
  Enquiry_Three:
    'De gegevens die u ons stuurt via contactverzoeken blijven bij ons totdat u ons verzoekt deze te verwijderen, uw toestemming voor het opslaan van de gegevens intrekt of het doel voor het opslaan van de gegevens niet langer van toepassing is (bijv. na afloop van het onderzoeksproject). Dwingende wettelijke bepalingen - in het bijzonder wettelijke bewaartermijnen - blijven onaangetast.',
  Plugins_Tools: 'Plugins en tools',
  Google_Fonts: 'Google Fonts',
  Google_Fonts_One:
    'Deze site maakt gebruik van zogenaamde Google Fonts, die worden geleverd door Google, voor de uniforme weergave van lettertypen. Wanneer u een pagina oproept, laadt uw browser de benodigde lettertypen in de cache van de browser om teksten en lettertypen correct weer te geven.',
  Google_Fonts_Two:
    'Hiervoor moet de browser die je gebruikt verbinding maken met de servers van Google. Hierdoor weet Google dat deze app via jouw IP-adres is geopend. Het gebruik van Google Fonts is gebaseerd op art. 6 lid 1 lit. f DSGVO. De exploitant van de app heeft een gerechtvaardigd belang bij de uniforme weergave van het lettertype op zijn app. Voor zover een overeenkomstige toestemming is gevraagd, wordt de verwerking uitsluitend uitgevoerd op basis van Art. 6 para. 1 lit. a DSGVO en § 25 para. 1 TTDSG, voor zover de toestemming de opslag van cookies of de toegang tot informatie op het eindapparaat van de gebruiker (bijv. device fingerprinting) in de zin van de TTDSG omvat. De toestemming kan te allen tijde worden ingetrokken.',
  Google_Fonts_Three:
    'Als uw browser geen Google Fonts ondersteunt, wordt op uw computer een standaardlettertype gebruikt.',
  Google_Fonts_Four:
    'Meer informatie over Google Fonts is te vinden op https://developers.google.com/fonts/faq en in het privacybeleid van Google: https://policies.google.com/privacy?hl=de.',
  Google_Fonts_Five:
    'Het bedrijf is gecertificeerd onder het EU-VS Data Privacy Framework (DPF). Het DPF is een overeenkomst tussen de Europese Unie en de VS die bedoeld is om naleving van de Europese normen voor gegevensbescherming te waarborgen voor gegevensverwerking in de VS. Elk bedrijf dat gecertificeerd is onder het DPF verplicht zich tot naleving van deze gegevensbeschermingsnormen. Neem voor meer informatie contact op met de aanbieder via de volgende link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active',
};
