/**
 * Input Camera
 */

import React, {useState, useEffect} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable,
  TouchableOpacity,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {SvgUri} from 'react-native-svg';
import {
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
} from '../../../constants/constants';

/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import IconArrow from './../../../assets/iconarrow.png';
import CameraIcon from './../../../assets/iconcamera.png';
import FolderIcon from './../../../assets/iconfolder.png';
import LoadingSVG from './../../../assets/loading.svg';
import {useTranslation} from 'react-i18next';

const InputCamera = ({navigation}): Node => {
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}
        accessibilityLabel={t('Activate_your_camera')}>
        <View style={styles.cameraBlock}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    '',
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Activate_your_camera')}
          </Text>
        </View>
        <TouchableOpacity
          onPress={() => navigation.navigate('Input Camera Content')}
          accessible={true}
          accessibilityLabel={t('Activate_camera')}
          accessibilityHint="Next step - Activate camera">
          <View style={styles.cameraBlock} accessible={true}>
            <View
              style={
                darkModeAvailable
                  ? styles.cameraBlockImageMainElementDark
                  : styles.cameraBlockImageMainElement
              }
              accessible={true}>
              <View
                style={
                  darkModeAvailable
                    ? styles.cameraBlockImageSubElementDark
                    : styles.cameraBlockImageSubElement
                }
                accessible={true}>
                <Image
                  width={Platform.OS == 'web' ? '100px' : 100}
                  height={Platform.OS == 'web' ? '100px' : 100}
                  source={{
                    uri: Platform.OS == 'web' ? CameraIcon : 'iconcamera',
                  }}
                  style={styles.settingsIconStyle}
                  resizeMode="contain"
                />
              </View>
              <View
                style={styles.cameraBlockImageMainElementText}
                accessible={true}
                accessibilityLabel={t('Activate_camera')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Activate_camera')}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
        <View
          style={styles.cameraBlock}
          accessible={true}
          accessibilityLabel={t('Select_video_file')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    '',
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Select_video_file')}
          </Text>
        </View>
        <View style={styles.cameraBlock} accessible={true}>
          <View
            style={
              darkModeAvailable
                ? styles.cameraBlockImageMainElementDark
                : styles.cameraBlockImageMainElement
            }
            accessible={true}>
            <View
              style={
                darkModeAvailable
                  ? styles.cameraBlockImageSubElementDark
                  : styles.cameraBlockImageSubElement
              }
              accessible={true}>
              <Image
                width={Platform.OS == 'web' ? '100px' : 100}
                height={Platform.OS == 'web' ? '100px' : 100}
                source={{
                  uri: Platform.OS == 'web' ? FolderIcon : 'iconfolder',
                }}
                style={styles.settingsIconStyle}
                resizeMode="contain"
              />
            </View>
            <View
              style={styles.cameraBlockImageMainElementText}
              accessible={true}
              accessibilityLabel={t('Open_video_file')}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Open_video_file')}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
  },
  mainMarginWeb: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: 'calc(100vh)',
  },
  cameraBlock: {
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
  },
  cameraBlockImageMainElement: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    backgroundColor: '#ECECEC',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '150px' : 150,
    height: Platform.OS === 'web' ? '150px' : 150,
  },
  cameraBlockImageMainElementDark: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    backgroundColor: '#000000',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '150px' : 150,
    height: Platform.OS === 'web' ? '150px' : 150,
  },
  cameraBlockImageSubElement: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '25px' : 25,
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    height: Platform.OS === 'web' ? '100px' : 100,
  },
  cameraBlockImageSubElementDark: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '25px' : 25,
    backgroundColor: '#ecb853',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    height: Platform.OS === 'web' ? '100px' : 100,
  },
  cameraBlockImageMainElementText: {
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: Platform.OS === 'web' ? '12px' : 12,
    paddingTop: Platform.OS === 'web' ? '5px' : 5,
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '100px' : 100,
    height: Platform.OS === 'web' ? '100px' : 100,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsIconCustomStyle: {
    marginLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  buttonMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMainDisabled: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    pointerEvents: 'none',
    opacity: 0.4,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#D05B5B',
    color: '#fff',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  textDark: {
    color: '#ecb853',
  },
});

export default InputCamera;
