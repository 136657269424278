export default {
  login: 'Anmelden',
  Status_Login: 'Login Status',
  Translation: 'Übersetzung',
  Translation_Output: 'Übersetzungsergebnis',
  Registration: 'Registrierung',
  Password_Info:
    'Das Passwort muss mindestens 8 Zeichen lang sein und einen Großbuchstaben und ein Satzzeichen enthalten.',
  Reset_password: 'Password Zurücksetzen',
  New_Password: 'Neues Passwort',
  About: 'Über',
  Start: 'Start',
  Logout: 'Abmelden',
  Profile: 'Profil',
  Info: 'Info',
  Settings: 'Einstellungen',
  Archive: 'Archiv',
  Default_Input: 'Allgemeine Eingabe',
  Default_Output: 'Allgemeine Ausgabe',
  Voice_settings: 'Stimmeinstellung',
  Contrast: 'Kontrast',
  Avatar_settings: 'Avatar Einstellung',
  Languages_Settings: 'Spracheinstellung',
  Upload_Video: 'Video hochladen',
  Choose: 'Auswählen',
  Select_Output_Method: 'Ausgabemethode wählen',
  Select_Output_Language: 'Ausgabesprache wählen',
  Select_Input_Language: 'Eingabesprache wählen',
  Submit: 'Absenden',
  Preview_Video_Upload: 'Die hochgeladene Videodatei wird hier angezeigt',
  Input_Method: 'Eingabemethode',
  Spoken_Language: 'Gesprochene Sprache',
  Spoken_Language_Settings: 'Einstellungen für gesprochene Sprache',
  Write: 'Schreiben',
  Record_speech: 'Sprachaufnahme',
  Record_content: 'Inhalt aufnehmen',
  Video_input: 'Videoaufnahme',
  Record_Video: 'Video aufnehmen',
  Output_Method: 'Ausgabemethode',
  Language: 'Sprache',
  Back: 'Zurück',
  All: 'Alle',
  Speech: 'Sprache',
  Text: 'Text',
  Please_enter_valid_email: 'Bitte eine gültige E-Mail angeben',
  Email_Address_is_Required: 'E-Mailadresse fehlt',
  Password_is_required: 'Password fehlt',
  Welcome_to: 'Willkommen bei',
  E_Mail: 'E-Mail',
  Email_is_not_correct: 'E-Mail ist fehlerhaft',
  Password: 'Passwort',
  Password_Repeat: 'Password wiederholen',
  Password_is_not_correct: 'Passwort ist fehlerhaft',
  Email_or_passwort_is_not_correct: 'E-Mail oder Passwort ist fehlerhaft',
  Username_empty: 'Benutzername darf nicht leer sein.',
  Email_empty: 'E-Mail darf nicht leer sein.',
  Email_exists: 'E-Mail existiert bereits.',
  Password_empty: 'Passwort darf nicht leer sein.',
  Password_not_match: 'Passwort stimmt nicht überein.',
  No_data_available: 'Keine Daten verfügbar',
  Password_security_rules:
    'Passwort stimmt den Sicherheitsregeln nicht überein.',
  MAIN_TEXT:
    'EASIER ist ein Horizont-2020-Projekt, das darauf abzielt, ein komplettes mehrsprachiges maschinelles Übersetzungssystem zu entwerfen, zu entwickeln und zu validieren, das als Rahmen für die barrierefreie Kommunikation zwischen Gehörlosen und Hörenden dienen und eine Plattform zur Unterstützung der Erstellung von Inhalten in Gebärdensprache bieten soll.',
  SUB_TEXT:
    'Das Projekt sieht eine starke Einbeziehung der Endnutzer in die Gestaltung, Entwicklung und Bewertung des EASIER-Ökosystems vor, in dem Nutzer verschiedener europäischer Gebärden- und gesprochener Sprachen in ihrer bevorzugten Sprache miteinander kommunizieren können.',
  FOUNDED_TEXT:
    'Das EASIER-Projekt wird durch das EU-Programm Horizont 2020 unter der Fördervereinbarung Nr. 101016982 finanziert.',
  Current_password: 'Aktuelles Passwort',
  New_password: 'Neues Passwort',
  New_password_is_invalid: 'Neues Passwort ist ungültig',
  code: 'Code',
  code_invalid: 'Code ist ungültig.',
  mailbox_check: 'Bitte prüfen Sie Ihren E-Mail-Posteingang.',
  Save: 'Speichern',
  Name: 'Name',
  ID: 'ID',
  Delete_account: 'Account Löschen',
  Username_is_Required: 'Name des Benutzer fehlt',
  Username: 'Name des Benutzer',
  Username_is_invalid: 'Name des Benutzer ist ungültig',
  Email_is_invalid: 'E-Mail ist ungültig',
  Password_is_invalid: 'Passwort ist ungültig',
  Back_to_Log_In: 'Zurück zum Login',
  Background: 'Hintergrund',
  Clothing: 'Bekleidung',
  Gender: 'Geschlecht',
  Dark_Mode: 'Dark Mode',
  Text_size: 'Textgröße',
  Camera: 'Gebärden',
  Adapt_intonation: 'Tonfall anpassen',
  Voice_style: 'Stimmtyp',
  Language_Level: 'Sprachlevel',
  Play_Audio:
    'Klicken Sie auf das Symbol, um die aufgenommene Audiodatei abzuspielen',
  Accent: 'Akzent',
  Voice: 'Stimme',
  Recording: 'Aufnahme',
  Logged_in_as: 'Angemeldet als:',
  START: 'START',
  Use_favorite_settings: 'Verwende bevorzugte Einstellung',
  Failure: 'Fehler',
  No_default_input_and_output:
    'Keine standard Einstellung für Ein-/Ausgabe gewählt. Bitte erst in das Einstellungsmenü gehen. Go_to_settings	Zu den Einstellungen',
  OK: 'Ok',
  Status: 'Status',
  Signing_in: 'Anmeldung...',
  Translation_in_progress: 'Übersetzung läuft...',
  Input: 'Eingabe',
  Recording_time: 'Aufnahmezeit',
  Delete: 'Löschen',
  Copy: 'Kopieren',
  Share: 'Teilen',
  NEXT: 'WEITER',
  Activate_your_camera: 'Aktiviere deine Kamera zur Videoaufnahme',
  Activate_camera: 'Aktiviere Kamera',
  Select_video_file: 'Wähle ein Video, das auf dem Gerät gespeichert ist',
  Open_video_file: 'Öffne Video Datei',
  Video_recording_element: 'Video-Aufnahme-Element',
  Choose_Language: 'Wähle eine Sprache',
  Tap_and_write: 'Antippen und schreiben',
  Clear_text: 'Text zurücksetzen',
  NO: 'NEIN',
  YES: 'JA',
  Clear: 'Löschen',
  Paste: 'Einfügen',
  TRANSLATE: 'ÜBERSETZEN',
  Output: 'Ausgabe',
  Audio_Input: 'Audio Eingabe',
  Text_Input: 'Text Eingabe',
  Camera_Input: 'Kamera Eingabe',
  Audio_Output: 'Audio Ausgabe',
  Text_Output: 'Text Ausgabe',
  Avatar_Output: 'Avatar Ausgabe',
  Audio_Play: 'Audio-Player',
  Camera_Play: 'Video-Player',
  BACK_TO_START_MENU: 'ZURÜCK ZUM STARTMENÜ',
  Avatar_Language_Unavailable:
    'Avatar ist derzeit nicht in dieser Sprache verfügbar.',
  English: 'Englisch',
  German: 'Deutsch',
  French: 'Französisch',
  Italian: 'Italienisch',
  Greek: 'Griechisch',
  Dutch: 'Niederländisch',
  Suizze_German: 'Schweizer-Deutsch',
  Record_Text: 'Aufnahme läuft',
  video_one:
    'Klicken Sie auf das Aufnahmesymbol, um die Aufnahme zu aktivieren',
  video_two:
    'Klicken Sie auf die weiße Schaltfläche links neben dem Timer für Start und Stopp',
  New_translation: 'Neue Übersetzung',
  Appearance: 'Erscheinungsbild',
  Session_Old: 'Die Sitzung ist alt. Bitte loggen Sie sich erneut ein.',
  Unable_Service_Message:
    'Es ist ein Problem aufgetreten oder der Service ist aktuell nicht verfügbar.',
  no_archives: 'Keine Archive verfügbar',
  Archive_Detail: 'Archiv Detail',
  Delete_all: 'Alle löschen',
  Delete_all_archive: 'Wollen Sie alle Archive löschen?',
  Delete_one_archive: 'Wollen Sie dieses Archiv löschen?',
  Delete_user_account: 'Wollen Sie Ihr Konto löschen?',
  Log_title: 'Protokoll',
  Affect_Fusion: 'Affect Fusion Ergebnis',
  Intermediate_translation: 'Zwischenübersetzung',
  Translation_input_from_text: 'Übersetzungseingang aus Text',
  Translation_input_from_speech: 'Übersetzungseingang aus Sprache',
  Translation_input_from_sign_video: 'Übersetzungseingang aus Gebärdenvideo',
  Translation_output_to_text: 'Übersetzung der Ausgabe in Text',
  Translation_output_to_speech: 'Übersetzung der Ausgabe in Sprache',
  Translation_output_to_avatar: 'Übersetzung der Ausgabe in Avatar',
  Privacy: 'Datenschutzerklärung',
  Privacy_Title: 'Mit der Registrierung akzeptieren Sie die',
  Privacy_Text: 'Datenschutzerklärung',
  Privacy_is_required: 'Datenschutzerklärung ist erforderlich',
  Introduction: 'Einleitung',
  Introduction_One:
    'EASIER ist ein Horizon 2020-Projekt, dessen Ziel der Entwurf, die Entwicklung und die Validierung eines vollständigen Systems zur mehrsprachigen maschinellen Übersetzung ist, welches einen Rahmen für die barrierefreie Kommunikation zwischen tauben und hörenden Menschen bietet, sowie eine Plattform bereitstellt, um die Erstellung von Inhalten in Gebärdensprache zu unterstützen.',
  Introduction_Two:
    'Das Projekt setzt auf eine starke Einbindung der Endnutzer:innen in Design, Entwicklung und Evaluation der EASIER-Plattform, in der Nutzer:innen verschiedener europäischer Gebärden- und Lautsprachen ihre bevorzugte Sprache für die Interaktion untereinander verwenden werden können.',
  Introduction_Three:
    'Das EASIER Projekt wird durch das Horizon 2020 Programm der EU unter der Fördervertragsnummer 101016982 finanziert.',
  Privacy_Overview: 'Datenschutz auf einen Blick',
  General_Information: 'Allgemeine Hinweise',
  General_Information_One:
    'Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese App verwenden. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen.',
  General_Information_Two:
    'Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.',
  Data_Collection: 'Datenerfassung in dieser App',
  Data_Collection_One:
    'Wer ist verantwortlich für die Datenerfassung auf dieser App?',
  Data_Collection_Two:
    'Die Datenverarbeitung in dieser App erfolgt durch den App Anbieter. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.',
  Getting_Data: 'Wie erfassen wir Ihre Daten?',
  Getting_Data_One:
    'Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in Registrierung oder bei der Verwendung eingeben.',
  Getting_Data_Two:
    'Andere Daten werden automatisch oder nach Ihrer Einwilligung bei Verwendung der App durch Ihren Browser von unseren IT-Systemen erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Appaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie die App verwenden.',
  Using_Data: 'Wofür nutzen wir Ihre Daten?',
  Using_Data_One:
    'Die Daten werden erhoben, um eine fehlerfreie Bereitstellung der App zu gewährleisten. Login Daten werden verwendet, um Ihre Nachrichten und deren Übersetzung nur Ihnen zugänglich zu machen. Die von Ihnen eigegeben oder aufgezeichneten Nachricht werden zur Übersetzung verarbeitet und sind im Archiv nur Ihnen zugänglich.',
  Delete_Data: 'Löschung der Daten',
  Delete_Data_One:
    'Die in dem System verarbeiteten Daten werden nach dem Abschluss des EASIER Projekts gelöscht. Die Löschung erfolgt spätestens Juli 2024.',
  Delete_Data_Two:
    'Sie können Ihre Daten in der App vor diesem Termin jederzeit selbst Löschen.',
  Delete_Data_Three:
    '- In dem Menüpunkt Archiv können Sie einzelne oder alle Nachrichten löschen.',
  Delete_Data_Four:
    '- In dem Menüpunkt Profil können Sie ihren Account mit allen Daten löschen.',
  Rights_Data: 'Welche Rechte haben Sie bezüglich Ihrer Daten?',
  Rights_Data_One:
    'Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.',
  Rights_Data_Two:
    'Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.',
  Hosting: 'Hosting und Partnersysteme',
  Hosting_One:
    'Die Verwendung folgender Anbieter erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung und Funktion unserer App. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.',
  Hetzner: 'Hetzner',
  Hetzner_One: 'Wir hosten die App bei folgendem Anbieter:',
  Hetzner_Two:
    'Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (nachfolgend Hetzner).',
  Hetzner_Three:
    'Details entnehmen Sie der Datenschutzerklärung von Hetzner: https://www.hetzner.com/de/rechtliches/datenschutz.',
  AWS: 'Amazon Web Services',
  AWS_One:
    'Komponenten zur Video-Analyse und Maschinellen Übersetzung hosten wir bei folgenden Anbieter:',
  AWS_Two:
    'Dienstanbieter ist das amerikanische Unternehmen Amazon Web Services, Inc., 410 Terry Avenue North, Seattle WA 98109, USA.',
  AWS_Three: 'https://aws.amazon.com/de/compliance/data-privacy/',
  Process_Order: 'Auftragsverarbeitung',
  Process_Order_One:
    'Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung der oben genannten Dienste mit den Anbietern geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer App-Nutzer nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet. ',
  General_Notes: 'Allgemeine Hinweise und Pflichtinformationen',
  Data_Protection: 'Datenschutz',
  Data_Protection_One:
    'Die Betreiber dieser App nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.',
  Data_Protection_Two:
    'Wenn Sie diese App benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.',
  Data_Protection_Three:
    'Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.',
  Responsible_Body: 'Hinweis zur verantwortlichen Stelle',
  Responsible_Body_One:
    'Die verantwortliche Stelle für die Datenverarbeitung in dieser App ist:',
  Responsible_Body_Two:
    'Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.',
  Phone: 'Telefon',
  Email: 'E-Mail',
  City: 'Köln',
  Storage_Period: 'Speicherdauer',
  Storage_Period_One:
    'Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.',
  Storage_Period_Two: 'Siehe auch „Löschung der Daten“ in Abschnitt 1.',
  General_Information_Legal:
    'Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung in dieser App',
  General_Information_Legal_One:
    'Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.',
  Revocation_Data: 'Widerruf Ihrer Einwilligung zur Datenverarbeitung',
  Revocation_Data_One:
    'Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.',
  Rights_Collection_Data:
    'Widerspruchsrecht gegen die Datenerhebung (Art. 21 DSGVO)',
  Rights_Collection_Data_One:
    'WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).',
  Right_Of_Appeal: 'Beschwerderecht bei der zuständigen Aufsichtsbehörde',
  Right_Of_Appeal_One:
    'Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.',
  Right_Of_Portability: 'Recht auf Datenübertragbarkeit',
  Right_Of_Portability_One:
    'Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.',
  Information_Correction_Deletion: 'Auskunft, Berichtigung und Löschung',
  Information_Correction_Deletion_One:
    'Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.',
  Information_Correction_Deletion_Two:
    'Sie können Ihre Daten in der App jederzeit selbst Löschen, siehe „Löschung der Daten“ in Abschnitt 2.',
  Right_Restriction_Processing: 'Recht auf Einschränkung der Verarbeitung',
  Right_Restriction_Processing_One:
    'Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:',
  Right_Restriction_Processing_List_One:
    '- Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.',
  Right_Restriction_Processing_List_Two:
    '- Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.',
  Right_Restriction_Processing_List_Three:
    '- Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.',
  Right_Restriction_Processing_Two:
    'Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.',
  Right_Restriction_Processing_Three:
    'Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.',
  SSL_TLS: 'SSL- bzw. TLS-Verschlüsselung',
  SSL_TLS_One:
    'Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.',
  SSL_TLS_Two:
    'Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.',
  Data_Collection_App: 'Datenerfassung in dieser App',
  Cookies: 'Cookies',
  Cookies_One:
    'Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies (z.B. App Einstellung) bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.',
  Cookies_Two:
    'Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies werden nicht verwendet.',
  Cookies_Three:
    'Die Cookies sind für die Verwendung der App technisch notwendig, um die Sicherheit einer Sitzung zu gewährleisten und die Funktion der App sicherzustellen. Eine Auswertung des Nutzerverhaltens findet nicht statt.',
  Cookies_Four:
    'Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der App-Betreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1. TTDSG); die Einwilligung ist jederzeit widerrufbar.',
  Cookies_Five:
    'Sie können Ihre Daten in der App jederzeit selbst Löschen, siehe „Löschung der Daten“ in Abschnitt 2.',
  Cookies_Six:
    'Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser App eingeschränkt sein. Welche Cookies und Dienste in dieser App eingesetzt werden, können Sie dieser Datenschutzerklärung entnehmen.',
  Enquiry: 'Anfrage per E-Mail, Telefon oder Telefax',
  Enquiry_One:
    'Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.',
  Enquiry_Two:
    'Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.',
  Enquiry_Three:
    'Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach Ende des Forschungsprojektes). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.',
  Plugins_Tools: 'Plugins und Tools',
  Google_Fonts: 'Google Fonts',
  Google_Fonts_One:
    'Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Google Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.',
  Google_Fonts_Two:
    'Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse diese App aufgerufen wurde. Die Nutzung von Google Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Appbetreiber hat ein berechtigtes Interesse an der einheitlichen Darstellung des Schriftbildes auf seiner App. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.',
  Google_Fonts_Three:
    'Wenn Ihr Browser Google Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.',
  Google_Fonts_Four:
    'Weitere Informationen zu Google Fonts finden Sie unter https://developers.google.com/fonts/faq und in der Datenschutzerklärung von Google: https://policies.google.com/privacy?hl=de.',
  Google_Fonts_Five:
    'Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active',
};
