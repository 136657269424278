/**
 * Archive
 */

import React, {useState, useEffect, useCallback} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable,
  TouchableOpacity,
  FlatList,
  Platform,
  RefreshControl,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  TEXT,
  AUDIO,
  AVATAR,
  ALL_ARCHIVE,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  USERTOKEN_KEY,
  CAMERA,
  VIDEO,
  DEVELOPMENT,
  TEST,
} from '../../constants/constants';
import {useTranslation} from 'react-i18next';
import axios from 'axios';

/* Icons */
import Logo from './../../assets/easierlogowithtext.png';
import IconArrow from './../../assets/iconarrow.png';
import TranslateIcon from './../../assets/illustrationheaderinputmethod.png';
import RecordIcon from './../../assets/iconspeech.png';
import RecordIconDark from './../../assets/iconspeechdark.png';
import TextIcon from './../../assets/icontext.png';
import TextIconDark from './../../assets/icontextdark.png';
import AvatarIcon from './../../assets/iconavatar.png';
import AvatarIconDark from './../../assets/iconavatardark.png';
import CameraIcon from './../../assets/iconcamera.png';
import CameraIconDark from './../../assets/iconcameradark.png';
import DeleteIcon from './../../assets/icondelete.png';
import DeleteIconDark from './../../assets/icondeletedark.png';

const Archive = ({navigation}): Node => {
  const [speech, setSpeech] = useState(false);
  const [text, setText] = useState(false);
  const [avatar, setAvatar] = useState(false);
  const [speechInput, setSpeechInput] = useState(false);
  const [textInput, setTextInput] = useState(false);
  const [camera, setCamera] = useState(false);
  const [allArchives, setAllArchives] = useState(true);
  const [archiveList, setArchiveList] = useState([
    {id: 1, text: 'EasierVideo_20210914_051917430', type: AUDIO},
    {id: 2, text: 'EasierText_20210914_051917430', type: TEXT},
    {id: 3, text: 'EasierText_20210914_051917430', type: TEXT},
    {id: 4, text: 'EasierAvatar_20210914_051917430', type: AVATAR},
    {id: 5, text: 'EasierVideo_20210914_051917430', type: AUDIO},
    {id: 6, text: 'EasierAvatar_20210914_051917430', type: AVATAR},
  ]);
  const [archiveData, setArchiveData] = useState([]);
  const [archiveDataList, setArchiveDataList] = useState([]);
  const [filterElement, setFilterElement] = useState([]);
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteOneModal, setDeleteOneModal] = useState(false);
  const [deleteAllModal, setDeleteAllModal] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const isFocused = useIsFocused();
  const {t, i18n} = useTranslation();

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  /*useEffect(() => {
    const refreshData = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);
      const archiveDetailDelete = await AsyncStorage.getItem('archiveDelete');

      if (archiveDetailDelete === 'true') {
        let dataArrayList = [];

        axios
          .get(TEST + 'translations?length=1000', {
            headers: {
              //...form.getHeaders(),
              Authorization: `Bearer ${userKeyToken}`,
            },
          })
          .then(async response => {
            if (response.status === 200) {
              for (let i = 0; i < response.data.translations.length; i++) {
                let getDataDatetime =
                  response.data.translations[i].creation_datetime;
                let formattedDatetime = getDataDatetime
                  .substring(0, 19)
                  .replace('T', '_');
                let formatInput =
                  response.data.translations[i].input_media.type;
                let formatOutput =
                  response.data.translations[i].output_media.type;
                dataArrayList.push({
                  id: i + 1,
                  text: 'archive_' + formattedDatetime,
                  typeInput: formatInput == 'camera' ? VIDEO : formatInput,
                  type: formatOutput == 'video' ? AVATAR : formatOutput,
                  dataID: response.data.translations[i].id,
                });
              }
              console.log(dataArrayList);
              setArchiveDataList(dataArrayList);
            } else {
              console.log('Error');
            }
          })
          .catch(e => {
            console.log(e);
            console.log('Failed');
          });
      }
    };
    refreshData();
  }, [isFocused]);*/

  useEffect(() => {
    const getArchiveData = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      let dataArrayList = [];

      axios
        .get(TEST + 'translations?length=1000', {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(async response => {
          if (response.status === 200) {
            if (Object.keys(response.data).length > 0) {
              for (let i = 0; i < response.data.translations.length; i++) {
                let getDataDatetime =
                  response.data.translations[i].creation_datetime;
                let formattedDatetime = getDataDatetime
                  .substring(0, 19)
                  .replace('T', '_');
                let formatInput =
                  response.data.translations[i].input_media.type;
                let formatOutput =
                  response.data.translations[i].output_media.type;
                dataArrayList.push({
                  id: i + 1,
                  text:
                    'ID: ' +
                    response.data.translations[i].id +
                    ' - archive_' +
                    formattedDatetime,
                  typeInput: formatInput == 'camera' ? VIDEO : formatInput,
                  type: formatOutput == 'video' ? AVATAR : formatOutput,
                  dataID: response.data.translations[i].id,
                });
              }
            } else {
              dataArrayList = [];
            }
            console.log(dataArrayList);
            setArchiveDataList(dataArrayList);
          } else {
            console.log('Error');
          }
        })
        .catch(e => {
          console.log(e);
          console.log('Failed an try again');
        });
    };
    getArchiveData();
  }, [isFocused]);

  const menuElementActive = (elementOne, elementTwo, elementThree) => {
    setSpeech(elementOne);
    setText(elementTwo);
    setAvatar(elementThree);
  };

  const newFilter = elementType => {
    if (elementType !== ALL_ARCHIVE) {
      // Old: archiveList
      const newData = archiveDataList.filter(item => {
        return item.type === elementType;
      });
      console.log(newData);
      setFilterElement(newData.length > 0 ? newData : []);
    } else {
      // Old: archiveList
      console.log(archiveDataList);
      setFilterElement(archiveDataList);
    }

    if (elementType === ALL_ARCHIVE) {
      setSpeech(false);
      setText(false);
      setAvatar(false);
      setSpeechInput(false);
      setTextInput(false);
      setCamera(false);
      setAllArchives(true);
    }

    if (elementType === AUDIO) {
      setSpeech(true);
      setText(false);
      setAvatar(false);
      setSpeechInput(false);
      setTextInput(false);
      setCamera(false);
      setAllArchives(false);
    }

    if (elementType === TEXT) {
      setSpeech(false);
      setText(true);
      setAvatar(false);
      setSpeechInput(false);
      setTextInput(false);
      setCamera(false);
      setAllArchives(false);
    }

    if (elementType === AVATAR) {
      setSpeech(false);
      setText(false);
      setAvatar(true);
      setSpeechInput(false);
      setTextInput(false);
      setCamera(false);
      setAllArchives(false);
    }
  };

  const newFilterInput = elementType => {
    if (elementType !== ALL_ARCHIVE) {
      // Old: archiveList
      const newData = archiveDataList.filter(item => {
        return item.typeInput === elementType;
      });
      console.log(newData);
      setFilterElement(newData.length > 0 ? newData : []);
    } else {
      // Old: archiveList
      console.log(archiveDataList);
      setFilterElement(archiveDataList);
    }

    if (elementType === ALL_ARCHIVE) {
      setSpeechInput(false);
      setTextInput(false);
      setCamera(false);
      setSpeech(false);
      setText(false);
      setAvatar(false);
      setAllArchives(true);
    }

    if (elementType === AUDIO) {
      setSpeechInput(true);
      setTextInput(false);
      setCamera(false);
      setSpeech(false);
      setText(false);
      setAvatar(false);
      setAllArchives(false);
    }

    if (elementType === TEXT) {
      setSpeechInput(false);
      setTextInput(true);
      setCamera(false);
      setSpeech(false);
      setText(false);
      setAvatar(false);
      setAllArchives(false);
    }

    if (elementType === VIDEO || elementType === CAMERA) {
      setSpeechInput(false);
      setTextInput(false);
      setCamera(true);
      setSpeech(false);
      setText(false);
      setAvatar(false);
      setAllArchives(false);
    }
  };

  const deleteOneArchive = async () => {
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);
    const archiveIDData = await AsyncStorage.getItem('archiveID');

    let dataArrayList = [];

    axios
      .delete(TEST + 'translation/' + archiveIDData, {
        headers: {
          //...form.getHeaders(),
          Authorization: `Bearer ${userKeyToken}`,
        },
      })
      .then(async res => {
        if (res.status === 200) {
          axios
            .get(TEST + 'translations?length=1000', {
              headers: {
                //...form.getHeaders(),
                Authorization: `Bearer ${userKeyToken}`,
              },
            })
            .then(async response => {
              if (response.status === 200) {
                for (let i = 0; i < response.data.translations.length; i++) {
                  let getDataDatetime =
                    response.data.translations[i].creation_datetime;
                  let formattedDatetime = getDataDatetime
                    .substring(0, 19)
                    .replace('T', '_');
                  let formatInput =
                    response.data.translations[i].input_media.type;
                  let formatOutput =
                    response.data.translations[i].output_media.type;
                  dataArrayList.push({
                    id: i + 1,
                    text: 'archive_' + formattedDatetime,
                    typeInput: formatInput == 'camera' ? VIDEO : formatInput,
                    type: formatOutput == 'video' ? AVATAR : formatOutput,
                    dataID: response.data.translations[i].id,
                  });
                }
                console.log(dataArrayList);
                setArchiveDataList(dataArrayList);
              } else {
                console.log('Error');
              }
            })
            .catch(e => {
              console.log(e);
              console.log('Failed');
            });

          setDeleteModal(false);
          setDeleteAllModal(false);
          setDeleteOneModal(false);
        } else {
          console.log('Error');
        }
      })
      .catch(e => {
        console.log(e);
        console.log('Failed');
      });
  };

  const deleteAllArchive = async () => {
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

    axios
      .delete(TEST + 'translation/all', {
        headers: {
          //...form.getHeaders(),
          Authorization: `Bearer ${userKeyToken}`,
        },
      })
      .then(async res => {
        if (res.status === 200) {
          setArchiveDataList([]);
          setDeleteModal(false);
          setDeleteAllModal(false);
          setDeleteOneModal(false);
        } else {
          console.log('Error');
        }
      })
      .catch(e => {
        console.log(e);
        console.log('Failed');
      });
  };

  const closeModal = () => {
    setDeleteModal(false);
    setDeleteAllModal(false);
    setDeleteOneModal(false);
  };

  const deleteOneArchiveModal = async id => {
    setDeleteModal(true);
    setDeleteAllModal(false);
    setDeleteOneModal(true);
    await AsyncStorage.setItem('archiveID', id);
  };

  const deleteAllArchiveModal = () => {
    setDeleteAllModal(true);
    setDeleteOneModal(false);
    setDeleteModal(true);
  };

  const refreshData = useCallback(async () => {
    const archiveDetailDelete = await AsyncStorage.getItem('archiveDelete');

    if (archiveDetailDelete === 'true') {
      setRefreshing(true);
      setTimeout(async () => {
        setRefreshing(false);
        await AsyncStorage.removeItem('archiveDelete');
      }, 2000);
    }
  }, []);

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={refreshData} />
      }>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View style={styles.additionalOptionMenuNormal} accessible={true}>
          <View style={styles.menuElementContainer} accessible={true}>
            <TouchableOpacity
              onPress={() => newFilter(ALL_ARCHIVE)}
              accessible={true}
              accessibilityLabel={t('All')}
              accessibilityHint="Show all">
              <View
                style={
                  allArchives
                    ? darkModeAvailable
                      ? styles.menuElementContainerInnerActiveNoImageDark
                      : styles.menuElementContainerInnerActiveNoImage
                    : darkModeAvailable
                    ? styles.menuElementContainerInnerNoImageDark
                    : styles.menuElementContainerInnerNoImage
                }
                accessible={true}>
                {/*<Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri: Platform.OS == 'web' ? RecordIcon : 'iconspeech',
                  }}
                  style={styles.logoNavigation}
                  resizeMode="contain"
                />*/}
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.menuElementNoImage,
                          styles.menuElementAdditionalNoImageDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.menuElementNoImage,
                          styles.menuElementAdditionalNoImage,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('All')}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.additionalOptionMenuNormal} accessible={true}>
          <View style={styles.menuElementContainer} accessible={true}>
            <TouchableOpacity
              onPress={() => newFilter(AUDIO)}
              accessible={true}
              accessibilityLabel={t('Speech')}
              accessibilityHint="Show all speech">
              <View
                style={
                  speech
                    ? darkModeAvailable
                      ? styles.menuElementContainerInnerActiveDark
                      : styles.menuElementContainerInnerActive
                    : darkModeAvailable
                    ? styles.menuElementContainerInnerDark
                    : styles.menuElementContainerInner
                }
                accessible={true}>
                <Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? RecordIconDark
                          : RecordIcon
                        : darkModeAvailable
                        ? 'iconspeechdark'
                        : 'iconspeech',
                  }}
                  style={styles.logoNavigation}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.menuElement,
                          styles.menuElementAdditionalDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.menuElement,
                          styles.menuElementAdditional,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Speech')} ({t('Output')})
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.additionalOptionMenu} accessible={true}>
          <View style={styles.menuElementContainer} accessible={true}>
            <TouchableOpacity
              onPress={() => newFilter(TEXT)}
              accessible={true}
              accessibilityLabel={t('Text')}
              accessibilityHint="Show all text">
              <View
                style={
                  text
                    ? darkModeAvailable
                      ? [
                          styles.menuElementContainerInnerActiveSmallDark,
                          styles.menuElementContainerInnerMiddleActiveDark,
                        ]
                      : [
                          styles.menuElementContainerInnerActiveSmall,
                          styles.menuElementContainerInnerMiddleActive,
                        ]
                    : darkModeAvailable
                    ? [
                        styles.menuElementContainerInnerSmallDark,
                        styles.menuElementContainerInnerMiddleDark,
                      ]
                    : [
                        styles.menuElementContainerInnerSmall,
                        styles.menuElementContainerInnerMiddle,
                      ]
                }
                accessible={true}>
                <Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? TextIconDark
                          : TextIcon
                        : darkModeAvailable
                        ? 'icontextdark'
                        : 'icontext',
                  }}
                  style={styles.logoNavigation}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.menuElementMiddle,
                          styles.menuElementAdditionalDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.menuElementMiddle,
                          styles.menuElementAdditional,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Text')} ({t('Output')})
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.additionalOptionMenu} accessible={true}>
          <View style={styles.menuElementContainer} accessible={true}>
            <TouchableOpacity
              onPress={() => newFilter(AVATAR)}
              accessible={true}
              accessibilityLabel={t('Avatar')}
              accessibilityHint="Show all avatar">
              <View
                style={
                  avatar
                    ? darkModeAvailable
                      ? styles.menuElementContainerInnerActiveLastDark
                      : styles.menuElementContainerInnerActiveLast
                    : darkModeAvailable
                    ? styles.menuElementContainerInnerLastDark
                    : styles.menuElementContainerInnerLast
                }
                accessible={true}>
                <Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? AvatarIconDark
                          : AvatarIcon
                        : darkModeAvailable
                        ? 'iconavatardark'
                        : 'iconavatar',
                  }}
                  style={styles.logoNavigation}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.menuElementLast,
                          styles.menuElementAdditionalDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.menuElementLast,
                          styles.menuElementAdditional,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Avatar')} ({t('Output')})
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.additionalOptionMenuNormal} accessible={true}>
          <View style={styles.menuElementContainer} accessible={true}>
            <TouchableOpacity
              onPress={() => newFilterInput(AUDIO)}
              accessible={true}
              accessibilityLabel={t('Speech')}
              accessibilityHint="Show all speech">
              <View
                style={
                  speechInput
                    ? darkModeAvailable
                      ? styles.menuElementContainerInnerActiveDark
                      : styles.menuElementContainerInnerActive
                    : darkModeAvailable
                    ? styles.menuElementContainerInnerDark
                    : styles.menuElementContainerInner
                }
                accessible={true}>
                <Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? RecordIconDark
                          : RecordIcon
                        : darkModeAvailable
                        ? 'iconspeechdark'
                        : 'iconspeech',
                  }}
                  style={styles.logoNavigation}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.menuElement,
                          styles.menuElementAdditionalDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.menuElement,
                          styles.menuElementAdditional,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Speech')} ({t('Input')})
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.additionalOptionMenu} accessible={true}>
          <View style={styles.menuElementContainer} accessible={true}>
            <TouchableOpacity
              onPress={() => newFilterInput(TEXT)}
              accessible={true}
              accessibilityLabel={t('Text')}
              accessibilityHint="Show all text">
              <View
                style={
                  textInput
                    ? darkModeAvailable
                      ? [
                          styles.menuElementContainerInnerActiveSmallDark,
                          styles.menuElementContainerInnerMiddleActiveDark,
                        ]
                      : [
                          styles.menuElementContainerInnerActiveSmall,
                          styles.menuElementContainerInnerMiddleActive,
                        ]
                    : darkModeAvailable
                    ? [
                        styles.menuElementContainerInnerSmallDark,
                        styles.menuElementContainerInnerMiddleDark,
                      ]
                    : [
                        styles.menuElementContainerInnerSmall,
                        styles.menuElementContainerInnerMiddle,
                      ]
                }
                accessible={true}>
                <Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? TextIconDark
                          : TextIcon
                        : darkModeAvailable
                        ? 'icontextdark'
                        : 'icontext',
                  }}
                  style={styles.logoNavigation}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.menuElementMiddle,
                          styles.menuElementAdditionalDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.menuElementMiddle,
                          styles.menuElementAdditional,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Text')} ({t('Input')})
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.additionalOptionMenu} accessible={true}>
          <View style={styles.menuElementContainer} accessible={true}>
            <TouchableOpacity
              onPress={() => newFilterInput(VIDEO)}
              accessible={true}
              accessibilityLabel={t('Camera')}
              accessibilityHint="Show all avatar">
              <View
                style={
                  camera
                    ? darkModeAvailable
                      ? styles.menuElementContainerInnerActiveLastDark
                      : styles.menuElementContainerInnerActiveLast
                    : darkModeAvailable
                    ? styles.menuElementContainerInnerLastDark
                    : styles.menuElementContainerInnerLast
                }
                accessible={true}>
                <Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? CameraIconDark
                          : CameraIcon
                        : darkModeAvailable
                        ? 'iconcameradark'
                        : 'iconcamera',
                  }}
                  style={styles.logoNavigation}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.menuElementLast,
                          styles.menuElementAdditionalDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.menuElementLast,
                          styles.menuElementAdditional,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Camera')} ({t('Input')})
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View style={styles.additionalOptionMenu} accessible={true}>
          <View style={styles.menuElementContainer} accessible={true}>
            <TouchableOpacity
              onPress={deleteAllArchiveModal}
              accessible={true}
              accessibilityLabel={t('Delete')}
              accessibilityHint="Delete all archives">
              <View
                style={
                  darkModeAvailable
                    ? [
                        styles.menuElementContainerInnerSmallDarkDelete,
                        styles.menuElementContainerInnerMiddleDarkDelete,
                      ]
                    : [
                        styles.menuElementContainerInnerSmallDelete,
                        styles.menuElementContainerInnerMiddleDelete,
                      ]
                }
                accessible={true}>
                <Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? DeleteIconDark
                          : DeleteIcon
                        : darkModeAvailable
                        ? 'icondeletedark'
                        : 'icondelete',
                  }}
                  style={styles.logoNavigation}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.menuElementMiddle,
                          styles.menuElementAdditionalDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.menuElementMiddle,
                          styles.menuElementAdditional,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Delete_all')}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
        {!deleteModal ? (
          <ScrollView style={styles.listElements} accessible={true}>
            <FlatList
              data={
                filterElement.length != 0
                  ? filterElement
                  : allArchives
                  ? archiveDataList
                  : []
              }
              accessible={true}
              ListEmptyComponent={
                <View>
                  <Text style={darkModeAvailable ? styles.textDark : ''}>
                    {t('no_archives')}
                  </Text>
                </View>
              }
              renderItem={({item}) => (
                <>
                  <View
                    style={styles.menuElementList}
                    onClick={() =>
                      navigation.navigate('Archive Detail', {
                        archiveID: item.dataID,
                      })
                    }
                    accessible={true}>
                    <View
                      style={
                        darkModeAvailable
                          ? styles.menuElementListImageDark
                          : styles.menuElementListImage
                      }
                      accessible={true}
                      accessibilityLabel="Icon for speech or text or avatar">
                      {item.typeInput == AUDIO ? (
                        <Image
                          width={Platform.OS == 'web' ? '30px' : 30}
                          height={Platform.OS == 'web' ? '30px' : 30}
                          source={{
                            uri:
                              Platform.OS == 'web'
                                ? darkModeAvailable
                                  ? RecordIconDark
                                  : RecordIcon
                                : darkModeAvailable
                                ? 'iconspeechdark'
                                : 'iconspeech',
                          }}
                          style={styles.logoNavigation}
                          resizeMode="contain"
                        />
                      ) : item.typeInput == TEXT ? (
                        <Image
                          width={Platform.OS == 'web' ? '30px' : 30}
                          height={Platform.OS == 'web' ? '30px' : 30}
                          source={{
                            uri:
                              Platform.OS == 'web'
                                ? darkModeAvailable
                                  ? TextIconDark
                                  : TextIcon
                                : darkModeAvailable
                                ? 'icontextdark'
                                : 'icontext',
                          }}
                          style={styles.logoNavigation}
                          resizeMode="contain"
                        />
                      ) : (
                        <Image
                          width={Platform.OS == 'web' ? '30px' : 30}
                          height={Platform.OS == 'web' ? '30px' : 30}
                          source={{
                            uri:
                              Platform.OS == 'web'
                                ? darkModeAvailable
                                  ? CameraIconDark
                                  : CameraIcon
                                : darkModeAvailable
                                ? 'iconcameradark'
                                : 'iconcamera',
                          }}
                          style={styles.logoNavigation}
                          resizeMode="contain"
                        />
                      )}
                    </View>
                    <View
                      style={
                        darkModeAvailable
                          ? styles.menuElementListTextDark
                          : styles.menuElementListText
                      }
                      accessible={true}
                      accessibilityLabel={item.text}>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.menuElementListTextInnerDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                styles.menuElementListTextInner,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {item.text}
                      </Text>
                    </View>
                    <View
                      style={
                        darkModeAvailable
                          ? styles.menuElementListImageDark
                          : styles.menuElementListImage
                      }
                      accessible={true}
                      accessibilityLabel="Icon for speech or text or avatar">
                      {item.type == AUDIO ? (
                        <Image
                          width={Platform.OS == 'web' ? '30px' : 30}
                          height={Platform.OS == 'web' ? '30px' : 30}
                          source={{
                            uri:
                              Platform.OS == 'web'
                                ? darkModeAvailable
                                  ? RecordIconDark
                                  : RecordIcon
                                : darkModeAvailable
                                ? 'iconspeechdark'
                                : 'iconspeech',
                          }}
                          style={styles.logoNavigation}
                          resizeMode="contain"
                        />
                      ) : item.type == TEXT ? (
                        <Image
                          width={Platform.OS == 'web' ? '30px' : 30}
                          height={Platform.OS == 'web' ? '30px' : 30}
                          source={{
                            uri:
                              Platform.OS == 'web'
                                ? darkModeAvailable
                                  ? TextIconDark
                                  : TextIcon
                                : darkModeAvailable
                                ? 'icontextdark'
                                : 'icontext',
                          }}
                          style={styles.logoNavigation}
                          resizeMode="contain"
                        />
                      ) : (
                        <Image
                          width={Platform.OS == 'web' ? '30px' : 30}
                          height={Platform.OS == 'web' ? '30px' : 30}
                          source={{
                            uri:
                              Platform.OS == 'web'
                                ? darkModeAvailable
                                  ? AvatarIconDark
                                  : AvatarIcon
                                : darkModeAvailable
                                ? 'iconavatardark'
                                : 'iconavatar',
                          }}
                          style={styles.logoNavigation}
                          resizeMode="contain"
                        />
                      )}
                    </View>
                  </View>
                  <View>
                    <View style={styles.textButtonDelete} accessible={true}>
                      <Pressable
                        style={
                          darkModeAvailable
                            ? [
                                styles.menuElementContainerInnerSmallDarkDelete,
                                styles.menuElementContainerInnerMiddleDarkDelete,
                              ]
                            : [
                                styles.menuElementContainerInnerSmallDelete,
                                styles.menuElementContainerInnerMiddleDelete,
                              ]
                        }
                        onPress={() => deleteOneArchiveModal(item.dataID)}
                        accessible={true}
                        accessibilityLabel={t('Delete')}>
                        <View
                          style={
                            darkModeAvailable
                              ? styles.textButtonsElementBackgroundDeleteDark
                              : styles.textButtonsElementBackgroundDelete
                          }
                          accessible={true}>
                          <Image
                            width={Platform.OS == 'web' ? '30px' : 30}
                            height={Platform.OS == 'web' ? '30px' : 30}
                            source={{
                              uri:
                                Platform.OS == 'web'
                                  ? darkModeAvailable
                                    ? DeleteIconDark
                                    : DeleteIcon
                                  : darkModeAvailable
                                  ? 'icondeletedark'
                                  : 'icondelete',
                            }}
                            style={styles.logoNavigation}
                            resizeMode="contain"
                          />
                        </View>
                        <View>
                          <Text
                            style={
                              darkModeAvailable
                                ? [
                                    styles.menuElementMiddle,
                                    styles.menuElementAdditionalDark,
                                    contrastSizing === SMALLTEXT
                                      ? styles.smallTextAll
                                      : contrastSizing === NORMALTEXT
                                      ? styles.normalTextAll
                                      : contrastSizing === BIGTEXT
                                      ? styles.bigTextAll
                                      : styles.normalTextAll,
                                  ]
                                : [
                                    styles.menuElementMiddle,
                                    styles.menuElementAdditional,
                                    contrastSizing === SMALLTEXT
                                      ? styles.smallTextAll
                                      : contrastSizing === NORMALTEXT
                                      ? styles.normalTextAll
                                      : contrastSizing === BIGTEXT
                                      ? styles.bigTextAll
                                      : styles.normalTextAll,
                                  ]
                            }>
                            {t('Delete')}
                          </Text>
                        </View>
                      </Pressable>
                    </View>
                  </View>
                </>
              )}
            />
          </ScrollView>
        ) : (
          <>
            <View
              style={
                darkModeAvailable
                  ? styles.modalContainerDark
                  : styles.modalContainer
              }>
              <View style={styles.modalContainerInner}>
                <Text
                  style={[
                    darkModeAvailable
                      ? styles.modalHeadlineDark
                      : styles.modalHeadline,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]}>
                  {deleteAllModal
                    ? t('Delete_all_archive')
                    : t('Delete_one_archive')}
                </Text>
                <View style={styles.textButtonDelete} accessible={true}>
                  <Pressable
                    style={
                      darkModeAvailable
                        ? [
                            styles.menuElementContainerInnerSmallModalDark,
                            styles.menuElementContainerInnerMiddleDarkDelete,
                          ]
                        : [
                            styles.menuElementContainerInnerSmallModal,
                            styles.menuElementContainerInnerMiddleDelete,
                          ]
                    }
                    onPress={closeModal}
                    accessible={true}
                    accessibilityLabel={t('NO')}>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.menuElementMiddle,
                                styles.menuElementAdditionalDark,
                                styles.modalButtonText,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                styles.menuElementMiddle,
                                styles.menuElementAdditional,
                                styles.modalButtonText,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {t('NO')}
                      </Text>
                    </View>
                  </Pressable>
                </View>
                <View style={styles.textButtonDelete} accessible={true}>
                  <Pressable
                    style={
                      darkModeAvailable
                        ? [
                            styles.menuElementContainerInnerSmallModalDark,
                            styles.menuElementContainerInnerMiddleDarkDelete,
                          ]
                        : [
                            styles.menuElementContainerInnerSmallModal,
                            styles.menuElementContainerInnerMiddleDelete,
                          ]
                    }
                    onPress={
                      deleteOneModal ? deleteOneArchive : deleteAllArchive
                    }
                    accessible={true}
                    accessibilityLabel={t('YES')}>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.menuElementMiddle,
                                styles.menuElementAdditionalDark,
                                styles.modalButtonText,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                styles.menuElementMiddle,
                                styles.menuElementAdditional,
                                styles.modalButtonText,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {t('YES')}
                      </Text>
                    </View>
                  </Pressable>
                </View>
              </View>
            </View>
          </>
        )}
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  additionalOption: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '125px' : 125,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  modalContainer: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '150px' : 150,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
  },
  modalContainerDark: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '150px' : 150,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
  },
  modalContainerInner: {
    width: Platform.OS === 'web' ? '250px' : 250,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  modalHeadline: {
    flexDirection: 'row',
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  modalHeadlineDark: {
    flexDirection: 'row',
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
  },
  modalButtonText: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '150px' : 150,
    textAlign: 'center',
  },
  additionalOptionMenu: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '25px' : 25,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  additionalOptionMenuNormal: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '25px' : 25,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  menuElementList: {
    flexDirection: 'row',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    cursor: 'pointer',
  },
  menuElementListImage: {
    height: Platform.OS === 'web' ? '50px' : 50,
    width: Platform.OS === 'web' ? '50px' : 50,
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    padding: Platform.OS === 'web' ? '10px' : 10,
  },
  menuElementListImageDark: {
    height: Platform.OS === 'web' ? '50px' : 50,
    width: Platform.OS === 'web' ? '50px' : 50,
    //backgroundColor: '#ecb853',
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    padding: Platform.OS === 'web' ? '7.5px' : 7.5,
  },
  menuElementListText: {
    height: Platform.OS === 'web' ? '50px' : 50,
    width: Platform.OS === 'web' ? '180px' : 180,
    borderColor: '#707070',
    borderStyle: 'solid',
    borderBottomWidth: Platform.OS === 'web' ? '1px' : 1,
    marginLeft: Platform.OS === 'web' ? '15px' : 15,
  },
  menuElementListTextDark: {
    height: Platform.OS === 'web' ? '50px' : 50,
    width: Platform.OS === 'web' ? '180px' : 180,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderBottomWidth: Platform.OS === 'web' ? '1px' : 1,
    marginLeft: Platform.OS === 'web' ? '15px' : 15,
  },
  menuElementListTextInner: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    flexWrap: 'wrap',
    maxWidth: Platform.OS === 'web' ? '170px' : 170,
  },
  menuElementListTextInnerDark: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
    flexWrap: 'wrap',
    maxWidth: Platform.OS === 'web' ? '170px' : 170,
  },
  menuElementContainer: {
    flexDirection: 'row',
  },
  menuElementContainerInnerNoImage: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
  },
  menuElementContainerInnerNoImageDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
  },
  menuElementContainerInnerActiveNoImage: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#EDB854',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
  },
  menuElementContainerInnerActiveNoImageDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
  },
  menuElementContainerInner: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  menuElementContainerInnerDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
  },
  menuElementContainerInnerActiveDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
  },
  menuElementContainerInnerActive: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#EDB854',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  menuElementContainerInnerLast: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  menuElementContainerInnerLastDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
  },
  menuElementContainerInnerActiveLastDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
  },
  menuElementContainerInnerActiveLast: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#EDB854',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  menuElementContainerInnerSmall: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  menuElementContainerInnerSmallDelete: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  menuElementContainerInnerSmallModal: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '150px' : 150,
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  menuElementContainerInnerSmallModalDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '150px' : 150,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  menuElementContainerInnerSmallDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
  },
  menuElementContainerInnerSmallDarkDelete: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
  },
  menuElementContainerInnerActiveSmallDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
  },
  menuElementContainerInnerActiveSmall: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    backgroundColor: '#EDB854',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  menuElementContainerInnerMiddle: {
    marginLeft: Platform.OS === 'web' ? '0px' : 0,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  menuElementContainerInnerMiddleDelete: {
    /*marginLeft: Platform.OS === 'web' ? '0px' : 0,
    marginRight: Platform.OS === 'web' ? '10px' : 10,*/
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  menuElementContainerInnerMiddleDark: {
    marginLeft: Platform.OS === 'web' ? '0px' : 0,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
  },
  menuElementContainerInnerMiddleDarkDelete: {
    /*marginLeft: Platform.OS === 'web' ? '0px' : 0,
    marginRight: Platform.OS === 'web' ? '10px' : 10,*/
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
  },
  menuElementContainerInnerMiddleActiveDark: {
    marginLeft: Platform.OS === 'web' ? '0px' : 0,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
  },
  menuElementContainerInnerMiddleActive: {
    marginLeft: Platform.OS === 'web' ? '0px' : 0,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#EDB854',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  listElements: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  menuElement: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  menuElementLast: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  menuElementNoImage: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    textAlign: 'center',
  },
  menuElementAdditional: {
    padding: Platform.OS === 'web' ? '5px' : 5,
  },
  menuElementAdditionalNoImage: {
    padding: Platform.OS === 'web' ? '7px' : 7,
  },
  menuElementAdditionalDark: {
    padding: Platform.OS === 'web' ? '5px' : 5,
    color: '#ecb853',
  },
  menuElementAdditionalNoImageDark: {
    padding: Platform.OS === 'web' ? '7px' : 7,
    color: '#ecb853',
  },
  menuElementMiddle: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? '-2px' : 0,
    marginRight: Platform.OS === 'web' ? '5px' : 5,
  },
  logoNavigation: {
    width: Platform.OS === 'web' ? '30px' : 30,
    height: Platform.OS === 'web' ? '30px' : 30,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  logo: {
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  textButtons: {
    /*flexDirection: 'row',
    flexWrap: 'wrap',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '20px' : 20,*/
    marginLeft: Platform.OS === 'web' ? '-60px' : -60,
  },
  textButtonDelete: {
    /*flexDirection: 'row',
    flexWrap: 'wrap',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '20px' : 20,*/
    marginLeft: Platform.OS === 'web' ? '0px' : 0,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
  },
  textButtonsElement: {
    // Before - Width
    // width: Platform.OS === 'web' ? '300px' : 300,
    // After - Width
    // width: Platform.OS === 'web' ? '300px' : 300,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  textButtonsElementBackground: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    backgroundColor: '#BEBEBE',
  },
  textButtonsElementBackgroundDark: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    borderColor: '#ecb853',
    borderWidth: 2,
    backgroundColor: '#000000',
  },
  textButtonsElementBackgroundDelete: {
    width: Platform.OS === 'web' ? '30px' : 30,
    height: Platform.OS === 'web' ? '30px' : 30,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    backgroundColor: '#BEBEBE',
  },
  textButtonsElementBackgroundDeleteDark: {
    width: Platform.OS === 'web' ? '30px' : 30,
    height: Platform.OS === 'web' ? '30px' : 30,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    /*borderColor: '#ecb853',
    borderWidth: 2,*/
    backgroundColor: '#000000',
  },
  textButtonsElementIcon: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
  },
  textButtonsElementIconMain: {
    paddingTop: Platform.OS === 'web' ? '7px' : 7,
    paddingLeft: Platform.OS === 'web' ? '8px' : 8,
  },
  textButtonsElementIconMainDark: {
    paddingTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '6px' : 6,
  },
  textDark: {
    color: '#ecb853',
  },
});

export default Archive;
