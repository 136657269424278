/**
 * Input Audio
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable,
  TouchableOpacity,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  SPEECH,
  TEXT,
  CAMERA,
  SELECT_INPUT_TYPE,
  SELECT_INPUT_LANGUAGE_TYPE,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_ITALIAN,
  LANGUAGE_LUXEMBOURG_FRENCH,
  LANGUAGE_LUXEMBOURG_GERMAN,
  LANGUAGE_DUTCH,
  LANGUAGE_SUIZZE_FRENCH,
  LANGUAGE_SUIZZE_ITALIAN,
  LANGUAGE_SUIZZE_GERMAN,
  LANGUAGE_BRITISH,
  CONTRAST_MODE_TYPE,
  AUDIO_RECORDING,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
} from '../../../constants/constants';
import {Audio} from 'expo-av';
//import Video from 'react-native-video';
/*import {
  RTCIceCandidate,
  RTCSessionDescription,
  RTCView,
  MediaStream,
  MediaStreamTrack,
  mediaDevices,
  registerGlobals
} from 'react-native-webrtc';*/
//import { RTCPeerConnection } from 'react-native-webrtc-web-shim';

/*const configuration = {"iceServers": [{"url": "stun:stun.l.google.com:19302"}]};
const pc = new RTCPeerConnection(configuration);

let isFront = true;
mediaDevices.enumerateDevices().then(sourceInfos => {
  console.log(sourceInfos);
  let videoSourceId;
  for (let i = 0; i < sourceInfos.length; i++) {
    const sourceInfo = sourceInfos[i];
    if(sourceInfo.kind == "videoinput" && sourceInfo.facing == (isFront ? "front" : "environment")) {
      videoSourceId = sourceInfo.deviceId;
    }
  }
  mediaDevices.getUserMedia({
    audio: true,
    video: {
      width: 640,
      height: 480,
      frameRate: 30,
      facingMode: (isFront ? "user" : "environment"),
      deviceId: videoSourceId
    }
  })
    .then(stream => {
      // Got stream
    })
    .catch(error => {
      // Log error
    });
});

pc.createOffer().then(desc => {
  pc.setLocalDescription(desc).then(() => {
    // Send pc.localDescription to peer
  });
});

pc.onicecandidate = function (event) {
  // send event.candidate to peer
};*/

/*// existing code, keep this for native support
webRtcPeer.onaddstream = async ({ stream }) =>
  await addVideo(sessionId, stream);

// add an ontrack listener for web support
webRtcPeer.ontrack = async ({ track, streams }) => {
  if (track.kind === 'video') {
    await addVideo(sessionId, streams[0]);
  }
};*/

/*import AudioRecorderPlayer from 'react-native-audio-recorder-player';
const audioRecorderPlayer = new AudioRecorderPlayer();*/

/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import IconArrow from './../../../assets/iconarrow.png';
import TranslateIcon from './../../../assets/illustrationheaderoutputmethod.png';
import RecordIcon from './../../../assets/iconspeech.png';
import TextIcon from './../../../assets/icontext.png';
import CameraIcon from './../../../assets/iconcamera.png';
import AvatarIcon from './../../../assets/iconavatar.png';
import EarthIcon from './../../../assets/illustrationheaderlanguage.png';
import RecordStart from './../../../assets/recordaudiobuton.png';
import RecordStop from './../../../assets/recordaudiobutton.png';
import EqualizerIcon from './../../../assets/equalizer.png';
import LanguageIcon from './../../../assets/iconlanguage.png';
import FlagGermanIcon from './../../../assets/flaggerman.png';
import FlagDutchIcon from './../../../assets/flagdutch.png';
import FlagFrenchIcon from './../../../assets/flagfrench.png';
import FlagItalianIcon from './../../../assets/flagitaly.png';
import FlagGreeceIcon from './../../../assets/flaggreece.png';
import FlagGreatBritianIcon from './../../../assets/flaggreatbritain.png';
import FlagLuxembourgIcon from './../../../assets/flagluxembourg.png';
import FlagSuizzeIcon from './../../../assets/flagsuizze.png';
import {useTranslation} from 'react-i18next';
const InputAudio = ({navigation}): Node => {
  const [speech, setSpeech] = useState(false);
  const [text, setText] = useState(false);
  const [camera, setCamera] = useState(false);
  const [language, setLanguage] = useState('');
  const [isRecorded, setIsRecorded] = useState(false);
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [message, setMessage] = React.useState('');
  const [recordings, setRecordings] = React.useState([]);
  const [recording, setRecording] = React.useState();
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  const setActiveDefaultInput = async (speechValue, textValue, cameraValue) => {
    setSpeech(speechValue);
    setText(textValue);
    setCamera(cameraValue);

    await AsyncStorage.setItem(
      SELECT_INPUT_TYPE,
      speechValue == true
        ? SPEECH
        : textValue == true
        ? TEXT
        : cameraValue == true
        ? CAMERA
        : '',
    );
  };

  useEffect(() => {
    const inputTypeDefault = async () => {
      const defaultInputLanguageType = await AsyncStorage.getItem(
        SELECT_INPUT_LANGUAGE_TYPE,
      );
      const defaultInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
      defaultInputLanguageType === LANGUAGE_FRENCH
        ? setLanguage(LANGUAGE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_GERMAN
        ? setLanguage(LANGUAGE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_GREEK
        ? setLanguage(LANGUAGE_GREEK)
        : defaultInputLanguageType === LANGUAGE_ITALIAN
        ? setLanguage(LANGUAGE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_FRENCH
        ? setLanguage(LANGUAGE_LUXEMBOURG_FRENCH)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_GERMAN
        ? setLanguage(LANGUAGE_LUXEMBOURG_GERMAN)
        : defaultInputLanguageType === LANGUAGE_DUTCH
        ? setLanguage(LANGUAGE_DUTCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_FRENCH
        ? setLanguage(LANGUAGE_SUIZZE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_ITALIAN
        ? setLanguage(LANGUAGE_SUIZZE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_GERMAN
        ? setLanguage(LANGUAGE_SUIZZE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_BRITISH
        ? setLanguage(LANGUAGE_BRITISH)
        : setLanguage('');

      defaultInputType === SPEECH
        ? setActiveDefaultInput(true, false, false)
        : defaultInputType === TEXT
        ? setActiveDefaultInput(false, true, false)
        : defaultInputType === CAMERA
        ? setActiveDefaultInput(false, false, true)
        : setActiveDefaultInput(false, false, false);
    };
    inputTypeDefault();
  }, [isFocused]);

  async function startRecord() {
    try {
      const permission = await Audio.requestPermissionsAsync();

      if (permission.status === 'granted') {
        await Audio.setAudioModeAsync({
          allowsRecordingIOS: true,
          playsInSilentModeIOS: true,
        });
        const {recording} = await Audio.Recording.createAsync(
          Audio.RECORDING_OPTIONS_PRESET_HIGH_QUALITY,
        );
        setRecording(recording);
        setIsRecorded(true);
      } else {
        setMessage('Please grant permission to app to access microphone');
      }
    } catch (err) {
      console.error('Failed to start recording', err);
    }
  }

  async function stopRecord() {
    await recording.stopAndUnloadAsync();
    let updatedRecordings = [...recordings];
    const {sound, status} = await recording.createNewLoadedSoundAsync();
    updatedRecordings.push({
      sound: sound,
      duration: getDurationFormatted(status.durationMillis),
      file: recording.getURI(),
    });
    setRecordings(updatedRecordings);
    setIsRecorded(false);
    navigation.navigate('Input Audio Content', {
      recodeing: updatedRecordings,
    });
  }
  function getDurationFormatted(millis) {
    const minutes = millis / 1000 / 60;
    const minutesDisplay = Math.floor(minutes);
    const seconds = Math.round((minutes - minutesDisplay) * 60);
    const secondsDisplay = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutesDisplay}:${secondsDisplay}`;
  }

  const backInputSetting = () => {
    navigation.navigate('Input Method');
  };
  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}
        accessibilityLabel={t('Input')}>
        <Text
          style={
            darkModeAvailable
              ? [
                  styles.textHeadlineDark,
                  contrastSizing === SMALLTEXT
                    ? styles.smallTextAll
                    : contrastSizing === NORMALTEXT
                    ? styles.normalTextAll
                    : contrastSizing === BIGTEXT
                    ? styles.bigTextAll
                    : styles.normalTextAll,
                ]
              : [
                  styles.textHeadline,
                  contrastSizing === SMALLTEXT
                    ? styles.smallTextAll
                    : contrastSizing === NORMALTEXT
                    ? styles.normalTextAll
                    : contrastSizing === BIGTEXT
                    ? styles.bigTextAll
                    : styles.normalTextAll,
                ]
          }>
          {t('Input')}
        </Text>
        <Pressable
          onPress={backInputSetting}
          accessible={true}
          accessibilityLabel={'Back to input method - Settings'}>
          <View
            style={darkModeAvailable ? styles.textTabDark : styles.textTab}
            accessible={true}>
            <View
              style={styles.textTabImage}
              accessible={true}
              accessibilityLabel={'Selected input method'}>
              <Image
                width={Platform.OS == 'web' ? '30px' : 30}
                height={Platform.OS == 'web' ? '30px' : 30}
                source={{
                  uri:
                    speech == true
                      ? Platform.OS == 'web'
                        ? RecordIcon
                        : 'iconinput'
                      : text == true
                      ? Platform.OS == 'web'
                        ? TextIcon
                        : 'icontext'
                      : Platform.OS == 'web'
                      ? CameraIcon
                      : 'iconcamera',
                }}
                style={
                  language == ''
                    ? [
                        styles.settingsIconStyleType,
                        styles.settingsIconCustomStyle,
                      ]
                    : [
                        styles.settingsIconStyleType,
                        styles.settingsIconCustomStyleActive,
                      ]
                }
                resizeMode="contain"
              />
            </View>
            <View
              style={styles.textTabImage}
              accessible={true}
              accessibilityLabel={'Selected language input'}>
              <Image
                width={Platform.OS == 'web' ? '25px' : 25}
                height={Platform.OS == 'web' ? '25px' : 25}
                source={{
                  uri:
                    language == LANGUAGE_GERMAN
                      ? Platform.OS == 'web'
                        ? FlagGermanIcon
                        : 'flaggerman'
                      : language == LANGUAGE_GREEK
                      ? Platform.OS == 'web'
                        ? FlagGreeceIcon
                        : 'flaggreece'
                      : language == LANGUAGE_FRENCH
                      ? Platform.OS == 'web'
                        ? FlagFrenchIcon
                        : 'flagfrench'
                      : language == LANGUAGE_ITALIAN
                      ? Platform.OS == 'web'
                        ? FlagItalianIcon
                        : 'flagitaly'
                      : language == LANGUAGE_LUXEMBOURG_FRENCH ||
                        language == LANGUAGE_LUXEMBOURG_GERMAN
                      ? Platform.OS == 'web'
                        ? FlagLuxembourgIcon
                        : 'flagluxembourg'
                      : language == LANGUAGE_DUTCH
                      ? Platform.OS == 'web'
                        ? FlagDutchIcon
                        : 'flagdutch'
                      : language == LANGUAGE_SUIZZE_FRENCH ||
                        language == LANGUAGE_SUIZZE_ITALIAN ||
                        language == LANGUAGE_SUIZZE_GERMAN
                      ? Platform.OS == 'web'
                        ? FlagSuizzeIcon
                        : 'flagsuizze'
                      : language == LANGUAGE_BRITISH
                      ? Platform.OS == 'web'
                        ? FlagGreatBritianIcon
                        : 'flaggreatbritain'
                      : Platform.OS == 'web'
                      ? LanguageIcon
                      : 'iconlanguage',
                }}
                style={
                  language == ''
                    ? [
                        styles.settingsIconStyleFlag,
                        styles.settingsIconCustomStyle,
                      ]
                    : [
                        styles.settingsIconStyleFlag,
                        styles.settingsIconCustomStyleActive,
                      ]
                }
                resizeMode="contain"
              />
            </View>
          </View>
        </Pressable>
        <View
          style={
            darkModeAvailable
              ? styles.textInputFieldMainDark
              : styles.textInputFieldMain
          }
          accessible={isRecorded}>
          {isRecorded ? (
            <>
              <Image
                width={Platform.OS == 'web' ? '200px' : 200}
                height={Platform.OS == 'web' ? '50px' : 50}
                source={{
                  uri: Platform.OS == 'web' ? EqualizerIcon : 'equalizer',
                }}
                style={styles.iconAudioEqualizer}
                resizeMode="contain"
              />
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.text,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Record_Text')}
              </Text>
              {/*Platform.OS !== "web" ? (
                <RTCView streamURL={stream.toURL()}/>
              ) : (
                <RTCView stream={stream}/>
              )*/}
            </>
          ) : (
            <></>
          )}
        </View>
        <TouchableOpacity
          onPress={isRecorded ? stopRecord : startRecord}
          accessible={isRecorded}>
          {isRecorded ? (
            <Image
              width={Platform.OS == 'web' ? '100px' : 100}
              height={Platform.OS == 'web' ? '100px' : 100}
              source={{
                uri: Platform.OS == 'web' ? RecordStop : 'recordaudiobutton',
              }}
              style={styles.iconAudioButtons}
              resizeMode="contain"
            />
          ) : (
            <Image
              width={Platform.OS == 'web' ? '100px' : 100}
              height={Platform.OS == 'web' ? '100px' : 100}
              source={{
                uri: Platform.OS == 'web' ? RecordStart : 'recordaudiobuton',
              }}
              style={styles.iconAudioButtons}
              resizeMode="contain"
            />
          )}
        </TouchableOpacity>
        {/*<TouchableOpacity
          style={styles.buttonMain}
          onPress={() => navigation.navigate('Output Method')}>
          <Text style={styles.button}>NEXT</Text>
        </TouchableOpacity>*/}
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsIconStyleType: {
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '35px' : 35,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-5px' : -5,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-5px' : -5,
  },
  settingsIconStyleFlag: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsIconCustomStyle: {
    marginLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  textTab: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#BEBEBE',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  textTabDark: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#ecb853',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  textTabImage: {
    width: Platform.OS === 'web' ? '25px' : 25,
  },
  textHeadline: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    alignItems: 'left',
    textAlign: 'left',
  },
  textHeadlineDark: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    color: '#ecb853',
    alignItems: 'left',
    textAlign: 'left',
  },
  textInputField: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    padding: Platform.OS === 'web' ? '10px' : 10,
  },
  textInputFieldMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#BEBEBE',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldMainDark: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldLength: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textInputFieldLengthColor: {
    color: '#BEBEBE',
  },
  textButtons: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textButtonsElement: {
    width: Platform.OS === 'web' ? '75px' : 75,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  textButtonsElementBackground: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    backgroundColor: '#BEBEBE',
  },
  textButtonsElementIcon: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
  },
  textButtonsElementIconMain: {
    paddingTop: Platform.OS === 'web' ? '7px' : 7,
    paddingLeft: Platform.OS === 'web' ? '8px' : 8,
  },
  textButtonsElementIconModal: {
    width: Platform.OS === 'web' ? '80px' : 80,
    height: Platform.OS === 'web' ? '80px' : 80,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  textButtonsTitle: {
    width: Platform.OS === 'web' ? '50px' : 50,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '7px' : 7,
  },
  buttonMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMainDisabled: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    pointerEvents: 'none',
    opacity: 0.4,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#D05B5B',
    color: '#fff',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  iconAudioEqualizer: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    position: 'absolute',
    top: Platform.OS === 'web' ? '125px' : 125,
    left: Platform.OS === 'web' ? '50px' : 50,
  },
  iconAudioButtons: {
    width: Platform.OS === 'web' ? '100px' : 100,
    height: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  textDark: {
    color: '#ecb853',
    //width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    position: 'absolute',
    top: Platform.OS === 'web' ? '200px' : 200,
    left: Platform.OS === 'web' ? '75px' : 75,
  },
  text: {
    color: '#000000',
    //width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    position: 'absolute',
    top: Platform.OS === 'web' ? '200px' : 200,
    left: Platform.OS === 'web' ? '75px' : 75,
  },
});

export default InputAudio;
