/**
 * Registration
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  TextInput,
  useColorScheme,
  View,
  Image,
  Button,
  Platform,
  Pressable,
  TouchableOpacity,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Logo from './../../assets/easierlogowithtext.png';
import LogoDark from './../../assets/easierlogowithtextdark.png';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  DEVELOPMENT,
  TEST,
  DEFAULT_VALUE_TYPE,
} from '../../constants/constants';
import {useTranslation} from 'react-i18next';
import axios from 'axios';

const Registration = ({navigation}): Node => {
  const CheckboxButton = ({onPress, selected, children}) => {
    return (
      <View style={styles.checkboxButtonContainer}>
        <TouchableOpacity
          onPress={onPress}
          style={
            selected
              ? darkModeAvailable
                ? styles.checkboxButtonActiveDark
                : styles.checkboxButtonActive
              : darkModeAvailable
              ? styles.checkboxButtonDark
              : styles.checkboxButton
          }>
          {selected ? (
            <View
              style={
                darkModeAvailable
                  ? styles.checkboxButtonIconDark
                  : styles.checkboxButtonIcon
              }
            />
          ) : null}
        </TouchableOpacity>
        <TouchableOpacity onPress={onPress}>
          <Text style={[styles.checkboxButtonText, styles.checkboxMainElement]}>
            {children}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const [email, onChangeEmail] = useState('');
  const [password, onChangePassword] = useState('');
  const [passwordRepeat, onChangePasswordRepeat] = useState('');
  const [username, onChangeUsername] = useState('');
  const [usernameErrorMessage, setUsernameErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');
  const [passwordRepeatErrorMessage, setPasswordRepeatErrorMessage] =
    useState('');
  const [registerCheckboxErrorMessage, setRegisterCheckboxErrorMessage] =
    useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordRepeatError, setPasswordRepeatError] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [registerCheckboxError, setRegisterCheckboxError] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [registerCheckbox, setRegisterCheckbox] = useState(false);
  const [defaultValue, setDefaultValue] = useState([
    {
      id: 1,
      value: 'default',
      selected: false,
    },
  ]);
  const {t} = useTranslation();
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const isFocused = useIsFocused();

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    /*const resetCheckboxDefault = async () => {
      await AsyncStorage.setItem('@PrivacyCheck', 'false');
      setRegisterCheckboxError(false);
    };*/
    checkDarkModeDefault();
    checkSizeDefault();
    //resetCheckboxDefault();
  }, [isFocused]);

  const registerData = async (username, userEmail, userPassword) => {
    if (username != '' && userEmail != '' && userPassword != '') {
      navigation.navigate('Log In');
      onChangeUsername('');
      onChangeEmail('');
      onChangePassword('');
      setUsernameError(false);
      setEmailError(false);
      setPasswordError(false);
    } else {
      setUsernameError(true);
      setEmailError(true);
      setPasswordError(true);
    }
  };

  const PrivacyData = async () => {
    await AsyncStorage.setItem('@PrivacyWay', 'Normal');
    await navigation.navigate('Privacy');
  };

  const validationRegister = e => {
    e.preventDefault();
    // Previous:
    /*const url = 'https://easier-integration.nuromedia.com/server';*/
    // New:
    const url = TEST + 'user/register';
    const data = {
      password: encodeURIComponent(password),
      email: email,
      username: username,
    };
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Method': '*',
      },
    };
    let emailValidation =
      /[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+/;
    let passwordValidation = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}/;
    /*const checkboxValidation = async () => {
      const checkboxValidator = await AsyncStorage.getItem('@PrivacyCheck');
      setRegisterCheckboxError(checkboxValidator);
    };
    await checkboxValidation();*/

    if (
      username.length >= 1 &&
      email.length >= 1 &&
      email.match(emailValidation) &&
      password.length >= 1 &&
      password.match(passwordValidation) &&
      passwordRepeat.length >= 1 &&
      passwordRepeat.match(passwordValidation) &&
      password === passwordRepeat &&
      registerCheckboxError
    ) {
      axios
        .post(url, data, headers)
        .then(response => {
          if (response.status === 200) {
            console.log(response.data.message);
            onChangeUsername('');
            onChangeEmail('');
            onChangePassword('');
            onChangePasswordRepeat('');
            setUsernameError(false);
            setEmailError(false);
            setPasswordError(false);
            setPasswordRepeatError(false);
            setRegisterCheckboxError(false);
            setTimeout(() => {
              navigation.navigate('Log In');
            }, 1000);
          } else {
            console.log('Error: Access Denied');
          }
        })
        .catch(e => {
          console.log(e);
          /*if (e.response.status === 400) {
            console.log('test 1');
            setEmailError(true);
          }*/
          if (username.length === 0) {
            setUsernameError(true);
            setUsernameErrorMessage(t('Username_empty'));
          }
          if (username.length >= 1) {
            setUsernameError(false);
            setUsernameErrorMessage('');
          }
          if (email.length === 0) {
            setEmailError(true);
            setEmailErrorMessage(t('Email_empty'));
          }
          if (email.length >= 1 && !email.match(emailValidation)) {
            setEmailError(true);
            setEmailErrorMessage(t('Email_is_invalid'));
          }
          if (email.length >= 1 && email.match(emailValidation)) {
            setEmailError(false);
            setEmailErrorMessage('');
          }
          if (password.length === 0) {
            setPasswordError(true);
            setPasswordErrorMessage(t('Password_empty'));
          }
          if (password.length >= 1 && !password.match(passwordValidation)) {
            setPasswordError(true);
            setPasswordErrorMessage(t('Password_security_rules'));
          }
          if (password.length >= 1 && password.match(passwordValidation)) {
            setPasswordError(false);
            setPasswordErrorMessage('');
          }
          if (passwordRepeat.length === 0) {
            setPasswordRepeatError(true);
            setPasswordRepeatErrorMessage(t('Password_empty'));
          }
          if (
            passwordRepeat.length >= 1 &&
            !passwordRepeat.match(passwordValidation)
          ) {
            setPasswordRepeatError(true);
            setPasswordRepeatErrorMessage(t('Password_security_rules'));
          }
          if (
            passwordRepeat.length >= 1 &&
            passwordRepeat.match(passwordValidation)
          ) {
            setPasswordRepeatError(false);
            setPasswordRepeatErrorMessage('');
          }
          if (password !== passwordRepeat) {
            setPasswordError(true);
            setPasswordRepeatError(true);
            setPasswordErrorMessage(t('Password_not_match'));
            setPasswordRepeatErrorMessage(t('Password_not_match'));
          }
          if (
            password.length >= 1 &&
            password.match(passwordValidation) &&
            password === passwordRepeat
          ) {
            setPasswordError(true);
            setPasswordRepeatError(true);
            setPasswordErrorMessage('');
            setPasswordRepeatErrorMessage('');
          }
          if (
            password.length >= 1 &&
            !password.match(passwordValidation) &&
            password === passwordRepeat
          ) {
            setPasswordError(true);
            setPasswordRepeatError(true);
            setPasswordErrorMessage(t('Password_security_rules'));
            setPasswordRepeatErrorMessage('');
          }
          if (
            password.length >= 1 &&
            !password.match(passwordValidation) &&
            password !== passwordRepeat
          ) {
            setPasswordError(true);
            setPasswordRepeatError(true);
            setPasswordErrorMessage(t('Password_security_rules'));
            setPasswordRepeatErrorMessage(t('Password_not_match'));
          }
          if (!registerCheckboxError) {
            setRegisterCheckboxErrorMessage(t('Privacy_is_required'));
          }
          if (registerCheckboxError) {
            setRegisterCheckboxErrorMessage('');
          }
          if (
            username.length >= 1 &&
            email.length >= 1 &&
            email.match(emailValidation) &&
            password.length >= 1 &&
            password.match(passwordValidation) &&
            passwordRepeat.length >= 1 &&
            passwordRepeat.match(passwordValidation) &&
            password === passwordRepeat &&
            registerCheckboxError
          ) {
            setEmailError(true);
            setEmailErrorMessage(t('Email_exists'));
          }
        });
    } else {
      if (username.length === 0) {
        setUsernameError(true);
        setUsernameErrorMessage(t('Username_empty'));
      }
      if (username.length >= 1) {
        setUsernameError(false);
        setUsernameErrorMessage('');
      }
      if (email.length === 0) {
        setEmailError(true);
        setEmailErrorMessage(t('Email_empty'));
      }
      if (email.length >= 1 && !email.match(emailValidation)) {
        setEmailError(true);
        setEmailErrorMessage(t('Email_is_invalid'));
      }
      if (email.length >= 1 && email.match(emailValidation)) {
        setEmailError(false);
        setEmailErrorMessage('');
      }
      if (password.length === 0) {
        setPasswordError(true);
        setPasswordErrorMessage(t('Password_empty'));
      }
      if (password.length >= 1 && !password.match(passwordValidation)) {
        setPasswordError(true);
        setPasswordErrorMessage(t('Password_security_rules'));
      }
      if (password.length >= 1 && password.match(passwordValidation)) {
        setPasswordError(false);
        setPasswordErrorMessage('');
      }
      if (
        passwordRepeat.length >= 1 &&
        !passwordRepeat.match(passwordValidation)
      ) {
        setPasswordRepeatError(true);
        setPasswordRepeatErrorMessage(t('Password_security_rules'));
      }
      if (passwordRepeat.length === 0) {
        setPasswordRepeatError(true);
        setPasswordRepeatErrorMessage(t('Password_empty'));
      }
      if (
        passwordRepeat.length >= 1 &&
        passwordRepeat.match(passwordValidation)
      ) {
        setPasswordRepeatError(false);
        setPasswordRepeatErrorMessage('');
      }
      if (password !== passwordRepeat) {
        setPasswordError(true);
        setPasswordRepeatError(true);
        setPasswordErrorMessage(t('Password_not_match'));
        setPasswordRepeatErrorMessage(t('Password_not_match'));
      }
      if (
        password.length >= 1 &&
        password.match(passwordValidation) &&
        password === passwordRepeat
      ) {
        setPasswordError(true);
        setPasswordRepeatError(true);
        setPasswordErrorMessage('');
        setPasswordRepeatErrorMessage('');
      }
      if (
        password.length >= 1 &&
        !password.match(passwordValidation) &&
        password === passwordRepeat
      ) {
        setPasswordError(true);
        setPasswordRepeatError(true);
        setPasswordErrorMessage(t('Password_security_rules'));
        setPasswordRepeatErrorMessage('');
      }
      if (
        password.length >= 1 &&
        !password.match(passwordValidation) &&
        password !== passwordRepeat
      ) {
        setPasswordError(true);
        setPasswordRepeatError(true);
        setPasswordErrorMessage(t('Password_security_rules'));
        setPasswordRepeatErrorMessage(t('Password_not_match'));
      }
      if (!registerCheckboxError) {
        setRegisterCheckboxErrorMessage(t('Privacy_is_required'));
      }
      if (registerCheckboxError) {
        setRegisterCheckboxErrorMessage('');
      }
    }
  };

  const registrationValidationSchema = Yup.object().shape({
    username: Yup.string().required(t('Username is Required')),
    email: Yup.string()
      .email(t('Please enter valid email'))
      .required(t('Email Address is Required')),
    password: Yup.string().required(t('Password is required')),
  });
  const toggleCheckbox = () => {
    setRegisterCheckboxError(!registerCheckboxError);
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel="Logo of EASIER">
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? LogoDark
                    : Logo
                  : darkModeAvailable
                  ? 'easierlogowithtextdark'
                  : 'easierlogowithtext',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <Formik
          initialValues={{email: '', password: ''}}
          /*validationSchema={registrationValidationSchema}*/
          onSubmit={values => console.log(values)}>
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            values,
          }) => (
            <>
              <View
                style={styles.formInput}
                accessible={true}
                accessibilityLabel={t('Username')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.labelTextDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.labelText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Username')}
                </Text>
                <TextInput
                  style={darkModeAvailable ? styles.inputDark : styles.input}
                  onChangeText={onChangeUsername}
                  value={username}
                  keyboardType="username"
                  accessibilityLabel="Input username"
                />
                <Text
                  nativeID="UsernameError"
                  style={
                    usernameError
                      ? darkModeAvailable
                        ? [
                            styles.errorTextDark,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.errorText,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                      : [
                          styles.errorTextHide,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }
                  accessible={emailError}>
                  {usernameErrorMessage}
                </Text>
                {/*errors.email && touched.email && (
                  <Text style={styles.errorText}>{errors.email}</Text>
                )*/}
              </View>
              <View
                style={styles.formInput}
                accessible={true}
                accessibilityLabel={t('E-Mail')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.labelTextDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.labelText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('E-Mail')}
                </Text>
                <TextInput
                  style={darkModeAvailable ? styles.inputDark : styles.input}
                  onChangeText={onChangeEmail}
                  value={email}
                  keyboardType="email-address"
                  accessibilityLabel="Input email address"
                />
                <Text
                  nativeID="EmailError"
                  style={
                    emailError
                      ? darkModeAvailable
                        ? [
                            styles.errorTextDark,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.errorText,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                      : [
                          styles.errorTextHide,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }
                  accessible={emailError}>
                  {emailErrorMessage}
                </Text>
                {/*errors.email && touched.email && (
                  <Text style={styles.errorText}>{errors.email}</Text>
                )*/}
              </View>
              <View
                style={styles.formInput}
                accessible={true}
                accessibilityLabel={t('Password')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.labelTextDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.labelText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Password')}
                </Text>
                <TextInput
                  style={darkModeAvailable ? styles.inputDark : styles.input}
                  onChangeText={onChangePassword}
                  value={password}
                  secureTextEntry={true}
                  accessibilityLabel="Input password"
                />
                <Text
                  nativeID="PasswordError"
                  style={
                    passwordError
                      ? darkModeAvailable
                        ? [
                            styles.errorTextDark,
                            styles.errorTextAdditionalDark,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.errorText,
                            styles.errorTextAdditional,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                      : [
                          styles.errorTextHide,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }
                  accessible={passwordError}>
                  {passwordErrorMessage}
                </Text>
                {/*errors.password && touched.password && (
                  <Text style={styles.errorText}>{errors.password}</Text>
                )*/}
              </View>
              <View
                style={styles.formInput}
                accessible={true}
                accessibilityLabel={t('Password_Repeat')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.labelTextDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.labelText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Password_Repeat')}
                </Text>
                <TextInput
                  style={darkModeAvailable ? styles.inputDark : styles.input}
                  onChangeText={onChangePasswordRepeat}
                  value={passwordRepeat}
                  secureTextEntry={true}
                  accessibilityLabel="Input password repeat"
                />
                <Text
                  nativeID="PasswordRepeatError"
                  style={
                    passwordRepeatError
                      ? darkModeAvailable
                        ? [
                            styles.errorTextDark,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.errorText,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                      : [
                          styles.errorTextHide,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }
                  accessible={passwordRepeatError}>
                  {passwordRepeatErrorMessage}
                </Text>
                {/*errors.password && touched.password && (
                  <Text style={styles.errorText}>{errors.password}</Text>
                )*/}
              </View>
              <View
                style={styles.formInputTwo}
                accessible={true}
                accessibilityLabel={t('Password')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.labelTextDarkTwo,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.labelTextTwo,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Password_Info')}
                </Text>
              </View>
              <View
                style={styles.formInputTwo}
                accessible={true}
                accessibilityLabel={t('Privacy')}>
                {defaultValue.map(item => (
                  <CheckboxButton
                    onPress={toggleCheckbox}
                    selected={registerCheckboxError}
                    key={1}
                    accessible={true}
                    accessibilityLabel={t('Privacy')}
                    accessibilityHint="Enable or disable privacy">
                    <Text
                      style={
                        darkModeAvailable
                          ? [
                              styles.labelTextDarkTwo,
                              contrastSizing === SMALLTEXT
                                ? styles.smallTextAll
                                : contrastSizing === NORMALTEXT
                                ? styles.normalTextAll
                                : contrastSizing === BIGTEXT
                                ? styles.bigTextAll
                                : styles.normalTextAll,
                            ]
                          : [
                              styles.labelTextTwo,
                              contrastSizing === SMALLTEXT
                                ? styles.smallTextAll
                                : contrastSizing === NORMALTEXT
                                ? styles.normalTextAll
                                : contrastSizing === BIGTEXT
                                ? styles.bigTextAll
                                : styles.normalTextAll,
                            ]
                      }>
                      <Text>
                        {t('Privacy_Title')}{' '}
                        <Text
                          style={styles.linkTextPrivacy}
                          onPress={PrivacyData}>
                          {t('Privacy_Text')}
                        </Text>
                        <Text> *</Text>
                      </Text>
                    </Text>
                  </CheckboxButton>
                ))}
                <Text
                  nativeID="RegisterCheckboxError"
                  style={
                    registerCheckboxError === false
                      ? darkModeAvailable
                        ? [
                            styles.errorTextDark,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.errorText,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                      : [
                          styles.errorTextHide,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }
                  accessible={registerCheckboxError}>
                  {registerCheckboxErrorMessage}
                </Text>
              </View>
              <View
                style={styles.additionalOption}
                accessible={true}
                accessibilityLabel={t('Back_to_Log_In')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.underlineTextDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.underlineText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }
                  onPress={() => navigation.navigate('Log In')}>
                  {t('Back_to_Log_In')}
                </Text>
              </View>
              <View
                style={styles.formInput}
                accessible={true}
                accessibilityLabel={t('Registration')}>
                {/*<Button
                  title={t('Registration')}
                  color={'#D05B5B'}
                  onPress={validationRegister}
                  style={styles.button}
                />*/}
                <Pressable
                  style={darkModeAvailable ? styles.buttonDark : styles.button}
                  onPress={validationRegister}
                  accessible={true}
                  accessibilityLabel={t('Registration')}
                  accessibilityHint="Registration starting">
                  <Text
                    style={
                      darkModeAvailable
                        ? [
                            styles.textDark,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.text,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                    }>
                    {t('Registration')}
                  </Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  button: {
    backgroundColor: '#D05B5B',
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  text: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textDark: {
    color: '#ecb853',
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  headlineMain: {
    position: Platform.OS === 'web' ? 'absolute' : 'absolute',
    width: Platform.OS === 'web' ? '100%' : '100%',
    margin: Platform.OS === 'web' ? 'auto' : 'auto',
    top: Platform.OS === 'web' ? '20px' : 20,
  },
  headline: {
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    fontSize: Platform.OS === 'web' ? '24px' : 24,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#C5C5C5',
  },
  errorText: {
    marginLeft: Platform.OS === 'web' ? '25px' : 25,
    color: '#ff0000',
    opacity: Platform.OS === 'web' ? '1.0' : 1.0,
  },
  errorTextDark: {
    marginLeft: Platform.OS === 'web' ? '25px' : 25,
    color: '#ecb853',
    opacity: Platform.OS === 'web' ? '1.0' : 1.0,
  },
  errorTextAdditional: {
    marginLeft: Platform.OS === 'web' ? '25px' : 25,
    color: '#ff0000',
    opacity: Platform.OS === 'web' ? '1.0' : 1.0,
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
  },
  errorTextAdditionalDark: {
    marginLeft: Platform.OS === 'web' ? '25px' : 25,
    color: '#ff0000',
    opacity: Platform.OS === 'web' ? '1.0' : 1.0,
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
  },
  errorTextHide: {
    opacity: Platform.OS === 'web' ? '0.0' : 0.0,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '15px' : 15,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  formInput: {
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  formInputTwo: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  labelText: {
    position: 'absolute',
    top: Platform.OS === 'web' ? '-13px' : -13,
    left: Platform.OS === 'web' ? '20px' : 20,
    padding: Platform.OS === 'web' ? '5px' : 5,
    backgroundColor: Platform.OS === 'web' ? '#FFFFFF' : 'white',
    zIndex: 10,
    color: '#707070',
  },
  labelTextDark: {
    position: 'absolute',
    top: Platform.OS === 'web' ? '-13px' : -13,
    left: Platform.OS === 'web' ? '20px' : 20,
    padding: Platform.OS === 'web' ? '5px' : 5,
    backgroundColor: '#000',
    zIndex: 10,
    color: '#ecb853',
  },
  labelTextTwo: {
    position: 'relative',
    top: Platform.OS === 'web' ? '-3px' : -3,
    padding: Platform.OS === 'web' ? '5px' : 5,
    backgroundColor: Platform.OS === 'web' ? '#FFFFFF' : 'white',
    zIndex: 10,
    color: '#707070',
  },
  labelTextDarkTwo: {
    position: 'relative',
    top: Platform.OS === 'web' ? '-3px' : -3,
    padding: Platform.OS === 'web' ? '5px' : 5,
    backgroundColor: '#000',
    zIndex: 10,
    color: '#ecb853',
  },
  additionalOption: {
    flexDirection: 'row',
    //width: Platform.OS === 'web' ? '250px' : 250,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '-10px' : -10,
  },
  resetPasswordStyle: {
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    /*marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,*/
  },
  underlineText: {
    textDecorationLine: 'underline',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  underlineTextDark: {
    textDecorationLine: 'underline',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
  },
  registerStyle: {
    marginLeft: Platform.OS === 'web' ? '20px' : 20,
    /*marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,*/
  },
  extraText: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    textDecorationLine: 'underline',
  },
  linkTextPrivacy: {
    fontWeight: 600,
    textDecorationLine: 'underline',
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  input: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    borderWidth: 1,
    padding: 15,
    borderColor: '#C5C5C5',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  inputDark: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    borderWidth: 1,
    padding: 15,
    borderColor: '#ecb853',
    color: '#ecb853',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  checkboxButtonMainContainer: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  checkboxButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  checkboxButton: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    //borderRadius: 10,
    borderWidth: 3,
    borderColor: '#707070',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxButtonActive: {
    height: 20,
    width: 20,
    backgroundColor: '#D05B5B',
    //borderRadius: 10,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#D05B5B',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxButtonIcon: {
    height: 15,
    width: 7,
    //borderRadius: 7,
    borderBottomWidth: 3,
    borderRightWidth: 3,
    borderColor: '#fff',
    backgroundColor: '#D05B5B',
    transform: [{rotate: '45deg'}],
    marginTop: Platform.OS === 'web' ? '-4px' : -4,
  },
  checkboxButtonDark: {
    height: 20,
    width: 20,
    backgroundColor: '#000000',
    //borderRadius: 10,
    borderWidth: 3,
    borderColor: '#ecb853',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxButtonActiveDark: {
    height: 20,
    width: 20,
    backgroundColor: '#000000',
    //borderRadius: 10,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#ecb853',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxButtonIconDark: {
    height: 15,
    width: 7,
    //borderRadius: 7,
    borderBottomWidth: 3,
    borderRightWidth: 3,
    borderColor: '#ecb853',
    backgroundColor: '#000000',
    transform: [{rotate: '45deg'}],
    marginTop: Platform.OS === 'web' ? '-4px' : -4,
  },
  checkboxButtonText: {
    fontSize: 14,
    marginLeft: Platform.OS === 'web' ? '16px' : 16,
  },
  checkboxButtonTextInner: {
    paddingRight: Platform.OS === 'web' ? '10px' : 10,
  },
  checkboxButtonTextInnerDark: {
    paddingRight: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
  },
  checkboxMainElement: {
    width: Platform.OS === 'web' ? '200px' : 200,
    lineHeight: Platform.OS === 'web' ? '2' : 2,
  },
});

export default Registration;
