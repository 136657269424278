export default {
  login: 'Accesso',
  Status_Login: 'Accesso allo stato',
  Translation: 'Traduzione',
  Translation_Output: 'Uscita di traduzione',
  Registration: 'Registrazione',
  Password_Info:
    'La password deve essere di almeno 8 caratteri con una lettera maiuscola e un segno di punteggiatura.',
  Reset_password: 'Reimpostare la password',
  New_Password: 'Nuova password',
  About: 'Informazioni su',
  Start: 'Iniziare',
  Logout: 'Disconnessione',
  Profile: 'Profilo',
  Info: 'Info',
  Settings: 'Impostazioni',
  Archive: 'Archivio',
  Default_Input: 'Ingresso generale',
  Default_Output: 'Uscita generale',
  Voice_settings: 'Impostazioni voce',
  Contrast: 'Contrasto',
  Avatar_settings: 'Impostazioni avatar',
  Languages_Settings: 'Impostazioni delle lingue',
  Upload_Video: 'Caricare un video',
  Choose: 'Scegliere',
  Select_Output_Method: 'Selezionare il metodo di output',
  Select_Output_Language: 'Selezionare la lingua di uscita',
  Select_Input_Language: 'Seleziona la lingua di ingresso',
  Submit: 'Invia',
  Preview_Video_Upload: 'Il file video caricato sarà presente qui',
  Input_Method: 'Metodo di input',
  Spoken_Language: 'Lingua parlata',
  Spoken_Language_Settings: 'Impostazioni della lingua parlata',
  Write: 'Scrivere',
  Record_speech: 'Registra il parlato',
  Record_content: 'Registrare il contenuto',
  Video_input: 'Ingresso video',
  Record_Video: 'Registrazione video',
  Output_Method: 'Metodo di uscita',
  Language: 'Lingua',
  Back: 'Indietro',
  All: 'Tutti',
  Speech: 'Discorso',
  Text: 'Testo',
  Please_enter_valid_email: "Inserisci un'email valida",
  Email_Address_is_Required: "L'indirizzo e-mail è richiesto",
  Password_is_required: 'La password è necessaria',
  Welcome_to: 'Benvenuti a',
  E_Mail: 'e-mail',
  Email_is_not_correct: "L'e-mail non è corretta.",
  Password: 'La password',
  Password_Repeat: 'Ripetizione della password',
  Password_is_not_correct: 'La password non è corretta.',
  Email_or_password_is_not_correct: "L'email o la password non sono corrette",
  Username_empty: 'Il nome utente non deve essere vuoto.',
  Email_empty: "L'e-mail non deve essere vuota.",
  Email_exists: "L'e-mail esiste già.",
  Password_empty: 'La password non deve essere vuota.',
  Password_not_match: 'La password non corrisponde.',
  No_data_available: 'Nessun dato disponibile',
  Password_security_rules:
    'La password non corrisponde alle regole di sicurezza.',
  MAIN_TEXT:
    'EASIER è un progetto Horizon 2020 che mira a progettare, sviluppare e convalidare un sistema completo di traduzione automatica multilingue che fungerà da struttura per la comunicazione senza barriere tra individui sordi e udenti, oltre a fornire una piattaforma per supportare la creazione di contenuti in lingua dei segni.',
  SUB_TEXT:
    "Il progetto prevede un forte coinvolgimento degli utenti finali nella progettazione, nello sviluppo e nella valutazione dell'ecosistema EASIER, in cui gli utenti delle varie lingue dei segni e delle lingue parlate europee potranno utilizzare la loro lingua preferita per interagire tra loro.",
  FOUNDED_TEXT:
    "Il progetto EASIER è stato finanziato dal programma Horizon 2020 dell'UE con il numero di accordo di sovvenzione 101016982.",
  Current_password: 'Password attuale',
  New_password: 'Nuova password',
  New_password_is_invalid: 'La nuova password non è valida.',
  code: 'Codice',
  code_invalid: 'Il codice non è valido.',
  mailbox_check: 'Controllare la casella di posta elettronica.',
  Save: 'Salva',
  Name: 'Nome',
  ID: 'ID',
  Delete_account: 'Elimina account',
  Username_is_Required: 'Il nome utente è richiesto',
  Username: 'Nome utente',
  Username_is_invalid: 'Il nome utente non è valido.',
  Email_is_invalid: "L'e-mail non è valida",
  Password_is_invalid: 'La password non è valida',
  Back_to_Log_In: 'Torna a Log In',
  Background: 'Sfondo',
  Clothing: 'Abbigliamento',
  Gender: 'Genere',
  Dark_Mode: 'Modalità scura',
  Text_size: 'Dimensione del testo',
  Camera: 'Segno',
  Adapt_intonation: "Adattare l'intonazione",
  Voice_style: 'Stile vocale',
  Language_Level: 'Livello linguistico',
  Play_Audio: "Fare clic sull'icona per riprodurre il file audio registrato.",
  Accent: 'Accento',
  Voice: 'Voce',
  Recording: 'Registrazione',
  Logged_in_as: 'Accesso come:',
  START: 'INIZIO',
  Use_favorite_settings: 'Usa le impostazioni preferite',
  Failure: 'Errore',
  No_default_input_and_output:
    'Non sono stati impostati ingressi e uscite predefiniti. Accedere dapprima al menu delle impostazioni.',
  Go_to_settings: 'Vai alle impostazioni',
  OK: 'Ok',
  Status: 'Stato',
  Signing_in: 'Accesso a ....',
  Translation_in_progress: 'Traduzione in corso ....',
  Input: 'Ingresso',
  Recording_time: 'Tempo di registrazione',
  Delete: 'Cancellare',
  Copy: 'Copiare',
  Share: 'Condividi',
  NEXT: 'AVANTI',
  Activate_your_camera: 'Attivare la fotocamera per registrare un video',
  Activate_camera: 'Attivare la fotocamera',
  Select_video_file: 'Selezionare un file video memorizzato sul dispositivo',
  Open_video_file: 'Aprire il file video',
  Video_recording_element: 'Elemento di registrazione video',
  Choose_Language: 'Scegliere la lingua',
  Tap_and_write: 'Toccare e scrivere',
  Clear_text: 'Testo chiaro?',
  NO: 'NO',
  YES: 'SÌ',
  Clear: 'Cancella',
  Paste: 'Incolla',
  TRANSLATE: 'TRADURRE',
  Output: 'Uscita',
  Audio_Input: 'Ingresso audio',
  Text_Input: 'Ingresso testo',
  Camera_Input: 'Ingresso telecamera',
  Audio_Output: 'Uscita audio',
  Text_Output: 'Uscita testo',
  Avatar_Output: 'Uscita Avatar',
  Audio_Play: 'Lettore audio',
  Camera_Play: 'Lettore video',
  BACK_TO_START_MENU: 'TORNA AL MENU INIZIALE',
  Avatar_Language_Unavailable:
    "L'Avatar non è attualmente disponibile in questa lingua.",
  English: 'Inglese',
  German: 'Tedesco',
  French: 'Francese',
  Italian: 'Italiano',
  Greek: 'Greco',
  Dutch: 'Olandese',
  Suizze_German: 'Svizzero-tedesco',
  Record_Text: 'Registrazione in corso',
  video_one:
    "Fare clic sull'icona di registrazione per attivare la registrazione",
  video_two:
    'Fare clic sul pulsante bianco a sinistra del timer per avviare e arrestare la registrazione',
  New_translation: 'Nuova traduzione',
  Appearance: 'Aspetto',
  Session_Old: 'La sessione è vecchia. Effettuare nuovamente il login.',
  Unable_Service_Message:
    'Si è verificato un problema o il servizio non è attualmente disponibile.',
  no_archives: 'Nessun archivio disponibile',
  Archive_Detail: 'Dettaglio archivio',
  Delete_all: 'Cancellare tutti',
  Delete_all_archive: 'Si desidera eliminare tutti gli archivi?',
  Delete_one_archive: 'Si desidera eliminare questo archivio?',
  Delete_user_account: 'Vuoi cancellare il tuo account?',
  Log_title: 'Log',
  Affect_Fusion: 'Risultato della fusione',
  Intermediate_translation: 'Traduzione intermedia',
  Translation_input_from_text: 'Input di traduzione dal testo',
  Translation_input_from_speech: 'Input di traduzione dal parlato',
  Translation_input_from_sign_video:
    'Ingresso di traduzione da video dei segni',
  Translation_output_to_text: 'Uscita di traduzione verso il testo',
  Translation_output_to_speech: 'Uscita di traduzione verso il parlato',
  Translation_output_to_avatar: 'Uscita di traduzione in avatar',
  Privacy: 'Informativa sulla privacy',
  Privacy_Title: 'Con la registrazione si accetta la',
  Privacy_Text: 'Informativa sulla privacy',
  Privacy_is_required: 'La privacy è necessaria',
  Introduction: 'Introduzione',
  Introduction_One:
    'EASIER è un progetto Horizon 2020 che mira a progettare, sviluppare e convalidare un sistema completo di traduzione automatica multilingue che fornisca una struttura per la comunicazione accessibile tra persone sorde e udenti, nonché una piattaforma per supportare la creazione di contenuti nella lingua dei segni.',
  Introduction_Two:
    'Il progetto si basa su un forte coinvolgimento degli utenti finali nella progettazione, nello sviluppo e nella valutazione della piattaforma EASIER, dove gli utenti di diverse lingue dei segni e parlate europee potranno utilizzare la loro lingua preferita per interagire tra loro.',
  Introduction_Three:
    "Il progetto EASIER è finanziato dal programma Horizon 2020 dell'UE con il numero di convenzione di sovvenzione 101016982.",
  Privacy_Overview: 'La protezione dei dati in sintesi',
  General_Information: 'Informazioni generali',
  General_Information_One:
    'Le seguenti note forniscono una semplice panoramica di ciò che accade ai vostri dati personali quando utilizzate questa applicazione. Per dati personali si intendono tutti i dati che possono essere utilizzati per identificarvi personalmente. Per informazioni dettagliate sul tema della protezione dei dati.',
  General_Information_Two:
    'Per informazioni dettagliate sul tema della protezione dei dati personali, consultare la nostra dichiarazione sulla protezione dei dati personali riportata sotto questo testo.',
  Data_Collection: 'Raccolta dei dati su questa applicazione',
  Data_Collection_One:
    'Chi è responsabile della raccolta dei dati su questa app?',
  Data_Collection_Two:
    "Il trattamento dei dati in questa app viene effettuato dal fornitore dell'app. I suoi dati di contatto sono riportati nella sezione 'Informazioni sul responsabile' della presente informativa sulla privacy.",
  Getting_Data: 'Come raccogliamo i vostri dati?',
  Getting_Data_One:
    "Da un lato, i vostri dati vengono raccolti quando ce li fornite. Può trattarsi, ad esempio, dei dati inseriti al momento della registrazione o dell'utilizzo dell'app. l'utilizzo.",
  Getting_Data_Two:
    "Altri dati vengono raccolti automaticamente o dopo il consenso dell'utente durante l'utilizzo dell'app attraverso il browser dai nostri sistemi informatici. Si tratta principalmente di dati tecnici (ad es. browser internet, sistema operativo o orario di accesso all'app). Questi dati vengono raccolti automaticamente non appena si utilizza l'app.",
  Using_Data: 'Per cosa utilizziamo i vostri dati?',
  Using_Data_One:
    "I dati vengono raccolti per garantire una fornitura dell'app priva di errori. I dati di accesso vengono utilizzati per rendere i vostri messaggi e la loro traduzione accessibili solo a voi. I messaggi inseriti o registrati vengono elaborati per la traduzione e sono accessibili solo all'utente nell'archivio.",
  Delete_Data: 'Cancellazione dei dati',
  Delete_Data_One:
    'I dati elaborati nel sistema saranno cancellati alla fine del progetto EASIER. La cancellazione avverrà entro luglio 2024.',
  Delete_Data_Two:
    "Potete cancellare voi stessi i vostri dati nell'applicazione in qualsiasi momento prima di questa data.",
  Delete_Data_Three:
    '- Alla voce di menu Archivio è possibile cancellare singoli messaggi o tutti i messaggi.',
  Delete_Data_Four:
    '- Alla voce di menu Profilo è possibile cancellare il proprio account con tutti i dati.',
  Rights_Data: 'Quali diritti avete in merito ai vostri dati?',
  Rights_Data_One:
    "Avete il diritto di ricevere in qualsiasi momento informazioni gratuite sull'origine, il destinatario e lo scopo dei vostri dati personali memorizzati. Avete inoltre il diritto di richiedere la correzione o la cancellazione di tali dati. Se avete dato il vostro consenso al trattamento dei dati, potete revocarlo in qualsiasi momento per il futuro. Avete anche il diritto di richiedere la limitazione del trattamento dei vostri dati personali in determinate circostanze. Inoltre, avete il diritto di presentare un reclamo all'autorità di vigilanza competente.",
  Rights_Data_Two:
    'Potete contattarci in qualsiasi momento per questa e altre domande sul tema della protezione dei dati.',
  Hosting: 'Sistemi di hosting e partner',
  Hosting_One:
    "I seguenti provider vengono utilizzati sulla base dell'art. 6 comma 1 lett. f DSGVO. Abbiamo un interesse legittimo alla presentazione e al funzionamento più affidabili della nostra app. Se è stato richiesto un consenso, il trattamento avviene esclusivamente sulla base dell'art. 6 comma 1 lett. a DSGVO e del § 25 comma 1 TTDSG, nella misura in cui il consenso comprende la memorizzazione di cookie o l'accesso a informazioni nel terminale dell'utente (ad es. fingerprinting del dispositivo) come definito dal TTDSG. Il consenso può essere revocato in qualsiasi momento.",
  Hetzner: 'Hetzner',
  Hetzner_One: "L'applicazione è ospitata dal seguente provider:",
  Hetzner_Two:
    'Il provider è Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (di seguito Hetzner).',
  Hetzner_Three:
    "Per maggiori dettagli, si prega di consultare l'informativa sulla privacy di Hetzner: https://www.hetzner.com/de/rechtliches/datenschutz.",
  AWS: 'Amazon Web Services',
  AWS_One:
    "Ospitiamo i componenti per l'analisi video e la traduzione automatica con il seguente provider:",
  AWS_Two:
    'Il fornitore di servizi è la società americana Amazon Web Services, Inc, 410 Terry Avenue North, Seattle WA 98109, USA.',
  AWS_Three: 'https://aws.amazon.com/de/compliance/data-privacy/',
  Process_Order: 'Elaborazione degli ordini',
  Process_Order_One:
    "Abbiamo stipulato con i fornitori un contratto di elaborazione degli ordini (AVV) per l'utilizzo dei servizi sopra citati. Si tratta di un contratto richiesto dalla legge sulla protezione dei dati, che garantisce che questi ultimi elaborino i dati personali degli utenti della nostra app solo in conformità alle nostre istruzioni e nel rispetto del GDPR.",
  General_Notes: 'Note generali e informazioni obbligatorie',
  Data_Protection: 'Protezione dei dati',
  Data_Protection_One:
    'I gestori di questa applicazione prendono molto sul serio la protezione dei vostri dati personali. Trattiamo i vostri dati personali in modo confidenziale e in conformità alle norme di legge sulla protezione dei dati e alla presente informativa sulla privacy.',
  Data_Protection_Two:
    'Quando si utilizza questa app, vengono raccolti diversi dati personali. I dati personali sono dati che possono essere utilizzati per identificarvi personalmente. La presente Informativa sulla privacy spiega quali dati raccogliamo e per quali scopi li utilizziamo. Spiega inoltre come ciò avviene e a quale scopo.',
  Data_Protection_Three:
    "Desideriamo sottolineare che la trasmissione di dati su Internet (ad esempio, quando si comunica via e-mail) può presentare lacune nella sicurezza. Non è possibile proteggere completamente i dati dall'accesso di terzi.",
  Responsible_Body: "Nota sull'ufficio responsabile",
  Responsible_Body_One:
    'Il responsabile del trattamento dei dati in questa applicazione è:',
  Responsible_Body_Two:
    'Il responsabile del trattamento è la persona fisica o giuridica che, da sola o insieme ad altri, determina le finalità e i mezzi del trattamento dei dati personali (ad es. nomi, indirizzi e-mail, ecc.).',
  Phone: 'Telefono',
  Email: 'E-mail',
  City: 'Colonia',
  Storage_Period: 'Periodo di conservazione',
  Storage_Period_One:
    "A meno che non sia stato specificato un periodo di conservazione più specifico all'interno della presente informativa sulla privacy, i vostri dati personali rimarranno presso di noi fino a quando non verrà meno lo scopo per cui sono stati elaborati. In caso di richiesta legittima di cancellazione o di revoca del consenso al trattamento dei dati, i vostri dati saranno cancellati, a meno che non vi siano altri motivi legalmente consentiti per la conservazione dei vostri dati personali (ad esempio, periodi di conservazione previsti dal diritto fiscale o commerciale); in quest'ultimo caso, la cancellazione avverrà dopo che tali motivi avranno cessato di essere validi.",
  Storage_Period_Two: 'Si veda anche la sezione 1 "Cancellazione dei dati".',
  General_Information_Legal:
    'Informazioni generali sulla base giuridica del trattamento dei dati in questa app',
  General_Information_Legal_One:
    "Se avete acconsentito al trattamento dei dati, elaboriamo i vostri dati personali sulla base dell'art. 6 (1) lett. a DSGVO o dell'art. 9 (2) lett. a DSGVO se vengono elaborate categorie speciali di dati ai sensi dell'art. 9 (1) DSGVO. In caso di consenso esplicito al trasferimento dei dati personali a paesi terzi, il trattamento dei dati avviene anche sulla base dell'art. 49 (1) a DSGVO. Se avete acconsentito alla memorizzazione dei cookie o all'accesso alle informazioni nel vostro terminale (ad es. tramite il fingerprinting del dispositivo), l'elaborazione dei dati avviene anche sulla base dell'art. 25 (1) TTDSG. Questo consenso può essere revocato in qualsiasi momento. Se i vostri dati sono necessari per l'esecuzione di un contratto o per l'attuazione di misure precontrattuali, li trattiamo sulla base dell'art. 6 comma 1 lett. b DSGVO. Inoltre, se i vostri dati sono necessari per l'adempimento di un obbligo legale, li trattiamo sulla base dell'art. 6 par. 1 lett. c DSGVO. Inoltre, il trattamento dei dati può essere effettuato sulla base del nostro legittimo interesse ai sensi dell'art. 6 comma 1 lett. f DSGVO. Le informazioni sulla base giuridica pertinente per ogni singolo caso sono fornite nei paragrafi successivi della presente dichiarazione sulla protezione dei dati.",
  Revocation_Data: 'Revoca del consenso al trattamento dei dati',
  Revocation_Data_One:
    'Molte operazioni di trattamento dei dati sono possibili solo con il vostro esplicito consenso. Potete revocare in qualsiasi momento il consenso che avete già dato. La legalità del trattamento dei dati effettuato fino alla revoca rimane inalterata.',
  Rights_Collection_Data:
    'Diritto di opposizione alla raccolta dei dati (art. 21 DSGVO)',
  Rights_Collection_Data_One:
    "SE IL TRATTAMENTO DEI DATI VIENE EFFETTUATO SULLA BASE DELL'ART. 6 ABS. 1 LIT. E O F DEL DSGVO, AVETE IL DIRITTO DI OPPORVI IN QUALSIASI MOMENTO AL TRATTAMENTO DEI VOSTRI DATI PERSONALI PER MOTIVI DERIVANTI DALLA VOSTRA PARTICOLARE SITUAZIONE. LE RISPETTIVE BASI GIURIDICHE SU CUI SI FONDA IL TRATTAMENTO SONO RIPORTATE NELLA PRESENTE DICHIARAZIONE SULLA PROTEZIONE DEI DATI. IN CASO DI OPPOSIZIONE, NON TRATTEREMO PIÙ I VOSTRI DATI PERSONALI, A MENO CHE NON POSSIAMO DIMOSTRARE L'ESISTENZA DI MOTIVI LEGITTIMI IMPELLENTI PER IL TRATTAMENTO CHE PREVALGONO SUI VOSTRI INTERESSI, DIRITTI E LIBERTÀ, OPPURE IL TRATTAMENTO È FINALIZZATO ALLA RIVENDICAZIONE, ALL'ESERCIZIO O ALLA DIFESA DI DIRITTI LEGALI (OBIEZIONE AI SENSI DELL'ARTICOLO 21, PARAGRAFO 1, DEL DSGVO).",
  Right_Of_Appeal: "Diritto di ricorso all'autorità di controllo competente",
  Right_Of_Appeal_One:
    "In caso di violazione del GDPR, gli interessati hanno il diritto di ricorrere a un'autorità di controllo, in particolare nello Stato membro in cui risiedono abitualmente, in cui lavorano o in cui si è verificata la presunta violazione. Il diritto di ricorso non pregiudica alcun altro ricorso amministrativo o giudiziario.",
  Right_Of_Portability: 'Diritto alla portabilità dei dati',
  Right_Of_Portability_One:
    'Avete il diritto di ottenere che i dati da noi trattati automaticamente sulla base del vostro consenso o in esecuzione di un contratto siano consegnati a voi o a terzi in un formato comune e leggibile a macchina. Se richiedete il trasferimento diretto dei dati a un altro soggetto responsabile, ciò avverrà solo nella misura in cui sia tecnicamente possibile.',
  Information_Correction_Deletion: 'Informazioni, correzione e cancellazione',
  Information_Correction_Deletion_One:
    "Nell'ambito delle disposizioni di legge applicabili, avete il diritto di ottenere in qualsiasi momento informazioni gratuite sui vostri dati personali memorizzati, sulla loro origine e sul loro destinatario e sulle finalità del trattamento dei dati e, se del caso, il diritto alla correzione o alla cancellazione di tali dati. Potete contattarci in qualsiasi momento per questo e altri quesiti sul tema dei dati personali.",
  Information_Correction_Deletion_Two:
    "È possibile cancellare autonomamente i propri dati nell'app in qualsiasi momento, vedi 'Cancellazione dei dati' nella sezione 2.",
  Right_Restriction_Processing: 'Diritto alla limitazione del trattamento',
  Right_Restriction_Processing_One:
    'Avete il diritto di richiedere la limitazione del trattamento dei vostri dati personali. A tal fine, potete contattarci in qualsiasi momento. Il diritto alla limitazione del trattamento sussiste nei seguenti casi:',
  Right_Restriction_Processing_List_One:
    "- Se contestate l'esattezza dei vostri dati personali da noi memorizzati, di solito abbiamo bisogno di tempo per verificarla. Per la durata della verifica, avete il diritto di richiedere la limitazione del trattamento dei vostri dati personali.",
  Right_Restriction_Processing_List_Two:
    '- Se il trattamento dei vostri dati personali è avvenuto/sta avvenendo in modo illegale, potete richiedere la limitazione del trattamento dei dati invece della cancellazione.',
  Right_Restriction_Processing_List_Three:
    '- Se i vostri dati personali non ci servono più, ma vi servono per esercitare, difendere o far valere diritti legali, avete il diritto di richiedere la limitazione del trattamento dei vostri dati personali invece della cancellazione.',
  Right_Restriction_Processing_Two:
    "Se avete presentato un'obiezione ai sensi dell'art. 21 (1) DSGVO, deve essere effettuato un bilanciamento tra i vostri interessi e i nostri. Finché non è ancora stato stabilito quali interessi prevalgono, avete il diritto di chiedere la limitazione del trattamento dei vostri dati personali.",
  Right_Restriction_Processing_Three:
    "Se avete limitato il trattamento dei vostri dati personali, questi potranno essere trattati - oltre che archiviati - solo con il vostro consenso o per la rivendicazione, l'esercizio o la difesa di diritti legali o per la tutela dei diritti di un'altra persona fisica o giuridica o per motivi di rilevante interesse pubblico dell'Unione Europea o di uno Stato membro.",
  SSL_TLS: 'Crittografia SSL o TLS',
  SSL_TLS_One:
    "Per motivi di sicurezza e per proteggere la trasmissione di contenuti riservati, come gli ordini o le richieste di informazioni che ci inviate in qualità di gestore del sito, questo sito utilizza la crittografia SSL o TLS. È possibile riconoscere una connessione crittografata dal fatto che la riga dell'indirizzo del browser passa da 'http://' a 'https://' e dal simbolo del lucchetto nella riga del browser.",
  SSL_TLS_Two:
    'Se la crittografia SSL o TLS è attivata, i dati che ci trasmettete non possono essere letti da terzi.',
  Data_Collection_App: 'Raccolta di dati in questa applicazione',
  Cookies: 'I cookie',
  Cookies_One:
    "Le nostre pagine Internet utilizzano i cosiddetti 'cookie'. I cookie sono piccoli pacchetti di dati e non causano alcun danno al vostro dispositivo finale. Vengono memorizzati temporaneamente per la durata di una sessione (cookie di sessione) o permanentemente (cookie permanenti) sul vostro dispositivo finale. I cookie di sessione vengono cancellati automaticamente al termine della visita. I cookie permanenti (ad es. impostazione delle app) rimangono memorizzati sul dispositivo finale dell'utente fino a quando non vengono eliminati dall'utente stesso o fino a quando non vengono eliminati automaticamente dal browser web.",
  Cookies_Two:
    'I cookie possono provenire da noi (cookie di prima parte) o da società terze (i cosiddetti cookie di terze parti). Non vengono utilizzati cookie di terze parti.',
  Cookies_Three:
    "I cookie sono tecnicamente necessari per l'utilizzo dell'applicazione, al fine di garantire la sicurezza di una sessione e assicurare il funzionamento dell'applicazione. Non viene effettuata una valutazione del comportamento dell'utente.",
  Cookies_Four:
    "I cookie necessari per lo svolgimento del processo di comunicazione elettronica, per fornire determinate funzioni richieste dall'utente (cookie necessari), vengono memorizzati sulla base dell'art. 6 (1) lett. f DSGVO, a meno che non venga specificata un'altra base giuridica. Il gestore dell'app ha un interesse legittimo a memorizzare i cookie necessari per la fornitura tecnicamente priva di errori dei suoi servizi. Nella misura in cui è stato richiesto il consenso alla memorizzazione dei cookie e di tecnologie di riconoscimento comparabili, il trattamento avviene esclusivamente sulla base di tale consenso (art. 6 comma 1 lett. a DSGVO e § 25 comma 1 TTDSG); il consenso può essere revocato in qualsiasi momento.",
  Cookies_Five:
    "È possibile cancellare autonomamente i propri dati nell'app in qualsiasi momento, vedi 'Cancellazione dei dati' al punto 2.",
  Cookies_Six:
    "Potete impostare il vostro browser in modo da essere informati sull'impostazione dei cookie e consentire i cookie solo in singoli casi, escludere l'accettazione dei cookie per determinati casi o in generale e attivare la cancellazione automatica dei cookie alla chiusura del browser. Se i cookie vengono disattivati, le funzionalità di questa applicazione potrebbero essere limitate. Per sapere quali cookie e servizi sono utilizzati in questa applicazione, consultare la presente informativa sulla privacy.",
  Enquiry: 'Richiesta via e-mail, telefono o fax',
  Enquiry_One:
    'Se ci contattate via e-mail, telefono o fax, la vostra richiesta, compresi tutti i dati personali che ne derivano (nome, richiesta), verrà da noi memorizzata ed elaborata allo scopo di evadere la vostra richiesta. Non trasmettiamo questi dati senza il vostro consenso.',
  Enquiry_Two:
    "Il trattamento di questi dati si basa sull'art. 6 (1) lit. b DSGVO se la vostra richiesta è legata all'esecuzione di un contratto o è necessaria per l'attuazione di misure precontrattuali. In tutti gli altri casi, il trattamento si basa sul nostro legittimo interesse all'effettivo trattamento delle richieste rivolteci (art. 6 cpv. 1 lett. f DSGVO) o sul vostro consenso (art. 6 cpv. 1 lett. a DSGVO) se questo è stato richiesto; il consenso può essere revocato in qualsiasi momento.",
  Enquiry_Three:
    'I dati che ci inviate tramite le richieste di contatto rimarranno presso di noi fino a quando non ci chiederete di cancellarli, non revocherete il vostro consenso alla loro memorizzazione o lo scopo della memorizzazione dei dati non sarà più valido (ad es. dopo la fine del progetto di ricerca). Le disposizioni di legge obbligatorie - in particolare i periodi di conservazione legale - rimangono inalterate.',
  Plugins_Tools: 'Plugin e strumenti',
  Google_Fonts: 'Google Fonts',
  Google_Fonts_One:
    'Questo sito utilizza i cosiddetti Google Fonts, forniti da Google, per la visualizzazione uniforme dei caratteri. Quando si richiama una pagina, il browser carica nella sua cache i font necessari per visualizzare correttamente i testi e i caratteri.',
  Google_Fonts_Two:
    "A tal fine, il browser utilizzato deve connettersi ai server di Google. In questo modo Google viene informato che l'accesso a questa applicazione è avvenuto tramite il vostro indirizzo IP. L'utilizzo di Google Fonts si basa sull'art. 6 comma 1 lett. f DSGVO. Il gestore dell'app ha un interesse legittimo alla presentazione uniforme del carattere tipografico sulla sua app. Nella misura in cui è stato richiesto il relativo consenso, il trattamento avviene esclusivamente sulla base dell'art. 6 comma 1 lett. a DSGVO e del § 25 comma 1 TTDSG, nella misura in cui il consenso comprende la memorizzazione di cookie o l'accesso a informazioni nel terminale dell'utente (ad es. fingerprinting del dispositivo) ai sensi del TTDSG. Il consenso può essere revocato in qualsiasi momento.",
  Google_Fonts_Three:
    'Se il vostro browser non supporta Google Fonts, il vostro computer utilizzerà un font standard.',
  Google_Fonts_Four:
    "Ulteriori informazioni su Google Fonts sono disponibili all'indirizzo https://developers.google.com/fonts/faq e nell'informativa sulla privacy di Google: https://policies.google.com/privacy?hl=de.",
  Google_Fonts_Five:
    "L'azienda è certificata ai sensi del Data Privacy Framework (DPF) UE-USA. Il DPF è un accordo tra l'Unione Europea e gli Stati Uniti che ha lo scopo di garantire la conformità agli standard europei di protezione dei dati per l'elaborazione dei dati negli Stati Uniti. Ogni azienda certificata ai sensi del DPF si impegna a rispettare questi standard di protezione dei dati. Per ulteriori informazioni, contattare il fornitore al seguente link: https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&id=a2zt000000001L5AAI&status=Active",
};
