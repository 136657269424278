/**
 * Input Text
 */

import React, {useState, useEffect} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  TextInput,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable,
  TouchableOpacity,
  Platform,
  Keyboard,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Clipboard from '@react-native-clipboard/clipboard';
import {
  SPEECH,
  TEXT,
  CAMERA,
  AVATAR,
  SELECT_INPUT_TYPE,
  SELECT_INPUT_LANGUAGE_TYPE,
  SELECT_OUTPUT_TYPE,
  SELECT_OUTPUT_LANGUAGE_TYPE,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_ITALIAN,
  LANGUAGE_LUXEMBOURG_FRENCH,
  LANGUAGE_LUXEMBOURG_GERMAN,
  LANGUAGE_DUTCH,
  LANGUAGE_SUIZZE_FRENCH,
  LANGUAGE_SUIZZE_ITALIAN,
  LANGUAGE_SUIZZE_GERMAN,
  LANGUAGE_BRITISH,
  CONTRAST_MODE_TYPE,
  TEXT_VALUE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  DEFAULT_VALUE_TYPE,
  SETTINGS_CHOICE,
  SETTINGS_NORMAL,
  SETTINGS_FAVORITE,
  USERTOKEN_KEY,
  DEVELOPMENT,
  TEST,
  DEFAULT_OUTPUT_TYPE,
} from '../../../constants/constants';
import axios from 'axios';

/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import TextIcon from './../../../assets/icontext.png';
import TextIconDark from './../../../assets/icontextdark.png';
import RecordIcon from './../../../assets/iconspeech.png';
import RecordIconDark from './../../../assets/iconspeechdark.png';
import CameraIcon from './../../../assets/iconcamera.png';
import CameraIconDark from './../../../assets/iconcameradark.png';
import PasteIcon from './../../../assets/iconcopy.png';
import PasteIconDark from './../../../assets/iconcopydark.png';
import DeleteIcon from './../../../assets/icondelete.png';
import DeleteIconDark from './../../../assets/icondeletedark.png';
import LanguageIcon from './../../../assets/iconlanguage.png';
import LanguageIconDark from './../../../assets/iconlanguagedark.png';
import FlagGermanIcon from './../../../assets/flaggerman.png';
import FlagDutchIcon from './../../../assets/flagdutch.png';
import FlagFrenchIcon from './../../../assets/flagfrench.png';
import FlagItalianIcon from './../../../assets/flagitaly.png';
import FlagGreeceIcon from './../../../assets/flaggreece.png';
import FlagGreatBritianIcon from './../../../assets/flaggreatbritain.png';
import FlagLuxembourgIcon from './../../../assets/flagluxembourg.png';
import FlagSuizzeIcon from './../../../assets/flagsuizze.png';
import DeleteInfoIcon from './../../../assets/icondeletedata.png';
import {useTranslation} from 'react-i18next';

const InputText = ({navigation}): Node => {
  const [speech, setSpeech] = useState(false);
  const [text, setText] = useState(false);
  const [camera, setCamera] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [language, setLanguage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [languageInputTranslate, setLanguageInputTranslate] = useState('');
  const [languageOutputTranslate, setLanguageOutputTranslate] = useState('');
  const [typeInputTranslate, setTypeInputTranslate] = useState('');
  const [typeOutputTranslate, setTypeOutputTranslate] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const isFocused = useIsFocused();
  const {t} = useTranslation();

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    const checkNewTranslation = async () => {
      if (await AsyncStorage.getItem('New_Translation')) {
        setInputValue('');
      }
    };
    checkDarkModeDefault();
    checkSizeDefault();
    checkNewTranslation();
  }, [isFocused]);

  const setActiveDefaultInput = async (speechValue, textValue, cameraValue) => {
    setSpeech(speechValue);
    setText(textValue);
    setCamera(cameraValue);

    await AsyncStorage.setItem(
      SELECT_INPUT_TYPE,
      speechValue == true
        ? SPEECH
        : textValue == true
        ? TEXT
        : cameraValue == true
        ? CAMERA
        : '',
    );
  };

  const clearText = () => {
    setInputValue('');
    setShowModal(false);
  };

  const getClipboardText = async () => {
    const text = await Clipboard.getString();
    setInputValue(inputValue + text);
  };

  const backInputSetting = async navigation => {
    const favoriteSetting = await AsyncStorage.getItem(DEFAULT_VALUE_TYPE);
    await AsyncStorage.setItem(SETTINGS_CHOICE, SETTINGS_NORMAL);

    if (favoriteSetting === 'false') {
      navigation.navigate('Input Method');
    } else {
      navigation.navigate('Default Input');
    }
  };

  const saveText = async () => {
    //await AsyncStorage.setItem(TEXT_VALUE, inputValue);
    // Old
    /*navigation.navigate('Output Method', {
      newRecorded: '',
    });*/
    // New
    //navigation.navigate('Status Translation');
  };
  useEffect(() => {
    const inputTypeDefault = async () => {
      const defaultInputLanguageType = await AsyncStorage.getItem(
        SELECT_INPUT_LANGUAGE_TYPE,
      );
      const defaultInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
      defaultInputLanguageType === LANGUAGE_FRENCH
        ? setLanguage(LANGUAGE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_GERMAN
        ? setLanguage(LANGUAGE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_GREEK
        ? setLanguage(LANGUAGE_GREEK)
        : defaultInputLanguageType === LANGUAGE_ITALIAN
        ? setLanguage(LANGUAGE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_FRENCH
        ? setLanguage(LANGUAGE_LUXEMBOURG_FRENCH)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_GERMAN
        ? setLanguage(LANGUAGE_LUXEMBOURG_GERMAN)
        : defaultInputLanguageType === LANGUAGE_DUTCH
        ? setLanguage(LANGUAGE_DUTCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_FRENCH
        ? setLanguage(LANGUAGE_SUIZZE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_ITALIAN
        ? setLanguage(LANGUAGE_SUIZZE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_GERMAN
        ? setLanguage(LANGUAGE_SUIZZE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_BRITISH
        ? setLanguage(LANGUAGE_BRITISH)
        : setLanguage('');

      defaultInputType === SPEECH
        ? setActiveDefaultInput(true, false, false)
        : defaultInputType === TEXT
        ? setActiveDefaultInput(false, true, false)
        : defaultInputType === CAMERA
        ? setActiveDefaultInput(false, false, true)
        : setActiveDefaultInput(false, false, false);
    };
    inputTypeDefault();
  }, [isFocused]);

  useEffect(() => {
    const loadData = async () => {
      const defaultInputLanguageType = await AsyncStorage.getItem(
        SELECT_INPUT_LANGUAGE_TYPE,
      );
      const defaultInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
      const defaultOutputLanguageType = await AsyncStorage.getItem(
        SELECT_OUTPUT_LANGUAGE_TYPE,
      );
      const defaultOutputType = await AsyncStorage.getItem(SELECT_OUTPUT_TYPE);

      if (defaultInputType === 'camera') {
        setTypeInputTranslate('video');
      }
      if (defaultInputType == 'text') {
        setTypeInputTranslate('text');
      }
      if (defaultInputType === 'speech') {
        setTypeInputTranslate('audio');
      }

      if (defaultOutputType === 'avatar') {
        setTypeOutputTranslate('video');
      }
      if (defaultOutputType == 'text') {
        setTypeOutputTranslate('text');
      }
      if (defaultOutputType === 'speech') {
        setTypeOutputTranslate('audio');
      }

      if (
        defaultInputLanguageType === 'german' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('DGS');
      }
      if (
        defaultInputLanguageType === 'german' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('DE');
      }
      if (
        defaultInputLanguageType === 'french' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('LSF');
      }
      if (
        defaultInputLanguageType === 'french' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('FR');
      }
      if (
        defaultInputLanguageType === 'british' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('BSL');
      }
      if (
        defaultInputLanguageType === 'british' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('EN');
      }
      if (
        defaultInputLanguageType === 'greek' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('GSL');
      }
      if (
        defaultInputLanguageType === 'greek' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('EL');
      }
      if (
        defaultInputLanguageType === 'italian' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('LIS');
      }
      if (
        defaultInputLanguageType === 'italian' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('IT');
      }
      if (
        defaultInputLanguageType === 'dutch' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('NGT');
      }
      if (
        defaultInputLanguageType === 'dutch' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('NL');
      }
      if (
        defaultInputLanguageType === 'suizze-german' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('DSGS');
      }
      if (
        defaultInputLanguageType === 'suizze-german' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('DE');
      }

      if (
        defaultOutputLanguageType === 'german' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('DGS');
      }
      if (
        defaultOutputLanguageType === 'german' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('DE');
      }
      if (
        defaultOutputLanguageType === 'french' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('LSF');
      }
      if (
        defaultOutputLanguageType === 'french' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('FR');
      }
      if (
        defaultOutputLanguageType === 'british' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('BSL');
      }
      if (
        defaultOutputLanguageType === 'british' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('EN');
      }
      if (
        defaultOutputLanguageType === 'greek' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('GSL');
      }
      if (
        defaultOutputLanguageType === 'greek' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('EL');
      }
      if (
        defaultOutputLanguageType === 'italian' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('LIS');
      }
      if (
        defaultOutputLanguageType === 'italian' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('IT');
      }
      if (
        defaultOutputLanguageType === 'dutch' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('NGT');
      }
      if (
        defaultOutputLanguageType === 'dutch' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('NL');
      }
      if (
        defaultOutputLanguageType === 'suizze-german' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('DSGS');
      }
      if (
        defaultOutputLanguageType === 'suizze-german' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('DE');
      }
    };
    loadData();
  }, [isFocused]);

  const disableEnter = e => {
    if (e.nativeEvent.key === 'Enter') {
      Keyboard.dismiss();
      return false;
    }
  };
  const translateText = async () => {
    await AsyncStorage.removeItem(TEXT_VALUE);
    await AsyncStorage.removeItem('New_Translation');
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);
    const settingsChoice = await AsyncStorage.getItem(DEFAULT_VALUE_TYPE);
    const defaultOutputChoice = await AsyncStorage.getItem(DEFAULT_OUTPUT_TYPE);
    const selectOutputChoice = await AsyncStorage.getItem(SELECT_OUTPUT_TYPE);

    let form = new FormData();

    form.append(
      'translation',
      '{"input_type": "' +
        typeInputTranslate +
        '", "input_language": "' +
        languageInputTranslate +
        '", "output_type": "' +
        typeOutputTranslate +
        '", "output_language": "' +
        languageOutputTranslate +
        '", "media_text": "' +
        inputValue +
        '", "test_mode": false}',
    );
    form.append('upload_file', '""');
    const getOutputTypeTranslation =
      typeOutputTranslate === 'text'
        ? 'text'
        : typeOutputTranslate === 'audio'
        ? 'audio'
        : 'video';

    navigation.navigate('Status Translation', {
      visualData: inputValue,
      languageDataInput: languageInputTranslate,
      typeDataInput: 'text',
      languageDataOutput: languageOutputTranslate,
      typeDataOutput: getOutputTypeTranslation,
    });
    /*if (
      (settingsChoice === 'false' && selectOutputChoice !== TEXT) ||
      (settingsChoice === 'true' && defaultOutputChoice !== TEXT)
    ) {
      navigation.navigate('Status Translation', {
        visualData: inputValue,
      });
    } else {
      axios
        .post(TEST + 'translation', form, {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(response => {
          if (response.status === 200) {
            setShowErrorMessage(false);
            setTimeout(() => {
              navigation.navigate('Status Translation', {
                visualData: '',
              });
            }, 1000);
          } else {
            console.log('Error');
          }
        })
        .catch(e => {
          console.log(e);
          setShowErrorMessage(true);
        });
    }*/
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}
        accessibilityLabel={t('Input')}>
        <Text
          style={
            darkModeAvailable
              ? [
                  styles.textHeadlineDark,
                  contrastSizing === SMALLTEXT
                    ? styles.smallTextAll
                    : contrastSizing === NORMALTEXT
                    ? styles.normalTextAll
                    : contrastSizing === BIGTEXT
                    ? styles.bigTextAll
                    : styles.normalTextAll,
                ]
              : [
                  styles.textHeadline,
                  contrastSizing === SMALLTEXT
                    ? styles.smallTextAll
                    : contrastSizing === NORMALTEXT
                    ? styles.normalTextAll
                    : contrastSizing === BIGTEXT
                    ? styles.bigTextAll
                    : styles.normalTextAll,
                ]
          }>
          {t('Input')}
        </Text>
        <Pressable
          onPress={() => backInputSetting(navigation)}
          accessible={true}
          accessibilityLabel={'Back to input method - Settings'}>
          <View
            style={darkModeAvailable ? styles.textTabDark : styles.textTab}
            accessible={true}>
            <View
              style={
                darkModeAvailable
                  ? styles.textTabImageTextDark
                  : styles.textTabImage
              }
              accessible={true}
              accessibilityLabel={'Selected input method'}>
              <Image
                width={Platform.OS == 'web' ? '30px' : 30}
                height={Platform.OS == 'web' ? '30px' : 30}
                source={{
                  uri:
                    speech == true
                      ? Platform.OS == 'web'
                        ? darkModeAvailable
                          ? RecordIconDark
                          : RecordIcon
                        : darkModeAvailable
                        ? 'iconinputdark'
                        : 'iconinput'
                      : text == true
                      ? Platform.OS == 'web'
                        ? darkModeAvailable
                          ? TextIconDark
                          : TextIcon
                        : darkModeAvailable
                        ? 'icontextdark'
                        : 'icontext'
                      : Platform.OS == 'web'
                      ? darkModeAvailable
                        ? CameraIconDark
                        : CameraIcon
                      : darkModeAvailable
                      ? 'iconcameradark'
                      : 'iconcamera',
                }}
                style={
                  language == ''
                    ? darkModeAvailable
                      ? [
                          styles.settingsIconStyleTypeTextDark,
                          styles.settingsIconCustomStyle,
                        ]
                      : [
                          styles.settingsIconStyleType,
                          styles.settingsIconCustomStyle,
                        ]
                    : darkModeAvailable
                    ? [
                        styles.settingsIconStyleTypeTextDark,
                        styles.settingsIconCustomStyleActive,
                      ]
                    : [
                        styles.settingsIconStyleType,
                        styles.settingsIconCustomStyleActive,
                      ]
                }
                resizeMode="contain"
              />
            </View>
            <View
              style={styles.textTabImage}
              accessible={true}
              accessibilityLabel={'Selected language input'}>
              <Image
                width={Platform.OS == 'web' ? '25px' : 25}
                height={Platform.OS == 'web' ? '25px' : 25}
                source={{
                  uri:
                    language == LANGUAGE_GERMAN
                      ? Platform.OS == 'web'
                        ? FlagGermanIcon
                        : 'flaggerman'
                      : language == LANGUAGE_GREEK
                      ? Platform.OS == 'web'
                        ? FlagGreeceIcon
                        : 'flaggreece'
                      : language == LANGUAGE_FRENCH
                      ? Platform.OS == 'web'
                        ? FlagFrenchIcon
                        : 'flagfrench'
                      : language == LANGUAGE_ITALIAN
                      ? Platform.OS == 'web'
                        ? FlagItalianIcon
                        : 'flagitaly'
                      : language == LANGUAGE_LUXEMBOURG_FRENCH ||
                        language == LANGUAGE_LUXEMBOURG_GERMAN
                      ? Platform.OS == 'web'
                        ? FlagLuxembourgIcon
                        : 'flagluxembourg'
                      : language == LANGUAGE_DUTCH
                      ? Platform.OS == 'web'
                        ? FlagDutchIcon
                        : 'flagdutch'
                      : language == LANGUAGE_SUIZZE_FRENCH ||
                        language == LANGUAGE_SUIZZE_ITALIAN ||
                        language == LANGUAGE_SUIZZE_GERMAN
                      ? Platform.OS == 'web'
                        ? FlagSuizzeIcon
                        : 'flagsuizze'
                      : language == LANGUAGE_BRITISH
                      ? Platform.OS == 'web'
                        ? FlagGreatBritianIcon
                        : 'flaggreatbritain'
                      : Platform.OS == 'web'
                      ? LanguageIcon
                      : 'iconlanguage',
                }}
                style={
                  language == ''
                    ? [
                        styles.settingsIconStyleFlag,
                        styles.settingsIconCustomStyle,
                      ]
                    : [
                        styles.settingsIconStyleFlag,
                        styles.settingsIconCustomStyleActive,
                      ]
                }
                resizeMode="contain"
              />
            </View>
          </View>
        </Pressable>
        <ScrollView
          style={
            darkModeAvailable
              ? styles.textInputFieldMainDark
              : styles.textInputFieldMain
          }
          accessible={true}>
          <TextInput
            multiline={true}
            numberOfLines={20}
            onKeyPress={disableEnter}
            onChangeText={inputValue => setInputValue(inputValue)}
            value={inputValue}
            style={
              darkModeAvailable
                ? styles.textInputFieldDark
                : styles.textInputField
            }
            placeholder={t('Tap_and_write')}
            placeholderTextColor={
              darkModeAvailable ? styles.placeholderTextDark : ''
            }
            maxLength={2000}
            accessible={true}
            accessibilityLabel={'Text input and function'}
          />
          {showModal ? (
            <View
              style={
                darkModeAvailable ? styles.modalMainDark : styles.modalMain
              }
              accessible={showModal}>
              {/*<View>
                <Text style={styles.modalHeadline}>Clear</Text>
              </View>*/}
              <View accessible={true} accessibilityLabel={t('Clear_text')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.modalDescriptionDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.modalDescription,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Clear_text')}
                </Text>
              </View>
              <View accessible={true} accessibilityLabel={'Delete icon'}>
                <Image
                  width={Platform.OS == 'web' ? '80px' : 80}
                  height={Platform.OS == 'web' ? '80px' : 80}
                  source={{
                    uri:
                      Platform.OS == 'web' ? DeleteInfoIcon : 'icondeletedata',
                  }}
                  style={styles.textButtonsElementIconModal}
                  resizeMode="contain"
                />
              </View>
              <View
                style={styles.modalButtons}
                accessible={true}
                accessibilityLabel={'Buttons: ' + t('NO') + ' and ' + t('YES')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.modalSubmitDefaultDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.modalSubmitDefault,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }
                  onPress={() => setShowModal(false)}>
                  {t('NO')}
                </Text>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.modalSubmitDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.modalSubmit,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }
                  onPress={clearText}>
                  {t('YES')}
                </Text>
              </View>
            </View>
          ) : (
            <View></View>
          )}
        </ScrollView>
        <View
          style={styles.textInputFieldLength}
          accessible={true}
          accessibilityLabel={'Length of value: ' + inputValue.length}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textInputFieldLengthColorDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.textInputFieldLengthColor,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {inputValue.length} / 2000
          </Text>
        </View>
        <View style={styles.textButtons}>
          <Pressable
            style={styles.textButtonsElement}
            onPress={() => setShowModal(true)}
            accessible={true}
            accessibilityLabel={'Delete/Clear text validation'}>
            <View
              style={
                darkModeAvailable
                  ? styles.textButtonsElementBackgroundDark
                  : styles.textButtonsElementBackground
              }
              accessible={true}>
              <View
                style={
                  darkModeAvailable
                    ? styles.textButtonsElementIconMainDark
                    : styles.textButtonsElementIconMain
                }
                accessible={true}
                accessibilityLabel={'Delete/Clear text with icon'}>
                <Image
                  width={Platform.OS == 'web' ? '25px' : 25}
                  height={Platform.OS == 'web' ? '25px' : 25}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? DeleteIconDark
                          : DeleteIcon
                        : darkModeAvailable
                        ? 'icondeletedark'
                        : 'icondelete',
                  }}
                  style={styles.textButtonsElementIcon}
                  resizeMode="contain"
                />
              </View>
            </View>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textButtonsTitleDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textButtonsTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Clear')}
            </Text>
          </Pressable>
          <Pressable
            style={styles.textButtonsElement}
            onPress={getClipboardText}
            accessible={true}
            accessibilityLabel={'Copy text validation'}>
            <View
              style={
                darkModeAvailable
                  ? styles.textButtonsElementBackgroundDark
                  : styles.textButtonsElementBackground
              }
              accessible={true}>
              <View
                style={
                  darkModeAvailable
                    ? styles.textButtonsElementIconMainDark
                    : styles.textButtonsElementIconMain
                }
                accessible={true}
                accessibilityLabel={'Delete with icon'}>
                <Image
                  width={Platform.OS == 'web' ? '25px' : 25}
                  height={Platform.OS == 'web' ? '25px' : 25}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? PasteIconDark
                          : PasteIcon
                        : darkModeAvailable
                        ? 'iconcopydark'
                        : 'iconcopy',
                  }}
                  style={styles.textButtonsElementIcon}
                  resizeMode="contain"
                />
              </View>
            </View>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textButtonsTitleDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textButtonsTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Paste')}
            </Text>
          </Pressable>
        </View>
        {showErrorMessage ? (
          <>
            <Text
              style={
                darkModeAvailable
                  ? styles.errorMessageDark
                  : styles.errorMessage
              }>
              {t('Unable_Service_Message')}
            </Text>
          </>
        ) : (
          <></>
        )}
        <TouchableOpacity
          style={
            inputValue.length > 0
              ? styles.buttonMain
              : styles.buttonMainDisabled
          }
          onPress={translateText}
          accessible={true}
          accessibilityLabel={t('TRANSLATE')}
          accessibilityHint="Start the translation process">
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.buttonDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.button,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('TRANSLATE')}
          </Text>
        </TouchableOpacity>
        {/*showModal ? (
          <View style={styles.modalMain}>
            <View>
              <Text style={styles.modalDescription}>Clear text?</Text>
            </View>
            <View>
              <Image
                width={Platform.OS == 'web' ? '80px' : 80}
                height={Platform.OS == 'web' ? '80px' : 80}
                source={{
                  uri: Platform.OS == 'web' ? DeleteInfoIcon : 'icondeletedata',
                }}
                style={styles.textButtonsElementIconModal}
                resizeMode="contain"
              />
            </View>
            <View style={styles.modalButtons}>
              <Text
                style={styles.modalSubmitDefault}
                onPress={() => setShowModal(false)}>
                NO
              </Text>
              <Text style={styles.modalSubmit} onPress={clearText}>
                YES
              </Text>
            </View>
          </View>
        ) : (
          <View></View>
        )*/}
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
  },
  mainMarginWeb: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsIconStyleType: {
    width: Platform.OS === 'web' ? '45px' : 45,
    height: Platform.OS === 'web' ? '45px' : 45,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-10px' : -10,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-10px' : -10,
  },
  settingsIconStyleTypeTextDark: {
    width: Platform.OS === 'web' ? '45px' : 45,
    height: Platform.OS === 'web' ? '45px' : 45,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-0.5px' : -0.5,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-12.5px' : -12.5,
  },
  textTabImageTextDark: {
    width: Platform.OS === 'web' ? '30px' : 30,
    height: Platform.OS === 'web' ? '22px' : 22,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    backgroundColor: '#000',
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginTop: Platform.OS === 'web' ? '2px' : 2,
  },
  settingsIconStyleFlag: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsIconCustomStyle: {
    marginLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  textTab: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#BEBEBE',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  textTabDark: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#ecb853',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  textTabImage: {
    width: Platform.OS === 'web' ? '25px' : 25,
  },
  textHeadline: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    alignItems: 'left',
    textAlign: 'left',
  },
  textHeadlineDark: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    color: '#ecb853',
    alignItems: 'left',
    textAlign: 'left',
  },
  textInputField: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    padding: Platform.OS === 'web' ? '10px' : 10,
  },
  textInputFieldDark: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    padding: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
  },
  textInputFieldMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    maxHeight: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#BEBEBE',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldMainDark: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    maxHeight: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldLength: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textInputFieldLengthColor: {
    color: '#BEBEBE',
  },
  textInputFieldLengthColorDark: {
    color: '#ecb853',
  },
  textButtons: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: Platform.OS === 'web' ? '200px' : 200,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textButtonsElement: {
    width: Platform.OS === 'web' ? '100px' : 100,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  textButtonsElementBackground: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    backgroundColor: '#BEBEBE',
  },
  textButtonsElementBackgroundDark: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    borderColor: '#ecb853',
    borderWidth: 2,
    backgroundColor: '#000000',
  },
  textButtonsElementIcon: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
  },
  textButtonsElementIconMain: {
    paddingTop: Platform.OS === 'web' ? '7px' : 7,
    paddingLeft: Platform.OS === 'web' ? '8px' : 8,
  },
  textButtonsElementIconMainDark: {
    paddingTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '6px' : 6,
  },
  textButtonsElementIconModal: {
    width: Platform.OS === 'web' ? '80px' : 80,
    height: Platform.OS === 'web' ? '80px' : 80,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  textButtonsTitle: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '7px' : 7,
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleDark: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '7px' : 7,
    color: '#ecb853',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMainDisabled: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    pointerEvents: 'none',
    opacity: 0.4,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#D05B5B',
    color: '#fff',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    //borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderRadius: 20,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonDark: {
    backgroundColor: '#000000',
    color: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    //borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderRadius: 20,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderColor: '#ecb853',
    borderWidth: 2,
  },
  modalMain: {
    width: Platform.OS === 'web' ? '210px' : 210,
    height: Platform.OS === 'web' ? '210px' : 210,
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '-260px' : -260,
    marginLeft: Platform.OS !== 'web' ? 'auto' : 'auto',
    position: 'relative',
    backgroundColor: '#ffffff',
    boxShadow: '0px 2px 5px 0px #000',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 6.68,
    elevation: 24,
    border: '1px solid #ccc',
    padding: Platform.OS === 'web' ? '20px' : 20,
  },
  modalMainDark: {
    width: Platform.OS === 'web' ? '210px' : 210,
    height: Platform.OS === 'web' ? '210px' : 210,
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '-260px' : -260,
    marginLeft: Platform.OS !== 'web' ? 'auto' : 'auto',
    position: 'relative',
    backgroundColor: '#000000',
    boxShadow: '0px 2px 5px 0px #000',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 6.68,
    elevation: 24,
    border: '1px solid #ecb853',
    padding: Platform.OS === 'web' ? '20px' : 20,
  },
  modalHeadline: {
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    fontSize: Platform.OS === 'web' ? '24px' : 24,
  },
  modalDescription: {
    marginTop: Platform.OS === 'web' ? '5px' : 5,
  },
  modalDescriptionDark: {
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    color: '#ecb853',
  },
  modalButtons: {
    flexDirection: 'row',
  },
  modalSubmit: {
    marginTop: Platform.OS === 'web' ? '40px' : 40,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#D05B5B',
    textAlign: 'right',
    flex: 1,
    //alignItems: 'right',
  },
  modalSubmitDark: {
    marginTop: Platform.OS === 'web' ? '40px' : 40,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#ecb853',
    textAlign: 'right',
    flex: 1,
    //alignItems: 'right',
  },
  modalSubmitDefault: {
    marginTop: Platform.OS === 'web' ? '40px' : 40,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#000000',
    textAlign: 'right',
    flex: 1,
    //alignItems: 'right',
  },
  modalSubmitDefaultDark: {
    marginTop: Platform.OS === 'web' ? '40px' : 40,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    textAlign: 'right',
    flex: 1,
    //alignItems: 'right',
    color: '#ecb853',
  },
  placeholderTextDark: {
    color: '#ecb853',
  },
  errorMessage: {
    color: '#D05B5B',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  errorMessageDark: {
    color: '#ecb853',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
});

export default InputText;
