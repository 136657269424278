/**
 * Input Camera Content
 */

import React, {useState, useEffect, useRef} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable,
  TouchableOpacity,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  SPEECH,
  TEXT,
  CAMERA,
  SELECT_INPUT_TYPE,
  SELECT_INPUT_LANGUAGE_TYPE,
  SELECT_OUTPUT_TYPE,
  SELECT_OUTPUT_LANGUAGE_TYPE,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_ITALIAN,
  LANGUAGE_LUXEMBOURG_FRENCH,
  LANGUAGE_LUXEMBOURG_GERMAN,
  LANGUAGE_DUTCH,
  LANGUAGE_SUIZZE_FRENCH,
  LANGUAGE_SUIZZE_ITALIAN,
  LANGUAGE_SUIZZE_GERMAN,
  LANGUAGE_BRITISH,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  DEFAULT_VALUE_TYPE,
  SETTINGS_CHOICE,
  SETTINGS_NORMAL,
  SETTINGS_FAVORITE,
  USERTOKEN_KEY,
  DEVELOPMENT,
  TEST,
  DEFAULT_OUTPUT_TYPE,
  AVATAR,
} from '../../../constants/constants';
import axios from 'axios';

/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import IconArrow from './../../../assets/iconarrow.png';
import TranslateIcon from './../../../assets/illustrationheaderoutputmethod.png';
import TextIcon from './../../../assets/icontext.png';
import TextIconDark from './../../../assets/icontextdark.png';
import RecordIcon from './../../../assets/iconspeech.png';
import RecordIconDark from './../../../assets/iconspeechdark.png';
import CameraIcon from './../../../assets/iconcamera.png';
import CameraIconDark from './../../../assets/iconcameradark.png';
import AvatarIcon from './../../../assets/iconavatar.png';
import EarthIcon from './../../../assets/illustrationheaderlanguage.png';
import RecordStart from './../../../assets/recordaudiobuton.png';
import RecordStop from './../../../assets/recordaudiobutton.png';
import PasteIcon from './../../../assets/iconcopy.png';
import PasteIconDark from './../../../assets/iconcopydark.png';
import DeleteIcon from './../../../assets/icondelete.png';
import DeleteIconDark from './../../../assets/icondeletedark.png';
import SaveIcon from './../../../assets/iconsave.png';
import SaveIconDark from './../../../assets/iconsavedark.png';
import ShareIcon from './../../../assets/iconshare.png';
import ShareIconDark from './../../../assets/iconsharedark.png';
import EqualizerIcon from './../../../assets/equalizer.png';
import EqualizerSmallIcon from '../../../assets/equalizersmall.png';
import LanguageIcon from './../../../assets/iconlanguage.png';
import LanguageIconDark from './../../../assets/iconlanguagedark.png';
import FlagGermanIcon from './../../../assets/flaggerman.png';
import FlagDutchIcon from './../../../assets/flagdutch.png';
import FlagFrenchIcon from './../../../assets/flagfrench.png';
import FlagItalianIcon from './../../../assets/flagitaly.png';
import FlagGreeceIcon from './../../../assets/flaggreece.png';
import FlagGreatBritianIcon from './../../../assets/flaggreatbritain.png';
import FlagLuxembourgIcon from './../../../assets/flagluxembourg.png';
import FlagSuizzeIcon from './../../../assets/flagsuizze.png';
import {Camera, CameraType} from 'expo-camera';
import {Video} from 'expo-av';
import {shareAsync} from 'expo-sharing';
import * as MediaLibrary from 'expo-media-library';
import VideoRecoder from './webvideo/VideoRecoder';
import {useTranslation} from 'react-i18next';

const InputAudioContentNew = ({navigation}): Node => {
  const [speech, setSpeech] = useState(false);
  const [text, setText] = useState(false);
  const [camera, setCamera] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [webFinishRecording, setWebFinishRecording] = useState(false);
  const [language, setLanguage] = useState('');
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();
  let cameraRef = useRef();
  const [hasCameraPermission, setHasCameraPermission] = useState();
  const [hasMicrophonePermission, setHasMicrophonePermission] = useState();
  const [hasMediaLibraryPermission, setHasMediaLibraryPermission] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [video, setVideo] = useState();
  const [fileRecord, setFileRecord] = useState();
  const [languageInputTranslate, setLanguageInputTranslate] = useState('');
  const [languageOutputTranslate, setLanguageOutputTranslate] = useState('');
  const [typeInputTranslate, setTypeInputTranslate] = useState('');
  const [typeOutputTranslate, setTypeOutputTranslate] = useState('');
  const [audioRecordData, setAudioRecordData] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    (async () => {
      const cameraPermission = await Camera.requestCameraPermissionsAsync();
      const microphonePermission =
        await Camera.requestMicrophonePermissionsAsync();
      const mediaLibraryPermission =
        await MediaLibrary.requestPermissionsAsync();
      setHasCameraPermission(cameraPermission.status === 'granted');
      setHasMicrophonePermission(microphonePermission.status === 'granted');
      setHasMediaLibraryPermission(mediaLibraryPermission.status === 'granted');
    })();
  }, []);

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    const resetOptionStart = async () => {
      const resetNewTranslation = await AsyncStorage.getItem('New_Translation');

      if (resetNewTranslation) {
        if (Platform.OS === 'web') {
          document.querySelector('.vjs-icon-record-start').click();
          setAudioRecordData(null);
        } else {
          setAudioRecordData(null);
        }
      } else {
        setAudioRecordData(null);
      }
    };
    resetOptionStart();
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  const setActiveDefaultInput = async (speechValue, textValue, cameraValue) => {
    setSpeech(speechValue);
    setText(textValue);
    setCamera(cameraValue);

    await AsyncStorage.setItem(
      SELECT_INPUT_TYPE,
      speechValue == true
        ? SPEECH
        : textValue == true
        ? TEXT
        : cameraValue == true
        ? CAMERA
        : '',
    );
  };

  useEffect(() => {
    const inputTypeDefault = async () => {
      const defaultInputLanguageType = await AsyncStorage.getItem(
        SELECT_INPUT_LANGUAGE_TYPE,
      );
      const defaultInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
      defaultInputLanguageType === LANGUAGE_FRENCH
        ? setLanguage(LANGUAGE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_GERMAN
        ? setLanguage(LANGUAGE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_GREEK
        ? setLanguage(LANGUAGE_GREEK)
        : defaultInputLanguageType === LANGUAGE_ITALIAN
        ? setLanguage(LANGUAGE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_FRENCH
        ? setLanguage(LANGUAGE_LUXEMBOURG_FRENCH)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_GERMAN
        ? setLanguage(LANGUAGE_LUXEMBOURG_GERMAN)
        : defaultInputLanguageType === LANGUAGE_DUTCH
        ? setLanguage(LANGUAGE_DUTCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_FRENCH
        ? setLanguage(LANGUAGE_SUIZZE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_ITALIAN
        ? setLanguage(LANGUAGE_SUIZZE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_GERMAN
        ? setLanguage(LANGUAGE_SUIZZE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_BRITISH
        ? setLanguage(LANGUAGE_BRITISH)
        : setLanguage('');

      defaultInputType === SPEECH
        ? setActiveDefaultInput(true, false, false)
        : defaultInputType === TEXT
        ? setActiveDefaultInput(false, true, false)
        : defaultInputType === CAMERA
        ? setActiveDefaultInput(false, false, true)
        : setActiveDefaultInput(false, false, false);
    };
    inputTypeDefault();
  }, [isFocused]);

  const recordVideo = () => {
    setIsRecording(true);
    let options = {
      quality: '480p',
      maxDuration: 1000,
      fps: 30,
      mute: false,
    };
    cameraRef.current.recordAsync(options).then(recordedVideo => {
      console.log(recordedVideo);
      setVideo(recordedVideo);
      setIsRecording(false);
      setIsRecorded(false);
      setIsVideoAvailable(true);
    });
  };

  const stopRecording = () => {
    console.log(isRecorded, 'isRecorded');
    console.log(isVideoAvailable, 'isVideoAvailable');
    cameraRef.current.stopRecording();
  };

  const startRecord = () => {
    setIsRecorded(true);
    setIsVideoAvailable(false);
    recordVideo();
  };

  const startRecordingWeb = () => {
    setIsRecorded(true);
  };

  const stopRecordingWeb = dataRecord => {
    console.log('stopRecordingWeb from parent');
    setIsRecorded(false);
    setWebFinishRecording(true);
    setAudioRecordData(dataRecord);
    //console.log(dataRecord);
  };

  const stopRecord = () => {
    stopRecording();
  };

  const nextPage = () => {
    navigation.navigate('Output Method');
  };

  const backInputSetting = async navigation => {
    const favoriteSetting = await AsyncStorage.getItem(DEFAULT_VALUE_TYPE);
    await AsyncStorage.setItem(SETTINGS_CHOICE, SETTINGS_NORMAL);

    if (favoriteSetting === 'false') {
      navigation.navigate('Input Method');
    } else {
      navigation.navigate('Default Input');
    }
  };

  const getDataRecordConst = () => {
    console.log(fileRecord);
  };

  const deleteRecord = () => {
    setIsRecorded(false);
    setIsVideoAvailable(false);
    setWebFinishRecording(false);
    if (Platform.OS === 'web') {
      document.querySelector('.vjs-icon-record-start').click();
      setAudioRecordData(null);
    } else {
      setAudioRecordData(null);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      const defaultInputLanguageType = await AsyncStorage.getItem(
        SELECT_INPUT_LANGUAGE_TYPE,
      );
      const defaultInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
      const defaultOutputLanguageType = await AsyncStorage.getItem(
        SELECT_OUTPUT_LANGUAGE_TYPE,
      );
      const defaultOutputType = await AsyncStorage.getItem(SELECT_OUTPUT_TYPE);

      if (defaultInputType === 'camera') {
        setTypeInputTranslate('video');
      }
      if (defaultInputType == 'text') {
        setTypeInputTranslate('text');
      }
      if (defaultInputType === 'speech') {
        setTypeInputTranslate('audio');
      }

      if (defaultOutputType === 'avatar') {
        setTypeOutputTranslate('video');
      }
      if (defaultOutputType == 'text') {
        setTypeOutputTranslate('text');
      }
      if (defaultOutputType === 'speech') {
        setTypeOutputTranslate('audio');
      }

      if (
        defaultInputLanguageType === 'german' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('DGS');
      }
      if (
        defaultInputLanguageType === 'german' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('DE');
      }
      if (
        defaultInputLanguageType === 'french' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('LSF');
      }
      if (
        defaultInputLanguageType === 'french' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('FR');
      }
      if (
        defaultInputLanguageType === 'british' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('BSL');
      }
      if (
        defaultInputLanguageType === 'british' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('EN');
      }
      if (
        defaultInputLanguageType === 'greek' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('GSL');
      }
      if (
        defaultInputLanguageType === 'greek' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('EL');
      }
      if (
        defaultInputLanguageType === 'italian' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('LIS');
      }
      if (
        defaultInputLanguageType === 'italian' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('IT');
      }
      if (
        defaultInputLanguageType === 'dutch' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('NGT');
      }
      if (
        defaultInputLanguageType === 'dutch' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('NL');
      }
      if (
        defaultInputLanguageType === 'suizze-german' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('DSGS');
      }
      if (
        defaultInputLanguageType === 'suizze-german' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('DE');
      }

      if (
        defaultOutputLanguageType === 'german' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('DGS');
      }
      if (
        defaultOutputLanguageType === 'german' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('DE');
      }
      if (
        defaultOutputLanguageType === 'french' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('LSF');
      }
      if (
        defaultOutputLanguageType === 'french' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('FR');
      }
      if (
        defaultOutputLanguageType === 'british' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('BSL');
      }
      if (
        defaultOutputLanguageType === 'british' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('EN');
      }
      if (
        defaultOutputLanguageType === 'greek' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('GSL');
      }
      if (
        defaultOutputLanguageType === 'greek' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('EL');
      }
      if (
        defaultOutputLanguageType === 'italian' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('LIS');
      }
      if (
        defaultOutputLanguageType === 'italian' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('IT');
      }
      if (
        defaultOutputLanguageType === 'dutch' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('NGT');
      }
      if (
        defaultOutputLanguageType === 'dutch' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('NL');
      }
      if (
        defaultOutputLanguageType === 'suizze-german' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('DSGS');
      }
      if (
        defaultOutputLanguageType === 'suizze-german' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('DE');
      }
    };
    loadData();
  }, [isFocused]);

  const translateAudio = async () => {
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);
    const settingsChoice = await AsyncStorage.getItem(DEFAULT_VALUE_TYPE);
    const defaultOutputChoice = await AsyncStorage.getItem(DEFAULT_OUTPUT_TYPE);
    const selectOutputChoice = await AsyncStorage.getItem(SELECT_OUTPUT_TYPE);

    let form = new FormData();

    form.append(
      'translation',
      '{"input_type": "' +
        typeInputTranslate +
        '","input_language": "' +
        languageInputTranslate +
        '","output_type": "' +
        typeOutputTranslate +
        '","output_language": "' +
        languageOutputTranslate +
        '","test_mode": false}',
    );
    form.append('upload_file', audioRecordData);
    const getOutputTypeTranslation =
      typeOutputTranslate === 'text'
        ? 'text'
        : typeOutputTranslate === 'audio'
        ? 'audio'
        : 'video';

    navigation.navigate('Status Translation', {
      visualData: audioRecordData,
      languageDataInput: languageInputTranslate,
      typeDataInput: 'audio',
      languageDataOutput: languageOutputTranslate,
      typeDataOutput: getOutputTypeTranslation,
    });
    /*if (
      (settingsChoice === 'false' && selectOutputChoice === AVATAR) ||
      (settingsChoice === 'true' && defaultOutputChoice === AVATAR)
    ) {
      navigation.navigate('Status Translation', {
        visualData: audioRecordData,
      });
    } else {
      axios
        .post(TEST + 'translation', form, {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(response => {
          if (response.status === 200) {
            setShowErrorMessage(false);
            setTimeout(() => {
              navigation.navigate('Status Translation', {
                visualData: '',
              });
            }, 1000);
          } else {
            console.log('Error');
          }
        })
        .catch(e => {
          console.log(e);
          setShowErrorMessage(true);
        });
    }*/
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}
        accessibilityLabel={t('Input')}>
        <Text
          style={
            darkModeAvailable
              ? [
                  styles.textHeadlineDark,
                  contrastSizing === SMALLTEXT
                    ? styles.smallTextAll
                    : contrastSizing === NORMALTEXT
                    ? styles.normalTextAll
                    : contrastSizing === BIGTEXT
                    ? styles.bigTextAll
                    : styles.normalTextAll,
                ]
              : [
                  styles.textHeadline,
                  contrastSizing === SMALLTEXT
                    ? styles.smallTextAll
                    : contrastSizing === NORMALTEXT
                    ? styles.normalTextAll
                    : contrastSizing === BIGTEXT
                    ? styles.bigTextAll
                    : styles.normalTextAll,
                ]
          }>
          {t('Input')}
        </Text>
        <Pressable
          onPress={() => backInputSetting(navigation)}
          accessible={true}
          accessibilityLabel={'Back to input method - Settings'}>
          <View
            style={darkModeAvailable ? styles.textTabDark : styles.textTab}
            accessible={true}>
            <View
              style={
                darkModeAvailable
                  ? styles.textTabImageAudioDark
                  : styles.textTabImage
              }
              accessible={true}
              accessibilityLabel={'Selected input method'}>
              <Image
                width={Platform.OS == 'web' ? '30px' : 30}
                height={Platform.OS == 'web' ? '30px' : 30}
                source={{
                  uri:
                    speech == true
                      ? Platform.OS == 'web'
                        ? darkModeAvailable
                          ? RecordIconDark
                          : RecordIcon
                        : darkModeAvailable
                        ? 'iconinputdark'
                        : 'iconinput'
                      : text == true
                      ? Platform.OS == 'web'
                        ? darkModeAvailable
                          ? TextIconDark
                          : TextIcon
                        : darkModeAvailable
                        ? 'icontextdark'
                        : 'icontext'
                      : Platform.OS == 'web'
                      ? darkModeAvailable
                        ? CameraIconDark
                        : CameraIcon
                      : darkModeAvailable
                      ? 'iconcameradark'
                      : 'iconcamera',
                }}
                style={
                  language == ''
                    ? darkModeAvailable
                      ? [
                          styles.settingsIconStyleTypeAudioDark,
                          styles.settingsIconCustomStyle,
                        ]
                      : [
                          styles.settingsIconStyleType,
                          styles.settingsIconCustomStyle,
                        ]
                    : darkModeAvailable
                    ? [
                        styles.settingsIconStyleTypeAudioDark,
                        styles.settingsIconCustomStyleActive,
                      ]
                    : [
                        styles.settingsIconStyleType,
                        styles.settingsIconCustomStyleActive,
                      ]
                }
                resizeMode="contain"
              />
            </View>
            <View
              style={styles.textTabImage}
              accessible={true}
              accessibilityLabel={'Selected language input'}>
              <Image
                width={Platform.OS == 'web' ? '25px' : 25}
                height={Platform.OS == 'web' ? '25px' : 25}
                source={{
                  uri:
                    language == LANGUAGE_GERMAN
                      ? Platform.OS == 'web'
                        ? FlagGermanIcon
                        : 'flaggerman'
                      : language == LANGUAGE_GREEK
                      ? Platform.OS == 'web'
                        ? FlagGreeceIcon
                        : 'flaggreece'
                      : language == LANGUAGE_FRENCH
                      ? Platform.OS == 'web'
                        ? FlagFrenchIcon
                        : 'flagfrench'
                      : language == LANGUAGE_ITALIAN
                      ? Platform.OS == 'web'
                        ? FlagItalianIcon
                        : 'flagitaly'
                      : language == LANGUAGE_LUXEMBOURG_FRENCH ||
                        language == LANGUAGE_LUXEMBOURG_GERMAN
                      ? Platform.OS == 'web'
                        ? FlagLuxembourgIcon
                        : 'flagluxembourg'
                      : language == LANGUAGE_DUTCH
                      ? Platform.OS == 'web'
                        ? FlagDutchIcon
                        : 'flagdutch'
                      : language == LANGUAGE_SUIZZE_FRENCH ||
                        language == LANGUAGE_SUIZZE_ITALIAN ||
                        language == LANGUAGE_SUIZZE_GERMAN
                      ? Platform.OS == 'web'
                        ? FlagSuizzeIcon
                        : 'flagsuizze'
                      : language == LANGUAGE_BRITISH
                      ? Platform.OS == 'web'
                        ? FlagGreatBritianIcon
                        : 'flaggreatbritain'
                      : Platform.OS == 'web'
                      ? LanguageIcon
                      : 'iconlanguage',
                }}
                style={
                  language == ''
                    ? [
                        styles.settingsIconStyleFlag,
                        styles.settingsIconCustomStyle,
                      ]
                    : [
                        styles.settingsIconStyleFlag,
                        styles.settingsIconCustomStyleActive,
                      ]
                }
                resizeMode="contain"
              />
            </View>
          </View>
        </Pressable>
        <View
          style={
            darkModeAvailable
              ? styles.textInputFieldMainDark
              : styles.textInputFieldMain
          }
          accessible={true}
          accessibilityLabel={'Video recorder and function'}>
          {Platform.OS === 'web' ? (
            <VideoRecoder
              startRecordingWeb={startRecordingWeb}
              stopRecordingWeb={stopRecordingWeb}
            />
          ) : (
            <View />
          )}
          {isRecorded && !isRecording && Platform.OS !== 'web' ? (
            <>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Video_recording_element')}
              </Text>
            </>
          ) : isVideoAvailable ? (
            <></>
          ) : (
            <>
              <Camera
                style={{flex: 1}}
                ref={cameraRef}
                type={CameraType.front}
              />
            </>
          )}
          {!isRecorded && isVideoAvailable ? (
            <>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                Show recorded audio/video - Video player
              </Text>
              <Video
                style={{flex: 1}}
                source={{uri: video.uri}}
                useNativeControls
                resizeMode="contain"
                isLooping
              />
            </>
          ) : (
            <></>
          )}
        </View>
        {isRecorded & !isVideoAvailable & (Platform.OS !== 'web') ? (
          <TouchableOpacity
            onPress={stopRecord}
            accessible={true}
            accessibilityLabel={'Stop video recorder and function'}>
            <Image
              width={Platform.OS == 'web' ? '100px' : 100}
              height={Platform.OS == 'web' ? '100px' : 100}
              source={{
                uri: Platform.OS == 'web' ? RecordStop : 'recordaudiobutton',
              }}
              style={styles.iconAudioButtons}
              resizeMode="contain"
            />
          </TouchableOpacity>
        ) : (
          <></>
        )}
        {!isRecorded & !isVideoAvailable & (Platform.OS !== 'web') ? (
          <TouchableOpacity
            onPress={startRecord}
            accessible={true}
            accessibilityLabel={'Start video recorder and function'}>
            <Image
              width={Platform.OS == 'web' ? '100px' : 100}
              height={Platform.OS == 'web' ? '100px' : 100}
              source={{
                uri: Platform.OS == 'web' ? RecordStart : 'recordaudiobuton',
              }}
              style={styles.iconAudioButtons}
              resizeMode="contain"
            />
          </TouchableOpacity>
        ) : (
          <></>
        )}
        {(isRecorded && !isVideoAvailable) || !webFinishRecording ? (
          <View style={styles.textRecordingContainer}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textRecordingContainerElement,
                      styles.textDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textRecordingContainerElement,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              1. {t('video_one')}
            </Text>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textRecordingContainerElement,
                      styles.textDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textRecordingContainerElement,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              2. {t('video_two')}
            </Text>
          </View>
        ) : (
          <></>
        )}
        {(!isRecorded && isVideoAvailable) || webFinishRecording ? (
          <>
            <View style={styles.textButtons} accessible={true}>
              <Pressable
                style={styles.textButtonsElement}
                accessible={true}
                accessibilityLabel={t('Delete')}
                onPress={deleteRecord}>
                <View
                  style={
                    darkModeAvailable
                      ? styles.textButtonsElementBackgroundDark
                      : styles.textButtonsElementBackground
                  }
                  accessible={true}>
                  <View
                    style={
                      darkModeAvailable
                        ? styles.textButtonsElementIconMainDark
                        : styles.textButtonsElementIconMain
                    }
                    accessible={true}
                    accessibilityLabel={'Delete with icon'}>
                    <Image
                      width={Platform.OS == 'web' ? '25px' : 25}
                      height={Platform.OS == 'web' ? '25px' : 25}
                      source={{
                        uri:
                          Platform.OS == 'web'
                            ? darkModeAvailable
                              ? DeleteIconDark
                              : DeleteIcon
                            : darkModeAvailable
                            ? 'icondeletedark'
                            : 'icondelete',
                      }}
                      style={styles.textButtonsElementIcon}
                      resizeMode="contain"
                    />
                  </View>
                </View>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textButtonsTitleCustomDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.textButtonsTitleCustom,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Delete')}
                </Text>
              </Pressable>
              {/*<Pressable
                style={styles.textButtonsElement}
                accessible={true}
                accessibilityLabel={t('Copy')}
                accessibilityHint="Copy validation">
                <View
                  style={
                    darkModeAvailable
                      ? styles.textButtonsElementBackgroundDark
                      : styles.textButtonsElementBackground
                  }
                  accessible={true}>
                  <View
                    style={
                      darkModeAvailable
                        ? styles.textButtonsElementIconMainDark
                        : styles.textButtonsElementIconMain
                    }
                    accessible={true}
                    accessibilityLabel={'Copy with icon'}>
                    <Image
                      width={Platform.OS == 'web' ? '25px' : 25}
                      height={Platform.OS == 'web' ? '25px' : 25}
                      source={{
                        uri: Platform.OS == 'web' ? PasteIcon : 'iconcopy',
                      }}
                      style={styles.textButtonsElementIcon}
                      resizeMode="contain"
                    />
                  </View>
                </View>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textButtonsTitleDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.textButtonsTitle,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Copy')}
                </Text>
              </Pressable>*/}
            </View>
            {/*<View style={styles.textButtons} accessible={true}>
              <Pressable
                style={styles.textButtonsElement}
                accessible={true}
                accessibilityLabel={t('Save')}
                accessibilityHint="Save validation">
                <View
                  style={
                    darkModeAvailable
                      ? styles.textButtonsElementBackgroundDark
                      : styles.textButtonsElementBackground
                  }
                  accessible={true}>
                  <View
                    style={
                      darkModeAvailable
                        ? styles.textButtonsElementIconMainDark
                        : styles.textButtonsElementIconMain
                    }
                    accessible={true}
                    accessibilityLabel={'Save with icon'}>
                    <Image
                      width={Platform.OS == 'web' ? '25px' : 25}
                      height={Platform.OS == 'web' ? '25px' : 25}
                      source={{
                        uri: Platform.OS == 'web' ? SaveIcon : 'iconsave',
                      }}
                      style={styles.textButtonsElementIcon}
                      resizeMode="contain"
                    />
                  </View>
                </View>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textButtonsTitleDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.textButtonsTitle,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Save')}
                </Text>
              </Pressable>
              <Pressable
                style={styles.textButtonsElement}
                accessible={true}
                accessibilityLabel={t('Share')}
                accessibilityHint="Share validation">
                <View
                  style={
                    darkModeAvailable
                      ? styles.textButtonsElementBackgroundDark
                      : styles.textButtonsElementBackground
                  }
                  accessible={true}>
                  <View
                    style={
                      darkModeAvailable
                        ? styles.textButtonsElementIconMainDark
                        : styles.textButtonsElementIconMain
                    }
                    accessible={true}
                    accessibilityLabel={'Share with icon'}>
                    <Image
                      width={Platform.OS == 'web' ? '25px' : 25}
                      height={Platform.OS == 'web' ? '25px' : 25}
                      source={{
                        uri: Platform.OS == 'web' ? ShareIcon : 'iconshare',
                      }}
                      style={styles.textButtonsElementIcon}
                      resizeMode="contain"
                    />
                  </View>
                </View>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textButtonsTitleDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.textButtonsTitle,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Share')}
                </Text>
              </Pressable>
            </View>*/}
            {showErrorMessage ? (
              <>
                <Text
                  style={
                    darkModeAvailable
                      ? styles.errorMessageDark
                      : styles.errorMessage
                  }>
                  {t('Unable_Service_Message')}
                </Text>
              </>
            ) : (
              <></>
            )}
            <TouchableOpacity
              style={styles.buttonMain}
              onPress={translateAudio}
              accessible={true}
              accessibilityLabel={t('TRANSLATE')}
              accessibilityHint="Start the translation process">
              {/*
                onPress={async () =>
                  navigation.navigate('Output Method', {
                    newRecorded: await AsyncStorage.getItem('videoFile'),
                  })
                }
              */}
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.buttonDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.button,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('TRANSLATE')}
              </Text>
            </TouchableOpacity>
          </>
        ) : (
          <></>
        )}
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
  },
  mainMarginWeb: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsIconStyleType: {
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '35px' : 35,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-5px' : -5,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-5px' : -5,
  },
  settingsIconStyleTypeAudioDark: {
    width: Platform.OS === 'web' ? '32px' : 32,
    height: Platform.OS === 'web' ? '32px' : 32,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-1px' : -1,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-5.5px' : -5.5,
  },
  textTabImageAudioDark: {
    width: Platform.OS === 'web' ? '27px' : 27,
    height: Platform.OS === 'web' ? '22px' : 22,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    backgroundColor: '#000',
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginTop: Platform.OS === 'web' ? '2px' : 2,
  },
  settingsIconStyleFlag: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsIconCustomStyle: {
    marginLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  textTab: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#BEBEBE',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  textTabDark: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#ecb853',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  textTabImage: {
    width: Platform.OS === 'web' ? '25px' : 25,
  },
  textHeadline: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    alignItems: 'left',
    textAlign: 'left',
  },
  textHeadlineDark: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    color: '#ecb853',
    alignItems: 'left',
    textAlign: 'left',
  },
  textInputField: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    padding: Platform.OS === 'web' ? '10px' : 10,
  },
  textInputFieldMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#BEBEBE',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldMainDark: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldLength: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textInputFieldLengthColor: {
    color: '#BEBEBE',
  },
  textButtons: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  textButtonsElement: {
    // Old - Width
    //width: Platform.OS === 'web' ? '150px' : 150,
    // New - Width
    width: Platform.OS === 'web' ? '300px' : 300,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  textButtonsElementBackground: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    backgroundColor: '#BEBEBE',
  },
  textButtonsElementBackgroundDark: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    borderColor: '#ecb853',
    borderWidth: 2,
    backgroundColor: '#000000',
  },
  textButtonsElementIcon: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
  },
  textButtonsElementIconMain: {
    paddingTop: Platform.OS === 'web' ? '7px' : 7,
    paddingLeft: Platform.OS === 'web' ? '8px' : 8,
  },
  textButtonsElementIconMainDark: {
    paddingTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '6px' : 6,
  },
  textButtonsElementIconModal: {
    width: Platform.OS === 'web' ? '80px' : 80,
    height: Platform.OS === 'web' ? '80px' : 80,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  textButtonsTitle: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    //paddingLeft: Platform.OS === 'web' ? '7px' : 7,
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleDark: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    //paddingLeft: Platform.OS === 'web' ? '8px' : 8,
    color: '#ecb853',
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleCustom: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    //paddingLeft: Platform.OS === 'web' ? '6px' : 6,
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleCustomDark: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    //paddingLeft: Platform.OS === 'web' ? '6px' : 6,
    color: '#ecb853',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMainDisabled: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    pointerEvents: 'none',
    opacity: 0.4,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#D05B5B',
    color: '#fff',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonDark: {
    backgroundColor: '#000000',
    color: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    //borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderRadius: 20,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderColor: '#ecb853',
    borderWidth: 2,
  },
  iconAudioEqualizer: {
    width: Platform.OS === 'web' ? '50px' : 50,
    height: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    position: 'absolute',
    top: Platform.OS === 'web' ? '125px' : 125,
    left: Platform.OS === 'web' ? '125px' : 125,
  },
  iconAudioButtons: {
    width: Platform.OS === 'web' ? '100px' : 100,
    height: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  textDark: {
    color: '#ecb853',
  },
  textRecordingContainer: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textRecordingContainerElement: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  errorMessage: {
    color: '#D05B5B',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  errorMessageDark: {
    color: '#ecb853',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
});

export default InputAudioContentNew;
