/**
 * Voice
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Switch,
  TouchableOpacity,
  Platform,
  Pressable,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import {
  VOICE_ANNOTATION_TYPE,
  VOICE_CLOTHES_TYPE,
  VOICE_GENDER_TYPE,
  VOICE_LANGUAGE_TYPE,
  VOICE_GENDER_MALE,
  VOICE_GENDER_FEMALE,
  VOICE_GENDER_DIVERS,
  VOICE_CLOTHES_BUSINESS,
  VOICE_CLOTHES_CASUAL,
  VOICE_LANGUAGE_BRITISH,
  VOICE_LANGUAGE_AMERICAN,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
} from '../../../constants/constants';
// import RadioButton from './../../../components/radioButton/radioButton';

/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import IconArrow from './../../../assets/iconarrow.png';
import BusinessStyleIcon from './../../../assets/iconstylebusiness.png';
import CasualStyleIcon from './../../../assets/iconstylecasual.png';
import GenderDiversIcon from './../../../assets/icongenderdivers.png';
import GenderFemaleIcon from './../../../assets/icongenderfemale.png';
import GenderMaleIcon from './../../../assets/icongendermale.png';
import BritishFlag from './../../../assets/flaggreatbritain.png';
import AmericanFlag from './../../../assets/flagamericanenglish.png';
import {useTranslation} from 'react-i18next';
/*const PROP = [
  {
    key: VOICE_CLOTHES_BUSINESS,
    text: VOICE_CLOTHES_BUSINESS,
  },
  {
    key: 'freestyle',
    text: 'Freestyle',
  },
];*/

const RadioButton = ({onPress, selected, contrastSizing, children}) => {
  return (
    <View style={styles.radioButtonContainer}>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation"
        style={selected ? styles.radioButtonActive : styles.radioButton}>
        {selected ? <View style={styles.radioButtonIcon} /> : null}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation">
        <Text
          style={[
            styles.radioButtonText,
            contrastSizing === SMALLTEXT
              ? styles.smallTextAll
              : contrastSizing === NORMALTEXT
              ? styles.normalTextAll
              : contrastSizing === BIGTEXT
              ? styles.bigTextAll
              : styles.normalTextAll,
          ]}>
          {children}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const RadioButtonDark = ({onPress, selected, contrastSizing, children}) => {
  return (
    <View style={styles.radioButtonContainer}>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation"
        style={selected ? styles.radioButtonActiveDark : styles.radioButton}>
        {selected ? <View style={styles.radioButtonIconDark} /> : null}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation">
        <Text
          style={[
            styles.radioButtonText,
            contrastSizing === SMALLTEXT
              ? styles.smallTextAll
              : contrastSizing === NORMALTEXT
              ? styles.normalTextAll
              : contrastSizing === BIGTEXT
              ? styles.bigTextAll
              : styles.normalTextAll,
          ]}>
          {children}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const Voice = ({navigation}): Node => {
  const [isEnabled, setIsEnabled] = useState(false);
  const {t} = useTranslation();
  const [clothes, setClothes] = useState([
    {
      id: 1,
      value: VOICE_CLOTHES_BUSINESS,
      name: (
        <View style={styles.settingsBackgroundColorIcon}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                Platform.OS == 'web' ? BusinessStyleIcon : 'iconstylebusiness',
            }}
            style={styles.settingsIconStyle}
            resizeMode="contain"
          />
        </View>
      ),
      selected: true,
    },
    {
      id: 2,
      value: VOICE_CLOTHES_CASUAL,
      name: (
        <View style={styles.settingsBackgroundColorIcon}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri: Platform.OS == 'web' ? CasualStyleIcon : 'iconstylecasual',
            }}
            style={styles.settingsIconStyle}
            resizeMode="contain"
          />
        </View>
      ),
      selected: false,
    },
  ]);
  const [gender, setGender] = useState([
    {
      id: 1,
      value: VOICE_GENDER_MALE,
      name: (
        <View style={styles.settingsBackgroundColorIcon}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri: Platform.OS == 'web' ? GenderMaleIcon : 'icongendermale',
            }}
            style={[styles.settingsIconStyle, styles.settingsGenderIconStyle]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: true,
    },
    {
      id: 2,
      value: VOICE_GENDER_FEMALE,
      name: (
        <View style={styles.settingsBackgroundColorIcon}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri: Platform.OS == 'web' ? GenderFemaleIcon : 'icongenderfemale',
            }}
            style={[styles.settingsIconStyle, styles.settingsGenderIconStyle]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: false,
    },
    {
      id: 3,
      value: VOICE_GENDER_DIVERS,
      name: (
        <View style={styles.settingsBackgroundColorIcon}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri: Platform.OS == 'web' ? GenderDiversIcon : 'icongenderdivers',
            }}
            style={[styles.settingsIconStyle, styles.settingsGenderIconStyle]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: false,
    },
  ]);
  const [language, setLanguage] = useState([
    {
      id: 1,
      value: VOICE_LANGUAGE_BRITISH,
      name: (
        <View>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri: Platform.OS == 'web' ? BritishFlag : 'flaggreatbritain',
            }}
            style={styles.settingsIconStyle}
            resizeMode="contain"
          />
        </View>
      ),
      selected: true,
    },
    {
      id: 2,
      value: VOICE_LANGUAGE_AMERICAN,
      name: (
        <View>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri: Platform.OS == 'web' ? AmericanFlag : 'flagamericanenglish',
            }}
            style={styles.settingsIconStyle}
            resizeMode="contain"
          />
        </View>
      ),
      selected: false,
    },
  ]);
  const [selectedClothes, setSelectedClothes] = useState(
    VOICE_CLOTHES_BUSINESS,
  );
  const [selectedGender, setSelectedGender] = useState(VOICE_GENDER_MALE);
  const [selectedLanguage, setSelectedLanguage] = useState(
    VOICE_LANGUAGE_BRITISH,
  );
  const [selectedIntonation, setSelectedIntonation] = useState(false);
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  const onRadioBtnClick = async item => {
    let updatedState = clothes.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setClothes(updatedState);
    setSelectedClothes(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].value,
    );

    await AsyncStorage.setItem(
      VOICE_CLOTHES_TYPE,
      updatedState[0].selected == true
        ? VOICE_CLOTHES_BUSINESS
        : VOICE_CLOTHES_CASUAL,
    );
  };
  const onRadioBtnClickGender = async item => {
    let updatedState = gender.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setGender(updatedState);
    setSelectedGender(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );

    await AsyncStorage.setItem(
      VOICE_GENDER_TYPE,
      updatedState[0].selected == true
        ? VOICE_GENDER_MALE
        : updatedState[1].selected == true
        ? VOICE_GENDER_FEMALE
        : VOICE_GENDER_DIVERS,
    );
  };
  const onRadioBtnClickLanguage = async item => {
    let updatedState = language.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setLanguage(updatedState);
    setSelectedLanguage(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].value,
    );

    await AsyncStorage.setItem(
      VOICE_LANGUAGE_TYPE,
      updatedState[0].selected == true
        ? VOICE_LANGUAGE_BRITISH
        : VOICE_LANGUAGE_AMERICAN,
    );
  };

  useEffect(() => {
    const voiceSettings = async () => {
      if ((await AsyncStorage.getItem(VOICE_ANNOTATION_TYPE)) == null) {
        await AsyncStorage.setItem(VOICE_ANNOTATION_TYPE, 'false');
      }
      if ((await AsyncStorage.getItem(VOICE_CLOTHES_TYPE)) == null) {
        await AsyncStorage.setItem(VOICE_CLOTHES_TYPE, VOICE_CLOTHES_BUSINESS);
      }
      if ((await AsyncStorage.getItem(VOICE_GENDER_TYPE)) == null) {
        await AsyncStorage.setItem(VOICE_GENDER_TYPE, VOICE_GENDER_MALE);
      }
      if ((await AsyncStorage.getItem(VOICE_LANGUAGE_TYPE)) == null) {
        await AsyncStorage.setItem(VOICE_LANGUAGE_TYPE, VOICE_LANGUAGE_BRITISH);
      }
    };

    voiceSettings();
  });

  useEffect(() => {
    const voiceTypeDefault = async () => {
      const defaultVoiceIntonationType = await AsyncStorage.getItem(
        VOICE_ANNOTATION_TYPE,
      );
      defaultVoiceIntonationType === 'true'
        ? setIsEnabled(true)
        : setIsEnabled(false);

      const defaultVoiceClothesType = await AsyncStorage.getItem(
        VOICE_CLOTHES_TYPE,
      );
      defaultVoiceClothesType === VOICE_CLOTHES_BUSINESS
        ? setSelectedClothes(
            ((clothes[0].selected = true), (clothes[1].selected = false)),
          )
        : defaultVoiceClothesType === VOICE_CLOTHES_CASUAL
        ? setSelectedClothes(
            ((clothes[1].selected = true), (clothes[0].selected = false)),
          )
        : setSelectedClothes(
            ((clothes[0].selected = true), (clothes[1].selected = false)),
          );

      const defaultVoiceGenderType = await AsyncStorage.getItem(
        VOICE_GENDER_TYPE,
      );
      defaultVoiceGenderType === VOICE_GENDER_MALE
        ? setSelectedGender(
            ((gender[0].selected = true),
            (gender[1].selected = false),
            (gender[2].selected = false)),
          )
        : defaultVoiceGenderType === VOICE_GENDER_FEMALE
        ? setSelectedGender(
            ((gender[0].selected = false),
            (gender[1].selected = true),
            (gender[2].selected = false)),
          )
        : defaultVoiceGenderType === VOICE_GENDER_DIVERS
        ? setSelectedGender(
            ((gender[0].selected = false),
            (gender[1].selected = false),
            (gender[2].selected = true)),
          )
        : setSelectedGender(
            ((gender[0].selected = true),
            (gender[1].selected = false),
            (gender[2].selected = false)),
          );

      const defaultVoiceLanguageType = await AsyncStorage.getItem(
        VOICE_LANGUAGE_TYPE,
      );
      defaultVoiceLanguageType === VOICE_LANGUAGE_BRITISH
        ? setSelectedLanguage(
            ((language[0].selected = true), (language[1].selected = false)),
          )
        : defaultVoiceLanguageType === VOICE_LANGUAGE_AMERICAN
        ? setSelectedLanguage(
            ((language[1].selected = true), (language[0].selected = false)),
          )
        : setSelectedLanguage(
            ((language[0].selected = true), (language[1].selected = false)),
          );
    };
    voiceTypeDefault();
  });

  const toggleSwitchDefault = () => {
    setIsEnabled(previousState => !previousState);
  };

  const toggleSwitch = async () => {
    //setIsEnabled(previousState => !previousState);
    if (isEnabled === true) {
      setIsEnabled(false);
      setSelectedIntonation('false');

      await AsyncStorage.setItem(VOICE_ANNOTATION_TYPE, 'false');
    } else {
      setIsEnabled(true);
      setSelectedIntonation('true');

      await AsyncStorage.setItem(VOICE_ANNOTATION_TYPE, 'true');
    }

    /*if (setIsEnabled === true) {
      setSelectedIntonation(true);
      console.log('true');
    } else {
      setSelectedIntonation(false);
      console.log('false');
    }*/
  };
  const saveData = () => {
    navigation.navigate('Settings');
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={
            darkModeAvailable
              ? [styles.additionalOption, styles.borderDownDark]
              : [styles.additionalOption, styles.borderDown]
          }
          accessible={true}>
          <View style={styles.switchText} accessible={true}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.mainTitleDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      '',
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Adapt_intonation')}
            </Text>
          </View>
          <View style={styles.switchSlider} accessible={true}>
            {darkModeAvailable ? (
              <Switch
                trackColor={{false: '#ccc', true: '#ecb853'}}
                thumbColor={isEnabled ? '#ecb853' : '#ecb853'}
                activeThumbColor="#ecb853"
                activeTrackColor="#ecb853"
                //ios_backgroundColor="#fff"
                onValueChange={toggleSwitch}
                value={isEnabled}
                accessible={true}
              />
            ) : (
              <Switch
                trackColor={{false: '#ccc', true: '#D05B5B'}}
                thumbColor={isEnabled ? '#fff' : '#fff'}
                activeThumbColor="#fff"
                activeTrackColor="#fff"
                //ios_backgroundColor="#fff"
                onValueChange={toggleSwitch}
                value={isEnabled}
                accessible={true}
              />
            )}
          </View>
        </View>
        <View
          style={
            darkModeAvailable
              ? [styles.additionalOptionStyles, styles.borderDownDark]
              : [styles.additionalOptionStyles, styles.borderDown]
          }
          accessible={true}>
          <View
            style={
              darkModeAvailable ? styles.clothesTextDark : styles.clothesText
            }
            accessible={true}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.categoryTitleDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.categoryTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Language_Level')}
            </Text>
            <View accessible={true}>
              {darkModeAvailable
                ? clothes.map(item => (
                    <RadioButtonDark
                      onPress={() => onRadioBtnClick(item)}
                      selected={item.selected}
                      contrastSizing={contrastSizing}
                      key={item.id}>
                      {item.name}
                    </RadioButtonDark>
                  ))
                : clothes.map(item => (
                    <RadioButton
                      onPress={() => onRadioBtnClick(item)}
                      selected={item.selected}
                      contrastSizing={contrastSizing}
                      key={item.id}>
                      {item.name}
                    </RadioButton>
                  ))}
            </View>
          </View>
          <View style={styles.genderBlock} accessible={true}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.categoryTitleDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.categoryTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Gender')}
            </Text>
            <View accessible={true}>
              {darkModeAvailable
                ? gender.map(item => (
                    <RadioButtonDark
                      onPress={() => onRadioBtnClickGender(item)}
                      selected={item.selected}
                      contrastSizing={contrastSizing}
                      key={item.id}>
                      {item.name}
                    </RadioButtonDark>
                  ))
                : gender.map(item => (
                    <RadioButton
                      onPress={() => onRadioBtnClickGender(item)}
                      selected={item.selected}
                      contrastSizing={contrastSizing}
                      key={item.id}>
                      {item.name}
                    </RadioButton>
                  ))}
            </View>
          </View>
        </View>
        <View style={styles.additionalOptionTitle} accessible={true}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.headlineTitleDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.headlineTitle,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Accent')}
          </Text>
        </View>
        <View style={styles.additionalOptionStyles} accessible={true}>
          <View
            style={
              darkModeAvailable ? styles.languageTextDark : styles.languageText
            }
            accessible={true}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.categoryTitleDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.categoryTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('English')}
            </Text>
            <View accessible={true}>
              {darkModeAvailable
                ? language.map(item => (
                    <RadioButtonDark
                      onPress={() => onRadioBtnClickLanguage(item)}
                      selected={item.selected}
                      contrastSizing={contrastSizing}
                      key={item.id}>
                      {item.name}
                    </RadioButtonDark>
                  ))
                : language.map(item => (
                    <RadioButton
                      onPress={() => onRadioBtnClickLanguage(item)}
                      selected={item.selected}
                      contrastSizing={contrastSizing}
                      key={item.id}>
                      {item.name}
                    </RadioButton>
                  ))}
            </View>
          </View>
          <View style={styles.emptyText} accessible={true}></View>
        </View>
        <View>
          <Pressable
            style={darkModeAvailable ? styles.buttonDark : styles.button}
            accessible={true}
            accessibilityLabel={t('Save')}
            accessibilityHint="Save voice and language level validation"
            onPress={saveData}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textButton,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Save')}
            </Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  switchEnableBorder: {
    borderColor: '#6fa6d3',
    borderWidth: 1,
  },
  switchDisableBorder: {
    borderColor: '#f2f2f2',
    borderWidth: 1,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  borderDown: {
    borderBottomWidth: 2,
    borderBottomColor: '#ccc',
  },
  borderDownDark: {
    borderBottomWidth: 2,
    borderBottomColor: '#ecb853',
  },
  additionalOption: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '50px' : 50,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  additionalOptionTitle: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '35px' : 35,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  additionalOptionStyles: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
  },
  headlineTitle: {
    width: Platform.OS === 'web' ? '300px' : 300,
    alignItems: 'center',
  },
  mainTitleDark: {
    color: '#ecb853',
  },
  categoryTitle: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  headlineTitleDark: {
    width: Platform.OS === 'web' ? '300px' : 300,
    alignItems: 'center',
    color: '#ecb853',
  },
  categoryTitleDark: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    //resizeMode: 'center',
    alignItems: 'center',
    color: '#ecb853',
  },
  switchText: {
    width: Platform.OS === 'web' ? '250px' : 250,
    marginRight: Platform.OS === 'web' ? '5px' : 5,
  },
  clothesText: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  clothesTextDark: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderRightColor: '#ecb853',
  },
  languageText: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  languageTextDark: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderRightColor: '#ecb853',
  },
  emptyText: {
    width: Platform.OS === 'web' ? '150px' : 150,
    height: Platform.OS === 'web' ? 'auto' : 'auto',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  genderBlock: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  switchSlider: {
    width: Platform.OS === 'web' ? '50px' : 50,
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsBackgroundColor: {
    backgroundColor: '#ECECEC',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    flex: 1,
    margin: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  settingsText: {
    fontSize: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsTextAdditional: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? '12px' : 12,
  },
  settingsIconStyle: {
    marginTop: Platform.OS === 'ios' ? 8 : '0px',
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '35px' : 35,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsGenderIconStyle: {
    marginTop: Platform.OS === 'web' ? '3px' : Platform.OS === 'ios' ? 10 : 3,
  },
  settingsBackgroundColorIcon: {
    backgroundColor: '#BEBEBE',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  radioButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  radioButton: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonActive: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#D05B5B',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonActiveDark: {
    height: 20,
    width: 20,
    backgroundColor: '#000',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ecb853',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonIcon: {
    height: 14,
    width: 14,
    borderRadius: 7,
    backgroundColor: '#D05B5B',
  },
  radioButtonIconDark: {
    height: 14,
    width: 14,
    borderRadius: 7,
    backgroundColor: '#ecb853',
  },
  radioButtonText: {
    fontSize: 16,
    marginLeft: 16,
  },
  text: {
    lineHeight: 30,
    fontSize: 20,
    marginVertical: 5,
  },
  button: {
    backgroundColor: '#D05B5B',
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textButton: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textDark: {
    color: '#ecb853',
  },
});

export default Voice;
