/**
 * Upload video
 */

import React, {useState, useEffect, useRef} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable,
  TouchableOpacity,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  SPEECH,
  TEXT,
  CAMERA,
  SELECT_INPUT_TYPE,
  SELECT_INPUT_LANGUAGE_TYPE,
  SELECT_OUTPUT_TYPE,
  SELECT_OUTPUT_LANGUAGE_TYPE,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_ITALIAN,
  LANGUAGE_LUXEMBOURG_FRENCH,
  LANGUAGE_LUXEMBOURG_GERMAN,
  LANGUAGE_DUTCH,
  LANGUAGE_SUIZZE_FRENCH,
  LANGUAGE_SUIZZE_ITALIAN,
  LANGUAGE_SUIZZE_GERMAN,
  LANGUAGE_BRITISH,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  DEFAULT_VALUE_TYPE,
  SETTINGS_CHOICE,
  SETTINGS_NORMAL,
  SETTINGS_FAVORITE,
  USERTOKEN_KEY,
  DEVELOPMENT,
  TEST,
  DEFAULT_OUTPUT_TYPE,
  AVATAR,
} from '../../../constants/constants';
import axios from 'axios';

/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import IconArrow from './../../../assets/iconarrow.png';
import TranslateIcon from './../../../assets/illustrationheaderoutputmethod.png';
import TextIcon from './../../../assets/icontext.png';
import TextIconDark from './../../../assets/icontextdark.png';
import RecordIcon from './../../../assets/iconspeech.png';
import RecordIconDark from './../../../assets/iconspeechdark.png';
import CameraIcon from './../../../assets/iconcamera.png';
import CameraIconDark from './../../../assets/iconcameradark.png';
import AvatarIcon from './../../../assets/iconavatar.png';
import EarthIcon from './../../../assets/illustrationheaderlanguage.png';
import RecordStart from './../../../assets/recordaudiobuton.png';
import RecordStop from './../../../assets/recordaudiobutton.png';
import PasteIcon from './../../../assets/iconcopy.png';
import PasteIconDark from './../../../assets/iconcopydark.png';
import DeleteIcon from './../../../assets/icondelete.png';
import DeleteIconDark from './../../../assets/icondeletedark.png';
import SaveIcon from './../../../assets/iconsave.png';
import SaveIconDark from './../../../assets/iconsavedark.png';
import ShareIcon from './../../../assets/iconshare.png';
import ShareIconDark from './../../../assets/iconsharedark.png';
import EqualizerIcon from './../../../assets/equalizer.png';
import EqualizerSmallIcon from '../../../assets/equalizersmall.png';
import LanguageIcon from './../../../assets/iconlanguage.png';
import LanguageIconDark from './../../../assets/iconlanguagedark.png';
import FlagGermanIcon from './../../../assets/flaggerman.png';
import FlagDutchIcon from './../../../assets/flagdutch.png';
import FlagFrenchIcon from './../../../assets/flagfrench.png';
import FlagItalianIcon from './../../../assets/flagitaly.png';
import FlagGreeceIcon from './../../../assets/flaggreece.png';
import FlagGreatBritianIcon from './../../../assets/flaggreatbritain.png';
import FlagLuxembourgIcon from './../../../assets/flagluxembourg.png';
import FlagSuizzeIcon from './../../../assets/flagsuizze.png';
import {Camera, CameraType} from 'expo-camera';
import {Video} from 'expo-av';
import {shareAsync} from 'expo-sharing';
import * as MediaLibrary from 'expo-media-library';
import VideoUploadComponent from './webvideoupload/videoUploadComponent';
import {useTranslation} from 'react-i18next';

const UploadVideoNew = ({navigation}): Node => {
  const [speech, setSpeech] = useState(false);
  const [text, setText] = useState(false);
  const [camera, setCamera] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [webFinishRecording, setWebFinishRecording] = useState(false);
  const [language, setLanguage] = useState('');
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();
  let cameraRef = useRef();
  const [hasCameraPermission, setHasCameraPermission] = useState();
  const [hasMicrophonePermission, setHasMicrophonePermission] = useState();
  const [hasMediaLibraryPermission, setHasMediaLibraryPermission] = useState();
  const [isRecording, setIsRecording] = useState(false);
  const [video, setVideo] = useState();
  const [languageInputTranslate, setLanguageInputTranslate] = useState('');
  const [languageOutputTranslate, setLanguageOutputTranslate] = useState('');
  const [typeInputTranslate, setTypeInputTranslate] = useState('');
  const [typeOutputTranslate, setTypeOutputTranslate] = useState('');
  const [videoRecordData, setVideoRecordData] = useState('');
  const [videoURIData, setVideoURIData] = useState();
  const [inputLanguage, setInputLanguage] = useState('');
  const [outputLanguage, setOutputLanguage] = useState('');
  const [outputMethod, setOutputMethod] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [] = useState();
  const {t} = useTranslation();

  /*useEffect(() => {
    (async () => {
      const cameraPermission = await Camera.requestCameraPermissionsAsync();
      const microphonePermission =
        await Camera.requestMicrophonePermissionsAsync();
      const mediaLibraryPermission =
        await MediaLibrary.requestPermissionsAsync();
      setHasCameraPermission(cameraPermission.status === 'granted');
      setHasMicrophonePermission(microphonePermission.status === 'granted');
      setHasMediaLibraryPermission(mediaLibraryPermission.status === 'granted');
    })();
  }, []);*/

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    const prepareSelectUploadData = async () => {
      await AsyncStorage.setItem(DEFAULT_VALUE_TYPE, 'false');
    };
    checkDarkModeDefault();
    checkSizeDefault();
    prepareSelectUploadData();
  }, [isFocused]);
  useEffect(() => {
    //setVideoURIData();
    setInputLanguage('');
    setOutputLanguage('');
    setOutputMethod('');
  }, [isFocused]);
  const setActiveDefaultInput = async (speechValue, textValue, cameraValue) => {
    setSpeech(speechValue);
    setText(textValue);
    setCamera(cameraValue);

    await AsyncStorage.setItem(
      SELECT_INPUT_TYPE,
      speechValue == true
        ? SPEECH
        : textValue == true
        ? TEXT
        : cameraValue == true
        ? CAMERA
        : '',
    );
  };

  useEffect(() => {
    const inputTypeDefault = async () => {
      const defaultInputLanguageType = await AsyncStorage.getItem(
        SELECT_INPUT_LANGUAGE_TYPE,
      );
      const defaultInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
      defaultInputLanguageType === LANGUAGE_FRENCH
        ? setLanguage(LANGUAGE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_GERMAN
        ? setLanguage(LANGUAGE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_GREEK
        ? setLanguage(LANGUAGE_GREEK)
        : defaultInputLanguageType === LANGUAGE_ITALIAN
        ? setLanguage(LANGUAGE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_FRENCH
        ? setLanguage(LANGUAGE_LUXEMBOURG_FRENCH)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_GERMAN
        ? setLanguage(LANGUAGE_LUXEMBOURG_GERMAN)
        : defaultInputLanguageType === LANGUAGE_DUTCH
        ? setLanguage(LANGUAGE_DUTCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_FRENCH
        ? setLanguage(LANGUAGE_SUIZZE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_ITALIAN
        ? setLanguage(LANGUAGE_SUIZZE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_GERMAN
        ? setLanguage(LANGUAGE_SUIZZE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_BRITISH
        ? setLanguage(LANGUAGE_BRITISH)
        : setLanguage('');

      defaultInputType === SPEECH
        ? setActiveDefaultInput(true, false, false)
        : defaultInputType === TEXT
        ? setActiveDefaultInput(false, true, false)
        : defaultInputType === CAMERA
        ? setActiveDefaultInput(false, false, true)
        : setActiveDefaultInput(false, false, false);
    };
    inputTypeDefault();
  }, [isFocused]);

  useEffect(() => {
    const loadData = async () => {
      const defaultInputLanguageType = await AsyncStorage.getItem(
        SELECT_INPUT_LANGUAGE_TYPE,
      );
      const defaultInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
      const defaultOutputLanguageType = await AsyncStorage.getItem(
        SELECT_OUTPUT_LANGUAGE_TYPE,
      );
      const defaultOutputType = await AsyncStorage.getItem(SELECT_OUTPUT_TYPE);

      if (defaultInputType === 'camera') {
        setTypeInputTranslate('video');
      }
      if (defaultInputType == 'text') {
        setTypeInputTranslate('text');
      }
      if (defaultInputType === 'speech') {
        setTypeInputTranslate('audio');
      }

      if (defaultOutputType === 'avatar') {
        setTypeOutputTranslate('video');
      }
      if (defaultOutputType == 'text') {
        setTypeOutputTranslate('text');
      }
      if (defaultOutputType === 'speech') {
        setTypeOutputTranslate('audio');
      }

      if (
        defaultInputLanguageType === 'german' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('DGS');
      }
      if (
        defaultInputLanguageType === 'german' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('DE');
      }
      if (
        defaultInputLanguageType === 'french' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('LSF');
      }
      if (
        defaultInputLanguageType === 'french' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('FR');
      }
      if (
        defaultInputLanguageType === 'british' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('BSL');
      }
      if (
        defaultInputLanguageType === 'british' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('EN');
      }
      if (
        defaultInputLanguageType === 'greek' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('GSL');
      }
      if (
        defaultInputLanguageType === 'greek' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('EL');
      }
      if (
        defaultInputLanguageType === 'italian' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('LIS');
      }
      if (
        defaultInputLanguageType === 'italian' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('IT');
      }
      if (
        defaultInputLanguageType === 'dutch' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('NGT');
      }
      if (
        defaultInputLanguageType === 'dutch' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('NL');
      }
      if (
        defaultInputLanguageType === 'suizze-german' &&
        defaultInputType === 'camera'
      ) {
        setLanguageInputTranslate('DSGS');
      }
      if (
        defaultInputLanguageType === 'suizze-german' &&
        defaultInputType !== 'camera'
      ) {
        setLanguageInputTranslate('DE');
      }

      if (
        defaultOutputLanguageType === 'german' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('DGS');
      }
      if (
        defaultOutputLanguageType === 'german' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('DE');
      }
      if (
        defaultOutputLanguageType === 'french' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('LSF');
      }
      if (
        defaultOutputLanguageType === 'french' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('FR');
      }
      if (
        defaultOutputLanguageType === 'british' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('BSL');
      }
      if (
        defaultOutputLanguageType === 'british' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('EN');
      }
      if (
        defaultOutputLanguageType === 'greek' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('GSL');
      }
      if (
        defaultOutputLanguageType === 'greek' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('EL');
      }
      if (
        defaultOutputLanguageType === 'italian' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('LIS');
      }
      if (
        defaultOutputLanguageType === 'italian' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('IT');
      }
      if (
        defaultOutputLanguageType === 'dutch' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('NGT');
      }
      if (
        defaultOutputLanguageType === 'dutch' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('NL');
      }
      if (
        defaultOutputLanguageType === 'suizze-german' &&
        defaultOutputType === 'avatar'
      ) {
        setLanguageOutputTranslate('DSGS');
      }
      if (
        defaultOutputLanguageType === 'suizze-german' &&
        defaultOutputType !== 'avatar'
      ) {
        setLanguageOutputTranslate('DE');
      }
      /*const refreshDataSource = async () => {
        const dataSourceNew = await AsyncStorage.getItem('sourceData');
        if (dataSourceNew === 'new') {
          setVideoURIData();
        }
      };
      refreshDataSource();*/
    };
    loadData();
  }, [isFocused]);

  const dataURISource = uploadedDataFile => {
    setVideoURIData(uploadedDataFile);
  };

  const inputLanguageSource = dataType => {
    setInputLanguage(dataType);
  };

  const outputLanguageSource = dataType => {
    setOutputLanguage(dataType);
  };

  const outputMethodSource = dataType => {
    setOutputMethod(dataType);
  };

  const translateVideo = async () => {
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

    await AsyncStorage.setItem(DEFAULT_VALUE_TYPE, 'false');
    await AsyncStorage.setItem(SELECT_INPUT_TYPE, 'camera');
    const selectedInputLanguage = inputLanguage;
    const selectedOutputLanguage = outputLanguage;
    const selectedOutputMethod = outputMethod;
    selectedInputLanguage === 'BSL'
      ? await AsyncStorage.setItem(SELECT_INPUT_LANGUAGE_TYPE, LANGUAGE_BRITISH)
      : selectedInputLanguage === 'DGS'
      ? await AsyncStorage.setItem(SELECT_INPUT_LANGUAGE_TYPE, LANGUAGE_GERMAN)
      : selectedInputLanguage === 'LSF'
      ? await AsyncStorage.setItem(SELECT_INPUT_LANGUAGE_TYPE, LANGUAGE_FRENCH)
      : selectedInputLanguage === 'LIS'
      ? await AsyncStorage.setItem(SELECT_INPUT_LANGUAGE_TYPE, LANGUAGE_ITALIAN)
      : selectedInputLanguage === 'GSL'
      ? await AsyncStorage.setItem(SELECT_INPUT_LANGUAGE_TYPE, LANGUAGE_GREEK)
      : selectedInputLanguage === 'NGT'
      ? await AsyncStorage.setItem(SELECT_INPUT_LANGUAGE_TYPE, LANGUAGE_DUTCH)
      : selectedInputLanguage === 'DSGS'
      ? await AsyncStorage.setItem(
          SELECT_INPUT_LANGUAGE_TYPE,
          LANGUAGE_SUIZZE_GERMAN,
        )
      : await AsyncStorage.setItem(SELECT_INPUT_LANGUAGE_TYPE, '');
    selectedOutputLanguage === 'BSL' || selectedOutputLanguage === 'EN'
      ? await AsyncStorage.setItem(
          SELECT_OUTPUT_LANGUAGE_TYPE,
          LANGUAGE_BRITISH,
        )
      : selectedOutputLanguage === 'DGS' || selectedOutputLanguage === 'DE'
      ? await AsyncStorage.setItem(SELECT_OUTPUT_LANGUAGE_TYPE, LANGUAGE_GERMAN)
      : selectedOutputLanguage === 'LSF' || selectedOutputLanguage === 'FR'
      ? await AsyncStorage.setItem(SELECT_OUTPUT_LANGUAGE_TYPE, LANGUAGE_FRENCH)
      : selectedOutputLanguage === 'LIS' || selectedOutputLanguage === 'IT'
      ? await AsyncStorage.setItem(
          SELECT_OUTPUT_LANGUAGE_TYPE,
          LANGUAGE_ITALIAN,
        )
      : selectedOutputLanguage === 'GSL' || selectedOutputLanguage === 'EL'
      ? await AsyncStorage.setItem(SELECT_OUTPUT_LANGUAGE_TYPE, LANGUAGE_GREEK)
      : selectedOutputLanguage === 'NGT' || selectedOutputLanguage === 'NL'
      ? await AsyncStorage.setItem(SELECT_OUTPUT_LANGUAGE_TYPE, LANGUAGE_DUTCH)
      : selectedOutputLanguage === 'DSGS' || selectedOutputLanguage === 'CH'
      ? await AsyncStorage.setItem(
          SELECT_OUTPUT_LANGUAGE_TYPE,
          LANGUAGE_SUIZZE_GERMAN,
        )
      : await AsyncStorage.setItem(SELECT_OUTPUT_LANGUAGE_TYPE, '');
    selectedOutputMethod === 'audio'
      ? await AsyncStorage.setItem(SELECT_OUTPUT_TYPE, SPEECH)
      : selectedOutputMethod === 'text'
      ? await AsyncStorage.setItem(SELECT_OUTPUT_TYPE, TEXT)
      : selectedOutputMethod === 'avatar' || selectedOutputMethod === 'video'
      ? await AsyncStorage.setItem(SELECT_OUTPUT_TYPE, AVATAR)
      : await AsyncStorage.setItem(SELECT_OUTPUT_TYPE, '');

    let form = new FormData();

    form.append(
      'translation',
      '{"input_type": "' +
        '"video"' +
        '","input_language": "' +
        inputLanguage +
        '","output_type": "' +
        outputMethod +
        '","output_language": "' +
        outputLanguage +
        '","test_mode": false}',
    );
    form.append('upload_file', videoURIData);
    /*const getOutputTypeTranslation =
      typeOutputTranslate === 'text'
        ? 'text'
        : typeOutputTranslate === 'audio'
        ? 'audio'
        : 'video';*/

    await AsyncStorage.setItem('sourceData', 'new');

    setTimeout(() => {
      navigation.navigate('Status Translation New', {
        visualData: videoURIData,
        languageDataInput: inputLanguage,
        typeDataInput: 'video',
        languageDataOutput: outputLanguage,
        typeDataOutput: outputMethod,
      });
    }, 2000);
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}
        accessibilityLabel={t('Input')}>
        <VideoUploadComponent
          width={300}
          height={300}
          dataSourceURI={dataURISource}
          darkModeAvailable={darkModeAvailable}
          contrastSizing={contrastSizing}
          inputLanguage={inputLanguageSource}
          outputLanguage={outputLanguageSource}
          outputMethod={outputMethodSource}
        />
        {videoURIData !== undefined && (
          <>
            <TouchableOpacity
              style={styles.buttonMain}
              onPress={translateVideo}
              accessible={true}
              accessibilityLabel={t('TRANSLATE')}
              accessibilityHint="Start the translation process for upload video">
              {/*
                onPress={async () =>
                  navigation.navigate('Output Method', {
                    newRecorded: await AsyncStorage.getItem('videoFile'),
                  })
                }
              */}
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.buttonDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.button,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('TRANSLATE')}
              </Text>
            </TouchableOpacity>
          </>
        )}
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
  },
  mainMarginWeb: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsIconStyleType: {
    width: Platform.OS === 'web' ? '45px' : 45,
    height: Platform.OS === 'web' ? '45px' : 45,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-10px' : -10,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-10px' : -10,
  },
  settingsIconStyleTypeVideoDark: {
    width: Platform.OS === 'web' ? '32px' : 32,
    height: Platform.OS === 'web' ? '32px' : 32,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '0px' : 0,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-6px' : -6,
  },
  textTabImageVideoDark: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '22px' : 22,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    backgroundColor: '#000',
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginTop: Platform.OS === 'web' ? '2px' : 2,
  },
  settingsIconStyleFlag: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsIconCustomStyle: {
    marginLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  textTab: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#BEBEBE',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  textTabDark: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#ecb853',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  textTabImage: {
    width: Platform.OS === 'web' ? '25px' : 25,
  },
  textHeadline: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    alignItems: 'left',
    textAlign: 'left',
  },
  textHeadlineDark: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    color: '#ecb853',
    alignItems: 'left',
    textAlign: 'left',
  },
  textInputField: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    padding: Platform.OS === 'web' ? '10px' : 10,
  },
  textInputFieldMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#BEBEBE',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldMainDark: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldLength: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textInputFieldLengthColor: {
    color: '#BEBEBE',
  },
  textButtons: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  textButtonsElement: {
    // Old - Width
    //width: Platform.OS === 'web' ? '150px' : 150,
    // New - Width
    width: Platform.OS === 'web' ? '300px' : 300,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  textButtonsElementBackground: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    backgroundColor: '#BEBEBE',
  },
  textButtonsElementBackgroundDark: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    borderColor: '#ecb853',
    borderWidth: 2,
    backgroundColor: '#000000',
  },
  textButtonsElementIcon: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
  },
  textButtonsElementIconMain: {
    paddingTop: Platform.OS === 'web' ? '7px' : 7,
    paddingLeft: Platform.OS === 'web' ? '8px' : 8,
  },
  textButtonsElementIconMainDark: {
    paddingTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '6px' : 6,
  },
  textButtonsElementIconModal: {
    width: Platform.OS === 'web' ? '80px' : 80,
    height: Platform.OS === 'web' ? '80px' : 80,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  textButtonsTitle: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    //paddingLeft: Platform.OS === 'web' ? '7px' : 7,
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleDark: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    //paddingLeft: Platform.OS === 'web' ? '8px' : 8,
    color: '#ecb853',
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleCustom: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    //paddingLeft: Platform.OS === 'web' ? '6px' : 6,
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleCustomDark: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    //paddingLeft: Platform.OS === 'web' ? '6px' : 6,
    color: '#ecb853',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMainDisabled: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    pointerEvents: 'none',
    opacity: 0.4,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#D05B5B',
    color: '#fff',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonDark: {
    backgroundColor: '#000000',
    color: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    //borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderRadius: 20,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderColor: '#ecb853',
    borderWidth: 2,
  },
  iconAudioEqualizer: {
    width: Platform.OS === 'web' ? '50px' : 50,
    height: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    position: 'absolute',
    top: Platform.OS === 'web' ? '125px' : 125,
    left: Platform.OS === 'web' ? '125px' : 125,
  },
  iconAudioButtons: {
    width: Platform.OS === 'web' ? '100px' : 100,
    height: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  textDark: {
    color: '#ecb853',
  },
  textRecordingContainer: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textRecordingContainerElement: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  errorMessage: {
    color: '#D05B5B',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  errorMessageDark: {
    color: '#ecb853',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
});

export default UploadVideoNew;
