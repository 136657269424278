/**
 * Input Audio Content
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable,
  TouchableOpacity,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  SPEECH,
  TEXT,
  CAMERA,
  SELECT_INPUT_TYPE,
  SELECT_INPUT_LANGUAGE_TYPE,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_ITALIAN,
  LANGUAGE_LUXEMBOURG_FRENCH,
  LANGUAGE_LUXEMBOURG_GERMAN,
  LANGUAGE_DUTCH,
  LANGUAGE_SUIZZE_FRENCH,
  LANGUAGE_SUIZZE_ITALIAN,
  LANGUAGE_SUIZZE_GERMAN,
  LANGUAGE_BRITISH,
  CONTRAST_MODE_TYPE,
  AUDIO_RECORDING,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
} from '../../../constants/constants';

/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import IconArrow from './../../../assets/iconarrow.png';
import TranslateIcon from './../../../assets/illustrationheaderoutputmethod.png';
import RecordIcon from './../../../assets/iconspeech.png';
import TextIcon from './../../../assets/icontext.png';
import CameraIcon from './../../../assets/iconcamera.png';
import AvatarIcon from './../../../assets/iconavatar.png';
import EarthIcon from './../../../assets/illustrationheaderlanguage.png';
import RecordStart from './../../../assets/recordaudiobuton.png';
import RecordStop from './../../../assets/recordaudiobutton.png';
import PasteIcon from './../../../assets/iconcopy.png';
import DeleteIcon from './../../../assets/icondelete.png';
import SaveIcon from './../../../assets/iconsave.png';
import ShareIcon from './../../../assets/iconshare.png';
import EqualizerIcon from './../../../assets/equalizer.png';
import EqualizerSmallIcon from '../../../assets/equalizersmall.png';
import LanguageIcon from './../../../assets/iconlanguage.png';
import FlagGermanIcon from './../../../assets/flaggerman.png';
import FlagDutchIcon from './../../../assets/flagdutch.png';
import FlagFrenchIcon from './../../../assets/flagfrench.png';
import FlagItalianIcon from './../../../assets/flagitaly.png';
import FlagGreeceIcon from './../../../assets/flaggreece.png';
import FlagGreatBritianIcon from './../../../assets/flaggreatbritain.png';
import FlagLuxembourgIcon from './../../../assets/flagluxembourg.png';
import FlagSuizzeIcon from './../../../assets/flagsuizze.png';
import {Audio} from 'expo-av';
import {useTranslation} from 'react-i18next';
const InputAudioContent = ({navigation, route}): Node => {
  const [speech, setSpeech] = useState(false);
  const [text, setText] = useState(false);
  const [camera, setCamera] = useState(false);
  const [language, setLanguage] = useState('');
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  const {recodeing} = route.params;
  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  const setActiveDefaultInput = async (speechValue, textValue, cameraValue) => {
    setSpeech(speechValue);
    setText(textValue);
    setCamera(cameraValue);

    await AsyncStorage.setItem(
      SELECT_INPUT_TYPE,
      speechValue == true
        ? SPEECH
        : textValue == true
        ? TEXT
        : cameraValue == true
        ? CAMERA
        : '',
    );
  };

  useEffect(() => {
    const inputTypeDefault = async () => {
      const defaultInputLanguageType = await AsyncStorage.getItem(
        SELECT_INPUT_LANGUAGE_TYPE,
      );
      const defaultInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
      defaultInputLanguageType === LANGUAGE_FRENCH
        ? setLanguage(LANGUAGE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_GERMAN
        ? setLanguage(LANGUAGE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_GREEK
        ? setLanguage(LANGUAGE_GREEK)
        : defaultInputLanguageType === LANGUAGE_ITALIAN
        ? setLanguage(LANGUAGE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_FRENCH
        ? setLanguage(LANGUAGE_LUXEMBOURG_FRENCH)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_GERMAN
        ? setLanguage(LANGUAGE_LUXEMBOURG_GERMAN)
        : defaultInputLanguageType === LANGUAGE_DUTCH
        ? setLanguage(LANGUAGE_DUTCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_FRENCH
        ? setLanguage(LANGUAGE_SUIZZE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_ITALIAN
        ? setLanguage(LANGUAGE_SUIZZE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_GERMAN
        ? setLanguage(LANGUAGE_SUIZZE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_BRITISH
        ? setLanguage(LANGUAGE_BRITISH)
        : setLanguage('');

      defaultInputType === SPEECH
        ? setActiveDefaultInput(true, false, false)
        : defaultInputType === TEXT
        ? setActiveDefaultInput(false, true, false)
        : defaultInputType === CAMERA
        ? setActiveDefaultInput(false, false, true)
        : setActiveDefaultInput(false, false, false);
    };
    inputTypeDefault();
  }, [isFocused]);

  const stopRecord = () => {
    navigation.navigate('Output Method');
  };
  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}
        accessibilityLabel={t('Input')}>
        <Text
          style={
            darkModeAvailable
              ? [
                  styles.textHeadlineDark,
                  contrastSizing === SMALLTEXT
                    ? styles.smallTextAll
                    : contrastSizing === NORMALTEXT
                    ? styles.normalTextAll
                    : contrastSizing === BIGTEXT
                    ? styles.bigTextAll
                    : styles.normalTextAll,
                ]
              : [
                  styles.textHeadline,
                  contrastSizing === SMALLTEXT
                    ? styles.smallTextAll
                    : contrastSizing === NORMALTEXT
                    ? styles.normalTextAll
                    : contrastSizing === BIGTEXT
                    ? styles.bigTextAll
                    : styles.normalTextAll,
                ]
          }>
          {t('Input')}
        </Text>
        <View style={styles.textTab} accessible={true}>
          <View
            style={styles.textTabImage}
            accessible={true}
            accessibilityLabel={'Selected input method'}>
            <Image
              width={Platform.OS == 'web' ? '30px' : 30}
              height={Platform.OS == 'web' ? '30px' : 30}
              source={{
                uri:
                  speech == true
                    ? Platform.OS == 'web'
                      ? RecordIcon
                      : 'iconinput'
                    : text == true
                    ? Platform.OS == 'web'
                      ? TextIcon
                      : 'icontext'
                    : Platform.OS == 'web'
                    ? CameraIcon
                    : 'iconcamera',
              }}
              style={
                language == ''
                  ? [styles.settingsIconStyle, styles.settingsIconCustomStyle]
                  : [
                      styles.settingsIconStyle,
                      styles.settingsIconCustomStyleActive,
                    ]
              }
              resizeMode="contain"
            />
          </View>
          <View
            style={styles.textTabImage}
            accessible={true}
            accessibilityLabel={'Selected language input'}>
            <Image
              width={Platform.OS == 'web' ? '25px' : 25}
              height={Platform.OS == 'web' ? '25px' : 25}
              source={{
                uri:
                  language == LANGUAGE_GERMAN
                    ? Platform.OS == 'web'
                      ? FlagGermanIcon
                      : 'flaggerman'
                    : language == LANGUAGE_GREEK
                    ? Platform.OS == 'web'
                      ? FlagGreeceIcon
                      : 'flaggreece'
                    : language == LANGUAGE_FRENCH
                    ? Platform.OS == 'web'
                      ? FlagFrenchIcon
                      : 'flagfrench'
                    : language == LANGUAGE_ITALIAN
                    ? Platform.OS == 'web'
                      ? FlagItalianIcon
                      : 'flagitaly'
                    : language == LANGUAGE_LUXEMBOURG_FRENCH ||
                      language == LANGUAGE_LUXEMBOURG_GERMAN
                    ? Platform.OS == 'web'
                      ? FlagLuxembourgIcon
                      : 'flagluxembourg'
                    : language == LANGUAGE_DUTCH
                    ? Platform.OS == 'web'
                      ? FlagDutchIcon
                      : 'flagdutch'
                    : language == LANGUAGE_SUIZZE_FRENCH ||
                      language == LANGUAGE_SUIZZE_ITALIAN ||
                      language == LANGUAGE_SUIZZE_GERMAN
                    ? Platform.OS == 'web'
                      ? FlagSuizzeIcon
                      : 'flagsuizze'
                    : language == LANGUAGE_BRITISH
                    ? Platform.OS == 'web'
                      ? FlagGreatBritianIcon
                      : 'flaggreatbritain'
                    : Platform.OS == 'web'
                    ? LanguageIcon
                    : 'iconlanguage',
              }}
              style={
                language == ''
                  ? [styles.settingsIconStyle, styles.settingsIconCustomStyle]
                  : [
                      styles.settingsIconStyle,
                      styles.settingsIconCustomStyleActive,
                    ]
              }
              resizeMode="contain"
            />
          </View>
        </View>
        <TouchableOpacity
          onPress={() => recodeing[recodeing.length - 1].sound.replayAsync()}
          accessible={true}
          accessibilityLabel={'Audio play icon and function'}>
          <View style={styles.textInputFieldMain} accessible={true}>
            <Image
              width={Platform.OS == 'web' ? '50px' : 50}
              height={Platform.OS == 'web' ? '50px' : 50}
              source={{
                uri:
                  Platform.OS == 'web' ? EqualizerSmallIcon : 'equalizersmall',
              }}
              style={styles.iconAudioEqualizer}
              resizeMode="contain"
            />
            <View style={styles.bottomText}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Play_Audio')}
              </Text>
              {/*recodeing[recodeing.length - 1].duration*/}
            </View>
          </View>
        </TouchableOpacity>
        <View style={styles.textButtons} accessible={true}>
          <Pressable
            style={styles.textButtonsElement}
            accessible={true}
            accessibilityLabel={t('Delete')}>
            <View style={styles.textButtonsElementBackground} accessible={true}>
              <View
                style={styles.textButtonsElementIconMain}
                accessible={true}
                accessibilityLabel={'Delete with icon'}>
                <Image
                  width={Platform.OS == 'web' ? '25px' : 25}
                  height={Platform.OS == 'web' ? '25px' : 25}
                  source={{
                    uri: Platform.OS == 'web' ? DeleteIcon : 'icondelete',
                  }}
                  style={styles.textButtonsElementIcon}
                  resizeMode="contain"
                />
              </View>
            </View>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textButtonsTitleCustomDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textButtonsTitleCustom,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Delete')}
            </Text>
          </Pressable>
          <Pressable
            style={styles.textButtonsElement}
            accessible={true}
            accessibilityLabel={t('Copy')}
            accessibilityHint="Copy validation">
            <View style={styles.textButtonsElementBackground} accessible={true}>
              <View
                style={styles.textButtonsElementIconMain}
                accessible={true}
                accessibilityLabel={'Copy with icon'}>
                <Image
                  width={Platform.OS == 'web' ? '25px' : 25}
                  height={Platform.OS == 'web' ? '25px' : 25}
                  source={{
                    uri: Platform.OS == 'web' ? PasteIcon : 'iconcopy',
                  }}
                  style={styles.textButtonsElementIcon}
                  resizeMode="contain"
                />
              </View>
            </View>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textButtonsTitleDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textButtonsTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Copy')}
            </Text>
          </Pressable>
        </View>
        <View style={styles.textButtons} accessible={true}>
          <Pressable
            style={styles.textButtonsElement}
            accessible={true}
            accessibilityLabel={t('Save')}
            accessibilityHint="Save validation">
            <View style={styles.textButtonsElementBackground} accessible={true}>
              <View
                style={styles.textButtonsElementIconMain}
                accessible={true}
                accessibilityLabel={'Save with icon'}>
                <Image
                  width={Platform.OS == 'web' ? '25px' : 25}
                  height={Platform.OS == 'web' ? '25px' : 25}
                  source={{
                    uri: Platform.OS == 'web' ? SaveIcon : 'iconsave',
                  }}
                  style={styles.textButtonsElementIcon}
                  resizeMode="contain"
                />
              </View>
            </View>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textButtonsTitleDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textButtonsTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Save')}
            </Text>
          </Pressable>
          <Pressable
            style={styles.textButtonsElement}
            accessible={true}
            accessibilityLabel={t('Share')}
            accessibilityHint="Share validation">
            <View style={styles.textButtonsElementBackground} accessible={true}>
              <View
                style={styles.textButtonsElementIconMain}
                accessible={true}
                accessibilityLabel={'Share with icon'}>
                <Image
                  width={Platform.OS == 'web' ? '25px' : 25}
                  height={Platform.OS == 'web' ? '25px' : 25}
                  source={{
                    uri: Platform.OS == 'web' ? ShareIcon : 'iconshare',
                  }}
                  style={styles.textButtonsElementIcon}
                  resizeMode="contain"
                />
              </View>
            </View>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textButtonsTitleDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textButtonsTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Share')}
            </Text>
          </Pressable>
        </View>
        <TouchableOpacity
          style={styles.buttonMain}
          onPress={() =>
            navigation.navigate('Output Method', {
              newRecorded: recodeing,
            })
          }
          accessible={true}
          accessibilityLabel={t('NEXT')}
          accessibilityHint="Next step">
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.buttonDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.button,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('NEXT')}
          </Text>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
  },
  mainMarginWeb: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsIconCustomStyle: {
    marginLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  textTab: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#BEBEBE',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  textTabImage: {
    width: Platform.OS === 'web' ? '25px' : 25,
  },
  textHeadline: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    alignItems: 'left',
    textAlign: 'left',
  },
  textHeadlineDark: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    color: '#ecb853',
    alignItems: 'left',
    textAlign: 'left',
  },
  textInputField: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    padding: Platform.OS === 'web' ? '10px' : 10,
  },
  textInputFieldMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#BEBEBE',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldLength: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textInputFieldLengthColor: {
    color: '#BEBEBE',
  },
  textButtons: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  textButtonsElement: {
    width: Platform.OS === 'web' ? '150px' : 150,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  textButtonsElementBackground: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    backgroundColor: '#BEBEBE',
  },
  textButtonsElementIcon: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
  },
  textButtonsElementIconMain: {
    paddingTop: Platform.OS === 'web' ? '7px' : 7,
    paddingLeft: Platform.OS === 'web' ? '8px' : 8,
  },
  textButtonsElementIconModal: {
    width: Platform.OS === 'web' ? '80px' : 80,
    height: Platform.OS === 'web' ? '80px' : 80,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  textButtonsTitle: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    //paddingLeft: Platform.OS === 'web' ? '7px' : 7,
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleDark: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    //paddingLeft: Platform.OS === 'web' ? '8px' : 8,
    color: '#ecb853',
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleCustom: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '6px' : 6,
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleCustomDark: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '6px' : 6,
    color: '#ecb853',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMainDisabled: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    pointerEvents: 'none',
    opacity: 0.4,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#D05B5B',
    color: '#fff',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonDark: {
    backgroundColor: '#000000',
    color: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    //borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderRadius: 20,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderColor: '#ecb853',
    borderWidth: 2,
  },
  iconAudioEqualizer: {
    width: Platform.OS === 'web' ? '50px' : 50,
    height: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    position: 'absolute',
    top: Platform.OS === 'web' ? '125px' : 125,
    left: Platform.OS === 'web' ? '125px' : 125,
  },
  iconAudioButtons: {
    width: Platform.OS === 'web' ? '100px' : 100,
    height: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  textDark: {
    color: '#ecb853',
  },
  bottomText: {
    position: 'absolute',
    bottom: Platform.OS === 'web' ? '10px' : 10,
    alignItems: 'center',
    textAlign: 'center',
  },
});

export default InputAudioContent;
