/**
 * Avatar New
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Switch,
  TouchableOpacity,
  Platform,
  Pressable,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import axios from 'axios';
import {
  AVATAR_CLOTHES_TYPE,
  AVATAR_GENDER_TYPE,
  AVATAR_BACKGROUND_TYPE,
  AVATAR_SKIN_TYPE,
  AVATAR_CONTRAST_TYPE,
  AVATAR_BACKGROUND_BLUE,
  AVATAR_BACKGROUND_GREY_LIGHT,
  AVATAR_BACKGROUND_WHITE,
  AVATAR_CLOTHES_GREEN,
  AVATAR_CLOTHES_PURPLE,
  AVATAR_CLOTHES_GREY,
  AVATAR_GENDER_MALE,
  AVATAR_GENDER_FEMALE,
  AVATAR_GENDER_DIVERS,
  AVATAR_SKIN_LIGHT,
  AVATAR_SKIN_LIGHT_DARK,
  AVATAR_SKIN_NORMAL,
  AVATAR_SKIN_DARK_LIGHT,
  AVATAR_SKIN_DARK,
  AVATAR_CONTRAST_RED,
  AVATAR_CONTRAST_GREEN,
  AVATAR_CONTRAST_BLUE,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  AVATAR_CHOICE,
  AVATAR_NORMAL,
  AVATAR_FAVORITE,
  AVATAR_BACKGROUND,
  AVATAR_SHIRT,
  USERTOKEN_KEY,
  DEVELOPMENT,
  TEST,
} from '../../../constants/constants';
// import RadioButton from './../../../components/radioButton/radioButton';
import {useTranslation} from 'react-i18next';
/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import IconArrow from './../../../assets/iconarrow.png';
import GenderDiversIcon from './../../../assets/icongenderdivers.png';
import GenderQueerIcon from './../../../assets/icongenderqueer.png';
import GenderQueerIconDark from './../../../assets/icongenderqueerdark.png';
import GenderFemaleIcon from './../../../assets/icongenderfemale.png';
import GenderFemaleIconDark from './../../../assets/icongenderfemaledark.png';
import GenderMaleIcon from './../../../assets/icongendermale.png';
import GenderMaleIconDark from './../../../assets/icongendermaledark.png';
import AvatarIcon from './../../../assets/avatar.png';
/* Assets - Picture */
import AvatarOne from './../../../assets/avatar/DGSB0S0.png';
import AvatarTwo from './../../../assets/avatar/DGSB1S1.png';
import AvatarThree from './../../../assets/avatar/DGSB1S2.png';
import AvatarFour from './../../../assets/avatar/DGSB1S3.png';
import AvatarFive from './../../../assets/avatar/DGSB2S1.png';
import AvatarSix from './../../../assets/avatar/DGSB2S2.png';
import AvatarSeven from './../../../assets/avatar/DGSB2S3.png';
import AvatarEight from './../../../assets/avatar/DGSB3S1.png';
import AvatarNine from './../../../assets/avatar/DGSB3S2.png';
import AvatarTen from './../../../assets/avatar/DGSB3S3.png';

/*const PROP = [
  {
    key: 'business',
    text: 'Business',
  },
  {
    key: 'freestyle',
    text: 'Freestyle',
  },
];*/

const RadioButton = ({onPress, selected, children}) => {
  return (
    <View style={styles.radioButtonContainer} accessible={true}>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation"
        style={selected ? styles.radioButtonActive : styles.radioButton}>
        {selected ? <View style={styles.radioButtonIcon} /> : null}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation">
        <Text style={styles.radioButtonText}>{children}</Text>
      </TouchableOpacity>
    </View>
  );
};

const RadioButtonDark = ({onPress, selected, children}) => {
  return (
    <View style={styles.radioButtonContainer} accessible={true}>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation"
        style={
          selected ? styles.radioButtonActiveDark : styles.radioButtonDark
        }>
        {selected ? <View style={styles.radioButtonIconDark} /> : null}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation">
        <Text style={styles.radioButtonText}>{children}</Text>
      </TouchableOpacity>
    </View>
  );
};

const RadioButtonVertical = ({onPress, selected, children}) => {
  return (
    <View
      style={[styles.radioButtonContainer, styles.skinColorBlock]}
      accessible={true}>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation"
        style={selected ? styles.radioButtonActive : styles.radioButton}>
        {selected ? <View style={styles.radioButtonIcon} /> : null}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation">
        <Text style={styles.radioButtonText}>{children}</Text>
      </TouchableOpacity>
    </View>
  );
};

const RadioButtonVerticalNew = ({
  onPress,
  selected,
  contrastSizing,
  children,
}) => {
  return (
    <View
      style={[styles.radioButtonContainerNew, styles.contrastColorBlock]}
      accessible={true}>
      <View accessible={true}>
        <TouchableOpacity
          onPress={onPress}
          accessible={true}
          accessibilityLabel={'Checkbox'}
          accessibilityHint="Checkbox validation">
          <Text
            style={[
              styles.radioButtonText,
              contrastSizing === SMALLTEXT
                ? styles.smallTextAll
                : contrastSizing === NORMALTEXT
                ? styles.normalTextAll
                : contrastSizing === BIGTEXT
                ? styles.bigTextAll
                : styles.normalTextAll,
            ]}>
            {children}
          </Text>
        </TouchableOpacity>
      </View>
      <View accessible={true}>
        <TouchableOpacity
          onPress={onPress}
          accessible={true}
          accessibilityLabel={'Checkbox'}
          accessibilityHint="Checkbox validation"
          style={selected ? styles.radioButtonActive : styles.radioButton}>
          {selected ? <View style={styles.radioButtonIcon} /> : null}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const RadioButtonVerticalNewDark = ({
  onPress,
  selected,
  contrastSizing,
  children,
}) => {
  return (
    <View
      style={[styles.radioButtonContainerNew, styles.contrastColorBlock]}
      accessible={true}>
      <View accessible={true}>
        <TouchableOpacity
          onPress={onPress}
          accessible={true}
          accessibilityLabel={'Checkbox'}
          accessibilityHint="Checkbox validation">
          <Text
            style={[
              styles.radioButtonText,
              contrastSizing === SMALLTEXT
                ? styles.smallTextAll
                : contrastSizing === NORMALTEXT
                ? styles.normalTextAll
                : contrastSizing === BIGTEXT
                ? styles.bigTextAll
                : styles.normalTextAll,
            ]}>
            {children}
          </Text>
        </TouchableOpacity>
      </View>
      <View accessible={true}>
        <TouchableOpacity
          onPress={onPress}
          accessible={true}
          accessibilityLabel={'Checkbox'}
          accessibilityHint="Checkbox validation"
          style={
            selected ? styles.radioButtonActiveDark : styles.radioButtonDark
          }>
          {selected ? <View style={styles.radioButtonIconDark} /> : null}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const Avatar = ({navigation}): Node => {
  const [isEnabled, setIsEnabled] = useState(false);
  const {t} = useTranslation();
  const [clothes, setClothes] = useState([
    {
      id: 1,
      value: AVATAR_CLOTHES_GREEN,
      name: <View style={styles.settingsBackgroundColorBlack}></View>,
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_CLOTHES_PURPLE,
      name: <View style={styles.settingsBackgroundColorPurple}></View>,
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_CLOTHES_GREY,
      name: <View style={styles.settingsBackgroundColorGreenDark}></View>,
      selected: false,
    },
  ]);
  const [gender, setGender] = useState([
    {
      id: 1,
      value: AVATAR_GENDER_MALE,
      name: (
        <View
          style={
            darkModeAvailable
              ? styles.settingsBackgroundColorIconDark
              : styles.settingsBackgroundColorIcon
          }>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? GenderMaleIconDark
                    : GenderMaleIcon
                  : darkModeAvailable
                  ? 'icongendermaledark'
                  : 'icongendermale',
            }}
            style={[styles.settingsIconStyle, styles.settingsGenderIconStyle]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_GENDER_FEMALE,
      name: (
        <View
          style={
            darkModeAvailable
              ? styles.settingsBackgroundColorIconDark
              : styles.settingsBackgroundColorIcon
          }>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? GenderFemaleIconDark
                    : GenderFemaleIcon
                  : darkModeAvailable
                  ? 'icongenderfemaledark'
                  : 'icongenderfemale',
            }}
            style={[styles.settingsIconStyle, styles.settingsGenderIconStyle]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_GENDER_DIVERS,
      name: (
        <View
          style={
            darkModeAvailable
              ? styles.settingsBackgroundColorIconDark
              : styles.settingsBackgroundColorIcon
          }>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? GenderQueerIconDark
                    : GenderQueerIcon
                  : darkModeAvailable
                  ? 'icongenderqueerdark'
                  : 'icongenderqueer',
            }}
            style={[styles.settingsIconStyle, styles.settingsGenderIconStyle]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: false,
    },
  ]);
  const [genderDark, setGenderDark] = useState([
    {
      id: 1,
      value: AVATAR_GENDER_MALE,
      name: (
        <View style={styles.settingsBackgroundColorIconDark}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                Platform.OS == 'web'
                  ? GenderMaleIconDark
                  : 'icongendermaledark',
            }}
            style={[
              styles.settingsIconStyle,
              styles.settingsGenderIconStyleDark,
            ]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_GENDER_FEMALE,
      name: (
        <View style={styles.settingsBackgroundColorIconDark}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                Platform.OS == 'web'
                  ? GenderFemaleIconDark
                  : 'icongenderfemaledark',
            }}
            style={[
              styles.settingsIconStyle,
              styles.settingsGenderIconStyleDark,
            ]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_GENDER_DIVERS,
      name: (
        <View style={styles.settingsBackgroundColorIconDark}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                Platform.OS == 'web'
                  ? GenderQueerIconDark
                  : 'icongenderqueerdark',
            }}
            style={[
              styles.settingsIconStyle,
              styles.settingsGenderIconStyleDark,
            ]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: false,
    },
  ]);
  const [background, setBackground] = useState([
    {
      id: 1,
      value: AVATAR_BACKGROUND_GREY_LIGHT,
      name: <View style={styles.settingsBackgroundColorGreyLight}></View>,
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_BACKGROUND_BLUE,
      name: <View style={styles.settingsBackgroundColorGreyGreen}></View>,
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_BACKGROUND_WHITE,
      name: <View style={styles.settingsBackgroundColorGreyDark}></View>,
      selected: false,
    },
  ]);
  const [skins, setSkins] = useState([
    {
      id: 1,
      value: AVATAR_SKIN_LIGHT,
      name: <View style={styles.settingsBackgroundColorSkinOne}></View>,
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_SKIN_LIGHT_DARK,
      name: <View style={styles.settingsBackgroundColorSkinTwo}></View>,
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_SKIN_NORMAL,
      name: <View style={styles.settingsBackgroundColorSkinThree}></View>,
      selected: false,
    },
    {
      id: 4,
      value: AVATAR_SKIN_DARK_LIGHT,
      name: <View style={styles.settingsBackgroundColorSkinFour}></View>,
      selected: false,
    },
    {
      id: 5,
      value: AVATAR_SKIN_DARK,
      name: <View style={styles.settingsBackgroundColorSkinFive}></View>,
      selected: false,
    },
  ]);
  const [contrast, setContrast] = useState([
    {
      id: 1,
      value: AVATAR_CONTRAST_RED,
      name: <View style={styles.settingsBackgroundColorContrastOne}></View>,
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_CONTRAST_GREEN,
      name: <View style={styles.settingsBackgroundColorContrastTwo}></View>,
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_CONTRAST_BLUE,
      name: <View style={styles.settingsBackgroundColorContrastThree}></View>,
      selected: false,
    },
  ]);
  const [selectedClothes, setSelectedClothes] = useState('');
  const [selectedGender, setSelectedGender] = useState(AVATAR_GENDER_FEMALE);
  const [selectedBackground, setSelectedBackground] = useState('');
  const [selectedSkin, setSelectedSkin] = useState('');
  const [selectedContrast, setSelectedContrast] = useState('');
  const [userDataAll, setUserDataAll] = useState({});
  const onRadioBtnClick = async item => {
    let updatedState = clothes.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setClothes(updatedState);
    setSelectedClothes(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );

    await AsyncStorage.setItem(
      AVATAR_CLOTHES_TYPE,
      updatedState[0].selected == true
        ? AVATAR_CLOTHES_GREEN
        : updatedState[1].selected == true
        ? AVATAR_CLOTHES_PURPLE
        : AVATAR_CLOTHES_GREY,
    );

    await AsyncStorage.setItem(
      AVATAR_SHIRT,
      updatedState[0].selected == true
        ? '1'
        : updatedState[1].selected == true
        ? '2'
        : '3',
    );
    setTimeout(async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      let allUserData = JSON.parse(userDataAll.settings);

      if (updatedState[0].selected === true) {
        allUserData.avatar_settings.shirt = updatedState[0].id;
      }
      if (updatedState[1].selected === true) {
        allUserData.avatar_settings.shirt = updatedState[1].id;
      }
      if (updatedState[2].selected === true) {
        allUserData.avatar_settings.shirt = updatedState[2].id;
      }

      allUserData.avatar_settings.background = await AsyncStorage.getItem(
        AVATAR_BACKGROUND,
      );

      axios
        .post(
          TEST + 'user/settings',
          {
            settings: JSON.stringify(allUserData),
          },
          {
            headers: {
              //...form.getHeaders(),
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userKeyToken}`,
            },
          },
        )
        .then(res => {
          if (res.status === 200) {
            console.log(res.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }, 2000);

    setModalClothes(false);
  };
  const onRadioBtnClickGender = async item => {
    let updatedState = gender.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setGender(updatedState);
    setSelectedGender(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );

    let updatedStateDark = genderDark.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setGenderDark(updatedStateDark);
    setSelectedGender(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );

    await AsyncStorage.setItem(
      AVATAR_GENDER_TYPE,
      updatedState[0].selected == true
        ? AVATAR_GENDER_MALE
        : updatedState[1].selected == true
        ? AVATAR_GENDER_FEMALE
        : AVATAR_GENDER_DIVERS,
    );

    setModalGender(false);
  };
  const onRadioBtnClickBackground = async item => {
    let updatedState = background.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setBackground(updatedState);
    setSelectedBackground(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );

    await AsyncStorage.setItem(
      AVATAR_BACKGROUND_TYPE,
      updatedState[0].selected == true
        ? AVATAR_BACKGROUND_BLUE
        : updatedState[1].selected == true
        ? AVATAR_BACKGROUND_GREY_LIGHT
        : AVATAR_BACKGROUND_WHITE,
    );

    await AsyncStorage.setItem(
      AVATAR_BACKGROUND,
      updatedState[0].selected == true
        ? '1'
        : updatedState[1].selected == true
        ? '2'
        : '3',
    );
    setTimeout(async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      let allUserData = JSON.parse(userDataAll.settings);

      if (updatedState[0].selected === true) {
        allUserData.avatar_settings.background = updatedState[0].id;
      }
      if (updatedState[1].selected === true) {
        allUserData.avatar_settings.background = updatedState[1].id;
      }
      if (updatedState[2].selected === true) {
        allUserData.avatar_settings.background = updatedState[2].id;
      }
      allUserData.avatar_settings.shirt = await AsyncStorage.getItem(
        AVATAR_SHIRT,
      );

      axios
        .post(
          TEST + 'user/settings',
          {
            settings: JSON.stringify(allUserData),
          },
          {
            headers: {
              //...form.getHeaders(),
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userKeyToken}`,
            },
          },
        )
        .then(res => {
          if (res.status === 200) {
            console.log(res.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }, 2000);

    setModalBackground(false);
  };
  const onRadioBtnClickSkins = async item => {
    let updatedState = skins.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setSkins(updatedState);
    setSelectedSkin(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].selected == true
        ? updatedState[2].value
        : updatedState[3].selected == true
        ? updatedState[3].value
        : updatedState[4].value,
    );

    await AsyncStorage.setItem(
      AVATAR_SKIN_TYPE,
      updatedState[0].selected == true
        ? AVATAR_SKIN_LIGHT
        : updatedState[1].selected == true
        ? AVATAR_SKIN_LIGHT_DARK
        : updatedState[2].selected == true
        ? AVATAR_SKIN_NORMAL
        : updatedState[3].selected == true
        ? AVATAR_SKIN_DARK_LIGHT
        : AVATAR_SKIN_DARK,
    );
  };
  const onRadioBtnClickContrast = async item => {
    let updatedState = contrast.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setContrast(updatedState);
    setSelectedContrast(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );

    await AsyncStorage.setItem(
      AVATAR_CONTRAST_TYPE,
      updatedState[0].selected == true
        ? AVATAR_CONTRAST_RED
        : updatedState[1].selected == true
        ? AVATAR_CONTRAST_GREEN
        : AVATAR_CONTRAST_BLUE,
    );
  };
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [modalClothes, setModalClothes] = useState(false);
  const [modalGender, setModalGender] = useState(false);
  const [modalBackground, setModalBackground] = useState(false);
  const [avatarSetup, setAvatarSetup] = useState('1');
  const isFocused = useIsFocused();
  useEffect(() => {
    const userDataSettings = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      axios
        .get(TEST + 'user', {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(response => {
          if (response.status === 200) {
            console.log(response.data);
            setUserDataAll(response.data);
            let allUserData = JSON.parse(response.data.settings);

            if (allUserData.avatar_settings.background === 0) {
              allUserData.avatar_settings.background = 1;
            }
            if (allUserData.avatar_settings.shirt === 0) {
              allUserData.avatar_settings.shirt = 1;
            }
            if (
              allUserData.avatar_settings.background === 0 &&
              allUserData.avatar_settings.shirt === 0
            ) {
              allUserData.avatar_settings.background = 1;
              allUserData.avatar_settings.shirt = 1;
            }

            axios
              .post(
                TEST + 'user/settings',
                {
                  settings: JSON.stringify(allUserData),
                },
                {
                  headers: {
                    //...form.getHeaders(),
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userKeyToken}`,
                  },
                },
              )
              .then(res => {
                if (res.status === 200) {
                  console.log(res.data);
                }
              })
              .catch(e => {
                console.log(e);
              });
          }
        })
        .catch(e => {
          console.log(e);
        });
    };
    userDataSettings();
  }, []);

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);
  useEffect(() => {
    const intervalAvatarSetup = setInterval(() => {
      const checkAvatarDefault = async () => {
        const avatarBackgroundType = await AsyncStorage.getItem(
          AVATAR_BACKGROUND,
        );
        const avatarShirtType = await AsyncStorage.getItem(AVATAR_SHIRT);

        if (
          (avatarShirtType == '1' || avatarShirtType == 1) &&
          (avatarBackgroundType == '1' || avatarBackgroundType == 1)
        ) {
          setAvatarSetup('2');
        } else if (
          (avatarShirtType == '2' || avatarShirtType == 2) &&
          (avatarBackgroundType == '1' || avatarBackgroundType == 1)
        ) {
          setAvatarSetup('3');
        } else if (
          (avatarShirtType == '3' || avatarShirtType == 3) &&
          (avatarBackgroundType == '1' || avatarBackgroundType == 1)
        ) {
          setAvatarSetup('4');
        } else if (
          (avatarShirtType == '1' || avatarShirtType == 1) &&
          (avatarBackgroundType == '2' || avatarBackgroundType == 2)
        ) {
          setAvatarSetup('5');
        } else if (
          (avatarShirtType == '2' || avatarShirtType == 2) &&
          (avatarBackgroundType == '2' || avatarBackgroundType == 2)
        ) {
          setAvatarSetup('6');
        } else if (
          (avatarShirtType == '3' || avatarShirtType == 3) &&
          (avatarBackgroundType == '2' || avatarBackgroundType == 2)
        ) {
          setAvatarSetup('7');
        } else if (
          (avatarShirtType == '1' || avatarShirtType == 1) &&
          (avatarBackgroundType == '3' || avatarBackgroundType == 3)
        ) {
          setAvatarSetup('8');
        } else if (
          (avatarShirtType == '2' || avatarShirtType == 2) &&
          (avatarBackgroundType == '3' || avatarBackgroundType == 3)
        ) {
          setAvatarSetup('9');
        } else if (
          (avatarShirtType == '3' || avatarShirtType == 3) &&
          (avatarBackgroundType == '3' || avatarBackgroundType == 3)
        ) {
          setAvatarSetup('10');
        } else {
          setAvatarSetup('1');
        }
      };
      checkAvatarDefault();
    }, 1000);

    return () => {
      clearInterval(intervalAvatarSetup);
    };
  }, [isFocused]);

  useEffect(() => {
    const avatarSettings = async () => {
      if ((await AsyncStorage.getItem(AVATAR_CLOTHES_TYPE)) == null) {
        await AsyncStorage.setItem(AVATAR_CLOTHES_TYPE, '');
      }
      if ((await AsyncStorage.getItem(AVATAR_GENDER_TYPE)) == null) {
        await AsyncStorage.setItem(AVATAR_GENDER_TYPE, AVATAR_GENDER_FEMALE);
      }
      if ((await AsyncStorage.getItem(AVATAR_BACKGROUND_TYPE)) == null) {
        await AsyncStorage.setItem(AVATAR_BACKGROUND_TYPE, '');
      }
      if ((await AsyncStorage.getItem(AVATAR_SKIN_TYPE)) == null) {
        await AsyncStorage.setItem(AVATAR_SKIN_TYPE, '');
      }
      if ((await AsyncStorage.getItem(AVATAR_CONTRAST_TYPE)) == null) {
        await AsyncStorage.setItem(AVATAR_CONTRAST_TYPE, '');
      }
      if (
        (await AsyncStorage.getItem(AVATAR_SHIRT)) == null ||
        (await AsyncStorage.getItem(AVATAR_SHIRT)) == '0'
      ) {
        await AsyncStorage.setItem(AVATAR_SHIRT, '1');
      }
      if (
        (await AsyncStorage.getItem(AVATAR_BACKGROUND)) == null ||
        (await AsyncStorage.getItem(AVATAR_BACKGROUND)) == '0'
      ) {
        await AsyncStorage.setItem(AVATAR_BACKGROUND, '1');
      }
    };

    avatarSettings();
  });

  useEffect(() => {
    const avatarTypeDefault = async () => {
      const defaultAvatarClothesType = await AsyncStorage.getItem(
        AVATAR_CLOTHES_TYPE,
      );
      defaultAvatarClothesType === AVATAR_CLOTHES_GREEN
        ? setSelectedClothes(
            ((clothes[0].selected = true),
            (clothes[1].selected = false),
            (clothes[2].selected = false)),
          )
        : defaultAvatarClothesType === AVATAR_CLOTHES_PURPLE
        ? setSelectedClothes(
            ((clothes[0].selected = false),
            (clothes[1].selected = true),
            (clothes[2].selected = false)),
          )
        : defaultAvatarClothesType === AVATAR_CLOTHES_GREY
        ? setSelectedClothes(
            ((clothes[0].selected = false),
            (clothes[1].selected = false),
            (clothes[2].selected = true)),
          )
        : setSelectedClothes(
            ((clothes[0].selected = true),
            (clothes[1].selected = false),
            (clothes[2].selected = false)),
          );

      const defaultAvatarGenderType = await AsyncStorage.getItem(
        AVATAR_GENDER_TYPE,
      );
      defaultAvatarGenderType === AVATAR_GENDER_MALE
        ? setSelectedGender(
            ((gender[0].selected = true),
            (gender[1].selected = false),
            (gender[2].selected = false)),
          )
        : defaultAvatarGenderType === AVATAR_GENDER_FEMALE
        ? setSelectedGender(
            ((gender[0].selected = false),
            (gender[1].selected = true),
            (gender[2].selected = false)),
          )
        : defaultAvatarGenderType === AVATAR_GENDER_DIVERS
        ? setSelectedGender(
            ((gender[0].selected = false),
            (gender[1].selected = false),
            (gender[2].selected = true)),
          )
        : setSelectedGender(
            ((gender[0].selected = true),
            (gender[1].selected = false),
            (gender[2].selected = false)),
          );

      const defaultAvatarBackgroundType = await AsyncStorage.getItem(
        AVATAR_BACKGROUND_TYPE,
      );
      defaultAvatarBackgroundType === AVATAR_BACKGROUND_BLUE
        ? setSelectedBackground(
            ((background[0].selected = true),
            (background[1].selected = false),
            (background[2].selected = false)),
          )
        : defaultAvatarBackgroundType === AVATAR_BACKGROUND_GREY_LIGHT
        ? setSelectedBackground(
            ((background[0].selected = false),
            (background[1].selected = true),
            (background[2].selected = false)),
          )
        : defaultAvatarBackgroundType === AVATAR_BACKGROUND_WHITE
        ? setSelectedBackground(
            ((background[0].selected = false),
            (background[1].selected = false),
            (background[2].selected = true)),
          )
        : setSelectedBackground(
            ((background[0].selected = true),
            (background[1].selected = false),
            (background[2].selected = false)),
          );

      const defaultAvatarSkinType = await AsyncStorage.getItem(
        AVATAR_SKIN_TYPE,
      );
      defaultAvatarSkinType === AVATAR_SKIN_LIGHT
        ? setSelectedSkin(
            ((skins[0].selected = true),
            (skins[1].selected = false),
            (skins[2].selected = false),
            (skins[3].selected = false),
            (skins[4].selected = false)),
          )
        : defaultAvatarSkinType === AVATAR_SKIN_LIGHT_DARK
        ? setSelectedSkin(
            ((skins[0].selected = false),
            (skins[1].selected = true),
            (skins[2].selected = false),
            (skins[3].selected = false),
            (skins[4].selected = false)),
          )
        : defaultAvatarSkinType === AVATAR_SKIN_NORMAL
        ? setSelectedSkin(
            ((skins[0].selected = false),
            (skins[1].selected = false),
            (skins[2].selected = true),
            (skins[3].selected = false),
            (skins[4].selected = false)),
          )
        : defaultAvatarSkinType === AVATAR_SKIN_DARK_LIGHT
        ? setSelectedSkin(
            ((skins[0].selected = false),
            (skins[1].selected = false),
            (skins[2].selected = false),
            (skins[3].selected = true),
            (skins[4].selected = false)),
          )
        : defaultAvatarSkinType === AVATAR_SKIN_DARK
        ? setSelectedSkin(
            ((skins[0].selected = false),
            (skins[1].selected = false),
            (skins[2].selected = false),
            (skins[3].selected = false),
            (skins[4].selected = true)),
          )
        : setSelectedSkin(
            ((skins[0].selected = true),
            (skins[1].selected = false),
            (skins[2].selected = false),
            (skins[3].selected = false),
            (skins[4].selected = false)),
          );

      const defaultAvatarContrastType = await AsyncStorage.getItem(
        AVATAR_CONTRAST_TYPE,
      );
      defaultAvatarContrastType === AVATAR_CONTRAST_RED
        ? setSelectedContrast(
            ((contrast[0].selected = true),
            (contrast[1].selected = false),
            (contrast[2].selected = false)),
          )
        : defaultAvatarContrastType === AVATAR_CONTRAST_GREEN
        ? setSelectedContrast(
            ((contrast[0].selected = false),
            (contrast[1].selected = true),
            (contrast[2].selected = false)),
          )
        : defaultAvatarContrastType === AVATAR_CONTRAST_BLUE
        ? setSelectedContrast(
            ((contrast[0].selected = false),
            (contrast[1].selected = false),
            (contrast[2].selected = true)),
          )
        : setSelectedContrast(
            ((contrast[0].selected = true),
            (contrast[1].selected = false),
            (contrast[2].selected = false)),
          );
    };
    avatarTypeDefault();
  });

  const saveData = async navigation => {
    const avatarStatus = await AsyncStorage.getItem(AVATAR_CHOICE);
    const archiveStatus = await AsyncStorage.getItem('archiveID');
    if (archiveStatus) {
      navigation.navigate('Archive Detail', {
        archiveID: archiveStatus,
      });
    } else if (avatarStatus === AVATAR_FAVORITE) {
      navigation.navigate('Default Output');
    } else {
      navigation.navigate('Output Method');
    }
  };

  const toggleSwitch = () => setIsEnabled(previousState => !previousState);

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={
            darkModeAvailable
              ? styles.additionalOptionDark
              : styles.additionalOption
          }
          accessible={true}
          accessibilityLabel="Avatar">
          {avatarSetup === '2' ? (
            <Image
              width={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              height={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              source={{
                uri: Platform.OS == 'web' ? AvatarTwo : 'dgsbb1s1',
              }}
              style={darkModeAvailable ? styles.logoDark : styles.logo}
              resizeMode="contain"
            />
          ) : avatarSetup === '3' ? (
            <Image
              width={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              height={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              source={{
                uri: Platform.OS == 'web' ? AvatarThree : 'dgsbb1s2',
              }}
              style={darkModeAvailable ? styles.logoDark : styles.logo}
              resizeMode="contain"
            />
          ) : avatarSetup === '4' ? (
            <Image
              width={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              height={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              source={{
                uri: Platform.OS == 'web' ? AvatarFour : 'dgsbb1s3',
              }}
              style={darkModeAvailable ? styles.logoDark : styles.logo}
              resizeMode="contain"
            />
          ) : avatarSetup === '5' ? (
            <Image
              width={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              height={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              source={{
                uri: Platform.OS == 'web' ? AvatarFive : 'dgsbb2s1',
              }}
              style={darkModeAvailable ? styles.logoDark : styles.logo}
              resizeMode="contain"
            />
          ) : avatarSetup === '6' ? (
            <Image
              width={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              height={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              source={{
                uri: Platform.OS == 'web' ? AvatarSix : 'dgsbb2s2',
              }}
              style={darkModeAvailable ? styles.logoDark : styles.logo}
              resizeMode="contain"
            />
          ) : avatarSetup === '7' ? (
            <Image
              width={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              height={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              source={{
                uri: Platform.OS == 'web' ? AvatarSeven : 'dgsbb2s3',
              }}
              style={darkModeAvailable ? styles.logoDark : styles.logo}
              resizeMode="contain"
            />
          ) : avatarSetup === '8' ? (
            <Image
              width={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              height={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              source={{
                uri: Platform.OS == 'web' ? AvatarEight : 'dgsbb3s1',
              }}
              style={darkModeAvailable ? styles.logoDark : styles.logo}
              resizeMode="contain"
            />
          ) : avatarSetup === '9' ? (
            <Image
              width={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              height={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              source={{
                uri: Platform.OS == 'web' ? AvatarNine : 'dgsbb3s2',
              }}
              style={darkModeAvailable ? styles.logoDark : styles.logo}
              resizeMode="contain"
            />
          ) : avatarSetup === '10' ? (
            <Image
              width={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              height={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              source={{
                uri: Platform.OS == 'web' ? AvatarTen : 'dgsbb3s3',
              }}
              style={darkModeAvailable ? styles.logoDark : styles.logo}
              resizeMode="contain"
            />
          ) : (
            <Image
              width={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              height={
                darkModeAvailable
                  ? Platform.OS == 'web'
                    ? '200px'
                    : 200
                  : Platform.OS == 'web'
                  ? '200px'
                  : 200
              }
              source={{
                uri: Platform.OS == 'web' ? AvatarOne : 'dgsbb0s0',
              }}
              style={darkModeAvailable ? styles.logoDark : styles.logo}
              resizeMode="contain"
            />
          )}
        </View>
        <View style={styles.additionalOptionStylesMain} accessible={true}>
          <Pressable
            style={
              darkModeAvailable
                ? [styles.backgroundTextMain, styles.borderNormalDark]
                : [styles.backgroundTextMain, styles.borderNormal]
            }
            accessible={true}
            onPress={() => setModalBackground(true)}>
            <View
              style={
                background[0].selected == true
                  ? styles.settingsBackgroundColorGreyLight
                  : background[1].selected == true
                  ? styles.settingsBackgroundColorGreyGreen
                  : styles.settingsBackgroundColorGreyDark
              }></View>
            <View style={styles.textOptionHeadline}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.categoryTitleDarkMode,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.categoryTitle,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }
                accessible={true}>
                {t('Background')}
              </Text>
            </View>
          </Pressable>
        </View>
        <View style={styles.additionalOptionStylesMain} accessible={true}>
          <Pressable
            style={
              darkModeAvailable
                ? [styles.clothesTextMain, styles.borderNormalDark]
                : [styles.clothesTextMain, styles.borderNormal]
            }
            accessible={true}
            onPress={() => setModalClothes(true)}>
            <View
              style={
                clothes[0].selected == true
                  ? styles.settingsBackgroundColorBlack
                  : clothes[1].selected == true
                  ? styles.settingsBackgroundColorPurple
                  : styles.settingsBackgroundColorGreenDark
              }></View>
            <View style={styles.textOptionHeadline}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.categoryTitleDarkMode,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.categoryTitle,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }
                accessible={true}>
                {t('Clothing')}
              </Text>
            </View>
          </Pressable>
        </View>
        <View style={styles.additionalOptionStylesMain} accessible={true}>
          <Pressable
            style={
              darkModeAvailable
                ? [
                    styles.genderBlockMain,
                    styles.borderNormalDark,
                    styles.disabledOption,
                  ]
                : [
                    styles.genderBlockMain,
                    styles.borderNormal,
                    styles.disabledOption,
                  ]
            }
            accessible={true}
            onPress={() => setModalGender(true)}>
            <View
              style={
                darkModeAvailable
                  ? styles.settingsBackgroundColorIconDark
                  : styles.settingsBackgroundColorIcon
              }>
              <Image
                width={Platform.OS == 'web' ? '25px' : 25}
                height={Platform.OS == 'web' ? '25px' : 25}
                source={{
                  uri:
                    gender[0].selected == true
                      ? Platform.OS == 'web'
                        ? darkModeAvailable
                          ? GenderMaleIconDark
                          : GenderMaleIcon
                        : darkModeAvailable
                        ? 'icongendermaledark'
                        : 'icongendermale'
                      : gender[1].selected == true
                      ? Platform.OS == 'web'
                        ? darkModeAvailable
                          ? GenderFemaleIconDark
                          : GenderFemaleIcon
                        : darkModeAvailable
                        ? 'icongenderfemaledark'
                        : 'icongenderfemale'
                      : Platform.OS == 'web'
                      ? darkModeAvailable
                        ? GenderQueerIconDark
                        : GenderQueerIcon
                      : darkModeAvailable
                      ? 'icongenderqueerdark'
                      : 'icongenderqueer',
                }}
                style={[
                  styles.settingsIconStyle,
                  styles.settingsGenderIconStyle,
                ]}
                resizeMode="contain"
              />
            </View>
            <View style={styles.textOptionHeadline}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.categoryTitleDarkMode,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.categoryTitle,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }
                accessible={true}>
                {t('Appearance')}
              </Text>
            </View>
          </Pressable>
        </View>
        {modalBackground ? (
          <View
            style={
              darkModeAvailable
                ? styles.additionalOptionStylesDark
                : styles.additionalOptionStyles
            }
            accessible={true}>
            <View style={styles.backgroundText} accessible={true}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.categoryTitleDarkMode,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.categoryTitle,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }
                accessible={true}>
                {t('Background')}
              </Text>
              <View accessible={true}>
                {darkModeAvailable
                  ? background.map(item => (
                      <RadioButtonDark
                        onPress={() => onRadioBtnClickBackground(item)}
                        selected={item.selected}
                        key={item.id}>
                        {item.name}
                      </RadioButtonDark>
                    ))
                  : background.map(item => (
                      <RadioButton
                        onPress={() => onRadioBtnClickBackground(item)}
                        selected={item.selected}
                        key={item.id}>
                        {item.name}
                      </RadioButton>
                    ))}
              </View>
            </View>
          </View>
        ) : (
          <></>
        )}
        {modalClothes ? (
          <View
            style={
              darkModeAvailable
                ? styles.additionalOptionStylesDark
                : styles.additionalOptionStyles
            }
            accessible={true}>
            <View style={styles.clothesText} accessible={true}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.categoryTitleDarkMode,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.categoryTitle,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }
                accessible={true}>
                {t('Clothing')}
              </Text>
              <View>
                {darkModeAvailable
                  ? clothes.map(item => (
                      <RadioButtonDark
                        onPress={() => onRadioBtnClick(item)}
                        selected={item.selected}
                        key={item.id}>
                        {item.name}
                      </RadioButtonDark>
                    ))
                  : clothes.map(item => (
                      <RadioButton
                        onPress={() => onRadioBtnClick(item)}
                        selected={item.selected}
                        key={item.id}>
                        {item.name}
                      </RadioButton>
                    ))}
              </View>
            </View>
          </View>
        ) : (
          <></>
        )}
        {modalGender ? (
          <View
            style={
              darkModeAvailable
                ? styles.additionalOptionStylesDark
                : styles.additionalOptionStyles
            }
            accessible={true}>
            <View style={styles.genderBlock} accessible={true}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.categoryTitleDarkMode,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.categoryTitle,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }
                accessible={true}>
                {t('Appearance')}
              </Text>
              <View accessible={true}>
                {darkModeAvailable
                  ? genderDark.map(item => (
                      <RadioButtonDark
                        onPress={() => onRadioBtnClickGender(item)}
                        selected={item.selected}
                        key={item.id}>
                        {item.name}
                      </RadioButtonDark>
                    ))
                  : gender.map(item => (
                      <RadioButton
                        onPress={() => onRadioBtnClickGender(item)}
                        selected={item.selected}
                        key={item.id}>
                        {item.name}
                      </RadioButton>
                    ))}
              </View>
            </View>
          </View>
        ) : (
          <></>
        )}
        {!modalBackground && !modalClothes && !modalGender ? (
          <Pressable
            style={darkModeAvailable ? styles.buttonDark : styles.button}
            accessible={true}
            accessibilityLabel={t('Save')}
            accessibilityHint="Save avatar validation"
            onPress={() => saveData(navigation)}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textButton,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Save')}
            </Text>
          </Pressable>
        ) : (
          <></>
        )}
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  switchEnableBorder: {
    borderColor: '#6fa6d3',
    borderWidth: 1,
  },
  switchDisableBorder: {
    borderColor: '#f2f2f2',
    borderWidth: 1,
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  logoDark: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  borderDown: {
    borderBottomWidth: 2,
    borderBottomColor: '#ccc',
  },
  borderNormal: {
    borderWidth: 2,
    borderColor: '#ccc',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '5px' : 5,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  borderNormalDark: {
    borderWidth: 2,
    borderColor: '#ecb853',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '5px' : 5,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  disabledOption: {
    opacity: Platform.OS === 'web' ? '0.5' : 0.5,
    pointerEvents: Platform.OS === 'web' ? 'none' : 'none',
  },
  additionalOption: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '200px' : 200,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  additionalOptionDark: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '204px' : 204,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    borderWidth: 2,
    borderColor: '#ecb853',
  },
  additionalOptionTitle: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '35px' : 35,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  additionalOptionStylesMain: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  additionalOptionStyles: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '200px' : 200,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
    paddingTop: Platform.OS === 'web' ? '20px' : 20,
    borderWidth: 2,
    borderColor: '#BEBEBE',
    marginTop: Platform.OS === 'web' ? '-255px' : -255,
    backgroundColor: '#ffffff',
  },
  additionalOptionStylesDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '200px' : 200,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
    paddingTop: Platform.OS === 'web' ? '20px' : 20,
    borderWidth: 2,
    borderColor: '#ecb853',
    marginTop: Platform.OS === 'web' ? '-255px' : -255,
    backgroundColor: '#000000',
  },
  additionalOptionSecondStyles: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
    paddingTop: Platform.OS === 'web' ? '20px' : 20,
  },
  headlineTitle: {
    width: Platform.OS === 'web' ? '300px' : 300,
    alignItems: 'left',
    //resizeMode: 'center',
  },
  headlineTitleMiddle: {
    width: Platform.OS === 'web' ? '300px' : 300,
    alignItems: 'center',
    //resizeMode: 'center',
  },
  categoryTitle: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  categoryTitleSecond: {
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  categoryTitleDarkMode: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    //resizeMode: 'center',
    alignItems: 'center',
    color: '#ecb853',
  },
  categoryTitleSecondDarkMode: {
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    color: '#ecb853',
  },
  switchText: {
    width: Platform.OS === 'web' ? '250px' : 250,
    marginRight: Platform.OS === 'web' ? '5px' : 5,
  },
  clothesTextMain: {
    width: Platform.OS === 'web' ? '200px' : 200,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  clothesText: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  textOptionHeadline: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  backgroundTextMain: {
    width: Platform.OS === 'web' ? '200px' : 200,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  backgroundText: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  emptyText: {
    width: Platform.OS === 'web' ? '100px' : 100,
    height: Platform.OS === 'web' ? 'auto' : 'auto',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  genderBlockMain: {
    width: Platform.OS === 'web' ? '200px' : 200,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  genderBlock: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  skinBlock: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  skinColorBlock: {
    transform: [{rotate: '-90deg'}],
    width: Platform.OS === 'web' ? '50px' : 50,
  },
  skinsRow: {
    width: Platform.OS === 'web' ? '300px' : 300,
    flexDirection: 'row',
  },
  contrastBlock: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  contrastColorBlock: {
    //transform: [{rotate: '-90deg'}],
    width: Platform.OS === 'web' ? '50px' : 50,
  },
  contrastRow: {
    width: Platform.OS === 'web' ? '200px' : 200,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    flexDirection: 'row',
  },
  switchSlider: {
    width: Platform.OS === 'web' ? '50px' : 50,
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsBackgroundColor: {
    backgroundColor: '#ECECEC',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    flex: 1,
    margin: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  settingsText: {
    fontSize: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsTextAdditional: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? '12px' : 12,
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '35px' : 35,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsGenderIconStyle: {
    marginTop: Platform.OS === 'web' ? '3px' : 3,
  },
  settingsGenderIconStyleDark: {
    marginTop: Platform.OS === 'web' ? '0px' : 0,
  },
  settingsBackgroundColorIcon: {
    backgroundColor: '#BEBEBE',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorIconDark: {
    backgroundColor: '#000',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#ecb853',
    borderWidth: 2,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorBlack: {
    backgroundColor: '#282823',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorPurple: {
    backgroundColor: '#562359',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorGreenDark: {
    backgroundColor: '#1E381A',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorGreyGreen: {
    backgroundColor: '#7f8a7a',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorGreyDark: {
    backgroundColor: '#6F656E',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderWidth: 1,
    borderColor: '#000',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorGreyLight: {
    backgroundColor: '#BEBEBE',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorSkinOne: {
    backgroundColor: '#F7D7C4',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorSkinTwo: {
    backgroundColor: '#D8B094',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorSkinThree: {
    backgroundColor: '#BB9167',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorSkinFour: {
    backgroundColor: '#8E562E',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorSkinFive: {
    backgroundColor: '#613D30',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorContrastOne: {
    backgroundColor: '#ff0000',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorContrastTwo: {
    backgroundColor: '#639F42',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorContrastThree: {
    backgroundColor: '#8BB0C2',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  radioButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  radioButtonContainerNew: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  radioButton: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonDark: {
    height: 20,
    width: 20,
    backgroundColor: '#000',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ecb853',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonActive: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#D05B5B',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonActiveDark: {
    height: 20,
    width: 20,
    backgroundColor: '#000',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ecb853',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonIcon: {
    height: 14,
    width: 14,
    borderRadius: 7,
    backgroundColor: '#D05B5B',
  },
  radioButtonIconDark: {
    height: 14,
    width: 14,
    borderRadius: 7,
    backgroundColor: '#ecb853',
  },
  radioButtonText: {
    fontSize: 16,
    marginLeft: 16,
  },
  text: {
    lineHeight: 30,
    fontSize: 20,
    marginVertical: 5,
  },
  button: {
    backgroundColor: '#D05B5B',
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textButton: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textDark: {
    color: '#ecb853',
  },
});

export default Avatar;
