/**
 * Home
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  TextInput,
  useColorScheme,
  View,
  Image,
  Button,
  Platform,
  Pressable,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Logo from './../../assets/easierlogowithtext.png';
import LogoDark from './../../assets/easierlogowithtextdark.png';
import {
  EMAIL_USER,
  PASSWORD,
  USERTOKEN_KEY,
  USERREFRESHTOKEN_KEY,
  USERTOKEN_VALUE,
  TOKEN_TIMER,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  APP_LANGUAGE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  DEVELOPMENT,
  TEST,
} from '../../constants/constants';
import {useTranslation} from 'react-i18next';
import i18n from '../../localization/index';
import axios from 'axios';

const Home = ({navigation}): Node => {
  const [email, onChangeEmail] = useState('');
  const [password, onChangePassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailPasswordError, setEmailPasswordError] = useState(false);
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  useEffect(() => {
    const getAppLanguage = async () => {
      const lang = await AsyncStorage.getItem(APP_LANGUAGE);
      console.log('language: ' + lang);
      if (lang === undefined || !lang) {
        await AsyncStorage.setItem(APP_LANGUAGE, 'en');
        i18n.changeLanguage('en');
      }
    };
    getAppLanguage();
  }, []);

  useEffect(() => {
    const userIsLoggedIn = async () => {
      if (
        (await AsyncStorage.getItem(USERTOKEN_KEY)) &&
        (await AsyncStorage.getItem(USERREFRESHTOKEN_KEY)) &&
        new Date().getTime() - (await AsyncStorage.getItem(TOKEN_TIMER)) <=
          8640000
      ) {
        setTimeout(() => {
          navigation.navigate('Start');
        }, 1000);
      } else {
        await navigation.navigate('Home');
      }
    };

    userIsLoggedIn();
  });

  const storeData = async (userEmail, userPassword) => {
    if (userEmail == EMAIL_USER && userPassword == PASSWORD) {
      await AsyncStorage.setItem(USERTOKEN_KEY, USERTOKEN_VALUE);
      navigation.navigate('Status Login');
      onChangeEmail('');
      onChangePassword('');
      setEmailPasswordError(false);
    } else {
      setEmailPasswordError(true);
    }
  };

  const validationLogin = async e => {
    e.preventDefault();
    // Previous:
    /*const url = 'https://easier-integration.nuromedia.com/server';*/
    // New:
    const url = TEST + 'oauth2/user/token';
    let authData = `grant_type=password&username=${encodeURIComponent(
      email,
    )}&password=${encodeURIComponent(
      password,
    )}&client_id=test-client&client_secret=Nuro_secret&scope=user`;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': '*',
    };
    axios
      .post(url, authData, headers)
      .then(async response => {
        if (response.status === 200) {
          console.log(response.data.message);
          await AsyncStorage.setItem(USERTOKEN_KEY, response.data.access_token);
          await AsyncStorage.setItem(
            USERREFRESHTOKEN_KEY,
            response.data.refresh_token,
          );
          await AsyncStorage.setItem(TOKEN_TIMER, new Date().getTime());
          onChangeEmail('');
          onChangePassword('');
          setEmailPasswordError(false);
          setTimeout(() => {
            //navigation.navigate('Home');
            navigation.navigate('Status Login');
          }, 1000);
        } else {
          console.log('Error: Access Denied');
        }
      })
      .catch(e => {
        console.log(e);
        setEmailPasswordError(true);
      });
  };

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email(t('Please enter valid email'))
      .required(t('Email Address is Required')),
    password: Yup.string().required(t('Password is required')),
  });

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={styles.headlineMain}
          accessible={true}
          accessibilityLabel={t('Welcome_to')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.headlineDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.headline,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Welcome_to')}
          </Text>
        </View>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel="Logo of EASIER">
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? LogoDark
                    : Logo
                  : darkModeAvailable
                  ? 'easierlogowithtextdark'
                  : 'easierlogowithtext',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <Formik
          initialValues={{email: '', password: ''}}
          onSubmit={values => console.log(values)}>
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
            values,
          }) => (
            <>
              <View
                style={styles.formInput}
                accessible={true}
                accessibilityLabel={t('E_Mail')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.labelTextDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.labelText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('E_Mail')}
                </Text>
                <TextInput
                  style={darkModeAvailable ? styles.inputDark : styles.input}
                  onChangeText={onChangeEmail}
                  value={email}
                  keyboardType="email-address"
                  onKeyPress={event => {
                    if (event.nativeEvent.key == 'Enter') {
                      validationLogin();
                    } else {
                      if (event.key == 'Enter') {
                        validationLogin();
                      }
                    }
                  }}
                  accessibilityLabel="Input username"
                />
                {/*!emailError ? (
                  <Text
                    nativeID="EmailError"
                    style={[
                      styles.errorTextHide,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]}>
                    {t('Email_is_not_correct')}
                  </Text>
                ) : (
                  <Text
                    nativeID="EmailError"
                    style={
                      darkModeAvailable
                        ? [
                            styles.errorTextDark,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.errorText,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                    }>
                    {t('Email_is_not_correct')}
                  </Text>
                )*/}
                {/*errors.email && touched.email && (
                  <Text style={styles.errorText}>{errors.email}</Text>
                )*/}
              </View>
              <View
                style={styles.formInput}
                accessible={true}
                accessibilityLabel={t('Password')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.labelTextDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.labelText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Password')}
                </Text>
                <TextInput
                  style={darkModeAvailable ? styles.inputDark : styles.input}
                  onChangeText={onChangePassword}
                  value={password}
                  secureTextEntry={true}
                  onKeyPress={event => {
                    if (event.nativeEvent.key == 'Enter') {
                      validationLogin();
                    } else {
                      if (event.key == 'Enter') {
                        validationLogin();
                      }
                    }
                  }}
                  accessibilityLabel="Input password"
                />
                {!emailPasswordError ? (
                  <Text
                    nativeID="EmailPasswordError"
                    style={[
                      styles.errorTextHide,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]}>
                    {t('Email_or_password_is_not_correct')}
                  </Text>
                ) : (
                  <Text
                    nativeID="EmailPasswordError"
                    style={
                      darkModeAvailable
                        ? [
                            styles.errorTextDark,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.errorText,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                    }>
                    {t('Email_or_password_is_not_correct')}
                  </Text>
                )}
                {/*errors.password && touched.password && (
                  <Text style={styles.errorText}>{errors.password}</Text>
                )*/}
              </View>
              <View
                style={styles.additionalOption}
                accessible={true}
                accessibilityLabel={
                  t('Reset_password') + ' - ' + t('Registration')
                }>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.underlineTextDark,
                          styles.resetPasswordStyle,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.underlineText,
                          styles.resetPasswordStyle,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }
                  onPress={() => navigation.navigate('Reset')}
                  accessible={true}
                  accessibilityLabel={t('Reset_password')}>
                  {t('Reset_password')}
                </Text>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.extraTextDark,
                          styles.resetPasswordStyle,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.extraText,
                          styles.resetPasswordStyle,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }
                  onPress={() => navigation.navigate('Registration')}
                  accessible={true}
                  accessibilityLabel={t('Registration')}>
                  {t('Registration')}
                </Text>
              </View>
              <View style={styles.formInput} accessible={true}>
                {/*<Button
                  title={t('login')}
                  color={'#D05B5B'}
                  onPress={validationLogin}
                  style={styles.button}
                />*/}
                <Pressable
                  style={darkModeAvailable ? styles.buttonDark : styles.button}
                  onPress={validationLogin}
                  accessible={true}
                  accessibilityLabel={t('login')}
                  accessibilityHint="Login validation">
                  <Text
                    style={
                      darkModeAvailable
                        ? [
                            styles.textDark,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            styles.text,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                    }>
                    {t('login')}
                  </Text>
                </Pressable>
              </View>
            </>
          )}
        </Formik>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  button: {
    backgroundColor: '#D05B5B',
    flex: 1,
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  text: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    flex: 1,
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textDark: {
    color: '#ecb853',
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  headlineMain: {
    position: Platform.OS === 'web' ? 'absolute' : 'absolute',
    width: Platform.OS === 'web' ? '100%' : '100%',
    margin: Platform.OS === 'web' ? 'auto' : 'auto',
    top: Platform.OS === 'web' ? '20px' : 20,
  },
  headline: {
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    fontSize: Platform.OS === 'web' ? '24px' : 24,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#C5C5C5',
  },
  headlineDark: {
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    fontSize: Platform.OS === 'web' ? '24px' : 24,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#ecb853',
  },
  errorText: {
    marginLeft: Platform.OS === 'web' ? '25px' : 25,
    color: '#ff0000',
    opacity: Platform.OS === 'web' ? '1.0' : 1.0,
  },
  errorTextDark: {
    marginLeft: Platform.OS === 'web' ? '25px' : 25,
    color: '#ecb853',
    opacity: Platform.OS === 'web' ? '1.0' : 1.0,
  },
  errorTextHide: {
    opacity: Platform.OS === 'web' ? '0.0' : 0.0,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '15px' : 15,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  formInput: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    marginTop: Platform.OS === 'web' ? '15px' : 15,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  labelText: {
    position: 'absolute',
    top: Platform.OS === 'web' ? '-13px' : -13,
    left: Platform.OS === 'web' ? '20px' : 20,
    padding: Platform.OS === 'web' ? '5px' : 5,
    backgroundColor: Platform.OS === 'web' ? '#FFFFFF' : 'white',
    zIndex: 10,
    color: '#707070',
  },
  labelTextDark: {
    position: 'absolute',
    top: Platform.OS === 'web' ? '-13px' : -13,
    left: Platform.OS === 'web' ? '20px' : 20,
    padding: Platform.OS === 'web' ? '5px' : 5,
    backgroundColor: '#000',
    zIndex: 10,
    color: '#ecb853',
  },
  additionalOption: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '250px' : 250,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  resetPasswordStyle: {
    /* marginRight is currently not needed until we show the registration again */
    /*marginRight: Platform.OS === 'web' ? '20px' : 20,*/
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  underlineText: {
    textDecorationLine: 'underline',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    /* marginRight and marginLeft is currently needed until we show the registration again */
    /*marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',*/
  },
  underlineTextDark: {
    textDecorationLine: 'underline',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
  },
  registerStyle: {
    marginLeft: Platform.OS === 'web' ? '20px' : 20,
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  extraText: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    textDecorationLine: 'underline',
  },
  extraTextDark: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    textDecorationLine: 'underline',
    color: '#ecb853',
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'cover',
    alignItems: 'center',
    //textAlign: 'center',
  },
  input: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    borderWidth: 1,
    padding: 15,
    borderColor: '#C5C5C5',
    backgroundColor: '#ffffff',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  inputDark: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    borderWidth: 1,
    padding: 15,
    borderColor: '#ecb853',
    color: '#ecb853',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
});

export default Home;
