/**
 * Status Login
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  TextInput,
  useColorScheme,
  View,
  Image,
  Button,
  Platform,
  Pressable,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
//import {SvgXml} from 'react-native-svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import LoadingIcon from './../../assets/loading.svg';
import SettingsIcon from './../../assets/illustrationheadersettings.png';
import SettingsIconDark from './../../assets/illustrationheadersettingsdark.png';
import {
  USERTOKEN_KEY,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
} from '../../constants/constants';
import {useTranslation} from 'react-i18next';

const StatusLogin = ({navigation}): Node => {
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  useEffect(() => {
    const userIsLoggedIn = async () => {
      if (await AsyncStorage.getItem(USERTOKEN_KEY)) {
        setTimeout(() => {
          navigation.navigate('Start');
        }, 2000);
      }
    };

    userIsLoggedIn();
  });

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={styles.headlineMain}
          accessible={true}
          accessibilityLabel={t('Status')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.headlineDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.headline,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Status')}
          </Text>
        </View>
        <View
          style={styles.headlineMain}
          accessible={true}
          accessibilityLabel={t('Signing_in')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.subheadlineDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.subheadline,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Signing_in')}
          </Text>
        </View>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel={'Loading icon'}>
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? SettingsIconDark
                    : SettingsIcon
                  : darkModeAvailable
                  ? 'illustrationheadersettingsdark'
                  : 'illustrationheadersettings',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  headlineMain: {
    position: Platform.OS === 'web' ? 'relative' : 'relative',
    width: Platform.OS === 'web' ? '100%' : '100%',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    top: Platform.OS === 'web' ? '20px' : 20,
  },
  headline: {
    textAlign: 'left',
    /*marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',*/
    fontSize: Platform.OS === 'web' ? '24px' : 24,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    color: '#000',
  },
  subheadline: {
    textAlign: 'left',
    /*marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',*/
    fontSize: Platform.OS === 'web' ? '16px' : 16,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#000',
  },
  headlineDark: {
    textAlign: 'left',
    /*marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',*/
    fontSize: Platform.OS === 'web' ? '24px' : 24,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
  },
  subheadlineDark: {
    textAlign: 'left',
    /*marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',*/
    fontSize: Platform.OS === 'web' ? '16px' : 16,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#ecb853',
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '15px' : 15,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  formInput: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    marginTop: Platform.OS === 'web' ? '15px' : 15,
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '30px' : 30,
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
});

export default StatusLogin;
