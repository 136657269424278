/**
 * Contrast
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Switch,
  TouchableOpacity,
  Platform,
  Pressable,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import {
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  USERTOKEN_KEY,
  DEVELOPMENT,
  TEST,
} from '../../../constants/constants';
// import RadioButton from './../../../components/radioButton/radioButton';

/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import IconArrow from './../../../assets/iconarrow.png';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
const RadioButton = ({onPress, selected, children}) => {
  return (
    <View style={styles.radioButtonContainer}>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation"
        style={selected ? styles.radioButtonActive : styles.radioButton}>
        {selected ? <View style={styles.radioButtonIcon} /> : null}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation">
        <Text style={styles.radioButtonText}>{children}</Text>
      </TouchableOpacity>
    </View>
  );
};

const RadioButtonDark = ({onPress, selected, children}) => {
  return (
    <View style={styles.radioButtonContainer}>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation"
        style={
          selected ? styles.radioButtonActiveDark : styles.radioButtonDark
        }>
        {selected ? <View style={styles.radioButtonIconDark} /> : null}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation">
        <Text style={styles.radioButtonText}>{children}</Text>
      </TouchableOpacity>
    </View>
  );
};

const Contrast = ({navigation}): Node => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const {t} = useTranslation();
  const isFocused = useIsFocused();
  const [textSizing, setTextSizing] = useState([
    {
      id: 1,
      value: SMALLTEXT,
      name: (
        <View
          style={
            darkModeAvailable
              ? styles.settingsBackgroundColorIconDark
              : styles.settingsBackgroundColorIcon
          }>
          <Text
            style={
              darkModeAvailable
                ? [styles.smallText, styles.textDark]
                : [styles.smallText]
            }>
            aA
          </Text>
        </View>
      ),
      selected: true,
    },
    {
      id: 2,
      value: NORMALTEXT,
      name: (
        <View
          style={
            darkModeAvailable
              ? styles.settingsBackgroundColorIconDark
              : styles.settingsBackgroundColorIcon
          }>
          <Text
            style={
              darkModeAvailable
                ? [styles.normalText, styles.textDark]
                : [styles.normalText]
            }>
            aA
          </Text>
        </View>
      ),
      selected: false,
    },
    {
      id: 3,
      value: BIGTEXT,
      name: (
        <View
          style={
            darkModeAvailable
              ? styles.settingsBackgroundColorIconDark
              : styles.settingsBackgroundColorIcon
          }>
          <Text
            style={
              darkModeAvailable
                ? [styles.bigText, styles.textDark]
                : [styles.bigText]
            }>
            aA
          </Text>
        </View>
      ),
      selected: false,
    },
  ]);
  const [textSizingDark, setTextSizingDark] = useState([
    {
      id: 1,
      value: SMALLTEXT,
      name: (
        <View style={styles.settingsBackgroundColorIconDark}>
          <Text style={[styles.smallText, styles.textDark]}>aA</Text>
        </View>
      ),
      selected: true,
    },
    {
      id: 2,
      value: NORMALTEXT,
      name: (
        <View style={styles.settingsBackgroundColorIconDark}>
          <Text style={[styles.normalText, styles.textDark]}>aA</Text>
        </View>
      ),
      selected: false,
    },
    {
      id: 3,
      value: BIGTEXT,
      name: (
        <View style={styles.settingsBackgroundColorIconDark}>
          <Text style={[styles.bigText, styles.textDark]}>aA</Text>
        </View>
      ),
      selected: false,
    },
  ]);
  const [selectTextSizing, setSelectTextSizing] = useState(SMALLTEXT);
  const [selectTextSizingDark, setSelectTextSizingDark] = useState(SMALLTEXT);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [selectedIntonation, setSelectedIntonation] = useState(false);
  const [userDataAll, setUserDataAll] = useState({});
  const onRadioBtnClickTextSizing = async item => {
    let updatedState = textSizing.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    let updatedStateDark = textSizingDark.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setTextSizing(updatedState);
    setTextSizingDark(updatedStateDark);
    setSelectTextSizing(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );
    setSelectTextSizingDark(
      updatedStateDark[0].selected == true
        ? updatedStateDark[0].value
        : updatedStateDark[1].selected == true
        ? updatedStateDark[1].value
        : updatedStateDark[2].value,
    );

    await AsyncStorage.setItem(
      CONTRAST_SIZE_TYPE,
      updatedState[0].selected == true
        ? SMALLTEXT
        : updatedState[1].selected == true
        ? NORMALTEXT
        : BIGTEXT,
    );

    const defaultTextSizingType = await AsyncStorage.getItem(
      CONTRAST_SIZE_TYPE,
    );

    defaultTextSizingType === SMALLTEXT
      ? setContrastSizing(SMALLTEXT)
      : defaultTextSizingType === NORMALTEXT
      ? setContrastSizing(NORMALTEXT)
      : defaultTextSizingType === BIGTEXT
      ? setContrastSizing(BIGTEXT)
      : setContrastSizing(NORMALTEXT);
  };

  useEffect(() => {
    const darkModeSettings = async () => {
      if ((await AsyncStorage.getItem(CONTRAST_MODE_TYPE)) == null) {
        await AsyncStorage.setItem(CONTRAST_MODE_TYPE, 'false');
        setDarkModeAvailable(false);
      }
      if ((await AsyncStorage.getItem(CONTRAST_SIZE_TYPE)) == null) {
        await AsyncStorage.setItem(CONTRAST_SIZE_TYPE, SMALLTEXT);
      }
    };

    darkModeSettings();
  });

  useEffect(() => {
    const contrastDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true' ? setIsEnabled(true) : setIsEnabled(false);

      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);

      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );
      defaultTextSizingType === SMALLTEXT
        ? (setSelectTextSizing(
            ((textSizing[0].selected = true),
            (textSizing[1].selected = false),
            (textSizing[2].selected = false)),
          ),
          setSelectTextSizingDark(
            ((textSizingDark[0].selected = true),
            (textSizingDark[1].selected = false),
            (textSizingDark[2].selected = false)),
          ))
        : defaultTextSizingType === NORMALTEXT
        ? (setSelectTextSizing(
            ((textSizing[0].selected = false),
            (textSizing[1].selected = true),
            (textSizing[2].selected = false)),
          ),
          setSelectTextSizingDark(
            ((textSizingDark[0].selected = false),
            (textSizingDark[1].selected = true),
            (textSizingDark[2].selected = false)),
          ))
        : defaultTextSizingType === BIGTEXT
        ? (setSelectTextSizing(
            ((textSizing[0].selected = false),
            (textSizing[1].selected = false),
            (textSizing[2].selected = true)),
          ),
          setSelectTextSizingDark(
            ((textSizingDark[0].selected = false),
            (textSizingDark[1].selected = false),
            (textSizingDark[2].selected = true)),
          ))
        : (setSelectTextSizing(
            ((textSizing[0].selected = true),
            (textSizing[1].selected = false),
            (textSizing[2].selected = false)),
          ),
          setSelectTextSizingDark(
            ((textSizingDark[0].selected = true),
            (textSizingDark[1].selected = false),
            (textSizingDark[2].selected = false)),
          ));

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    contrastDefault();
  });
  useEffect(() => {
    const intervalMode = setInterval(() => {
      const checkSizeContrast = async () => {
        const defaultTextSizingType = await AsyncStorage.getItem(
          CONTRAST_SIZE_TYPE,
        );
        defaultTextSizingType === SMALLTEXT
          ? setContrastSizing(SMALLTEXT)
          : defaultTextSizingType === NORMALTEXT
          ? setContrastSizing(NORMALTEXT)
          : defaultTextSizingType === BIGTEXT
          ? setContrastSizing(BIGTEXT)
          : setContrastSizing(NORMALTEXT);
      };
      const checkDarkModeDefault = async () => {
        const defaultDarkModeType = await AsyncStorage.getItem(
          CONTRAST_MODE_TYPE,
        );
        defaultDarkModeType === 'true'
          ? setDarkModeAvailable(true)
          : setDarkModeAvailable(false);
      };
      checkDarkModeDefault();
      checkSizeContrast();
    }, 1000);

    return () => {
      clearInterval(intervalMode);
    };
  }, []);

  const toggleSwitchDefault = () => {
    setIsEnabled(previousState => !previousState);
  };
  const changeTextSize = async item => {
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

    let allUserData = JSON.parse(userDataAll.settings);

    allUserData.contrast_size_type = item.value;

    axios
      .post(
        TEST + 'user/settings',
        {
          settings: JSON.stringify(allUserData),
        },
        {
          headers: {
            //...form.getHeaders(),
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userKeyToken}`,
          },
        },
      )
      .then(res => {
        if (res.status === 200) {
          console.log(res.data);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  useEffect(() => {
    const userDataSettings = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      axios
        .get(TEST + 'user', {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(response => {
          if (response.status === 200) {
            console.log(response.data);
            setUserDataAll(response.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    };
    userDataSettings();
  }, [isFocused]);

  const toggleSwitch = async () => {
    //setIsEnabled(previousState => !previousState);
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

    let allUserData = JSON.parse(userDataAll.settings);
    if (isEnabled === true) {
      setIsEnabled(false);
      setSelectedIntonation('false');

      await AsyncStorage.setItem(CONTRAST_MODE_TYPE, 'false');

      allUserData.contrast_mode_type = false;

      axios
        .post(
          TEST + 'user/settings',
          {
            settings: JSON.stringify(allUserData),
          },
          {
            headers: {
              //...form.getHeaders(),
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userKeyToken}`,
            },
          },
        )
        .then(res => {
          if (res.status === 200) {
            console.log(res.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      setIsEnabled(true);
      setSelectedIntonation('true');

      await AsyncStorage.setItem(CONTRAST_MODE_TYPE, 'true');

      allUserData.contrast_mode_type = true;

      axios
        .post(
          TEST + 'user/settings',
          {
            settings: JSON.stringify(allUserData),
          },
          {
            headers: {
              //...form.getHeaders(),
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userKeyToken}`,
            },
          },
        )
        .then(res => {
          if (res.status === 200) {
            console.log(res.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  };
  const saveData = () => {
    navigation.navigate('Settings');
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={
            darkModeAvailable
              ? [styles.additionalOption, styles.borderDownDark]
              : [styles.additionalOption, styles.borderDown]
          }
          accessible={true}>
          <View style={styles.switchText} accessible={true}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textDarkMode,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      '',
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Dark_Mode')}
            </Text>
          </View>
          <View style={styles.switchSlider} accessible={true}>
            {darkModeAvailable ? (
              <Switch
                trackColor={{false: '#ccc', true: '#ecb853'}}
                thumbColor={isEnabled ? '#ecb853' : '#ecb853'}
                activeThumbColor="#ecb853"
                activeTrackColor="#ecb853"
                //ios_backgroundColor="#fff"
                onValueChange={toggleSwitch}
                value={isEnabled}
                accessible={true}
              />
            ) : (
              <Switch
                trackColor={{false: '#ccc', true: '#D05B5B'}}
                thumbColor={isEnabled ? '#fff' : '#fff'}
                activeThumbColor="#fff"
                activeTrackColor="#fff"
                //ios_backgroundColor="#fff"
                onValueChange={toggleSwitch}
                value={isEnabled}
                accessible={true}
              />
            )}
          </View>
        </View>
        <View style={styles.additionalOptionStyles} accessible={true}>
          <View style={styles.genderBlock} accessible={true}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textDarkMode,
                      styles.categoryTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.categoryTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Text_size')}
            </Text>
            <View accessible={true}>
              {darkModeAvailable
                ? textSizingDark.map(item => (
                    <RadioButtonDark
                      onPress={() => {
                        onRadioBtnClickTextSizing(item);
                        changeTextSize(item);
                      }}
                      selected={item.selected}
                      key={item.id}>
                      {item.name}
                    </RadioButtonDark>
                  ))
                : textSizing.map(item => (
                    <RadioButton
                      onPress={() => {
                        onRadioBtnClickTextSizing(item);
                        changeTextSize(item);
                      }}
                      selected={item.selected}
                      key={item.id}>
                      {item.name}
                    </RadioButton>
                  ))}
            </View>
          </View>
        </View>
        <View>
          <Pressable
            style={darkModeAvailable ? styles.buttonDark : styles.button}
            accessible={true}
            accessibilityLabel={t('Save')}
            accessibilityHint="Save contrast validation"
            onPress={saveData}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textButton,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Save')}
            </Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  switchEnableBorder: {
    borderColor: '#6fa6d3',
    borderWidth: 1,
  },
  switchDisableBorder: {
    borderColor: '#f2f2f2',
    borderWidth: 1,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  textDarkMode: {
    color: '#ecb853',
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  borderDown: {
    borderBottomWidth: 2,
    borderBottomColor: '#ccc',
  },
  borderDownDark: {
    borderBottomWidth: 2,
    borderBottomColor: '#ecb853',
  },
  additionalOption: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '50px' : 50,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  additionalOptionTitle: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '35px' : 35,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  additionalOptionStyles: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
  },
  headlineTitle: {
    width: Platform.OS === 'web' ? '300px' : 300,
    alignItems: 'center',
  },
  categoryTitle: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  switchText: {
    width: Platform.OS === 'web' ? '250px' : 250,
    marginRight: Platform.OS === 'web' ? '5px' : 5,
  },
  clothesText: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  languageText: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  emptyText: {
    width: Platform.OS === 'web' ? '150px' : 150,
    height: Platform.OS === 'web' ? 'auto' : 'auto',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  genderBlock: {
    width: Platform.OS === 'web' ? '150px' : 150,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  switchSlider: {
    width: Platform.OS === 'web' ? '50px' : 50,
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsBackgroundColor: {
    backgroundColor: '#ECECEC',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    flex: 1,
    margin: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  settingsText: {
    fontSize: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsTextAdditional: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? '12px' : 12,
  },
  settingsIconStyle: {
    marginTop: Platform.OS === 'ios' ? 8 : '0px',
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '35px' : 35,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsGenderIconStyle: {
    marginTop: Platform.OS === 'web' ? '3px' : Platform.OS === 'ios' ? 10 : 3,
  },
  settingsBackgroundColorIcon: {
    backgroundColor: '#BEBEBE',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorIconDark: {
    backgroundColor: '#000000',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#ecb853',
    borderWidth: 2,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  radioButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  radioButton: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonDark: {
    height: 20,
    width: 20,
    backgroundColor: '#000',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ecb853',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonActive: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#D05B5B',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonActiveDark: {
    height: 20,
    width: 20,
    backgroundColor: '#000',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ecb853',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonIcon: {
    height: 14,
    width: 14,
    borderRadius: 7,
    backgroundColor: '#D05B5B',
  },
  radioButtonIconDark: {
    height: 14,
    width: 14,
    borderRadius: 7,
    backgroundColor: '#ecb853',
  },
  radioButtonText: {
    fontSize: 16,
    marginLeft: 16,
  },
  text: {
    lineHeight: 30,
    fontSize: 20,
    marginVertical: 5,
  },
  smallText: {
    fontSize: Platform.OS === 'web' ? '10px' : 10,
    paddingTop: Platform.OS === 'web' ? '14px' : 14,
    fontWeight: 600,
  },
  normalText: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
    paddingTop: Platform.OS === 'web' ? '12px' : 12,
    fontWeight: 600,
  },
  bigText: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
    paddingTop: Platform.OS === 'web' ? '10px' : 10,
    fontWeight: 600,
  },
  button: {
    backgroundColor: '#D05B5B',
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textButton: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textDark: {
    color: '#ecb853',
  },
});

export default Contrast;
