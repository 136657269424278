/**
 * Start - After logged in
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  TouchableOpacity,
  Platform,
  Alert,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Logo from './../../assets/easierlogowithtext.png';
import LogoDark from './../../assets/easierlogowithtextdark.png';
import {
  DEFAULT_VALUE_TYPE,
  DEFAULT_OUTPUT_LANGUAGE_TYPE,
  DEFAULT_OUTPUT_TYPE,
  DEFAULT_INPUT_LANGUAGE_TYPE,
  DEFAULT_INPUT_TYPE,
  SELECT_INPUT_TYPE,
  SELECT_INPUT_LANGUAGE_TYPE,
  SELECT_OUTPUT_TYPE,
  SELECT_OUTPUT_LANGUAGE_TYPE,
  EMAIL_USER,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  SPEECH,
  TEXT,
  CAMERA,
  USERTOKEN_KEY,
  AVATAR_BACKGROUND,
  AVATAR_SHIRT,
  DEFAULT_OPTION_TYPE,
  DEVELOPMENT,
  TEST,
} from '../../constants/constants';
import {useTranslation} from 'react-i18next';
import axios from 'axios';

const Start = ({navigation}): Node => {
  const CheckboxButton = ({onPress, selected, children}) => {
    return (
      <View style={styles.checkboxButtonContainer}>
        <TouchableOpacity onPress={onPress}>
          <Text style={styles.checkboxButtonText}>{children}</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={onPress}
          style={
            selected
              ? darkModeAvailable
                ? styles.checkboxButtonActiveDark
                : styles.checkboxButtonActive
              : darkModeAvailable
              ? styles.checkboxButtonDark
              : styles.checkboxButton
          }>
          {selected ? (
            <View
              style={
                darkModeAvailable
                  ? styles.checkboxButtonIconDark
                  : styles.checkboxButtonIcon
              }
            />
          ) : null}
        </TouchableOpacity>
      </View>
    );
  };
  const [toggleCheckBox, setToggleCheckBox] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);
  const [defaultValue, setDefaultValue] = useState([
    {
      id: 1,
      value: 'default',
      selected: false,
    },
  ]);
  const [selectedDefaultValue, setSelectedDefaultValue] = useState('false');
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [tokenKey, setTokenKey] = useState('');
  const [audioVideoFile, setAudioVideoFile] = useState();
  const [userDataSession, setUserDataSession] = useState([]);
  const [userDataAll, setUserDataAll] = useState({});
  /*const [widthPercent, setWidthPercent] = useState(3);
  const [progressPercent, setProgressPercent] = useState(1);*/
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);
  const changeFavoriteStatus = async item => {
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

    let allUserData = JSON.parse(userDataAll.settings);

    let updatedState = defaultValue.map(isItem =>
      isItem.id === item.id && isItem.selected === false
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    if (updatedState[0].selected == true) {
      allUserData.favorite_settings = true;
    } else {
      allUserData.favorite_settings = false;
    }

    axios
      .post(
        TEST + 'user/settings',
        {
          settings: JSON.stringify(allUserData),
        },
        {
          headers: {
            //...form.getHeaders(),
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userKeyToken}`,
          },
        },
      )
      .then(res => {
        if (res.status === 200) {
          console.log(res.data);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  const onCheckboxBtnClickDefault = async item => {
    let updatedState = defaultValue.map(isItem =>
      isItem.id === item.id && isItem.selected === false
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setDefaultValue(updatedState);
    setSelectedDefaultValue(
      updatedState[0].selected == true ? 'false' : 'true',
    );

    await AsyncStorage.setItem(
      DEFAULT_VALUE_TYPE,
      updatedState[0].selected == true ? 'true' : 'false',
    );
  };

  const startProcess = async () => {
    const favoriteSetting = await AsyncStorage.getItem(DEFAULT_VALUE_TYPE);
    const defaultValueOutputLanguage = await AsyncStorage.getItem(
      DEFAULT_OUTPUT_LANGUAGE_TYPE,
    );
    const defaultValueOutputType = await AsyncStorage.getItem(
      DEFAULT_OUTPUT_TYPE,
    );
    const defaultValueInputLanguage = await AsyncStorage.getItem(
      DEFAULT_INPUT_LANGUAGE_TYPE,
    );
    const defaultValueInputType = await AsyncStorage.getItem(
      DEFAULT_INPUT_TYPE,
    );
    const defaultValueAvatarBackground = await AsyncStorage.getItem(
      AVATAR_BACKGROUND,
    );
    const defaultValueAvatarShirt = await AsyncStorage.getItem(AVATAR_SHIRT);

    if (!defaultValueInputType) {
      await AsyncStorage.setItem(DEFAULT_INPUT_TYPE, '');
    }
    if (!defaultValueInputLanguage) {
      await AsyncStorage.setItem(DEFAULT_INPUT_LANGUAGE_TYPE, '');
    }
    if (!defaultValueOutputType) {
      await AsyncStorage.setItem(DEFAULT_OUTPUT_TYPE, '');
    }
    if (!defaultValueOutputLanguage) {
      await AsyncStorage.setItem(DEFAULT_OUTPUT_LANGUAGE_TYPE, '');
    }
    if (!defaultValueAvatarBackground) {
      await AsyncStorage.setItem(AVATAR_BACKGROUND, '0');
    }
    if (!defaultValueAvatarShirt) {
      await AsyncStorage.setItem(AVATAR_SHIRT, '0');
    }

    if (favoriteSetting === 'false') {
      await AsyncStorage.setItem(SELECT_INPUT_TYPE, 'speech');
      await AsyncStorage.setItem(SELECT_INPUT_LANGUAGE_TYPE, 'british');
      await AsyncStorage.setItem(SELECT_OUTPUT_TYPE, 'avatar');
      await AsyncStorage.setItem(SELECT_OUTPUT_LANGUAGE_TYPE, 'british');
      await AsyncStorage.setItem(DEFAULT_OPTION_TYPE, 'false');
    } else {
      await AsyncStorage.setItem(SELECT_INPUT_TYPE, defaultValueInputType);
      await AsyncStorage.setItem(
        SELECT_INPUT_LANGUAGE_TYPE,
        defaultValueInputLanguage,
      );
      await AsyncStorage.setItem(SELECT_OUTPUT_TYPE, defaultValueOutputType);
      await AsyncStorage.setItem(
        SELECT_OUTPUT_LANGUAGE_TYPE,
        defaultValueOutputLanguage,
      );
      await AsyncStorage.setItem(DEFAULT_OPTION_TYPE, 'true');
    }

    await AsyncStorage.removeItem('New_Translation');
  };

  useEffect(() => {
    const generalSettings = async () => {
      if ((await AsyncStorage.getItem(DEFAULT_VALUE_TYPE)) == null) {
        await AsyncStorage.setItem(DEFAULT_VALUE_TYPE, 'false');
      }
    };
    generalSettings();
    startProcess();
  });

  const goToSettings = async () => {
    setShowFailureAlert(false);
    await AsyncStorage.setItem(DEFAULT_OPTION_TYPE, 'true');
    navigation.navigate('Settings');
  };

  useEffect(() => {
    const generalSettingsDefault = async () => {
      const defaultValueTypeSelected = await AsyncStorage.getItem(
        DEFAULT_VALUE_TYPE,
      );
      defaultValueTypeSelected === 'true'
        ? setSelectedDefaultValue((defaultValue[0].selected = true))
        : defaultValueTypeSelected === 'false'
        ? setSelectedDefaultValue((defaultValue[0].selected = false))
        : setSelectedDefaultValue((defaultValue[0].selected = false));
    };
    generalSettingsDefault();
  }, [isFocused, defaultValue]);

  /*const failureAlertSettings = () => {
    if (Platform.OS === 'web') {
      window.alert(
        'No default input and output has been set. Go to the settings menu at first.',
      );
    } else {
      Alert.alert(
        'Failure',
        'No default input and output has been set. Go to the settings menu at first.',
        [{text: 'OK', onPress: () => console.log('Submit')}],
      );
    }
  };*/

  const closeFailureAlert = () => {
    setShowFailureAlert(false);
  };

  const checkProcess = async () => {
    const defaultValueTypeSelected = await AsyncStorage.getItem(
      DEFAULT_VALUE_TYPE,
    );
    const defaultInputTypeSelected = await AsyncStorage.getItem(
      DEFAULT_INPUT_TYPE,
    );
    const defaultInputLanguageTypeSelected = await AsyncStorage.getItem(
      DEFAULT_INPUT_LANGUAGE_TYPE,
    );
    const defaultOutputTypeSelected = await AsyncStorage.getItem(
      DEFAULT_OUTPUT_TYPE,
    );
    const defaultOutputLanguageTypeSelected = await AsyncStorage.getItem(
      DEFAULT_OUTPUT_LANGUAGE_TYPE,
    );
    const favoriteSetting = await AsyncStorage.getItem(DEFAULT_VALUE_TYPE);
    if (
      defaultValueTypeSelected === 'true' &&
      !defaultInputTypeSelected &&
      !defaultInputLanguageTypeSelected &&
      !defaultOutputTypeSelected &&
      !defaultOutputLanguageTypeSelected
    ) {
      console.log('Alert will be follow');
      setShowFailureAlert(true);
    } else {
      setShowFailureAlert(false);
      if (favoriteSetting === 'false') {
        navigation.navigate('Input Audio Content New');
      } else {
        if (defaultInputTypeSelected === TEXT) {
          navigation.navigate('Input Text');
        }
        if (defaultInputTypeSelected === SPEECH) {
          navigation.navigate('Input Audio Content New');
        }
        if (defaultInputTypeSelected === CAMERA) {
          navigation.navigate('Input Camera Content');
        }
      }
    }
  };

  const goToVideoUpload = async () => {
    await navigation.navigate('Upload Video');
  };

  useEffect(() => {
    const getToken = async () => {
      setTokenKey(await AsyncStorage.getItem(USERTOKEN_KEY));
    };
    getToken();
  }, [isFocused]);
  useEffect(() => {
    const userData = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      axios
        .get(TEST + 'oauth2/user/session', {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(response => {
          if (response.status === 200) {
            console.log(response.data);
            setUserDataSession(response.data);
          }
        })
        .catch(e => {
          console.log(e);
        });

      axios
        .get(TEST + 'user', {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(async response => {
          if (response.status === 200) {
            console.log(response.data);
            setUserDataAll(response.data);
            /*if (response.data.settings.avatar_settings == null) {
              let allUserData = JSON.parse(response.data.settings);

              allUserData.avatar_settings = {
                shirt: 0,
                background: 0,
              };
              axios
                .post(
                  TEST + 'user/settings',
                  {
                    settings: JSON.stringify(allUserData),
                  },
                  {
                    headers: {
                      //...form.getHeaders(),
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${userKeyToken}`,
                    },
                  },
                )
                .then(async res => {
                  if (res.status === 200) {
                    console.log(res.data);
                  }
                })
                .catch(e => {
                  console.log(e);
                });
            }*/
            if (response.data.settings == null) {
              axios
                .post(
                  TEST + 'user/settings',
                  {
                    settings: JSON.stringify({
                      input_media_type: '',
                      input_language_name: '',
                      output_media_type: '',
                      output_language_name: '',
                      favorite_settings: false,
                      avatar_settings: {
                        shirt: 0,
                        background: 0,
                      },
                      contrast_mode_type: false,
                      contrast_size_type: 'small',
                    }),
                  },
                  {
                    headers: {
                      //...form.getHeaders(),
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${userKeyToken}`,
                    },
                  },
                )
                .then(async res => {
                  if (res.status === 200) {
                    console.log(res.data);
                    await AsyncStorage.setItem(DEFAULT_VALUE_TYPE, false);
                    await AsyncStorage.setItem(DEFAULT_INPUT_TYPE, '');
                    await AsyncStorage.setItem(DEFAULT_INPUT_LANGUAGE_TYPE, '');
                    await AsyncStorage.setItem(DEFAULT_OUTPUT_TYPE, '');
                    await AsyncStorage.setItem(
                      DEFAULT_OUTPUT_LANGUAGE_TYPE,
                      '',
                    );
                    await AsyncStorage.setItem(AVATAR_BACKGROUND, 0);
                    await AsyncStorage.setItem(AVATAR_SHIRT, 0);
                    await AsyncStorage.setItem(DEFAULT_OPTION_TYPE, false);
                    await AsyncStorage.setItem(CONTRAST_MODE_TYPE, false);
                    await AsyncStorage.setItem(CONTRAST_SIZE_TYPE, 'small');
                  }
                })
                .catch(e => {
                  console.log(e);
                });
            } else {
              console.log(JSON.parse(response.data.settings));
              const dataDetails = JSON.parse(response.data.settings);
              await AsyncStorage.setItem(
                DEFAULT_VALUE_TYPE,
                dataDetails.favorite_settings,
              );
              await AsyncStorage.setItem(
                DEFAULT_INPUT_TYPE,
                dataDetails.input_media_type,
              );
              await AsyncStorage.setItem(
                DEFAULT_INPUT_LANGUAGE_TYPE,
                dataDetails.input_language_name,
              );
              await AsyncStorage.setItem(
                DEFAULT_OUTPUT_TYPE,
                dataDetails.output_media_type,
              );
              await AsyncStorage.setItem(
                DEFAULT_OUTPUT_LANGUAGE_TYPE,
                dataDetails.output_language_name,
              );
              if (dataDetails.avatar_settings === null) {
                await AsyncStorage.setItem(AVATAR_BACKGROUND, 0);
                await AsyncStorage.setItem(AVATAR_SHIRT, 0);
              }
              if (dataDetails.avatar_settings !== null) {
                await AsyncStorage.setItem(
                  AVATAR_BACKGROUND,
                  dataDetails.avatar_settings.background,
                );
                await AsyncStorage.setItem(
                  AVATAR_SHIRT,
                  dataDetails.avatar_settings.shirt,
                );
              }
              await AsyncStorage.setItem(
                DEFAULT_OPTION_TYPE,
                dataDetails.favorite_settings,
              );
              await AsyncStorage.setItem(
                CONTRAST_MODE_TYPE,
                dataDetails.contrast_mode_type,
              );
              await AsyncStorage.setItem(
                CONTRAST_SIZE_TYPE,
                dataDetails.contrast_size_type,
              );
            }
          }
        })
        .catch(e => {
          console.log(e);
        });
    };
    userData();
  }, [isFocused]);

  /*useEffect(() => {
    const intervalMode = setInterval(() => {
      setWidthPercent(widthPercent + 3);
      setProgressPercent(progressPercent + 1);
    }, 1000);

    return () => {
      if (progressPercent >= 30) {
        clearInterval(intervalMode);
        setWidthPercent(300);
        setProgressPercent(100);
      }
      if (progressPercent < 30) {
        clearInterval(intervalMode);
      }
    };
  }, [widthPercent, progressPercent]);*/

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        {/*<View>
          <View
            style={
              darkModeAvailable
                ? styles.progressBarMainDark
                : styles.progressBarMain
            }
            className="progress-bar">
            <View
              style={
                darkModeAvailable
                  ? [styles.progressBarMainTimerDark, {width: widthPercent}]
                  : [styles.progressBarMainTimer, {width: widthPercent}]
              }
            />
          </View>
          <View style={styles.progressBarMainPercentMain}>
            <Text
              style={
                darkModeAvailable
                  ? styles.progressBarMainPercentDark
                  : styles.progressBarMainPercent
              }>
              {progressPercent} %
            </Text>
          </View>
        </View>*/}
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel="Logo of EASIER">
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? LogoDark
                    : Logo
                  : darkModeAvailable
                  ? 'easierlogowithtextdark'
                  : 'easierlogowithtext',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View
          style={styles.userDataInfo}
          accessible={true}
          accessibilityLabel={
            t('Logged_in_as') + ' - ' + userDataSession.email
          }>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.userDataTextDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.userDataText,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Logged_in_as')}
          </Text>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.userDataTextDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.userDataText,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {userDataSession.email}
          </Text>
        </View>
        <TouchableOpacity
          style={darkModeAvailable ? styles.buttonMainDark : styles.buttonMain}
          onPress={startProcess}
          accessible={true}
          accessibilityLabel={t('Start')}
          accessibilityHint="Starting translation">
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.buttonDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.button,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }
            onPress={checkProcess}>
            {t('Start')}
          </Text>
        </TouchableOpacity>
        <View style={styles.checkboxButtonMainContainer}>
          {defaultValue.map(item => (
            <CheckboxButton
              onPress={() => {
                onCheckboxBtnClickDefault(item);
                changeFavoriteStatus(item);
              }}
              selected={item.selected}
              key={item.id}
              accessible={true}
              accessibilityLabel={t('Use_favorite_settings')}
              accessibilityHint="Enable or disable default options">
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.checkboxButtonTextInnerDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.checkboxButtonTextInner,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Use_favorite_settings')}
              </Text>
            </CheckboxButton>
          ))}
        </View>
        <TouchableOpacity
          style={darkModeAvailable ? styles.buttonMainDark : styles.buttonMain}
          onPress={startProcess}
          accessible={true}
          accessibilityLabel={t('Upload_Video')}
          accessibilityHint="Upload video">
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.buttonDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.button,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }
            onPress={goToVideoUpload}>
            {t('Upload_Video')}
          </Text>
        </TouchableOpacity>
        {showFailureAlert ? (
          <View
            style={
              darkModeAvailable ? styles.alertFailureDark : styles.alertFailure
            }
            accessible={showFailureAlert}>
            <View accessible={true} accessibilityLabel={t('Failure')}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.alertHeadlineDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.alertHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Failure')}
              </Text>
            </View>
            <View
              accessible={true}
              accessibilityLabel={t('No_default_input_and_output')}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.alertDescriptionDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.alertDescription,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('No_default_input_and_output')}
              </Text>
            </View>
            <View accessible={true} accessibilityLabel={t('Go_to_settings')}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.alertSettingsLinkDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.alertSettingsLink,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }
                onPress={goToSettings}>
                {t('Go_to_settings')}
              </Text>
            </View>
            <View accessible={true} accessibilityLabel={t('OK')}>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.alertSubmitDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.alertSubmit,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }
                onPress={() => setShowFailureAlert(false)}>
                {t('OK')}
              </Text>
            </View>
          </View>
        ) : (
          <View></View>
        )}
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  alertFailure: {
    width: Platform.OS === 'web' ? '210px' : 210,
    height: Platform.OS === 'web' ? '210px' : 210,
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '-200px' : -200,
    marginLeft: Platform.OS !== 'web' ? 'auto' : 'auto',
    position: 'relative',
    backgroundColor: '#ffffff',
    boxShadow: '0px 2px 5px 0px #000',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 6.68,
    elevation: 24,
    border: '1px solid #ccc',
    padding: Platform.OS === 'web' ? '20px' : 20,
  },
  alertHeadline: {
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    fontSize: Platform.OS === 'web' ? '24px' : 24,
  },
  alertDescription: {
    marginTop: Platform.OS === 'web' ? '15px' : 15,
  },
  alertSubmit: {
    marginTop: Platform.OS === 'web' ? '15px' : 15,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#D05B5B',
    textAlign: 'right',
    //alignItems: 'right',
  },
  alertSettingsLink: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#D05B5B',
    textDecoration: 'underline',
    textAlign: 'left',
    //alignItems: 'right',
  },
  alertFailureDark: {
    width: Platform.OS === 'web' ? '210px' : 210,
    height: Platform.OS === 'web' ? '210px' : 210,
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '-200px' : -200,
    marginLeft: Platform.OS !== 'web' ? 'auto' : 'auto',
    position: 'relative',
    backgroundColor: '#000000',
    boxShadow: '0px 2px 5px 0px #000',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.1,
    shadowRadius: 6.68,
    elevation: 24,
    border: '1px solid #ccc',
    padding: Platform.OS === 'web' ? '20px' : 20,
  },
  alertHeadlineDark: {
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    fontSize: Platform.OS === 'web' ? '24px' : 24,
    color: '#ecb853',
  },
  alertDescriptionDark: {
    marginTop: Platform.OS === 'web' ? '15px' : 15,
    color: '#ecb853',
  },
  alertSubmitDark: {
    marginTop: Platform.OS === 'web' ? '15px' : 15,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#ecb853',
    textAlign: 'right',
    //alignItems: 'right',
  },
  alertSettingsLinkDark: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#ecb853',
    textDecoration: 'underline',
    textAlign: 'left',
    //alignItems: 'right',
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '15px' : 15,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  userDataInfo: {
    width: Platform.OS === 'web' ? '250px' : 250,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '30px' : 30,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    textAlign: 'center',
    alignItems: 'center',
  },
  userDataText: {
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    color: '#666666',
  },
  userDataTextDark: {
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    color: '#ecb853',
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  buttonMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '150px' : 150,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMainDark: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '150px' : 150,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    borderWidth: 2,
    borderColor: '#ecb853',
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#D05B5B',
    color: '#fff',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '150px' : 150,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonDark: {
    backgroundColor: '#000000',
    color: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  menuMainUser: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    top: Platform.OS === 'web' ? '0px' : 0,
    right: Platform.OS === 'web' ? '20px' : 20,
    position: 'absolute',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 3px 0px #000',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,
    elevation: 11,
    border: '1px solid #ccc',
    padding: Platform.OS === 'web' ? '20px' : 20,
  },
  checkboxButtonMainContainer: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  checkboxButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  checkboxButton: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    //borderRadius: 10,
    borderWidth: 3,
    borderColor: '#707070',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxButtonActive: {
    height: 20,
    width: 20,
    backgroundColor: '#D05B5B',
    //borderRadius: 10,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#D05B5B',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxButtonIcon: {
    height: 15,
    width: 7,
    //borderRadius: 7,
    borderBottomWidth: 3,
    borderRightWidth: 3,
    borderColor: '#fff',
    backgroundColor: '#D05B5B',
    transform: [{rotate: '45deg'}],
    marginTop: Platform.OS === 'web' ? '-4px' : -4,
  },
  checkboxButtonDark: {
    height: 20,
    width: 20,
    backgroundColor: '#000000',
    //borderRadius: 10,
    borderWidth: 3,
    borderColor: '#ecb853',
    borderRadius: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxButtonActiveDark: {
    height: 20,
    width: 20,
    backgroundColor: '#000000',
    //borderRadius: 10,
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#ecb853',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkboxButtonIconDark: {
    height: 15,
    width: 7,
    //borderRadius: 7,
    borderBottomWidth: 3,
    borderRightWidth: 3,
    borderColor: '#ecb853',
    backgroundColor: '#000000',
    transform: [{rotate: '45deg'}],
    marginTop: Platform.OS === 'web' ? '-4px' : -4,
  },
  checkboxButtonText: {
    fontSize: 14,
    marginLeft: Platform.OS === 'web' ? '16px' : 16,
  },
  checkboxButtonTextInner: {
    paddingRight: Platform.OS === 'web' ? '10px' : 10,
  },
  checkboxButtonTextInnerDark: {
    paddingRight: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
  },
  progressBarMain: {
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#ddd',
    borderRadius: 5,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  progressBarMainDark: {
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#000',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  progressBarMainTimer: {
    //width: Platform.OS === 'web' ? '3px' : 3,
    height: Platform.OS === 'web' ? '8px' : 8,
    backgroundColor: '#D05B5B',
    borderRadius: 5,
  },
  progressBarMainTimerDark: {
    //width: Platform.OS === 'web' ? '3px' : 3,
    height: Platform.OS === 'web' ? '8px' : 8,
    backgroundColor: '#ecb853',
    borderRadius: 5,
  },
  progressBarMainPercent: {
    //width: Platform.OS === 'web' ? '300px' : 300,
    color: '#000',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressBarMainPercentDark: {
    //width: Platform.OS === 'web' ? '300px' : 300,
    color: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressBarMainPercentMain: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default Start;
