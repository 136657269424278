/**
 * Status Tranlation
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  TextInput,
  useColorScheme,
  View,
  Image,
  Button,
  Platform,
  ActivityIndicator,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
//import {SvgXml} from 'react-native-svg';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  SPEECH,
  TEXT,
  CAMERA,
  AVATAR,
  USERTOKEN_KEY,
  SELECT_INPUT_TYPE,
  SELECT_OUTPUT_TYPE,
  SELECT_INPUT_LANGUAGE_TYPE,
  SELECT_OUTPUT_LANGUAGE_TYPE,
  DEFAULT_INPUT_TYPE,
  DEFAULT_INPUT_LANGUAGE_TYPE,
  DEFAULT_OUTPUT_TYPE,
  DEFAULT_OUTPUT_LANGUAGE_TYPE,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_ITALIAN,
  LANGUAGE_LUXEMBOURG_FRENCH,
  LANGUAGE_LUXEMBOURG_GERMAN,
  LANGUAGE_DUTCH,
  LANGUAGE_SUIZZE_FRENCH,
  LANGUAGE_SUIZZE_ITALIAN,
  LANGUAGE_SUIZZE_GERMAN,
  LANGUAGE_BRITISH,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  TEXT_VALUE,
  DEVELOPMENT,
  TEST,
  DEFAULT_VALUE_TYPE,
} from '../../constants/constants';
import axios from 'axios';

/* Icons */
import LoadingIcon from './../../assets/loading.svg';
import SettingsIcon from './../../assets/illustrationheadersettings.png';
import SettingsIconDark from './../../assets/illustrationheadersettingsdark.png';
import TextIcon from './../../assets/icontext.png';
import TextIconDark from './../../assets/icontextdark.png';
import RecordIcon from './../../assets/iconspeech.png';
import RecordIconDark from './../../assets/iconspeechdark.png';
import CameraIcon from './../../assets/iconcamera.png';
import CameraIconDark from './../../assets/iconcameradark.png';
import AvatarIcon from './../../assets/iconavatar.png';
import AvatarIconDark from './../../assets/iconavatardark.png';
import ArrowIcon from './../../assets/iconarrow.png';
import ArrowIconDark from './../../assets/iconarrowdark.png';
import FlagGermanIcon from './../../assets/flaggerman.png';
import FlagDutchIcon from './../../assets/flagdutch.png';
import FlagFrenchIcon from './../../assets/flagfrench.png';
import FlagItalianIcon from './../../assets/flagitaly.png';
import FlagGreeceIcon from './../../assets/flaggreece.png';
import FlagGreatBritianIcon from './../../assets/flaggreatbritain.png';
import FlagLuxembourgIcon from './../../assets/flagluxembourg.png';
import FlagSuizzeIcon from './../../assets/flagsuizze.png';
import {useTranslation} from 'react-i18next';

const StatusTranlationNewest = ({navigation, route}): Node => {
  const [inputLanguage, setInputLanguage] = useState('');
  const [outputLanguage, setOutputLanguage] = useState('');
  const [inputTypeFormat, setInputTypeFormat] = useState('');
  const [outputTypeFormat, setOutputTypeFormat] = useState('');
  const [inputLanguageTranslate, setInputLanguageTranslate] = useState('');
  const [outputLanguageTranslate, setOutputLanguageTranslate] = useState('');
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [loadingSpinnerAvailable, setLoadingSpinnerAvailable] = useState(true);
  const [widthPercent, setWidthPercent] = useState(3);
  const [progressPercent, setProgressPercent] = useState(1);
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  const {
    visualData,
    languageDataInput,
    languageDataOutput,
    typeDataInput,
    typeDataOutput,
  } = route.params;

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
    setWidthPercent(3);
    setProgressPercent(1);
  }, [isFocused]);
  useEffect(() => {
    const intervalMode = setInterval(() => {
      setWidthPercent(widthPercent + 3);
      setProgressPercent(progressPercent + 1);
    }, 1000);

    return () => {
      if (progressPercent >= 60 || !loadingSpinnerAvailable) {
        clearInterval(intervalMode);
        setWidthPercent(300);
        setProgressPercent(100);
      }
      if (progressPercent < 60) {
        clearInterval(intervalMode);
      }
    };
  }, [loadingSpinnerAvailable, widthPercent, progressPercent]);
  useEffect(() => {
    const callTranslationAPI = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);
      const settingsChoice = await AsyncStorage.getItem(DEFAULT_VALUE_TYPE);
      if (
        (settingsChoice === 'false' && typeDataInput === 'text') ||
        (settingsChoice === 'true' && typeDataInput === 'text')
      ) {
        setLoadingSpinnerAvailable(true);
        let form = new FormData();
        form.append(
          'translation',
          '{"input_type": "' +
            typeDataInput +
            '", "input_language": "' +
            languageDataInput +
            '", "output_type": "' +
            typeDataOutput +
            '", "output_language": "' +
            languageDataOutput +
            '", "media_text": "' +
            visualData +
            '", "test_mode": false}',
        );
        form.append('upload_file', '""');
        await axios
          .post(TEST + 'translation', form, {
            headers: {
              //...form.getHeaders(),
              Authorization: `Bearer ${userKeyToken}`,
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
            },
          })
          .then(response => {
            if (response.status === 200) {
              setLoadingSpinnerAvailable(false);
              setTimeout(() => {
                navigation.navigate('Result Translation', {
                  translationNumber: response.data.id,
                  translationStatus: 'true',
                });
              }, 2000);
            } else {
              console.log('Error');
              setLoadingSpinnerAvailable(false);
              setTimeout(() => {
                navigation.navigate('Result Translation', {
                  translationNumber: '',
                  translationStatus: 'false',
                });
              }, 2000);
            }
          })
          .catch(e => {
            console.log(e);
            setTimeout(() => {
              navigation.navigate('Result Translation', {
                translationNumber: '',
                translationStatus: 'false',
              });
            }, 2000);
          });
      }
      if (
        (settingsChoice === 'false' && typeDataInput === 'audio') ||
        (settingsChoice === 'true' && typeDataInput === 'audio') ||
        (settingsChoice === 'false' && typeDataInput === 'video') ||
        (settingsChoice === 'true' && typeDataInput === 'video')
      ) {
        setLoadingSpinnerAvailable(true);
        let form = new FormData();
        form.append(
          'translation',
          '{"input_type": "' +
            typeDataInput +
            '", "input_language": "' +
            languageDataInput +
            '", "output_type": "' +
            typeDataOutput +
            '", "output_language": "' +
            languageDataOutput +
            '", "test_mode": false}',
        );
        form.append('upload_file', visualData);
        await axios
          .post(TEST + 'translation', form, {
            headers: {
              //...form.getHeaders(),
              Authorization: `Bearer ${userKeyToken}`,
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
            },
          })
          .then(response => {
            if (response.status === 200) {
              setLoadingSpinnerAvailable(false);
              setTimeout(() => {
                navigation.navigate('Result Translation', {
                  translationNumber: response.data.id,
                  translationStatus: 'true',
                });
              }, 2000);
            } else {
              setLoadingSpinnerAvailable(false);
              console.log('Error');
              setTimeout(() => {
                navigation.navigate('Result Translation', {
                  translationNumber: '',
                  translationStatus: 'false',
                });
              }, 2000);
            }
          })
          .catch(e => {
            console.log(e);
            setLoadingSpinnerAvailable(false);
            setTimeout(() => {
              navigation.navigate('Result Translation', {
                translationNumber: '',
                translationStatus: 'false',
              });
            }, 2000);
          });
      }
    };
    callTranslationAPI();
  }, [
    languageDataInput,
    languageDataOutput,
    navigation,
    typeDataInput,
    typeDataOutput,
    visualData,
  ]);
  useEffect(() => {
    const redirectTranslation = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);
      const settingsChoice = await AsyncStorage.getItem(DEFAULT_VALUE_TYPE);
      const defaultOutputChoice = await AsyncStorage.getItem(
        DEFAULT_OUTPUT_TYPE,
      );
      const defaultInputChoice = await AsyncStorage.getItem(DEFAULT_INPUT_TYPE);
      const selectOutputChoice = await AsyncStorage.getItem(SELECT_OUTPUT_TYPE);
      const selectInputChoice = await AsyncStorage.getItem(SELECT_INPUT_TYPE);

      const defaultInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
      defaultInputType === SPEECH
        ? setInputTypeFormat(SPEECH)
        : defaultInputType === TEXT
        ? setInputTypeFormat(TEXT)
        : defaultInputType === CAMERA
        ? setInputTypeFormat(CAMERA)
        : setInputTypeFormat('');

      const defaultOutputType = await AsyncStorage.getItem(SELECT_OUTPUT_TYPE);
      defaultOutputType === SPEECH
        ? setOutputTypeFormat(SPEECH)
        : defaultOutputType === TEXT
        ? setOutputTypeFormat(TEXT)
        : defaultOutputType === AVATAR
        ? setOutputTypeFormat(AVATAR)
        : setOutputTypeFormat('');

      const defaultInputLanguageType = await AsyncStorage.getItem(
        SELECT_INPUT_LANGUAGE_TYPE,
      );
      const defaultOutputLanguageType = await AsyncStorage.getItem(
        SELECT_OUTPUT_LANGUAGE_TYPE,
      );
      /*defaultInputLanguageType === LANGUAGE_FRENCH
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('LSF')
          : setInputLanguageTranslate('FR')
        : defaultInputLanguageType === LANGUAGE_GERMAN
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('DGS')
          : setInputLanguageTranslate('DE')
        : defaultInputLanguageType === LANGUAGE_GREEK
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('GSL')
          : setInputLanguageTranslate('EL')
        : defaultInputLanguageType === LANGUAGE_ITALIAN
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('LIS')
          : setInputLanguageTranslate('IT')
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_FRENCH
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('LSF')
          : setInputLanguageTranslate('FR')
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_GERMAN
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('DGS')
          : setInputLanguageTranslate('DE')
        : defaultInputLanguageType === LANGUAGE_DUTCH
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('NGT')
          : setInputLanguageTranslate('NL')
        : defaultInputLanguageType === LANGUAGE_SUIZZE_FRENCH
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('LSF')
          : setInputLanguageTranslate('FR')
        : defaultInputLanguageType === LANGUAGE_SUIZZE_ITALIAN
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('LIS')
          : setInputLanguageTranslate('IT')
        : defaultInputLanguageType === LANGUAGE_SUIZZE_GERMAN
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('DSGS')
          : setInputLanguageTranslate('CH')
        : defaultInputLanguageType === LANGUAGE_BRITISH
        ? defaultInputType === CAMERA
          ? setInputLanguageTranslate('BSL')
          : setInputLanguageTranslate('EN')
        : setInputLanguageTranslate('');

      defaultOutputLanguageType === LANGUAGE_FRENCH
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('LSF')
          : setOutputLanguageTranslate('FR')
        : defaultOutputLanguageType === LANGUAGE_GERMAN
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('DGS')
          : setOutputLanguageTranslate('DE')
        : defaultOutputLanguageType === LANGUAGE_GREEK
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('GSL')
          : setOutputLanguageTranslate('EL')
        : defaultOutputLanguageType === LANGUAGE_ITALIAN
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('LIS')
          : setOutputLanguageTranslate('IT')
        : defaultOutputLanguageType === LANGUAGE_LUXEMBOURG_FRENCH
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('LSF')
          : setOutputLanguageTranslate('FR')
        : defaultOutputLanguageType === LANGUAGE_LUXEMBOURG_GERMAN
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('DGS')
          : setOutputLanguageTranslate('DE')
        : defaultOutputLanguageType === LANGUAGE_DUTCH
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('NGT')
          : setOutputLanguageTranslate('NL')
        : defaultOutputLanguageType === LANGUAGE_SUIZZE_FRENCH
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('LSF')
          : setOutputLanguageTranslate('FR')
        : defaultOutputLanguageType === LANGUAGE_SUIZZE_ITALIAN
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('LIS')
          : setOutputLanguageTranslate('IT')
        : defaultOutputLanguageType === LANGUAGE_SUIZZE_GERMAN
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('DSGS')
          : setOutputLanguageTranslate('CH')
        : defaultOutputLanguageType === LANGUAGE_BRITISH
        ? defaultOutputType === AVATAR
          ? setOutputLanguageTranslate('BSL')
          : setOutputLanguageTranslate('EN')
        : setOutputLanguageTranslate('');*/
      /*if (
        (settingsChoice === 'false' && typeDataInput === 'text') ||
        (settingsChoice === 'true' && typeDataInput === 'text')
      ) {
        let form = new FormData();
        form.append(
          'translation',
          '{"input_type": "' +
            typeDataInput +
            '", "input_language": "' +
            languageDataInput +
            '", "output_type": "' +
            typeDataOutput +
            '", "output_language": "' +
            languageDataOutput +
            '", "media_text": "' +
            visualData +
            '", "test_mode": false}',
        );
        form.append('upload_file', '""');
        await axios
          .post(TEST + 'translation', form, {
            headers: {
              //...form.getHeaders(),
              Authorization: `Bearer ${userKeyToken}`,
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
            },
          })
          .then(response => {
            if (response.status === 200) {
              setTimeout(() => {
                navigation.navigate('Result Translation');
              }, 1000);
            } else {
              console.log('Error');
            }
          })
          .catch(e => {
            console.log(e);
          });
      }
      if (
        (settingsChoice === 'false' && typeDataInput === 'audio') ||
        (settingsChoice === 'true' && typeDataInput === 'audio') ||
        (settingsChoice === 'false' && typeDataInput === 'video') ||
        (settingsChoice === 'true' && typeDataInput === 'video')
      ) {
        let form = new FormData();
        form.append(
          'translation',
          '{"input_type": "' +
            typeDataInput +
            '", "input_language": "' +
            languageDataInput +
            '", "output_type": "' +
            typeDataOutput +
            '", "output_language": "' +
            languageDataOutput +
            '", "test_mode": false}',
        );
        form.append('upload_file', visualData);
        await axios
          .post(TEST + 'translation', form, {
            headers: {
              //...form.getHeaders(),
              Authorization: `Bearer ${userKeyToken}`,
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Headers': '*',
            },
          })
          .then(response => {
            if (response.status === 200) {
              setTimeout(() => {
                navigation.navigate('Result Translation');
              }, 1000);
            } else {
              console.log('Error');
            }
          })
          .catch(e => {
            console.log(e);
          });
      }*/
    };

    redirectTranslation();
    const translateLanguages = async () => {
      const settingsChoice = await AsyncStorage.getItem(DEFAULT_VALUE_TYPE);
      const defaultInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
      const defaultOutputType = await AsyncStorage.getItem(SELECT_OUTPUT_TYPE);
      const defaultInputLanguageType = await AsyncStorage.getItem(
        SELECT_INPUT_LANGUAGE_TYPE,
      );
      defaultInputLanguageType === LANGUAGE_FRENCH
        ? setInputLanguage(LANGUAGE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_GERMAN
        ? setInputLanguage(LANGUAGE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_GREEK
        ? setInputLanguage(LANGUAGE_GREEK)
        : defaultInputLanguageType === LANGUAGE_ITALIAN
        ? setInputLanguage(LANGUAGE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_FRENCH
        ? setInputLanguage(LANGUAGE_LUXEMBOURG_FRENCH)
        : defaultInputLanguageType === LANGUAGE_LUXEMBOURG_GERMAN
        ? setInputLanguage(LANGUAGE_LUXEMBOURG_GERMAN)
        : defaultInputLanguageType === LANGUAGE_DUTCH
        ? setInputLanguage(LANGUAGE_DUTCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_FRENCH
        ? setInputLanguage(LANGUAGE_SUIZZE_FRENCH)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_ITALIAN
        ? setInputLanguage(LANGUAGE_SUIZZE_ITALIAN)
        : defaultInputLanguageType === LANGUAGE_SUIZZE_GERMAN
        ? setInputLanguage(LANGUAGE_SUIZZE_GERMAN)
        : defaultInputLanguageType === LANGUAGE_BRITISH
        ? setInputLanguage(LANGUAGE_BRITISH)
        : setInputLanguage('');

      const defaultOutputLanguageType = await AsyncStorage.getItem(
        SELECT_OUTPUT_LANGUAGE_TYPE,
      );
      defaultOutputLanguageType === LANGUAGE_FRENCH
        ? setOutputLanguage(LANGUAGE_FRENCH)
        : defaultOutputLanguageType === LANGUAGE_GERMAN
        ? setOutputLanguage(LANGUAGE_GERMAN)
        : defaultOutputLanguageType === LANGUAGE_GREEK
        ? setOutputLanguage(LANGUAGE_GREEK)
        : defaultOutputLanguageType === LANGUAGE_ITALIAN
        ? setOutputLanguage(LANGUAGE_ITALIAN)
        : defaultOutputLanguageType === LANGUAGE_LUXEMBOURG_FRENCH
        ? setOutputLanguage(LANGUAGE_LUXEMBOURG_FRENCH)
        : defaultOutputLanguageType === LANGUAGE_LUXEMBOURG_GERMAN
        ? setOutputLanguage(LANGUAGE_LUXEMBOURG_GERMAN)
        : defaultOutputLanguageType === LANGUAGE_DUTCH
        ? setOutputLanguage(LANGUAGE_DUTCH)
        : defaultOutputLanguageType === LANGUAGE_SUIZZE_FRENCH
        ? setOutputLanguage(LANGUAGE_SUIZZE_FRENCH)
        : defaultOutputLanguageType === LANGUAGE_SUIZZE_ITALIAN
        ? setOutputLanguage(LANGUAGE_SUIZZE_ITALIAN)
        : defaultOutputLanguageType === LANGUAGE_SUIZZE_GERMAN
        ? setOutputLanguage(LANGUAGE_SUIZZE_GERMAN)
        : defaultOutputLanguageType === LANGUAGE_BRITISH
        ? setOutputLanguage(LANGUAGE_BRITISH)
        : setOutputLanguage('');
    };
    translateLanguages();
  });

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={styles.headlineMain}
          accessible={true}
          accessibilityLabel={t('Status')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.headlineDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.headline,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Status')}
          </Text>
        </View>
        <View
          style={styles.headlineMain}
          accessible={true}
          accessibilityLabel={t('Translation_in_progress')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.subheadlineDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.subheadline,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Translation_in_progress')}
          </Text>
        </View>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel={'Loading icon'}>
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? SettingsIconDark
                    : SettingsIcon
                  : darkModeAvailable
                  ? 'illustrationheadersettingsdark'
                  : 'illustrationheadersettings',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View style={styles.loadingSpinner}>
          {/*<ActivityIndicator
            size="large"
            animating={loadingSpinnerAvailable}
            color={darkModeAvailable ? '#ecb853' : '#D05B5B'}
          />*/}
          <View>
            <View
              style={
                darkModeAvailable
                  ? styles.progressBarMainDark
                  : styles.progressBarMain
              }
              className="progress-bar">
              <View
                style={
                  darkModeAvailable
                    ? [styles.progressBarMainTimerDark, {width: widthPercent}]
                    : [styles.progressBarMainTimer, {width: widthPercent}]
                }
              />
            </View>
            <View style={styles.progressBarMainPercentMain}>
              <Text
                style={
                  darkModeAvailable
                    ? styles.progressBarMainPercentDark
                    : styles.progressBarMainPercent
                }>
                {progressPercent} %
              </Text>
            </View>
          </View>
        </View>
        <View
          style={styles.mainMarginDetails}
          accessible={true}
          accessibilityLabel={
            'Loading icon and selected input and output options'
          }>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                inputTypeFormat == SPEECH
                  ? Platform.OS == 'web'
                    ? darkModeAvailable
                      ? RecordIconDark
                      : RecordIcon
                    : darkModeAvailable
                    ? 'iconinputdark'
                    : 'iconinput'
                  : inputTypeFormat == TEXT
                  ? Platform.OS == 'web'
                    ? darkModeAvailable
                      ? TextIconDark
                      : TextIcon
                    : darkModeAvailable
                    ? 'icontextdark'
                    : 'icontext'
                  : inputTypeFormat == CAMERA
                  ? Platform.OS == 'web'
                    ? darkModeAvailable
                      ? CameraIconDark
                      : CameraIcon
                    : darkModeAvailable
                    ? 'iconcameradark'
                    : 'iconcamera'
                  : '',
            }}
            style={styles.iconTypeFormat}
            resizeMode="contain"
          />
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                inputLanguage == LANGUAGE_GERMAN
                  ? Platform.OS == 'web'
                    ? FlagGermanIcon
                    : 'flaggerman'
                  : inputLanguage == LANGUAGE_GREEK
                  ? Platform.OS == 'web'
                    ? FlagGreeceIcon
                    : 'flaggreece'
                  : inputLanguage == LANGUAGE_FRENCH
                  ? Platform.OS == 'web'
                    ? FlagFrenchIcon
                    : 'flagfrench'
                  : inputLanguage == LANGUAGE_ITALIAN
                  ? Platform.OS == 'web'
                    ? FlagItalianIcon
                    : 'flagitaly'
                  : inputLanguage == LANGUAGE_LUXEMBOURG_FRENCH ||
                    inputLanguage == LANGUAGE_LUXEMBOURG_GERMAN
                  ? Platform.OS == 'web'
                    ? FlagLuxembourgIcon
                    : 'flagluxembourg'
                  : inputLanguage == LANGUAGE_DUTCH
                  ? Platform.OS == 'web'
                    ? FlagDutchIcon
                    : 'flagdutch'
                  : inputLanguage == LANGUAGE_SUIZZE_FRENCH ||
                    inputLanguage == LANGUAGE_SUIZZE_ITALIAN ||
                    inputLanguage == LANGUAGE_SUIZZE_GERMAN
                  ? Platform.OS == 'web'
                    ? FlagSuizzeIcon
                    : 'flagsuizze'
                  : inputLanguage == LANGUAGE_BRITISH
                  ? Platform.OS == 'web'
                    ? FlagGreatBritianIcon
                    : 'flaggreatbritain'
                  : '',
            }}
            style={
              darkModeAvailable ? styles.iconLanguageDark : styles.iconLanguage
            }
            resizeMode="contain"
          />
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? ArrowIconDark
                    : ArrowIcon
                  : darkModeAvailable
                  ? 'iconarrowdark'
                  : 'iconarrow',
            }}
            style={styles.iconArrow}
            resizeMode="contain"
          />
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                outputTypeFormat == SPEECH
                  ? Platform.OS == 'web'
                    ? darkModeAvailable
                      ? RecordIconDark
                      : RecordIcon
                    : darkModeAvailable
                    ? 'iconinputdark'
                    : 'iconinput'
                  : outputTypeFormat == TEXT
                  ? Platform.OS == 'web'
                    ? darkModeAvailable
                      ? TextIconDark
                      : TextIcon
                    : darkModeAvailable
                    ? 'icontextdark'
                    : 'icontext'
                  : outputTypeFormat == AVATAR
                  ? Platform.OS == 'web'
                    ? darkModeAvailable
                      ? AvatarIconDark
                      : AvatarIcon
                    : darkModeAvailable
                    ? 'iconavatardark'
                    : 'iconavatar'
                  : '',
            }}
            style={styles.iconTypeFormat}
            resizeMode="contain"
          />
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri:
                outputLanguage == LANGUAGE_GERMAN
                  ? Platform.OS == 'web'
                    ? FlagGermanIcon
                    : 'flaggerman'
                  : outputLanguage == LANGUAGE_GREEK
                  ? Platform.OS == 'web'
                    ? FlagGreeceIcon
                    : 'flaggreece'
                  : outputLanguage == LANGUAGE_FRENCH
                  ? Platform.OS == 'web'
                    ? FlagFrenchIcon
                    : 'flagfrench'
                  : outputLanguage == LANGUAGE_ITALIAN
                  ? Platform.OS == 'web'
                    ? FlagItalianIcon
                    : 'flagitaly'
                  : outputLanguage == LANGUAGE_LUXEMBOURG_FRENCH ||
                    outputLanguage == LANGUAGE_LUXEMBOURG_GERMAN
                  ? Platform.OS == 'web'
                    ? FlagLuxembourgIcon
                    : 'flagluxembourg'
                  : outputLanguage == LANGUAGE_DUTCH
                  ? Platform.OS == 'web'
                    ? FlagDutchIcon
                    : 'flagdutch'
                  : outputLanguage == LANGUAGE_SUIZZE_FRENCH ||
                    outputLanguage == LANGUAGE_SUIZZE_ITALIAN ||
                    outputLanguage == LANGUAGE_SUIZZE_GERMAN
                  ? Platform.OS == 'web'
                    ? FlagSuizzeIcon
                    : 'flagsuizze'
                  : outputLanguage == LANGUAGE_BRITISH
                  ? Platform.OS == 'web'
                    ? FlagGreatBritianIcon
                    : 'flaggreatbritain'
                  : '',
            }}
            style={
              darkModeAvailable ? styles.iconLanguageDark : styles.iconLanguage
            }
            resizeMode="contain"
          />
        </View>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  headlineMain: {
    position: Platform.OS === 'web' ? 'relative' : 'relative',
    width: Platform.OS === 'web' ? '100%' : '100%',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    top: Platform.OS === 'web' ? '20px' : 20,
  },
  headline: {
    textAlign: 'left',
    /*marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',*/
    fontSize: Platform.OS === 'web' ? '24px' : 24,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    color: '#000',
  },
  headlineDark: {
    textAlign: 'left',
    /*marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',*/
    fontSize: Platform.OS === 'web' ? '24px' : 24,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
  },
  subheadline: {
    textAlign: 'left',
    /*marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',*/
    fontSize: Platform.OS === 'web' ? '16px' : 16,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#000',
  },
  subheadlineDark: {
    textAlign: 'left',
    /*marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',*/
    fontSize: Platform.OS === 'web' ? '16px' : 16,
    fontWeight: Platform.OS === 'web' ? 'bold' : 'bold',
    color: '#ecb853',
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '15px' : 15,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  formInput: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    marginTop: Platform.OS === 'web' ? '15px' : 15,
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '30px' : 30,
  },
  mainMarginDetails: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '50px' : 50,
    flexDirection: 'row',
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  iconLanguage: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '2.5px' : 2.5,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  iconLanguageDark: {
    width: Platform.OS === 'web' ? '24px' : 24,
    height: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '2.5px' : 2.5,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    borderWidth: 2,
    borderColor: '#ecb853',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  iconTypeFormat: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  iconArrow: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  loadingSpinner: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  progressBarMain: {
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#ddd',
    borderRadius: 5,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  progressBarMainDark: {
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#000',
    borderRadius: 5,
    borderWidth: 1,
    borderColor: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  progressBarMainTimer: {
    //width: Platform.OS === 'web' ? '3px' : 3,
    height: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#D05B5B',
    borderRadius: 5,
  },
  progressBarMainTimerDark: {
    //width: Platform.OS === 'web' ? '3px' : 3,
    height: Platform.OS === 'web' ? '8px' : 8,
    backgroundColor: '#ecb853',
    borderRadius: 5,
  },
  progressBarMainPercent: {
    //width: Platform.OS === 'web' ? '300px' : 300,
    color: '#000',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressBarMainPercentDark: {
    //width: Platform.OS === 'web' ? '300px' : 300,
    color: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressBarMainPercentMain: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default StatusTranlationNewest;
