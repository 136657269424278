/**
 * Settings
 */

import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Platform,
  TouchableOpacity,
  Pressable,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import {
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  SETTINGS_CHOICE,
  SETTINGS_NORMAL,
  SETTINGS_FAVORITE,
} from '../../constants/constants';

/* Icons */
import Logo from './../../assets/easierlogowithtext.png';
import IconArrow from './../../assets/iconarrow.png';
import SettingsIcon from './../../assets/illustrationheadersettings.png';
import SettingsIconDark from './../../assets/illustrationheadersettingsdark.png';
import InputIcon from './../../assets/iconinput.png';
import InputIconDark from './../../assets/iconinputdark.png';
import OutputIcon from './../../assets/iconoutput.png';
import OutputIconDark from './../../assets/iconoutputdark.png';
import AvatarIcon from './../../assets/iconavatar.png';
import RecordIcon from './../../assets/iconspeech.png';
import VoiceIcon from './../../assets/iconvoice.png';
import {useTranslation} from 'react-i18next';
import LanguagesList from '../../components/languagesList/languagesList';
const Settings = ({navigation}): Node => {
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const {t} = useTranslation();
  const isFocused = useIsFocused();

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    const settingsChoice = async () => {
      await AsyncStorage.setItem(SETTINGS_CHOICE, SETTINGS_FAVORITE);
    };
    settingsChoice();
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);
  const saveData = () => {
    navigation.navigate('Start');
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel="Setting logo">
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? SettingsIconDark
                    : SettingsIcon
                  : darkModeAvailable
                  ? 'illustrationheadersettingsdark'
                  : 'illustrationheadersettings',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View
          style={
            darkModeAvailable
              ? styles.settingsBackgroundColorDarkMode
              : styles.settingsBackgroundColor
          }
          accessible={true}>
          <View style={styles.additionalOption} accessible={true}>
            <View
              style={
                darkModeAvailable
                  ? styles.settingsBackgroundColorIconDark
                  : styles.settingsBackgroundColorIcon
              }
              accessible={true}>
              <Pressable
                onPress={
                  Platform.OS == 'web'
                    ? () => {
                        navigation.navigate('Default Input');
                      }
                    : () => {
                        navigation.navigate('Default Input');
                      }
                }
                accessible={true}
                accessibilityLabel={t('Default_Input')}
                accessibilityHint="Default input option">
                <Image
                  width={Platform.OS == 'web' ? '75px' : 75}
                  height={Platform.OS == 'web' ? '75px' : 75}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? InputIconDark
                          : InputIcon
                        : darkModeAvailable
                        ? 'iconinputdark'
                        : 'iconinput',
                  }}
                  style={styles.settingsIconStyle}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.settingsTextDarkMode,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.settingsText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Default_Input')}
                </Text>
              </Pressable>
            </View>
            <View
              style={
                darkModeAvailable
                  ? styles.settingsBackgroundColorIconDark
                  : styles.settingsBackgroundColorIcon
              }
              accessible={true}>
              <Pressable
                onPress={
                  Platform.OS == 'web'
                    ? () => {
                        navigation.navigate('Default Output');
                      }
                    : () => {
                        navigation.navigate('Default Output');
                      }
                }
                accessible={true}
                accessibilityLabel={t('Default_Output')}
                accessibilityHint="Default output option">
                <Image
                  width={Platform.OS == 'web' ? '75px' : 75}
                  height={Platform.OS == 'web' ? '75px' : 75}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? OutputIconDark
                          : OutputIcon
                        : darkModeAvailable
                        ? 'iconoutputdark'
                        : 'iconoutput',
                  }}
                  style={styles.settingsIconStyle}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.settingsTextDarkMode,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.settingsText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Default_Output')}
                </Text>
              </Pressable>
            </View>
            <View
              style={
                darkModeAvailable
                  ? styles.settingsBackgroundColorIconDark
                  : styles.settingsBackgroundColorIcon
              }
              accessible={true}>
              <Pressable
                onPress={
                  Platform.OS == 'web'
                    ? () => {
                        navigation.navigate('Contrast');
                      }
                    : () => {
                        navigation.navigate('Contrast');
                      }
                }
                accessible={true}
                accessibilityLabel={t('Contrast')}
                accessibilityHint="Contrast option">
                <Image
                  width={Platform.OS == 'web' ? '75px' : 75}
                  height={Platform.OS == 'web' ? '75px' : 75}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? SettingsIconDark
                          : SettingsIcon
                        : darkModeAvailable
                        ? 'illustrationheadersettingsdark'
                        : 'illustrationheadersettings',
                  }}
                  style={styles.settingsIconStyle}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.settingsTextDarkMode,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.settingsText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Contrast')}
                </Text>
              </Pressable>
            </View>
          </View>
          {/*<View style={styles.additionalOption} accessible={true}>
            <View
              style={
                darkModeAvailable
                  ? styles.settingsBackgroundColorIconDark
                  : styles.settingsBackgroundColorIcon
              }
              accessible={true}>
              <Pressable
                onPress={
                  Platform.OS == 'web'
                    ? () => {
                        navigation.navigate('Voice');
                      }
                    : () => {
                        navigation.navigate('Voice');
                      }
                }
                accessible={true}
                accessibilityLabel={t('Voice')}
                accessibilityHint="Voice option">
                <Image
                  width={Platform.OS == 'web' ? '75px' : 75}
                  height={Platform.OS == 'web' ? '75px' : 75}
                  source={{
                    uri: Platform.OS == 'web' ? VoiceIcon : 'iconvoice',
                  }}
                  style={styles.settingsIconStyle}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.settingsTextDarkMode,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.settingsText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Voice')}
                </Text>
              </Pressable>
            </View>
            <View
              style={
                darkModeAvailable
                  ? styles.settingsBackgroundColorIconDark
                  : styles.settingsBackgroundColorIcon
              }
              accessible={true}>
              <Image
                width={Platform.OS == 'web' ? '75px' : 75}
                height={Platform.OS == 'web' ? '75px' : 75}
                source={{
                  uri: Platform.OS == 'web' ? RecordIcon : 'iconspeech',
                }}
                style={styles.settingsIconStyle}
                resizeMode="contain"
              />
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.settingsTextDarkMode,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.settingsText,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Recording')}
              </Text>
            </View>
            <View
              style={
                darkModeAvailable
                  ? styles.settingsBackgroundColorIconDark
                  : styles.settingsBackgroundColorIcon
              }
              accessible={true}>
              <Pressable
                onPress={
                  Platform.OS == 'web'
                    ? () => {
                        navigation.navigate('Avatar');
                      }
                    : () => {
                        navigation.navigate('Avatar');
                      }
                }
                accessible={true}
                accessibilityLabel={t('Avatar')}
                accessibilityHint="Avatar option">
                <Image
                  width={Platform.OS == 'web' ? '75px' : 75}
                  height={Platform.OS == 'web' ? '75px' : 75}
                  source={{
                    uri: Platform.OS == 'web' ? AvatarIcon : 'iconavatar',
                  }}
                  style={styles.settingsIconStyle}
                  resizeMode="contain"
                />
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.settingsTextDarkMode,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.settingsText,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Avatar')}
                </Text>
              </Pressable>
            </View>
          </View>*/}
        </View>
        <View>
          <Pressable
            style={darkModeAvailable ? styles.buttonDark : styles.button}
            accessible={true}
            accessibilityLabel={t('BACK_TO_START_MENU')}
            accessibilityHint="Back to the start validation"
            onPress={saveData}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.textButton,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('BACK_TO_START_MENU')}
            </Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  additionalOption: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '125px' : 125,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  settingsBackgroundColor: {
    backgroundColor: '#ECECEC',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //flex: 1,
    flexDirection: 'column',
    //margin: Platform.OS === 'web' ? 'auto' : 'auto',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingLeft: Platform.OS === 'web' ? '20px' : 20,
    paddingBottom: Platform.OS === 'web' ? '30px' : 30,
  },
  settingsBackgroundColorDarkMode: {
    backgroundColor: '#000000',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //flex: 1,
    flexDirection: 'column',
    //margin: Platform.OS === 'web' ? 'auto' : 'auto',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingLeft: Platform.OS === 'web' ? '20px' : 20,
    paddingBottom: Platform.OS === 'web' ? '30px' : 30,
  },
  settingsText: {
    fontSize: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  settingsTextDarkMode: {
    fontSize: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    color: '#ecb853',
  },
  settingsBackgroundColorIcon: {
    backgroundColor: '#BEBEBE',
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '75px' : 75,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorIconDark: {
    backgroundColor: '#000',
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '75px' : 75,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#ecb853',
    borderWidth: 2,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '75px' : 75,
    height: Platform.OS === 'web' ? '75px' : 75,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  button: {
    backgroundColor: '#D05B5B',
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textButton: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textDark: {
    color: '#ecb853',
  },
});

export default Settings;
