/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, {useEffect, useState, useRef, useMemo} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Dimensions,
  Platform,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import SplashScreen from 'react-native-splash-screen';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  NavigationContainer,
  DefaultTheme,
  //useIsFocused,
} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome';
import axios from 'axios';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {
  USERTOKEN_KEY,
  USERREFRESHTOKEN_KEY,
  TOKEN_TIMER,
  CONTRAST_MODE_TYPE,
  APP_LANGUAGE,
  SPEECH,
  TEXT,
  AVATAR,
  CAMERA,
  SETTINGS_CHOICE,
  SETTINGS_NORMAL,
  SETTINGS_FAVORITE,
  AVATAR_CHOICE,
  AVATAR_NORMAL,
  AVATAR_FAVORITE,
  DEFAULT_VALUE_TYPE,
  DEFAULT_INPUT_TYPE,
  SELECT_INPUT_TYPE,
  DEFAULT_OUTPUT_TYPE,
  SELECT_OUTPUT_TYPE,
  DEFAULT_OPTION_TYPE,
  DEVELOPMENT,
  TEST,
} from './constants/constants';
import LanguagesList from './components/languagesList/languagesList';
import RNRestart from 'react-native-restart';

/* Components */
// import Section from './components/section/section';

/* Views */
import Home from './views/home/home';
import StatusLogin from './views/statusLogin/statusLogin';
import Registration from './views/registration/registration';
import About from './views/about/about';
import Reset from './views/reset/reset';
import NewPassword from './views/newPassword/newPassword';
import Start from './views/start/start';
import Profile from './views/profile/profile';
import Info from './views/info/info';
import Privacy from './views/privacy/privacy';
import Settings from './views/settings/settings';
import DefaultInput from './views/settings/defaultInput/defaultInput';
import DefaultOutput from './views/settings/defaultOutput/defaultOutput';
import Voice from './views/settings/voice/voice';
import Avatar from './views/settings/avatar/avatar';
import AvatarNew from './views/settings/avatar/avatarNew';
import Contrast from './views/settings/contrast/contrast';
import LanguagesInput from './views/settings/languages/languages';
import LanguagesOutput from './views/settings/languages/languagesOutput';
import Archive from './views/archive/archive';
import ArchiveDetail from './views/archive/archiveDetail';
import InputMethod from './views/translator/inputMethod/inputMethod';
import InputLanguageMethod from './views/translator/inputLanguageMethod/inputLanguageMethod';
import InputText from './views/translator/inputText/inputText';
import InputAudio from './views/translator/inputAudio/inputAudio';
import InputAudioContent from './views/translator/inputAudioContent/inputAudioContent';
import InputAudioContentNew from './views/translator/inputAudioContentNew/inputAudioContentNew';
import InputCamera from './views/translator/inputCamera/inputCamera';
import InputCameraContent from './views/translator/inputCameraContent/inputCameraContent';
import OutputMethod from './views/translator/outputMethod/outputMethod';
import OutputLanguageMethod from './views/translator/outputLanguageMethod/outputLanguageMethod';
import StatusTranslation from './views/statusTranslation/statusTranslation';
import StatusTranslationNew from './views/statusTranslation/statusTranslationNew';
import StatusTranslationNewest from './views/statusTranslation/statusTranslationNewest';
import ResultTranslation from './views/translator/resultTranslation/resultTranslation';
import UploadVideo from './views/translator/uploadVideo/uploadVideo';
import UploadVideoNew from './views/translator/uploadVideo/uploadVideoNew';
import UploadVideoNewest from './views/translator/uploadVideo/uploadVideoNewest';
import {useTranslation} from 'react-i18next';
/* Images */
import MenuIcon from './assets/iconmenu.png';
import MenuIconDark from './assets/iconmenudark.png';

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: 'rgb(255, 0, 0)',
  },
};

const Stack = createStackNavigator();

const App = (): Node => {
  const ref = useRef();

  //const window = Dimensions.get('window');

  const [openMenu, setOpenMenu] = useState(false);
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isFavoriteAvatar, setIsFavoriteAvatar] = useState('1');
  const [isPrivacyNormal, setIsPrivacyNormal] = useState('1');
  const [isInputLanguageSign, setIsInputLanguageSign] = useState(false);
  const [isInputSelectLanguageSign, setIsInputSelectLanguageSign] =
    useState(false);
  const [isOutputLanguageSign, setIsOutputLanguageSign] = useState(false);
  const [isOutputSelectLanguageSign, setIsOutputSelectLanguageSign] =
    useState(false);
  const [isArchiveID, setIsArchiveID] = useState(false);
  const [archiveStatus, setArchiveStatus] = useState();
  const {t} = useTranslation();

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    checkDarkModeDefault();
  });
  useEffect(() => {
    const timerOld = async () => {
      const timer = await AsyncStorage.getItem(TOKEN_TIMER);
      if (
        new Date().getTime() - timer >= 8640000 ||
        timer === '' ||
        timer === null
      ) {
        AsyncStorage.removeItem(USERREFRESHTOKEN_KEY);
        AsyncStorage.removeItem(USERTOKEN_KEY);
        AsyncStorage.removeItem(TOKEN_TIMER);
        // navigation.navigate('Log In');
      } else {
        console.log('Session are active');
      }
    };
    timerOld();
  }, []);
  useEffect(() => {
    const intervalMode = setInterval(() => {
      const checkDarkModeDefault = async () => {
        const defaultDarkModeType = await AsyncStorage.getItem(
          CONTRAST_MODE_TYPE,
        );
        defaultDarkModeType === 'true'
          ? setDarkModeAvailable(true)
          : setDarkModeAvailable(false);
      };
      const settingsChoice = async () => {
        const settingsStatus = await AsyncStorage.getItem(SETTINGS_CHOICE);

        if (settingsStatus === SETTINGS_FAVORITE) {
          setIsFavorite(true);
        } else {
          setIsFavorite(false);
        }
      };
      const avatarChoice = async () => {
        const avatarStatus = await AsyncStorage.getItem(AVATAR_CHOICE);

        if (avatarStatus === AVATAR_FAVORITE) {
          setIsFavoriteAvatar('1');
        } else if (avatarStatus === AVATAR_NORMAL) {
          setIsFavoriteAvatar('2');
        } else {
          setIsFavoriteAvatar('3');
        }
      };
      const privacyChoice = async () => {
        const privacyStatus = await AsyncStorage.getItem('@PrivacyWay');

        if (privacyStatus === 'Normal') {
          setIsPrivacyNormal('1');
        } else {
          setIsPrivacyNormal('2');
        }
      };
      const signLanguage = async () => {
        const selectInputType = await AsyncStorage.getItem(SELECT_INPUT_TYPE);
        const defaultInputType = await AsyncStorage.getItem(DEFAULT_INPUT_TYPE);
        const defaultOutputType = await AsyncStorage.getItem(
          DEFAULT_OUTPUT_TYPE,
        );
        const selectOutputType = await AsyncStorage.getItem(SELECT_OUTPUT_TYPE);
        const defaultOptionType = await AsyncStorage.getItem(
          DEFAULT_OPTION_TYPE,
        );

        if (defaultInputType === CAMERA && defaultOptionType === 'true') {
          setIsInputLanguageSign(true);
        }
        if (selectInputType === CAMERA && defaultOptionType === 'false') {
          setIsInputSelectLanguageSign(true);
          setIsInputLanguageSign(true);
        }
        if (
          (defaultInputType === TEXT || defaultInputType === SPEECH) &&
          defaultOptionType === true
        ) {
          setIsInputLanguageSign(false);
        }
        if (
          (selectInputType === TEXT || selectInputType === SPEECH) &&
          defaultOptionType === 'false'
        ) {
          setIsInputSelectLanguageSign(false);
          setIsInputLanguageSign(false);
        }
        if (defaultOutputType === AVATAR && defaultOptionType === 'true') {
          setIsOutputLanguageSign(true);
        }
        if (selectOutputType === AVATAR && defaultOptionType === 'false') {
          setIsOutputSelectLanguageSign(true);
          setIsOutputLanguageSign(true);
        }
        if (
          (defaultOutputType === TEXT || defaultOutputType === SPEECH) &&
          defaultOptionType === 'true'
        ) {
          setIsOutputLanguageSign(false);
        }
        if (
          (selectOutputType === TEXT || selectOutputType === SPEECH) &&
          defaultOptionType === 'false'
        ) {
          setIsOutputSelectLanguageSign(false);
          setIsOutputLanguageSign(false);
        }
      };
      signLanguage();
      avatarChoice();
      privacyChoice();
      settingsChoice();
      checkDarkModeDefault();
    }, 1000);

    return () => {
      clearInterval(intervalMode);
    };
  }, []);

  useEffect(() => {
    const authBodyRefreshToken = {
      grant_type: 'refresh_token',
      client_id: 'test-client',
      client_secret: 'Nuro_secret',
      scope: 'user',
    };
    const intervalMode = setInterval(() => {
      const tryRefreshToken = async () => {
        if (
          ((await AsyncStorage.getItem(USERREFRESHTOKEN_KEY)) == '' ||
            (await AsyncStorage.getItem(USERREFRESHTOKEN_KEY)) == null) &&
          ((await AsyncStorage.getItem(USERTOKEN_KEY)) == '' ||
            (await AsyncStorage.getItem(USERTOKEN_KEY)) == null) &&
          ((await AsyncStorage.getItem(TOKEN_TIMER)) == '' ||
            (await AsyncStorage.getItem(TOKEN_TIMER)) == null)
        ) {
          if (Platform.OS === 'web') {
            window.location.reload();
          } else {
            setTimeout(async navigation => {
              await navigation.navigate('Log In');
              RNRestart.Restart();
            }, 1000);
          }
        } else {
          const body = Object.entries({
            ...authBodyRefreshToken,
            refresh_token: await AsyncStorage.getItem(USERREFRESHTOKEN_KEY),
          })
            .map(arr => arr.join('='))
            .join('&');
          return new Promise((resolve, reject) => {
            axios
              .post(TEST + 'oauth2/user/token', body)
              .then(
                async res => {
                  resolve(res);
                  await AsyncStorage.setItem(
                    USERREFRESHTOKEN_KEY,
                    res.data.refresh_token,
                  );
                  await AsyncStorage.setItem(
                    USERTOKEN_KEY,
                    res.data.access_token,
                  );
                  await AsyncStorage.setItem(TOKEN_TIMER, new Date().getTime());
                },
                async err => {
                  reject(err);
                  await AsyncStorage.removeItem(USERREFRESHTOKEN_KEY);
                  await AsyncStorage.removeItem(USERTOKEN_KEY);
                  await AsyncStorage.removeItem(TOKEN_TIMER);
                  if (Platform.OS === 'web') {
                    window.location.reload();
                  } else {
                    setTimeout(async navigation => {
                      await navigation.navigate('Log In');
                      RNRestart.Restart();
                    }, 1000);
                  }
                },
              )
              .catch(async err => {
                await AsyncStorage.removeItem(USERREFRESHTOKEN_KEY);
                await AsyncStorage.removeItem(USERTOKEN_KEY);
                await AsyncStorage.removeItem(TOKEN_TIMER);
                if (Platform.OS === 'web') {
                  window.location.reload();
                } else {
                  setTimeout(async navigation => {
                    await navigation.navigate('Log In');
                    RNRestart.Restart();
                  }, 1000);
                }
              });
          });
        }
      };
      tryRefreshToken();
    }, 900000);

    return () => {
      clearInterval(intervalMode);
    };
  }, []);

  useEffect(() => {
    if (Platform.OS === 'ios' || Platform.OS === 'android') {
      SplashScreen.hide();
    }
  });

  useEffect(() => {
    const onChange = result => {
      setDimensions(result.window);
    };

    Dimensions.addEventListener('change', onChange);

    return () => Dimensions.removeEventListener('change', onChange);
    //console.log(Dimensions.get('window'));
  }, []);

  const isDarkMode = useColorScheme() === 'dark';

  const backgroundStyle = {
    /*backgroundColor: isDarkMode ? Colors.darker : Colors.lighter,*/
  };

  const logout = navigation => {
    if (Platform.OS === 'web') {
      AsyncStorage.removeItem(USERTOKEN_KEY);
      AsyncStorage.removeItem(USERREFRESHTOKEN_KEY);
      AsyncStorage.removeItem(TOKEN_TIMER);
      navigation.navigate('Log In');
    }
    setOpenMenu(false);
  };

  const inputBack = async navigation => {
    if ((await AsyncStorage.getItem(SELECT_INPUT_TYPE)) === TEXT) {
      navigation.navigate('Input Text');
    } else if ((await AsyncStorage.getItem(SELECT_INPUT_TYPE)) === SPEECH) {
      navigation.navigate('Input Audio Content New');
    } else if ((await AsyncStorage.getItem(SELECT_INPUT_TYPE)) === CAMERA) {
      navigation.navigate('Input Camera Content');
    } else {
      navigation.navigate('Input Method');
    }
  };

  const inputFavoriteBack = async navigation => {
    if ((await AsyncStorage.getItem(DEFAULT_INPUT_TYPE)) === TEXT) {
      navigation.navigate('Input Text');
    } else if ((await AsyncStorage.getItem(DEFAULT_INPUT_TYPE)) === SPEECH) {
      navigation.navigate('Input Audio Content New');
    } else if ((await AsyncStorage.getItem(DEFAULT_INPUT_TYPE)) === CAMERA) {
      navigation.navigate('Input Camera Content');
    } else {
      navigation.navigate('Default Input');
    }
  };

  useEffect(() => {
    if (Platform.OS === 'web') {
      const checkIfClickedOutside = e => {
        if (openMenu && ref.current && !ref.current.contains(e.target)) {
          setOpenMenu(false);
        }
      };

      document.addEventListener('mousedown', checkIfClickedOutside);

      return () => {
        document.removeEventListener('mousedown', checkIfClickedOutside);
      };
    }
  }, [openMenu]);

  return (
    <NavigationContainer theme={MyTheme}>
      <Stack.Navigator initialRouteName="Log In">
        <Stack.Screen
          name="Log In"
          component={Home}
          options={{
            title: t('login'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerRight: () => <LanguagesList />,
          }}
        />
        <Stack.Screen
          name="Status Login"
          component={StatusLogin}
          options={{
            title: 'Log In',
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => <></>,
          }}
        />
        <Stack.Screen
          name="Status Translation"
          component={StatusTranslation}
          options={({navigation}) => ({
            title: t('Translation'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                {/*<View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Output Method');
                          }
                        : () => {
                            navigation.navigate('Output Method');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>*/}
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Status Translation New"
          component={StatusTranslationNew}
          options={({navigation}) => ({
            title: t('Translation'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                {/*<View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Output Method');
                          }
                        : () => {
                            navigation.navigate('Output Method');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>*/}
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Status Translation Newest"
          component={StatusTranslationNewest}
          options={({navigation}) => ({
            title: t('Translation'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                {/*<View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Output Method');
                          }
                        : () => {
                            navigation.navigate('Output Method');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>*/}
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Result Translation"
          component={ResultTranslation}
          options={({navigation}) => ({
            title: t('Translation_Output'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                {/*<View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Status Translation');
                          }
                        : () => {
                            navigation.navigate('Output Method');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>*/}
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Registration"
          component={Registration}
          options={({navigation}) => ({
            title: t('Registration'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Log In');
                          }
                        : () => {
                            navigation.navigate('Log In');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
            headerRight: () => <LanguagesList />,
          })}
        />
        <Stack.Screen
          name="Reset"
          component={Reset}
          options={({navigation}) => ({
            title: t('Reset_password'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Log In');
                          }
                        : () => {
                            navigation.navigate('Log In');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="New Password"
          component={NewPassword}
          options={({navigation}) => ({
            title: t('New_Password'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Reset');
                          }
                        : () => {
                            navigation.navigate('Reset');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="About"
          component={About}
          options={{
            title: t('About'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
          }}
        />
        <Stack.Screen
          name="Start"
          component={Start}
          options={({navigation}) => ({
            title: t('Start'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (Platform.OS === 'web' ? '' : <></>),
            headerRight: () =>
              Platform.OS === 'web' && dimensions.width > 991 ? (
                <>
                  <View style={styles.menuMainUserWeb}>
                    <Text
                      style={
                        darkModeAvailable
                          ? styles.menuItemElementWebDark
                          : styles.menuItemElementWeb
                      }
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              logout(navigation);
                              setOpenMenu(false);
                            }
                          : () => {
                              logout(navigation);
                              setOpenMenu(false);
                              AsyncStorage.removeItem(USERTOKEN_KEY);
                              AsyncStorage.removeItem(USERREFRESHTOKEN_KEY);
                              navigation.navigate('Log In');
                            }
                      }>
                      {t('Logout')}
                    </Text>
                    <Text
                      style={
                        darkModeAvailable
                          ? styles.menuItemElementWebDark
                          : styles.menuItemElementWeb
                      }
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              navigation.navigate('Profile');
                              setOpenMenu(false);
                            }
                          : () => {
                              setOpenMenu(false);
                              navigation.navigate('Profile');
                            }
                      }>
                      {t('Profile')}
                    </Text>
                    <Text
                      style={
                        darkModeAvailable
                          ? styles.menuItemElementWebDark
                          : styles.menuItemElementWeb
                      }
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              navigation.navigate('Info');
                              setOpenMenu(false);
                            }
                          : () => {
                              setOpenMenu(false);
                              navigation.navigate('Info');
                            }
                      }>
                      {t('Info')}
                    </Text>
                    <Text
                      style={
                        darkModeAvailable
                          ? styles.menuItemElementWebDark
                          : styles.menuItemElementWeb
                      }
                      onPress={
                        Platform.OS == 'web'
                          ? async () => {
                              await navigation.navigate('Settings');
                              await AsyncStorage.setItem(
                                DEFAULT_OPTION_TYPE,
                                'true',
                              );
                              setOpenMenu(false);
                            }
                          : async () => {
                              setOpenMenu(false);
                              await AsyncStorage.setItem(
                                DEFAULT_OPTION_TYPE,
                                'true',
                              );
                              await navigation.navigate('Settings');
                            }
                      }>
                      {t('Settings')}
                    </Text>
                    <Text
                      style={
                        darkModeAvailable
                          ? styles.menuItemElementWebDark
                          : styles.menuItemElementWeb
                      }
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              navigation.navigate('Archive');
                              setOpenMenu(false);
                            }
                          : () => {
                              setOpenMenu(false);
                              navigation.navigate('Archive');
                            }
                      }>
                      {t('Archive')}
                    </Text>
                    <LanguagesList style={{marginTop: -20}} />
                  </View>
                </>
              ) : (
                <>
                  <View ref={ref} nativeID="menu">
                    <TouchableWithoutFeedback
                      onPress={() => setOpenMenu(!openMenu)}>
                      <Image
                        width={Platform.OS == 'web' ? '25px' : 25}
                        height={Platform.OS == 'web' ? '25px' : 25}
                        source={{
                          uri:
                            Platform.OS == 'web'
                              ? darkModeAvailable
                                ? MenuIconDark
                                : MenuIcon
                              : darkModeAvailable
                              ? 'iconmenudark'
                              : 'iconmenu',
                        }}
                        style={styles.menuImage}
                        resizeMode="contain"
                      />
                    </TouchableWithoutFeedback>
                    <View
                      style={
                        openMenu
                          ? darkModeAvailable
                            ? styles.menuMainUserDark
                            : styles.menuMainUserHidden
                          : !darkModeAvailable
                          ? styles.menuMainUser
                          : styles.menuMainUserHidden
                      }>
                      <Text
                        style={
                          darkModeAvailable
                            ? styles.menuItemElementDark
                            : styles.menuItemElement
                        }
                        onPress={
                          Platform.OS == 'web'
                            ? () => {
                                logout(navigation);
                                setOpenMenu(false);
                              }
                            : () => {
                                logout(navigation);
                                setOpenMenu(false);
                                AsyncStorage.removeItem(USERTOKEN_KEY);
                                AsyncStorage.removeItem(USERREFRESHTOKEN_KEY);
                                navigation.navigate('Log In');
                              }
                        }>
                        {t('Logout')}
                      </Text>
                      <Text
                        style={
                          darkModeAvailable
                            ? styles.menuItemElementDark
                            : styles.menuItemElement
                        }
                        onPress={
                          Platform.OS == 'web'
                            ? () => {
                                navigation.navigate('Profile');
                                setOpenMenu(false);
                              }
                            : () => {
                                setOpenMenu(false);
                                navigation.navigate('Profile');
                              }
                        }>
                        {t('Profile')}
                      </Text>
                      <Text
                        style={
                          darkModeAvailable
                            ? styles.menuItemElementDark
                            : styles.menuItemElement
                        }
                        onPress={
                          Platform.OS == 'web'
                            ? () => {
                                navigation.navigate('Info');
                                setOpenMenu(false);
                              }
                            : () => {
                                setOpenMenu(false);
                                navigation.navigate('Info');
                              }
                        }>
                        {t('Info')}
                      </Text>
                      <Text
                        style={
                          darkModeAvailable
                            ? styles.menuItemElementDark
                            : styles.menuItemElement
                        }
                        onPress={
                          Platform.OS == 'web'
                            ? async () => {
                                navigation.navigate('Settings');
                                await AsyncStorage.setItem(
                                  DEFAULT_OPTION_TYPE,
                                  'true',
                                );
                                setOpenMenu(false);
                              }
                            : async () => {
                                setOpenMenu(false);
                                await AsyncStorage.setItem(
                                  DEFAULT_OPTION_TYPE,
                                  'true',
                                );
                                navigation.navigate('Settings');
                              }
                        }>
                        {t('Settings')}
                      </Text>
                      <Text
                        style={
                          darkModeAvailable
                            ? styles.menuItemElementDark
                            : styles.menuItemElement
                        }
                        onPress={
                          Platform.OS == 'web'
                            ? () => {
                                navigation.navigate('Archive');
                                setOpenMenu(false);
                              }
                            : () => {
                                setOpenMenu(false);
                                navigation.navigate('Archive');
                              }
                        }>
                        {t('Archive')}
                      </Text>
                      <LanguagesList
                        style={{marginLeft: -16, marginTop: -8}}
                        color={darkModeAvailable ? 'orange' : 'black'}
                      />
                    </View>
                  </View>
                </>
              ),
          })}
        />
        <Stack.Screen
          name="Profile"
          component={Profile}
          options={({navigation}) => ({
            title: t('Profile'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Start');
                          }
                        : () => {
                            navigation.navigate('Start');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Info"
          component={Info}
          options={({navigation}) => ({
            title: t('Info'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Start');
                          }
                        : () => {
                            navigation.navigate('Start');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Privacy"
          component={Privacy}
          options={({navigation}) => ({
            title: t('Privacy'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                {isPrivacyNormal == '1' ? (
                  <View accessible={true}>
                    <TouchableOpacity
                      style={styles.backArrow}
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              navigation.navigate('Registration');
                            }
                          : () => {
                              navigation.navigate('Registration');
                            }
                      }>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={
                          darkModeAvailable
                            ? styles.backArrowIconDark
                            : styles.backArrowIcon
                        }
                      />
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View accessible={true}>
                    <TouchableOpacity
                      style={styles.backArrow}
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              navigation.navigate('Info');
                            }
                          : () => {
                              navigation.navigate('Info');
                            }
                      }>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={
                          darkModeAvailable
                            ? styles.backArrowIconDark
                            : styles.backArrowIcon
                        }
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Settings"
          component={Settings}
          options={({navigation}) => ({
            title: t('Settings'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Start');
                          }
                        : () => {
                            navigation.navigate('Start');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Default Input"
          component={DefaultInput}
          options={({navigation}) => ({
            title: t('Default_Input'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                {isFavorite ? (
                  <View accessible={true}>
                    <TouchableOpacity
                      style={styles.backArrow}
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              navigation.navigate('Settings');
                            }
                          : () => {
                              navigation.navigate('Settings');
                            }
                      }>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={
                          darkModeAvailable
                            ? styles.backArrowIconDark
                            : styles.backArrowIcon
                        }
                      />
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View accessible={true}>
                    <TouchableOpacity
                      style={styles.backArrow}
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              inputFavoriteBack(navigation);
                            }
                          : () => {
                              inputFavoriteBack(navigation);
                            }
                      }>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={
                          darkModeAvailable
                            ? styles.backArrowIconDark
                            : styles.backArrowIcon
                        }
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Default Output"
          component={DefaultOutput}
          options={({navigation}) => ({
            title: t('Default_Output'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                {isFavorite ? (
                  <View accessible={true}>
                    <TouchableOpacity
                      style={styles.backArrow}
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              navigation.navigate('Settings');
                            }
                          : () => {
                              navigation.navigate('Settings');
                            }
                      }>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={
                          darkModeAvailable
                            ? styles.backArrowIconDark
                            : styles.backArrowIcon
                        }
                      />
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View accessible={true}>
                    <TouchableOpacity
                      style={styles.backArrow}
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              navigation.navigate('Default Input');
                            }
                          : () => {
                              navigation.navigate('Default Input');
                            }
                      }>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={
                          darkModeAvailable
                            ? styles.backArrowIconDark
                            : styles.backArrowIcon
                        }
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Voice"
          component={Voice}
          options={({navigation}) => ({
            title: t('Voice_settings'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Settings');
                          }
                        : () => {
                            navigation.navigate('Settings');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Contrast"
          component={Contrast}
          options={({navigation}) => ({
            title: t('Contrast'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Settings');
                          }
                        : () => {
                            navigation.navigate('Settings');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Avatar"
          component={Avatar}
          options={({navigation}) => ({
            title: t('Avatar_settings'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Settings');
                          }
                        : () => {
                            navigation.navigate('Settings');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Avatar New"
          component={AvatarNew}
          options={({navigation}) => ({
            title: t('Avatar_settings'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                {isFavoriteAvatar === '1' ? (
                  <View accessible={true}>
                    <TouchableOpacity
                      style={styles.backArrow}
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              navigation.navigate('Default Output');
                            }
                          : () => {
                              navigation.navigate('Default Output');
                            }
                      }>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={
                          darkModeAvailable
                            ? styles.backArrowIconDark
                            : styles.backArrowIcon
                        }
                      />
                    </TouchableOpacity>
                  </View>
                ) : isFavoriteAvatar === '2' ? (
                  <View accessible={true}>
                    <TouchableOpacity
                      style={styles.backArrow}
                      onPress={
                        Platform.OS == 'web'
                          ? () => {
                              navigation.navigate('Output Method');
                            }
                          : () => {
                              navigation.navigate('Output Method');
                            }
                      }>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={
                          darkModeAvailable
                            ? styles.backArrowIconDark
                            : styles.backArrowIcon
                        }
                      />
                    </TouchableOpacity>
                  </View>
                ) : (
                  <View accessible={true}>
                    <TouchableOpacity
                      style={styles.backArrow}
                      onPress={
                        Platform.OS == 'web'
                          ? async () => {
                              navigation.navigate('Archive Detail', {
                                archiveID: await AsyncStorage.getItem(
                                  'archiveID',
                                ),
                              });
                            }
                          : async () => {
                              navigation.navigate('Archive Detail', {
                                archiveID: await AsyncStorage.getItem(
                                  'archiveID',
                                ),
                              });
                            }
                      }>
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={
                          darkModeAvailable
                            ? styles.backArrowIconDark
                            : styles.backArrowIcon
                        }
                      />
                    </TouchableOpacity>
                  </View>
                )}
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Languages Settings Input"
          component={LanguagesInput}
          options={({navigation}) => ({
            title: t('Languages_Settings'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Default Input');
                          }
                        : () => {
                            navigation.navigate('Default Input');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Languages Settings Output"
          component={LanguagesOutput}
          options={({navigation}) => ({
            title: t('Languages_Settings'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Default Output');
                          }
                        : () => {
                            navigation.navigate('Default Output');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Archive"
          component={Archive}
          options={({navigation}) => ({
            title: t('Archive'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Start');
                          }
                        : () => {
                            navigation.navigate('Start');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Archive Detail"
          component={ArchiveDetail}
          getId={({params}) => params.archiveID}
          options={({navigation}) => ({
            title: t('Archive_Detail'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Archive');
                          }
                        : () => {
                            navigation.navigate('Archive');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Input Method"
          component={InputMethod}
          options={({navigation}) => ({
            title: t('Input_Method'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            inputBack(navigation);
                          }
                        : () => {
                            inputBack(navigation);
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Input Language Method"
          component={InputLanguageMethod}
          options={({navigation}) => ({
            title: t('Languages_Settings'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                {/*<View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Input Method');
                          }
                        : () => {
                            navigation.navigate('Input Method');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>*/}
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Output Language Method"
          component={OutputLanguageMethod}
          options={({navigation}) => ({
            title: t('Languages_Settings'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                {/*<View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Output Method');
                          }
                        : () => {
                            navigation.navigate('Output Method');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>*/}
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Input Text"
          component={InputText}
          options={({navigation}) => ({
            title: t('Write'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Start');
                          }
                        : () => {
                            navigation.navigate('Start');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Input Audio"
          component={InputAudio}
          options={({navigation}) => ({
            title: t('Record_speech'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Input Method');
                          }
                        : () => {
                            navigation.navigate('Input Method');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Input Audio Content"
          component={InputAudioContent}
          options={({navigation}) => ({
            title: t('Record_content'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Input Audio');
                          }
                        : () => {
                            navigation.navigate('Input Audio');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Input Audio Content New"
          component={InputAudioContentNew}
          options={({navigation}) => ({
            title: t('Record_content'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Start');
                          }
                        : () => {
                            navigation.navigate('Start');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Upload Video"
          component={UploadVideo}
          options={({navigation}) => ({
            title: t('Upload_Video'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Start');
                          }
                        : () => {
                            navigation.navigate('Start');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Upload Video New"
          component={UploadVideoNew}
          options={({navigation}) => ({
            title: t('Upload_Video'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Start');
                          }
                        : () => {
                            navigation.navigate('Start');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Upload Video Newest"
          component={UploadVideoNewest}
          options={({navigation}) => ({
            title: t('Upload_Video'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Start');
                          }
                        : () => {
                            navigation.navigate('Start');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Input Camera"
          component={InputCamera}
          options={({navigation}) => ({
            title: t('Video_input'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Input Method');
                          }
                        : () => {
                            navigation.navigate('Input Method');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Input Camera Content"
          component={InputCameraContent}
          options={({navigation}) => ({
            title: t('Record_Video'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={
                      Platform.OS == 'web'
                        ? () => {
                            navigation.navigate('Start');
                          }
                        : () => {
                            navigation.navigate('Start');
                          }
                    }>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
        <Stack.Screen
          name="Output Method"
          component={OutputMethod}
          options={({navigation}) => ({
            title: t('Output_Method'),
            headerTintColor: darkModeAvailable ? '#ecb853' : '#fff',
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: darkModeAvailable ? '#000000' : '#D05B5B',
            },
            headerLeft: () => (
              <>
                <View accessible={true}>
                  <TouchableOpacity
                    style={styles.backArrow}
                    onPress={() => {
                      navigation.navigate('Input Method');
                    }}>
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      style={
                        darkModeAvailable
                          ? styles.backArrowIconDark
                          : styles.backArrowIcon
                      }
                    />
                  </TouchableOpacity>
                </View>
              </>
            ),
          })}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

const styles = StyleSheet.create({
  sectionContainer: {
    marginTop: 32,
    paddingHorizontal: 24,
  },
  sectionTitle: {
    fontSize: 24,
    fontWeight: '600',
  },
  sectionDescription: {
    marginTop: 8,
    fontSize: 18,
    fontWeight: '400',
  },
  highlight: {
    fontWeight: '700',
  },
  submenuHeader: {
    padding: Platform.OS === 'web' ? '20px' : 20,
  },
  backArrow: {
    marginLeft: Platform.OS === 'web' ? '15px' : 15,
    color: '#ffffff',
  },
  backArrowIcon: {
    color: '#ffffff',
  },
  backArrowIconDark: {
    color: '#ecb853',
  },
  menuImage: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    marginRight: Platform.OS === 'web' ? '15px' : 15,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  menuMainUserWeb: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginRight: Platform.OS === 'web' ? '15px' : 15,
    color: '#ffffff',
  },
  menuMainUser: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '250px' : 250,
    top: Platform.OS === 'web' ? '30px' : 30,
    right: Platform.OS === 'web' ? '20px' : 20,
    position: 'absolute',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 3px 0px #000',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,
    elevation: 11,
    padding: Platform.OS === 'web' ? '20px' : 20,
  },
  menuMainUserDark: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '250px' : 250,
    top: Platform.OS === 'web' ? '30px' : 30,
    right: Platform.OS === 'web' ? '20px' : 20,
    position: 'absolute',
    backgroundColor: '#000',
    boxShadow: '0px 0px 3px 0px #000',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,
    elevation: 11,
    padding: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderWidth: 2,
    borderColor: '#ecb853',
  },
  menuMainUserHidden: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '250px' : 250,
    top: Platform.OS === 'web' ? '30px' : 30,
    right: Platform.OS === 'web' ? '20px' : 20,
    position: 'absolute',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 3px 0px #000',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,
    elevation: 11,
    padding: Platform.OS === 'web' ? '20px' : 20,
    opacity: Platform.OS === 'web' ? '0' : 0,
  },
  menuItemElement: {
    //paddingTop: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
  },
  menuItemElementDark: {
    //paddingTop: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
    color: '#ecb853',
  },
  menuItemElementWeb: {
    paddingRight: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
    color: '#ffffff',
  },
  menuItemElementWebDark: {
    paddingRight: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
    color: '#ecb853',
  },
});

export default App;
