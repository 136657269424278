/**
 * Languages Output
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable,
  TouchableOpacity,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import {
  DEFAULT_OUTPUT_LANGUAGE_TYPE,
  DEFAULT_OUTPUT_TYPE,
  DEFAULT_INPUT_LANGUAGE_TYPE,
  DEFAULT_INPUT_TYPE,
  SELECT_INPUT_LANGUAGE_TYPE,
  SELECT_OUTPUT_LANGUAGE_TYPE,
  SPEECH,
  TEXT,
  AVATAR,
  CAMERA,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_ITALIAN,
  LANGUAGE_LUXEMBOURG_FRENCH,
  LANGUAGE_LUXEMBOURG_GERMAN,
  LANGUAGE_DUTCH,
  LANGUAGE_SUIZZE_FRENCH,
  LANGUAGE_SUIZZE_ITALIAN,
  LANGUAGE_SUIZZE_GERMAN,
  LANGUAGE_BRITISH,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  USERTOKEN_KEY,
  DEVELOPMENT,
  TEST,
} from '../../../constants/constants';
import {useTranslation} from 'react-i18next';
import axios from 'axios';

/* Icons */
import EarthIcon from './../../../assets/illustrationheaderlanguage.png';
import EarthIconDark from './../../../assets/illustrationheaderlanguagedark.png';
import FlagGermanIcon from './../../../assets/flaggerman.png';
import FlagDutchIcon from './../../../assets/flagdutch.png';
import FlagFrenchIcon from './../../../assets/flagfrench.png';
import FlagItalianIcon from './../../../assets/flagitaly.png';
import FlagGreeceIcon from './../../../assets/flaggreece.png';
import FlagGreatBritianIcon from './../../../assets/flaggreatbritain.png';
import FlagLuxembourgIcon from './../../../assets/flagluxembourg.png';
import FlagSuizzeIcon from './../../../assets/flagsuizze.png';

const LanguagesOutput = ({navigation}): Node => {
  const [french, setFrench] = useState(false);
  const [german, setGerman] = useState(false);
  const [greek, setGreek] = useState(false);
  const [italian, setItalian] = useState(false);
  const [luxembourgFrench, setLuxembourgFrench] = useState(false);
  const [luxembourgGerman, setLuxembourgGerman] = useState(false);
  const [dutch, setDutch] = useState(false);
  const [suizzeFrench, setSuizzeFrench] = useState(false);
  const [suizzeItalian, setSuizzeItalian] = useState(false);
  const [suizzeGerman, setSuizzeGerman] = useState(false);
  const [british, setBritish] = useState(false);
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [isSignLanguage, setIsSignLanguage] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [userDataAll, setUserDataAll] = useState({});
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    const checkSelectedTypeDefault = async () => {
      const defaultSelectedType = await AsyncStorage.getItem(
        DEFAULT_OUTPUT_TYPE,
      );

      defaultSelectedType === SPEECH
        ? setIsSignLanguage(false)
        : defaultSelectedType === TEXT
        ? setIsSignLanguage(false)
        : defaultSelectedType === AVATAR
        ? setIsSignLanguage(true)
        : setIsSignLanguage(false);
    };
    const userDataSettings = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      axios
        .get(TEST + 'user', {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(response => {
          if (response.status === 200) {
            console.log(response.data);
            setUserDataAll(response.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    };
    checkSelectedTypeDefault();
    checkDarkModeDefault();
    checkSizeDefault();
    userDataSettings();
  }, [isFocused]);
  const updateUserSettings = async (
    l1,
    l2,
    l3,
    l4,
    l5,
    l6,
    l7,
    l8,
    l9,
    l10,
    l11,
  ) => {
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

    let allUserData = JSON.parse(userDataAll.settings);
    if (l1 === true) {
      allUserData.output_language_name = LANGUAGE_FRENCH;
    }
    if (l2 === true) {
      allUserData.output_language_name = LANGUAGE_GERMAN;
    }
    if (l3 === true) {
      allUserData.output_language_name = LANGUAGE_GREEK;
    }
    if (l4 === true) {
      allUserData.output_language_name = LANGUAGE_ITALIAN;
    }
    if (l5 === true) {
      allUserData.output_language_name = LANGUAGE_LUXEMBOURG_FRENCH;
    }
    if (l6 === true) {
      allUserData.output_language_name = LANGUAGE_LUXEMBOURG_GERMAN;
    }
    if (l7 === true) {
      allUserData.output_language_name = LANGUAGE_DUTCH;
    }
    if (l8 === true) {
      allUserData.output_language_name = LANGUAGE_SUIZZE_FRENCH;
    }
    if (l9 === true) {
      allUserData.output_language_name = LANGUAGE_SUIZZE_ITALIAN;
    }
    if (l10 === true) {
      allUserData.output_language_name = LANGUAGE_SUIZZE_GERMAN;
    }
    if (l11 === true) {
      allUserData.output_language_name = LANGUAGE_BRITISH;
    }
    axios
      .post(
        TEST + 'user/settings',
        {
          settings: JSON.stringify(allUserData),
        },
        {
          headers: {
            //...form.getHeaders(),
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userKeyToken}`,
          },
        },
      )
      .then(res => {
        if (res.status === 200) {
          console.log(res.data);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const setActiveDefaultOutput = async (
    l1,
    l2,
    l3,
    l4,
    l5,
    l6,
    l7,
    l8,
    l9,
    l10,
    l11,
  ) => {
    setFrench(l1);
    setGerman(l2);
    setGreek(l3);
    setItalian(l4);
    setLuxembourgFrench(l5);
    setLuxembourgGerman(l6);
    setDutch(l7);
    setSuizzeFrench(l8);
    setSuizzeItalian(l9);
    setSuizzeGerman(l10);
    setBritish(l11);

    await AsyncStorage.setItem(
      DEFAULT_OUTPUT_LANGUAGE_TYPE,
      l1 == true
        ? LANGUAGE_FRENCH
        : l2 == true
        ? LANGUAGE_GERMAN
        : l3 == true
        ? LANGUAGE_GREEK
        : l4 == true
        ? LANGUAGE_ITALIAN
        : l5 == true
        ? LANGUAGE_LUXEMBOURG_FRENCH
        : l6 == true
        ? LANGUAGE_LUXEMBOURG_GERMAN
        : l7 == true
        ? LANGUAGE_DUTCH
        : l8 == true
        ? LANGUAGE_SUIZZE_FRENCH
        : l9 == true
        ? LANGUAGE_SUIZZE_ITALIAN
        : l10 == true
        ? LANGUAGE_SUIZZE_GERMAN
        : l11 == true
        ? LANGUAGE_BRITISH
        : '',
    );

    await AsyncStorage.setItem(
      SELECT_OUTPUT_LANGUAGE_TYPE,
      l1 == true
        ? LANGUAGE_FRENCH
        : l2 == true
        ? LANGUAGE_GERMAN
        : l3 == true
        ? LANGUAGE_GREEK
        : l4 == true
        ? LANGUAGE_ITALIAN
        : l5 == true
        ? LANGUAGE_LUXEMBOURG_FRENCH
        : l6 == true
        ? LANGUAGE_LUXEMBOURG_GERMAN
        : l7 == true
        ? LANGUAGE_DUTCH
        : l8 == true
        ? LANGUAGE_SUIZZE_FRENCH
        : l9 == true
        ? LANGUAGE_SUIZZE_ITALIAN
        : l10 == true
        ? LANGUAGE_SUIZZE_GERMAN
        : l11 == true
        ? LANGUAGE_BRITISH
        : '',
    );

    /*await AsyncStorage.setItem(
      DEFAULT_INPUT_LANGUAGE_TYPE,
      l1 == true
        ? LANGUAGE_FRENCH
        : l2 == true
        ? LANGUAGE_GERMAN
        : l3 == true
        ? LANGUAGE_GREEK
        : l4 == true
        ? LANGUAGE_ITALIAN
        : l5 == true
        ? LANGUAGE_LUXEMBOURG_FRENCH
        : l6 == true
        ? LANGUAGE_LUXEMBOURG_GERMAN
        : l7 == true
        ? LANGUAGE_DUTCH
        : l8 == true
        ? LANGUAGE_SUIZZE_FRENCH
        : l9 == true
        ? LANGUAGE_SUIZZE_ITALIAN
        : l10 == true
        ? LANGUAGE_SUIZZE_GERMAN
        : l11 == true
        ? LANGUAGE_BRITISH
        : '',
    );*/

    /*await AsyncStorage.setItem(
      SELECT_INPUT_LANGUAGE_TYPE,
      l1 == true
        ? LANGUAGE_FRENCH
        : l2 == true
        ? LANGUAGE_GERMAN
        : l3 == true
        ? LANGUAGE_GREEK
        : l4 == true
        ? LANGUAGE_ITALIAN
        : l5 == true
        ? LANGUAGE_LUXEMBOURG_FRENCH
        : l6 == true
        ? LANGUAGE_LUXEMBOURG_GERMAN
        : l7 == true
        ? LANGUAGE_DUTCH
        : l8 == true
        ? LANGUAGE_SUIZZE_FRENCH
        : l9 == true
        ? LANGUAGE_SUIZZE_ITALIAN
        : l10 == true
        ? LANGUAGE_SUIZZE_GERMAN
        : l11 == true
        ? LANGUAGE_BRITISH
        : '',
    );*/
  };

  useEffect(() => {
    const OutputTypeDefault = async () => {
      const defaultOutputLanguageType = await AsyncStorage.getItem(
        DEFAULT_OUTPUT_LANGUAGE_TYPE,
      );
      defaultOutputLanguageType == LANGUAGE_FRENCH
        ? setActiveDefaultOutput(
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          )
        : defaultOutputLanguageType == LANGUAGE_GERMAN
        ? setActiveDefaultOutput(
            false,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          )
        : defaultOutputLanguageType == LANGUAGE_GREEK
        ? setActiveDefaultOutput(
            false,
            false,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          )
        : defaultOutputLanguageType == LANGUAGE_ITALIAN
        ? setActiveDefaultOutput(
            false,
            false,
            false,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          )
        : defaultOutputLanguageType == LANGUAGE_LUXEMBOURG_FRENCH
        ? setActiveDefaultOutput(
            false,
            false,
            false,
            false,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
          )
        : defaultOutputLanguageType == LANGUAGE_LUXEMBOURG_GERMAN
        ? setActiveDefaultOutput(
            false,
            false,
            false,
            false,
            false,
            true,
            false,
            false,
            false,
            false,
            false,
          )
        : defaultOutputLanguageType == LANGUAGE_DUTCH
        ? setActiveDefaultOutput(
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            false,
            false,
            false,
            false,
          )
        : defaultOutputLanguageType == LANGUAGE_SUIZZE_FRENCH
        ? setActiveDefaultOutput(
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            false,
            false,
            false,
          )
        : defaultOutputLanguageType == LANGUAGE_SUIZZE_ITALIAN
        ? setActiveDefaultOutput(
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            false,
            false,
          )
        : defaultOutputLanguageType == LANGUAGE_SUIZZE_GERMAN
        ? setActiveDefaultOutput(
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
            false,
          )
        : defaultOutputLanguageType == LANGUAGE_BRITISH
        ? setActiveDefaultOutput(
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
          )
        : setActiveDefaultOutput(
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
          );
    };
    OutputTypeDefault();
  });
  const saveData = () => {
    navigation.navigate('Default Output');
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel="Illustration - Languages">
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? EarthIconDark
                    : EarthIcon
                  : darkModeAvailable
                  ? 'illustrationheaderlanguagedark'
                  : 'illustrationheaderlanguage',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View style={styles.languagesContainer} accessible={true}>
          <TouchableOpacity
            style={styles.languagesElement}
            accessible={true}
            accessibilityLabel={t('French') + ' - ' + 'Icon'}
            onPress={() => {
              setActiveDefaultOutput(
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              );
              updateUserSettings(
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              );
            }}>
            <Image
              width={Platform.OS == 'web' ? '35px' : 35}
              height={Platform.OS == 'web' ? '35px' : 35}
              source={{
                uri: Platform.OS == 'web' ? FlagFrenchIcon : 'flagfrench',
              }}
              style={
                darkModeAvailable
                  ? styles.flagLanguageDark
                  : styles.flagLanguage
              }
              resizeMode="contain"
            />
            <TouchableOpacity
              style={
                french
                  ? darkModeAvailable
                    ? styles.settingsTextActiveDark
                    : styles.settingsTextActive
                  : darkModeAvailable
                  ? styles.settingsTextDark
                  : styles.settingsText
              }
              onPress={() => {
                setActiveDefaultOutput(
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                );
                updateUserSettings(
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                );
              }}
              accessible={true}
              accessibilityLabel={t('French')}
              accessibilityHint="Select french language validation">
              <View accessible={true} accessibilityLabel={t('French')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {isSignLanguage ? 'LSF' : t('French')}
                </Text>
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.languagesElement}
            accessible={true}
            accessibilityLabel={t('German') + ' - ' + 'Icon'}
            onPress={() => {
              setActiveDefaultOutput(
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              );
              updateUserSettings(
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              );
            }}>
            <Image
              width={Platform.OS == 'web' ? '35px' : 35}
              height={Platform.OS == 'web' ? '35px' : 35}
              source={{
                uri: Platform.OS == 'web' ? FlagGermanIcon : 'flaggerman',
              }}
              style={
                darkModeAvailable
                  ? styles.flagLanguageDark
                  : styles.flagLanguage
              }
              resizeMode="contain"
            />
            <TouchableOpacity
              style={
                german
                  ? darkModeAvailable
                    ? styles.settingsTextActiveDark
                    : styles.settingsTextActive
                  : darkModeAvailable
                  ? styles.settingsTextDark
                  : styles.settingsText
              }
              onPress={() => {
                setActiveDefaultOutput(
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                );
                updateUserSettings(
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                );
              }}
              accessible={true}
              accessibilityLabel={t('German')}
              accessibilityHint="Select german language validation">
              <View accessible={true} accessibilityLabel={t('German')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {isSignLanguage ? 'DGS' : t('German')}
                </Text>
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
        </View>
        <View style={styles.languagesContainer} accessible={true}>
          <TouchableOpacity
            style={styles.languagesElement}
            accessible={true}
            accessibilityLabel={t('Greek') + ' - ' + 'Icon'}
            onPress={() => {
              setActiveDefaultOutput(
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              );
              updateUserSettings(
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              );
            }}>
            <Image
              width={Platform.OS == 'web' ? '35px' : 35}
              height={Platform.OS == 'web' ? '35px' : 35}
              source={{
                uri: Platform.OS == 'web' ? FlagGreeceIcon : 'flaggreece',
              }}
              style={
                darkModeAvailable
                  ? styles.flagLanguageDark
                  : styles.flagLanguage
              }
              resizeMode="contain"
            />
            <TouchableOpacity
              style={
                greek
                  ? darkModeAvailable
                    ? styles.settingsTextActiveDark
                    : styles.settingsTextActive
                  : darkModeAvailable
                  ? styles.settingsTextDark
                  : styles.settingsText
              }
              onPress={() => {
                setActiveDefaultOutput(
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                );
                updateUserSettings(
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                );
              }}
              accessible={true}
              accessibilityLabel={t('Greek')}
              accessibilityHint="Select greek language validation">
              <View accessible={true} accessibilityLabel={t('Greek')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {isSignLanguage ? 'GSL' : t('Greek')}
                </Text>
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.languagesElement}
            accessible={true}
            accessibilityLabel={t('Italian') + ' - ' + 'Icon'}
            onPress={() => {
              setActiveDefaultOutput(
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              );
              updateUserSettings(
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
              );
            }}>
            <Image
              width={Platform.OS == 'web' ? '35px' : 35}
              height={Platform.OS == 'web' ? '35px' : 35}
              source={{
                uri: Platform.OS == 'web' ? FlagItalianIcon : 'flagitaly',
              }}
              style={
                darkModeAvailable
                  ? styles.flagLanguageDark
                  : styles.flagLanguage
              }
              resizeMode="contain"
            />
            <TouchableOpacity
              style={
                italian
                  ? darkModeAvailable
                    ? styles.settingsTextActiveDark
                    : styles.settingsTextActive
                  : darkModeAvailable
                  ? styles.settingsTextDark
                  : styles.settingsText
              }
              onPress={() => {
                setActiveDefaultOutput(
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                );
                updateUserSettings(
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                );
              }}
              accessible={true}
              accessibilityLabel={t('Italian')}
              accessibilityHint="Select italian language validation">
              <View accessible={true} accessibilityLabel={t('Italian')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {isSignLanguage ? 'LIS' : t('Italian')}
                </Text>
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
        </View>
        {/*
          <View
            style={styles.languagesElement}
            accessible={true}
            accessibilityLabel={
              t('French') +
              ' and ' +
              t('German') +
              ' from Luxembourg - ' +
              'Icon'
            }>
            <Image
              width={Platform.OS == 'web' ? '35px' : 35}
              height={Platform.OS == 'web' ? '35px' : 35}
              source={{
                uri:
                  Platform.OS == 'web' ? FlagLuxembourgIcon : 'flagluxembourg',
              }}
              style={
                darkModeAvailable
                  ? styles.flagLanguageDark
                  : styles.flagLanguage
              }
              resizeMode="contain"
            />
            <TouchableOpacity
              style={
                luxembourgFrench
                  ? styles.settingsTextActive
                  : styles.settingsText
              }
              onPress={() =>
                setActiveDefaultOutput(
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                )
              }
              accessible={true}
              accessibilityLabel={t('French')}
              accessibilityHint="Select french language validation">
              <View accessible={true} accessibilityLabel={t('French')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('French')}
                </Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={
                luxembourgGerman
                  ? styles.settingsTextActive
                  : styles.settingsText
              }
              onPress={() =>
                setActiveDefaultOutput(
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                  false,
                )
              }
              accessible={true}
              accessibilityLabel={t('German')}
              accessibilityHint="Select german language validation">
              <View accessible={true} accessibilityLabel={t('German')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('German')}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>*/}
        <View style={styles.languagesContainer} accessible={true}>
          <TouchableOpacity
            style={styles.languagesElement}
            accessible={true}
            accessibilityLabel={
              t('French') +
              ' - ' +
              t('Italian') +
              ' - ' +
              t('Suizze_German') +
              ' from Switzerland - ' +
              'Icon'
            }
            onPress={() => {
              setActiveDefaultOutput(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
              );
              updateUserSettings(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
              );
            }}>
            <Image
              width={Platform.OS == 'web' ? '35px' : 35}
              height={Platform.OS == 'web' ? '35px' : 35}
              source={{
                uri: Platform.OS == 'web' ? FlagSuizzeIcon : 'flagsuizze',
              }}
              style={
                darkModeAvailable
                  ? styles.flagLanguageDark
                  : styles.flagLanguage
              }
              resizeMode="contain"
            />
            {/*<TouchableOpacity
              style={
                suizzeFrench ? styles.settingsTextActive : styles.settingsText
              }
              onPress={() =>
                setActiveDefaultOutput(
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                )
              }
              accessible={true}
              accessibilityLabel={t('French')}
              accessibilityHint="Select french language validation">
              <View accessible={true} accessibilityLabel={t('French')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('French')}
                </Text>
              </View>
            </TouchableOpacity>*/}
            {/*<TouchableOpacity
              style={
                suizzeItalian ? styles.settingsTextActive : styles.settingsText
              }
              onPress={() =>
                setActiveDefaultOutput(
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                )
              }
              accessible={true}
              accessibilityLabel={t('Italian')}
              accessibilityHint="Select italian language validation">
              <View accessible={true} accessibilityLabel={t('Italian')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Italian')}
                </Text>
              </View>
            </TouchableOpacity>*/}
            <TouchableOpacity
              style={
                suizzeGerman
                  ? darkModeAvailable
                    ? styles.settingsTextActiveDark
                    : styles.settingsTextActive
                  : darkModeAvailable
                  ? styles.settingsTextDark
                  : styles.settingsText
              }
              onPress={() => {
                setActiveDefaultOutput(
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                );
                updateUserSettings(
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                );
              }}
              accessible={true}
              accessibilityLabel={t('Suizze_German')}
              accessibilityHint="Select suizze german language validation">
              <View accessible={true} accessibilityLabel={t('Suizze_German')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {isSignLanguage ? 'DSGS' : t('Suizze_German')}
                </Text>
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.languagesElement}
            accessible={true}
            accessibilityLabel={t('English') + ' - ' + 'Icon'}
            onPress={() => {
              setActiveDefaultOutput(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
              );
              updateUserSettings(
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                false,
                true,
              );
            }}>
            <Image
              width={Platform.OS == 'web' ? '35px' : 35}
              height={Platform.OS == 'web' ? '35px' : 35}
              source={{
                uri:
                  Platform.OS == 'web'
                    ? FlagGreatBritianIcon
                    : 'flaggreatbritain',
              }}
              style={
                darkModeAvailable
                  ? styles.flagLanguageDark
                  : styles.flagLanguage
              }
              resizeMode="contain"
            />
            <TouchableOpacity
              style={
                british
                  ? darkModeAvailable
                    ? styles.settingsTextActiveDark
                    : styles.settingsTextActive
                  : darkModeAvailable
                  ? styles.settingsTextDark
                  : styles.settingsText
              }
              onPress={() => {
                setActiveDefaultOutput(
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                );
                updateUserSettings(
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                );
              }}
              accessible={true}
              accessibilityLabel={t('English')}
              accessibilityHint="Select english language validation">
              <View accessible={true} accessibilityLabel={t('English')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {isSignLanguage ? 'BSL' : t('English')}
                </Text>
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
        </View>
        <View style={styles.languagesContainer} accessible={true}>
          <TouchableOpacity
            style={styles.languagesElement}
            accessible={true}
            accessibilityLabel={t('Dutch') + ' - ' + 'Icon'}
            onPress={() => {
              setActiveDefaultOutput(
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
              );
              updateUserSettings(
                false,
                false,
                false,
                false,
                false,
                false,
                true,
                false,
                false,
                false,
                false,
              );
            }}>
            <Image
              width={Platform.OS == 'web' ? '35px' : 35}
              height={Platform.OS == 'web' ? '35px' : 35}
              source={{
                uri: Platform.OS == 'web' ? FlagDutchIcon : 'flagdutch',
              }}
              style={
                darkModeAvailable
                  ? styles.flagLanguageDark
                  : styles.flagLanguage
              }
              resizeMode="contain"
            />
            <TouchableOpacity
              style={
                dutch
                  ? darkModeAvailable
                    ? styles.settingsTextActiveDark
                    : styles.settingsTextActive
                  : darkModeAvailable
                  ? styles.settingsTextDark
                  : styles.settingsText
              }
              onPress={() => {
                setActiveDefaultOutput(
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                );
                updateUserSettings(
                  false,
                  false,
                  false,
                  false,
                  false,
                  false,
                  true,
                  false,
                  false,
                  false,
                  false,
                );
              }}
              accessible={true}
              accessibilityLabel={t('Dutch')}
              accessibilityHint="Select dutch language validation">
              <View accessible={true} accessibilityLabel={t('Dutch')}>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {isSignLanguage ? 'NGT' : t('Dutch')}
                </Text>
              </View>
            </TouchableOpacity>
          </TouchableOpacity>
        </View>
        <View>
          <Pressable
            style={darkModeAvailable ? styles.buttonDark : styles.button}
            accessible={true}
            accessibilityLabel={t('Save')}
            accessibilityHint="Save language input validation"
            onPress={saveData}>
            <Text
              style={darkModeAvailable ? styles.textDark : styles.textButton}>
              {t('Save')}
            </Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  languagesContainer: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '30px' : 30,
  },
  languagesElement: {
    width: Platform.OS === 'web' ? '150px' : 150, // 100 in previous scenario
    //resizeMode: 'center',
    alignItems: 'center',
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  additionalOption: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '125px' : 125,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  settingsBackgroundColor: {
    backgroundColor: '#ECECEC',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //flex: 1,
    flexDirection: 'column',
    margin: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  settingsText: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
    width: Platform.OS === 'web' ? '95px' : 95,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#BEBEBE',
    borderRadius: Platform.OS === 'web' ? '2.5px' : 2.5,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsTextDark: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
    width: Platform.OS === 'web' ? '95px' : 95,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '2.5px' : 2.5,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsTextActive: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
    width: Platform.OS === 'web' ? '95px' : 95,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#EDB854',
    borderRadius: Platform.OS === 'web' ? '2.5px' : 2.5,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsTextActiveDark: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
    width: Platform.OS === 'web' ? '95px' : 95,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    backgroundColor: '#000',
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: Platform.OS === 'web' ? '2.5px' : 2.5,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  logo: {
    width: Platform.OS === 'web' ? '150px' : 150,
    height: Platform.OS === 'web' ? '150px' : 150,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  flagLanguage: {
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '35px' : 35,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  flagLanguageDark: {
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '30px' : 30,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 10,
  },
  button: {
    backgroundColor: '#D05B5B',
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textButton: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textDark: {
    color: '#ecb853',
  },
});

export default LanguagesOutput;
