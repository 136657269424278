/**
 * Archive Detail
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Pressable,
  TouchableOpacity,
  Platform,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
/*import resolveAssetSource from 'resolveAssetSource';
import Sound from 'react-native-sound';*/
import axios from 'axios';
import {
  SPEECH,
  TEXT,
  CAMERA,
  AVATAR,
  SELECT_INPUT_TYPE,
  SELECT_INPUT_LANGUAGE_TYPE,
  SELECT_OUTPUT_TYPE,
  SELECT_OUTPUT_LANGUAGE_TYPE,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_ITALIAN,
  LANGUAGE_LUXEMBOURG_FRENCH,
  LANGUAGE_LUXEMBOURG_GERMAN,
  LANGUAGE_DUTCH,
  LANGUAGE_SUIZZE_FRENCH,
  LANGUAGE_SUIZZE_ITALIAN,
  LANGUAGE_SUIZZE_GERMAN,
  LANGUAGE_BRITISH,
  CONTRAST_MODE_TYPE,
  TEXT_VALUE,
  AUDIO_RECORDING,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  DEFAULT_INPUT_TYPE,
  DEFAULT_VALUE_TYPE,
  USERTOKEN_KEY,
  AVATAR_CHOICE,
  APP_LANGUAGE,
  AVATAR_SHIRT,
  AVATAR_BACKGROUND,
  DEVELOPMENT,
  DEVELOPMENT_TWO,
  TEST,
  TEST_TWO,
} from '../../constants/constants';

/* Icons */
import Logo from './../../assets/easierlogowithtext.png';
import IconArrow from './../../assets/iconarrow.png';
import TranslateIcon from './../../assets/illustrationheaderoutputmethod.png';
import TextIcon from './../../assets/icontext.png';
import TextIconDark from './../../assets/icontextdark.png';
import RecordIcon from './../../assets/iconspeech.png';
import RecordIconDark from './../../assets/iconspeechdark.png';
import CameraIcon from './../../assets/iconcamera.png';
import CameraIconDark from './../../assets/iconcameradark.png';
import AvatarIcon from './../../assets/iconavatar.png';
import AvatarIconDark from './../../assets/iconavatardark.png';
import PasteIcon from './../../assets/iconcopy.png';
import PasteIconDark from './../../assets/iconcopydark.png';
import DeleteIcon from './../../assets/icondelete.png';
import DeleteIconDark from './../../assets/icondeletedark.png';
import SaveIcon from './../../assets/iconsave.png';
import SaveIconDark from './../../assets/iconsavedark.png';
import ShareIcon from './../../assets/iconshare.png';
import ShareIconDark from './../../assets/iconsharedark.png';
import SettingsIcon from './../../assets/illustrationheadersettings.png';
import SettingsIconDark from './../../assets/illustrationheadersettingsdark.png';
import LanguageIcon from './../../assets/iconlanguage.png';
import LanguageIconDark from './../../assets/iconlanguagedark.png';
import FlagGermanIcon from './../../assets/flaggerman.png';
import FlagDutchIcon from './../../assets/flagdutch.png';
import FlagFrenchIcon from './../../assets/flagfrench.png';
import FlagItalianIcon from './../../assets/flagitaly.png';
import FlagGreeceIcon from './../../assets/flaggreece.png';
import FlagGreatBritianIcon from './../../assets/flaggreatbritain.png';
import FlagLuxembourgIcon from './../../assets/flagluxembourg.png';
import FlagSuizzeIcon from './../../assets/flagsuizze.png';
import {useTranslation} from 'react-i18next';
import {Video} from 'expo-av';
//import VideoPlayer from './videoPlayer/Player';
/* Audio and Video */
/*import AudioTest from './../../assets/audios/easiertest.mp4';
import AvatarVideoTest from './../../assets/audios/dgs.mp4';*/
import DGSB0S0 from '../../assets/videos/mockups/DGSB0S0.mp4';
import DGSB1S1 from '../../assets/videos/mockups/DGSB1S1.mp4';
import DGSB1S2 from '../../assets/videos/mockups/DGSB1S2.mp4';
import DGSB1S3 from '../../assets/videos/mockups/DGSB1S3.mp4';
import DGSB2S1 from '../../assets/videos/mockups/DGSB2S1.mp4';
import DGSB2S2 from '../../assets/videos/mockups/DGSB2S2.mp4';
import DGSB2S3 from '../../assets/videos/mockups/DGSB2S3.mp4';
import DGSB3S1 from '../../assets/videos/mockups/DGSB3S1.mp4';
import DGSB3S2 from '../../assets/videos/mockups/DGSB3S2.mp4';
import DGSB3S3 from '../../assets/videos/mockups/DGSB3S3.mp4';
import DSGSB0S0 from '../../assets/videos/mockups/DSGSB0S0.mp4';
import DSGSB1S1 from '../../assets/videos/mockups/DSGSB1S1.mp4';
import DSGSB1S2 from '../../assets/videos/mockups/DSGSB1S2.mp4';
import DSGSB1S3 from '../../assets/videos/mockups/DSGSB1S3.mp4';
import DSGSB2S1 from '../../assets/videos/mockups/DSGSB2S1.mp4';
import DSGSB2S2 from '../../assets/videos/mockups/DSGSB2S2.mp4';
import DSGSB2S3 from '../../assets/videos/mockups/DSGSB2S3.mp4';
import DSGSB3S1 from '../../assets/videos/mockups/DSGSB3S1.mp4';
import DSGSB3S2 from '../../assets/videos/mockups/DSGSB3S2.mp4';
import DSGSB3S3 from '../../assets/videos/mockups/DSGSB3S3.mp4';
import LSFB0S0 from '../../assets/videos/mockups/LSFB0S0.mp4';
import LSFB1S1 from '../../assets/videos/mockups/LSFB1S1.mp4';
import LSFB1S2 from '../../assets/videos/mockups/LSFB1S2.mp4';
import LSFB1S3 from '../../assets/videos/mockups/LSFB1S3.mp4';
import LSFB2S1 from '../../assets/videos/mockups/LSFB2S1.mp4';
import LSFB2S2 from '../../assets/videos/mockups/LSFB2S2.mp4';
import LSFB2S3 from '../../assets/videos/mockups/LSFB2S3.mp4';
import LSFB3S1 from '../../assets/videos/mockups/LSFB3S1.mp4';
import LSFB3S2 from '../../assets/videos/mockups/LSFB3S2.mp4';
import LSFB3S3 from '../../assets/videos/mockups/LSFB3S3.mp4';
import GSLB0S0 from '../../assets/videos/mockups/GSLB0S0.mp4';
import GSLB1S1 from '../../assets/videos/mockups/GSLB1S1.mp4';
import GSLB1S2 from '../../assets/videos/mockups/GSLB1S2.mp4';
import GSLB1S3 from '../../assets/videos/mockups/GSLB1S3.mp4';
import GSLB2S1 from '../../assets/videos/mockups/GSLB2S1.mp4';
import GSLB2S2 from '../../assets/videos/mockups/GSLB2S2.mp4';
import GSLB2S3 from '../../assets/videos/mockups/GSLB2S3.mp4';
import GSLB3S1 from '../../assets/videos/mockups/GSLB3S1.mp4';
import GSLB3S2 from '../../assets/videos/mockups/GSLB3S2.mp4';
import GSLB3S3 from '../../assets/videos/mockups/GSLB3S3.mp4';

const ArchiveDetail = ({navigation, route}): Node => {
  const [speech, setSpeech] = useState(false);
  const [text, setText] = useState(false);
  const [camera, setCamera] = useState(false);
  const [speechInput, setSpeechInput] = useState([]);
  const [textInput, setTextInput] = useState('');
  const [cameraInput, setCameraInput] = useState([]);
  const [audioVideoInput, setAudioVideoInput] = useState();
  const [audioVideoOutput, setAudioVideoOutput] = useState();
  const [speechOutput, setSpeechOutput] = useState(false);
  const [textOutput, setTextOutput] = useState(false);
  const [avatarOutput, setAvatarOutput] = useState(false);
  const [textOutputMessage, setTextOutputMessage] = useState(false);
  const [tabOne, setTabOne] = useState(false);
  const [tabTwo, setTabTwo] = useState(true);
  const [tabThree, setTabThree] = useState(false);
  const [logStart, setLogStart] = useState();
  const [logAffectFusion, setLogAffectFusion] = useState();
  const [logEnd, setLogEnd] = useState();
  const [logOne, setLogOne] = useState();
  const [logTwo, setLogTwo] = useState();
  const [logThree, setLogThree] = useState();
  const [logFour, setLogFour] = useState();
  const [logFive, setLogFive] = useState();
  const [logSix, setLogSix] = useState();
  const [logSeven, setLogSeven] = useState();
  const [logTen, setLogTen] = useState();
  const [logEleven, setLogEleven] = useState();
  const [logThirteen, setLogThirteen] = useState();
  const [logFourteen, setLogFourteen] = useState();
  const [logThreeZeroThree, setLogThreeZeroThree] = useState();
  const [logThreeZeroFour, setLogThreeZeroFour] = useState();
  const [logFourZeroZero, setLogFourZeroZero] = useState();
  const [logFourZeroOne, setLogFourZeroOne] = useState();
  const [logFourZeroTwo, setLogFourZeroTwo] = useState();
  const [logOneTitle, setLogOneTitle] = useState();
  const [logTwoTitle, setLogTwoTitle] = useState();
  const [logThreeTitle, setLogThreeTitle] = useState();
  const [logFourTitle, setLogFourTitle] = useState();
  const [logFiveTitle, setLogFiveTitle] = useState();
  const [logSixTitle, setLogSixTitle] = useState();
  const [logSevenTitle, setLogSevenTitle] = useState();
  const [logTenTitle, setLogTenTitle] = useState();
  const [logElevenTitle, setLogElevenTitle] = useState();
  const [logThirteenTitle, setLogThirteenTitle] = useState();
  const [logFourteenTitle, setLogFourteenTitle] = useState();
  const [logThreeZeroThreeTitle, setLogThreeZeroThreeTitle] = useState();
  const [logThreeZeroFourTitle, setLogThreeZeroFourTitle] = useState();
  const [logFourZeroZeroTitle, setLogFourZeroZeroTitle] = useState();
  const [logFourZeroOneTitle, setLogFourZeroOneTitle] = useState();
  const [logFourZeroTwoTitle, setLogFourZeroTwoTitle] = useState();
  const [language, setLanguage] = useState('');
  const [languageOutput, setLanguageOutput] = useState('');
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [avatarMockupVideo, setAvatarMockupVideo] = useState(DGSB0S0);
  const [archiveData, setArchiveData] = useState({});
  const [modalAvailable, setModalAvailable] = useState(false);
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  const {archiveID} = route.params;
  useEffect(() => {
    const logTranslationData = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      axios
        .get(TEST + 'translation/' + archiveID, {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(response => {
          if (response.status === 200 || response.status === 304) {
            response.data.stepLogs.map(logData => {
              console.log(logData);
              if (logData.converter_id === 102) {
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.text())
                  .then(res => {
                    if (res) {
                      setLogStart(res);
                    } else {
                      setLogStart(t('No_data_available'));
                    }
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogStart(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 101) {
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.blob())
                  .then(res => {
                    // eslint-disable-next-line no-undef
                    const newBlobConverter = new Blob([res], {
                      type: 'video/mp4',
                    });
                    setLogStart(URL.createObjectURL(newBlobConverter));
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogStart();
                  });
              }
              if (logData.converter_id === 100) {
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.blob())
                  .then(res => {
                    // eslint-disable-next-line no-undef
                    const newBlobConverter = new Blob([res], {
                      type: 'video/mp4',
                    });
                    setLogStart(URL.createObjectURL(newBlobConverter));
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogStart();
                  });
              }
              if (logData.converter_id === 202) {
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.text())
                  .then(res => {
                    if (res) {
                      setLogEnd(res);
                    } else {
                      setLogEnd(t('No_data_available'));
                    }
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogEnd(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 201) {
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.blob())
                  .then(res => {
                    // eslint-disable-next-line no-undef
                    const newBlobConverter = new Blob([res], {
                      type: 'video/mp4',
                    });
                    setLogEnd(URL.createObjectURL(newBlobConverter));
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogEnd();
                  });
              }
              if (logData.converter_id === 200) {
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.blob())
                  .then(res => {
                    // eslint-disable-next-line no-undef
                    const newBlobConverter = new Blob([res], {
                      type: 'video/mp4',
                    });
                    setLogEnd(URL.createObjectURL(newBlobConverter));
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogEnd();
                  });
              }
              if (logData.converter_id === 6) {
                setLogSixTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'application/json; charset=us-ascii',
                  },
                })
                  .then(response => response.json())
                  .then(res => {
                    if (res) {
                      setLogAffectFusion(
                        JSON.stringify(res['emotion-fusion'], null, 4),
                      ); // res.emotion_fusion
                      setLogSix(JSON.stringify(res, null, 4));
                    } else {
                      setLogAffectFusion(t('No_data_available'));
                      setLogSix(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogAffectFusion(t('No_data_available'));
                    setLogSix(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 1) {
                setLogOneTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'application/json; charset=us-ascii',
                  },
                })
                  .then(response => response.json())
                  .then(res => {
                    if (res) {
                      /*const rawJsonData = JSON.stringify(res.data, null, 4);
                      const beautyJson = JSON.parse(rawJsonData);*/
                      setLogOne(JSON.stringify(res, null, 4));
                    } else {
                      setLogOne(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogOne(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 2) {
                setLogTwoTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'application/json; charset=us-ascii',
                  },
                })
                  .then(response => response.json())
                  .then(res => {
                    if (res) {
                      /*const rawJsonData = JSON.stringify(res.data, null, 4);
                      const beautyJson = JSON.parse(rawJsonData);*/
                      setLogTwo(JSON.stringify(res, null, 4));
                    } else {
                      setLogTwo(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogTwo(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 3) {
                setLogThreeTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'application/json; charset=us-ascii',
                  },
                })
                  .then(response => response.json())
                  .then(res => {
                    if (res) {
                      /*const rawJsonData = JSON.stringify(res.data, null, 4);
                      const beautyJson = JSON.parse(rawJsonData);*/
                      setLogThree(JSON.stringify(res, null, 4));
                    } else {
                      setLogThree(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogThree(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 4) {
                setLogFourTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'application/json; charset=us-ascii',
                  },
                })
                  .then(response => response.json())
                  .then(res => {
                    if (res) {
                      /*const rawJsonData = JSON.stringify(res.data, null, 4);
                      const beautyJson = JSON.parse(rawJsonData);*/
                      setLogFour(JSON.stringify(res, null, 4));
                    } else {
                      setLogFour(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogFour(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 5) {
                setLogFiveTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'application/json; charset=us-ascii',
                  },
                })
                  .then(response => response.json())
                  .then(res => {
                    if (res) {
                      /*const rawJsonData = JSON.stringify(res.data, null, 4);
                      const beautyJson = JSON.parse(rawJsonData);*/
                      setLogFive(JSON.stringify(res, null, 4));
                    } else {
                      setLogFive(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogFive(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 7) {
                setLogSevenTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.text())
                  .then(res => {
                    if (res) {
                      setLogSeven(res);
                    } else {
                      setLogSeven(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogSeven(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 10) {
                setLogTenTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'video/mp4;charset=UTF-8',
                  },
                })
                  .then(response => response.blob())
                  .then(res => {
                    if (res) {
                      // eslint-disable-next-line no-undef
                      const newBlobConverter = new Blob([res], {
                        type: 'video/mp4',
                      });
                      setLogTen(URL.createObjectURL(newBlobConverter));
                    } else {
                      setLogTen(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogTen(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 11) {
                setLogElevenTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'video/mp4;charset=UTF-8',
                  },
                })
                  .then(response => response.blob())
                  .then(res => {
                    if (res) {
                      // eslint-disable-next-line no-undef
                      const newBlobConverter = new Blob([res], {
                        type: 'video/mp4',
                      });
                      setLogEleven(URL.createObjectURL(newBlobConverter));
                    } else {
                      setLogEleven(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogEleven(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 13) {
                setLogThirteenTitle(logData.converter_name);
                if (text && speechOutput) {
                  fetch(TEST_TWO + logData.media_url, {
                    headers: {
                      Authorization: `Bearer ${userKeyToken}`,
                      Accept: 'text/plain; charset=us-ascii',
                    },
                  })
                    .then(response => response.text())
                    .then(res => {
                      if (res) {
                        setLogThirteen(res);
                      } else {
                        setLogThirteen(t('No_data_available'));
                      }
                      //console.log(res.data);
                    })
                    .catch(e => {
                      console.log(e);
                      console.log('Binary - False - Not access');
                      setLogThirteen(t('No_data_available'));
                    });
                } else {
                  fetch(TEST_TWO + logData.media_url, {
                    headers: {
                      Authorization: `Bearer ${userKeyToken}`,
                      Accept: 'application/json; charset=binary',
                    },
                  })
                    .then(response => response.json())
                    .then(res => {
                      if (res) {
                        setLogThirteen(JSON.stringify(res.text));
                      } else {
                        setLogThirteen(t('No_data_available'));
                      }
                      //console.log(res.data);
                    })
                    .catch(e => {
                      console.log(e);
                      console.log('Binary - False - Not access');
                      setLogThirteen(t('No_data_available'));
                    });
                }
              }
              if (logData.converter_id === 14) {
                setLogFourteenTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.text())
                  .then(res => {
                    if (res) {
                      setLogFourteen(res);
                    } else {
                      setLogFourteen(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogFourteen(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 303) {
                setLogThreeZeroThreeTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'video/mp4;charset=UTF-8',
                  },
                })
                  .then(response => response.blob())
                  .then(res => {
                    if (res) {
                      // eslint-disable-next-line no-undef
                      const newBlobConverter = new Blob([res], {
                        type: 'video/mp4',
                      });
                      setLogThreeZeroThree(
                        URL.createObjectURL(newBlobConverter),
                      );
                    } else {
                      setLogThreeZeroThree(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                  });
              }
              if (logData.converter_id === 304) {
                setLogThreeZeroFourTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.text())
                  .then(res => {
                    if (res) {
                      setLogThreeZeroFour(res);
                    } else {
                      setLogThreeZeroFour(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                    setLogThreeZeroFour(t('No_data_available'));
                  });
              }
              if (logData.converter_id === 400) {
                setLogFourZeroZeroTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'video/mp4;charset=UTF-8',
                  },
                })
                  .then(response => response.blob())
                  .then(res => {
                    if (res) {
                      // eslint-disable-next-line no-undef
                      const newBlobConverter = new Blob([res], {
                        type: 'video/mp4',
                      });
                      setLogFourZeroZero(URL.createObjectURL(newBlobConverter));
                    } else {
                      setLogFourZeroZero(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                  });
              }
              if (logData.converter_id === 401) {
                setLogFourZeroOneTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'video/mp4;charset=UTF-8',
                  },
                })
                  .then(response => response.blob())
                  .then(res => {
                    if (res) {
                      // eslint-disable-next-line no-undef
                      const newBlobConverter = new Blob([res], {
                        type: 'video/mp4',
                      });
                      setLogFourZeroOne(URL.createObjectURL(newBlobConverter));
                    } else {
                      setLogFourZeroOne(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                  });
              }
              if (logData.converter_id === 402) {
                setLogFourZeroTwoTitle(logData.converter_name);
                fetch(TEST_TWO + logData.media_url, {
                  headers: {
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'video/mp4;charset=UTF-8',
                  },
                })
                  .then(response => response.blob())
                  .then(res => {
                    if (res) {
                      // eslint-disable-next-line no-undef
                      const newBlobConverter = new Blob([res], {
                        type: 'video/mp4',
                      });
                      setLogFourZeroTwo(URL.createObjectURL(newBlobConverter));
                    } else {
                      setLogFourZeroTwo(t('No_data_available'));
                    }
                    //console.log(res.data);
                  })
                  .catch(e => {
                    console.log(e);
                    console.log('Binary - False - Not access');
                  });
              }
            });
          }
        })
        .catch(e => {
          console.log(e);
        });
    };
    logTranslationData();
  }, [text, speechOutput, t, archiveID]);
  useEffect(() => {
    const archiveDataBackend = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      axios
        .get(TEST + 'translation/' + archiveID, {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(response => {
          if (response.status === 200 || response.status === 304) {
            console.log(response.data);
            setArchiveData(response.data);
            let inputData = response.data.input_media;
            let outputData = response.data.output_media;

            console.log(outputData.type);

            if (inputData.type === 'text') {
              setText(true);
              fetch(TEST_TWO + inputData.url, {
                headers: {
                  Authorization: `Bearer ${userKeyToken}`,
                  Accept: 'text/plain; charset=us-ascii',
                },
              })
                .then(response => response.text())
                .then(res => {
                  setTextInput(res);
                })
                .catch(e => {
                  console.log(e);
                  console.log('Binary - False - Not access');
                });
            }
            if (inputData.type === 'audio') {
              setSpeech(true);
              fetch(TEST_TWO + inputData.url, {
                headers: {
                  //...form.getHeaders(),
                  Authorization: `Bearer ${userKeyToken}`,
                  Accept: 'video/mp4;charset=UTF-8',
                },
                responseType: 'blob',
              })
                .then(response => response.blob())
                .then(res => {
                  // eslint-disable-next-line no-undef
                  const newBlobConverter = new Blob([res], {
                    type: 'video/mp4',
                  });
                  setAudioVideoInput(URL.createObjectURL(newBlobConverter));
                })
                .catch(e => {
                  console.log(e);
                  console.log('Binary - False - Not access');
                });
            }
            if (inputData.type === 'video') {
              setCamera(true);
              fetch(TEST_TWO + inputData.url, {
                headers: {
                  //...form.getHeaders(),
                  Authorization: `Bearer ${userKeyToken}`,
                  Accept: 'video/mp4;charset=UTF-8',
                },
                responseType: 'blob',
              })
                .then(response => response.blob())
                .then(res => {
                  // eslint-disable-next-line no-undef
                  const newBlobConverter = new Blob([res], {
                    type: 'video/mp4',
                  });
                  setAudioVideoInput(URL.createObjectURL(newBlobConverter));
                })
                .catch(e => {
                  console.log(e);
                  console.log('Binary - False - Not access');
                });
            }
            if (outputData.type === 'text') {
              setTextOutput(true);
              setAvatarOutput(false);
              setSpeechOutput(false);
              console.log('Text is true');
              if (inputData.type === 'text' || inputData.type === 'audio') {
                fetch(TEST_TWO + outputData.url, {
                  headers: {
                    //...form.getHeaders(),
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.text())
                  .then(res => {
                    setTextOutputMessage(res);
                  })
                  .catch(e => {
                    console.log(e);
                    setTextOutputMessage(t('No_data_available'));
                    console.log('Binary - False - Not access');
                  });
              } else if (inputData.type === 'video') {
                fetch(TEST_TWO + outputData.url, {
                  headers: {
                    //...form.getHeaders(),
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.text())
                  .then(res => {
                    setTextOutputMessage(res);
                  })
                  .catch(e => {
                    console.log(e);
                    setTextOutputMessage(t('No_data_available'));
                    console.log('Binary - False - Not access');
                  });
              } else {
                fetch(TEST_TWO + outputData.url, {
                  headers: {
                    //...form.getHeaders(),
                    Authorization: `Bearer ${userKeyToken}`,
                    Accept: 'text/plain; charset=us-ascii',
                  },
                })
                  .then(response => response.json())
                  .then(res => {
                    setTextOutputMessage(res.text);
                  })
                  .catch(e => {
                    console.log(e);
                    setTextOutputMessage(t('No_data_available'));
                    console.log('Binary - False - Not access');
                  });
              }
            }
            if (outputData.type === 'audio') {
              setSpeechOutput(true);
              setTextOutput(false);
              setAvatarOutput(false);
              console.log('Speech is true');
              fetch(TEST_TWO + outputData.url, {
                headers: {
                  //...form.getHeaders(),
                  Authorization: `Bearer ${userKeyToken}`,
                  Accept: 'video/mp4;charset=UTF-8',
                },
                responseType: 'blob',
              })
                .then(response => response.blob())
                .then(response => {
                  setAudioVideoOutput(URL.createObjectURL(response));
                })
                .catch(e => {
                  console.log(e);
                  console.log('Binary - False - Not access');
                });
            }
            if (outputData.type === 'video') {
              setAvatarOutput(true);
              setSpeechOutput(false);
              setTextOutput(false);
              console.log('Avatar is true');
              fetch(TEST_TWO + outputData.url, {
                headers: {
                  //...form.getHeaders(),
                  Authorization: `Bearer ${userKeyToken}`,
                  Accept: 'video/mp4;charset=UTF-8',
                },
                responseType: 'blob',
              })
                .then(response => response.blob())
                .then(response => {
                  setAudioVideoOutput(URL.createObjectURL(response));
                })
                .catch(e => {
                  console.log(e);
                  console.log('Binary - False - Not access');
                });
            }
            if (
              inputData.languageCode === 'DE' ||
              inputData.languageCode === 'DGS'
            ) {
              setLanguage(LANGUAGE_GERMAN);
            }
            if (
              inputData.languageCode === 'CH' ||
              inputData.languageCode === 'DSGS'
            ) {
              setLanguage(LANGUAGE_SUIZZE_GERMAN);
            }
            if (
              inputData.languageCode === 'EN' ||
              inputData.languageCode === 'BSL'
            ) {
              setLanguage(LANGUAGE_BRITISH);
            }
            if (
              inputData.languageCode === 'FR' ||
              inputData.languageCode === 'LSF'
            ) {
              setLanguage(LANGUAGE_FRENCH);
            }
            if (
              inputData.languageCode === 'IT' ||
              inputData.languageCode === 'LIS'
            ) {
              setLanguage(LANGUAGE_ITALIAN);
            }
            if (
              inputData.languageCode === 'EL' ||
              inputData.languageCode === 'GSL'
            ) {
              setLanguage(LANGUAGE_GREEK);
            }
            if (
              inputData.languageCode === 'NL' ||
              inputData.languageCode === 'NGT'
            ) {
              setLanguage(LANGUAGE_DUTCH);
            }
            if (
              outputData.languageCode === 'DE' ||
              outputData.languageCode === 'DGS'
            ) {
              setLanguageOutput(LANGUAGE_GERMAN);
            }
            if (
              outputData.languageCode === 'CH' ||
              outputData.languageCode === 'DSGS'
            ) {
              setLanguageOutput(LANGUAGE_SUIZZE_GERMAN);
            }
            if (
              outputData.languageCode === 'EN' ||
              outputData.languageCode === 'BSL'
            ) {
              setLanguageOutput(LANGUAGE_BRITISH);
            }
            if (
              outputData.languageCode === 'FR' ||
              outputData.languageCode === 'LSF'
            ) {
              setLanguageOutput(LANGUAGE_FRENCH);
            }
            if (
              outputData.languageCode === 'IT' ||
              outputData.languageCode === 'LIS'
            ) {
              setLanguageOutput(LANGUAGE_ITALIAN);
            }
            if (
              outputData.languageCode === 'EL' ||
              outputData.languageCode === 'GSL'
            ) {
              setLanguageOutput(LANGUAGE_GREEK);
            }
            if (
              outputData.languageCode === 'NL' ||
              outputData.languageCode === 'NGT'
            ) {
              setLanguageOutput(LANGUAGE_DUTCH);
            }
          } else {
            console.log('Error');
          }
        })
        .catch(e => {
          console.log(e);
        });
    };
    archiveDataBackend();
  }, [t, isFocused, archiveID]);
  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  const activeTabOne = () => {
    setTabOne(true);
    setTabTwo(false);
    setTabThree(false);
  };

  const activeTabTwo = () => {
    setTabOne(false);
    setTabTwo(true);
    setTabThree(false);
  };

  const activeTabThree = () => {
    setTabOne(false);
    setTabTwo(false);
    setTabThree(true);
  };

  const goToAvatarSettings = async () => {
    await AsyncStorage.setItem('archiveID', archiveID);
    await AsyncStorage.setItem(AVATAR_CHOICE, 'avatar');
    await navigation.navigate('Avatar New');
  };

  const setActiveDefaultInput = async (speechValue, textValue, cameraValue) => {
    setSpeech(speechValue);
    setText(textValue);
    setCamera(cameraValue);

    await AsyncStorage.setItem(
      SELECT_INPUT_TYPE,
      speechValue == true
        ? SPEECH
        : textValue == true
        ? TEXT
        : cameraValue == true
        ? CAMERA
        : '',
    );
  };

  const setActiveDefaultOutput = async (
    speechValue,
    textValue,
    avatarValue,
  ) => {
    setSpeechOutput(speechValue);
    setTextOutput(textValue);
    setAvatarOutput(avatarValue);

    await AsyncStorage.setItem(
      SELECT_OUTPUT_TYPE,
      speechValue == true
        ? SPEECH
        : textValue == true
        ? TEXT
        : avatarValue == true
        ? AVATAR
        : '',
    );
  };

  useEffect(() => {
    const prepareRenderVideo = async () => {
      const selectedLanguage = await AsyncStorage.getItem(APP_LANGUAGE);
      const avatarShirt = await AsyncStorage.getItem(AVATAR_SHIRT);
      const avatarBackground = await AsyncStorage.getItem(AVATAR_BACKGROUND);

      if (selectedLanguage === 'de') {
        if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(DGSB1S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(DGSB1S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(DGSB1S3);
        } else if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(DGSB2S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(DGSB2S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(DGSB2S3);
        } else if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(DGSB3S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(DGSB3S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(DGSB3S3);
        } else {
          setAvatarMockupVideo(DGSB0S0);
        }
      }

      if (selectedLanguage === 'ch') {
        if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(DSGSB1S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(DSGSB1S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(DSGSB1S3);
        } else if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(DSGSB2S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(DSGSB2S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(DSGSB2S3);
        } else if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(DSGSB3S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(DSGSB3S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(DSGSB3S3);
        } else {
          setAvatarMockupVideo(DSGSB0S0);
        }
      }

      if (selectedLanguage === 'en') {
        if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(GSLB1S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(GSLB1S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(GSLB1S3);
        } else if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(GSLB2S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(GSLB2S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(GSLB2S3);
        } else if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(GSLB3S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(GSLB3S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(GSLB3S3);
        } else {
          setAvatarMockupVideo(GSLB0S0);
        }
      }

      if (selectedLanguage === 'fr') {
        if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(LSFB1S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(LSFB1S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 1 || avatarBackground === '1')
        ) {
          setAvatarMockupVideo(LSFB1S3);
        } else if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(LSFB2S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(LSFB2S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 2 || avatarBackground === '2')
        ) {
          setAvatarMockupVideo(LSFB2S3);
        } else if (
          (avatarShirt === 1 || avatarShirt === '1') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(LSFB3S1);
        } else if (
          (avatarShirt === 2 || avatarShirt === '2') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(LSFB3S2);
        } else if (
          (avatarShirt === 3 || avatarShirt === '3') &&
          (avatarBackground === 3 || avatarBackground === '3')
        ) {
          setAvatarMockupVideo(LSFB3S3);
        } else {
          setAvatarMockupVideo(LSFB0S0);
        }
      }
    };
    prepareRenderVideo();
  }, [isFocused]);

  const renderVideo = () => {
    return (
      <Video
        style={{flex: 1}}
        source={avatarMockupVideo}
        useNativeControls
        resizeMode="contain"
        shouldPlay
      />
    );
  };

  const renderVideoMockup = () => {
    return (
      <Video
        style={{flex: 1}}
        source={avatarMockupVideo}
        useNativeControls
        resizeMode="contain"
        shouldPlay
      />
    );
  };

  const deleteArchiveModal = () => {
    setModalAvailable(true);
  };
  const closeModal = () => {
    setModalAvailable(false);
  };

  const deleteArchive = async id => {
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

    axios
      .delete(TEST + 'translation/' + archiveID, {
        headers: {
          //...form.getHeaders(),
          Authorization: `Bearer ${userKeyToken}`,
        },
      })
      .then(async res => {
        if (res.status === 200) {
          setModalAvailable(false);
          await AsyncStorage.removeItem('archiveID');
          await navigation.navigate('Start');
        } else {
          console.log('Error');
        }
      })
      .catch(e => {
        console.log(e);
        console.log('Failed');
      });
  };

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}
        accessibilityLabel={t('Input')}>
        <View
          style={styles.textHeadlineMain}
          accessible={true}
          accessibilityLabel={t('Input') + ' - ' + t('Output')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textHeadlineDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.textHeadline,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Input')}
          </Text>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textHeadlineTwoDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.textHeadlineTwo,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Output')}
          </Text>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textHeadlineThreeDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.textHeadlineThree,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Log_title')}
          </Text>
        </View>
        <View style={styles.textTab} accessible={true}>
          <View
            style={
              tabOne
                ? darkModeAvailable
                  ? styles.textTabMainActiveDark
                  : styles.textTabMainActive
                : darkModeAvailable
                ? styles.textTabMainDark
                : styles.textTabMain
            }
            accessible={true}>
            <TouchableOpacity
              onPress={activeTabOne}
              accessible={true}
              accessibilityLabel={'Tab one - Input'}>
              <View
                style={
                  darkModeAvailable
                    ? speech == true
                      ? styles.textTabImageAudioDark
                      : text == true
                      ? styles.textTabImageTextDark
                      : styles.textTabImageVideoDark
                    : styles.textTabImage
                }
                accessible={true}
                accessibilityLabel={'Selected input method'}>
                <Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri:
                      speech == true
                        ? Platform.OS == 'web'
                          ? darkModeAvailable
                            ? RecordIconDark
                            : RecordIcon
                          : darkModeAvailable
                          ? 'iconinputdark'
                          : 'iconinput'
                        : text == true
                        ? Platform.OS == 'web'
                          ? darkModeAvailable
                            ? TextIconDark
                            : TextIcon
                          : darkModeAvailable
                          ? 'icontextdark'
                          : 'icontext'
                        : Platform.OS == 'web'
                        ? darkModeAvailable
                          ? CameraIconDark
                          : CameraIcon
                        : darkModeAvailable
                        ? 'iconcameradark'
                        : 'iconcamera',
                  }}
                  style={
                    language == ''
                      ? text == true
                        ? darkModeAvailable
                          ? [
                              styles.settingsIconStyleTypeTextDark,
                              styles.settingsIconCustomStyle,
                            ]
                          : [
                              styles.settingsIconStyleType,
                              styles.settingsIconCustomStyle,
                            ]
                        : speech == true
                        ? darkModeAvailable
                          ? [
                              styles.settingsIconStyleTypeAudioDark,
                              styles.settingsIconCustomStyle,
                            ]
                          : [
                              styles.settingsIconStyleTypeThree,
                              styles.settingsIconCustomStyle,
                            ]
                        : darkModeAvailable
                        ? [
                            styles.settingsIconStyleTypeVideoDark,
                            styles.settingsIconCustomStyle,
                          ]
                        : [
                            styles.settingsIconStyleTypeTwo,
                            styles.settingsIconCustomStyle,
                          ]
                      : text == true
                      ? darkModeAvailable
                        ? [
                            styles.settingsIconStyleTypeTextDark,
                            styles.settingsIconCustomStyleActive,
                          ]
                        : [
                            styles.settingsIconStyleType,
                            styles.settingsIconCustomStyleActive,
                          ]
                      : speech == true
                      ? darkModeAvailable
                        ? [
                            styles.settingsIconStyleTypeAudioDark,
                            styles.settingsIconCustomStyleActive,
                          ]
                        : [
                            styles.settingsIconStyleTypeThree,
                            styles.settingsIconCustomStyleActive,
                          ]
                      : darkModeAvailable
                      ? [
                          styles.settingsIconStyleTypeVideoDark,
                          styles.settingsIconCustomStyleActive,
                        ]
                      : [
                          styles.settingsIconStyleTypeTwo,
                          styles.settingsIconCustomStyleActive,
                        ]
                  }
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={activeTabOne}
              accessible={true}
              accessibilityLabel={'Tab one - Input'}>
              <View
                style={styles.textTabImage}
                accessible={true}
                accessibilityLabel={'Selected language input'}>
                <Image
                  width={Platform.OS == 'web' ? '25px' : 25}
                  height={Platform.OS == 'web' ? '25px' : 25}
                  source={{
                    uri:
                      language == LANGUAGE_GERMAN
                        ? Platform.OS == 'web'
                          ? FlagGermanIcon
                          : 'flaggerman'
                        : language == LANGUAGE_GREEK
                        ? Platform.OS == 'web'
                          ? FlagGreeceIcon
                          : 'flaggreece'
                        : language == LANGUAGE_FRENCH
                        ? Platform.OS == 'web'
                          ? FlagFrenchIcon
                          : 'flagfrench'
                        : language == LANGUAGE_ITALIAN
                        ? Platform.OS == 'web'
                          ? FlagItalianIcon
                          : 'flagitaly'
                        : language == LANGUAGE_LUXEMBOURG_FRENCH ||
                          language == LANGUAGE_LUXEMBOURG_GERMAN
                        ? Platform.OS == 'web'
                          ? FlagLuxembourgIcon
                          : 'flagluxembourg'
                        : language == LANGUAGE_DUTCH
                        ? Platform.OS == 'web'
                          ? FlagDutchIcon
                          : 'flagdutch'
                        : language == LANGUAGE_SUIZZE_FRENCH ||
                          language == LANGUAGE_SUIZZE_ITALIAN ||
                          language == LANGUAGE_SUIZZE_GERMAN
                        ? Platform.OS == 'web'
                          ? FlagSuizzeIcon
                          : 'flagsuizze'
                        : language == LANGUAGE_BRITISH
                        ? Platform.OS == 'web'
                          ? FlagGreatBritianIcon
                          : 'flaggreatbritain'
                        : Platform.OS == 'web'
                        ? LanguageIcon
                        : 'iconlanguage',
                  }}
                  style={
                    language == ''
                      ? [
                          styles.settingsIconStyleFlag,
                          styles.settingsIconCustomStyle,
                        ]
                      : [
                          styles.settingsIconStyleFlag,
                          styles.settingsIconCustomStyleActive,
                        ]
                  }
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>
          </View>
          <View
            style={
              tabTwo
                ? darkModeAvailable
                  ? styles.textTabSecondActiveDark
                  : styles.textTabSecondActive
                : darkModeAvailable
                ? styles.textTabSecondDark
                : styles.textTabSecond
            }
            accessible={true}>
            <TouchableOpacity
              onPress={activeTabTwo}
              accessible={true}
              accessibilityLabel={'Tab two - Output'}>
              <View
                style={
                  darkModeAvailable
                    ? speechOutput == true
                      ? styles.textTabImageAudioDark
                      : textOutput == true
                      ? styles.textTabImageTextDark
                      : styles.textTabImageVideoDark
                    : styles.textTabImage
                }
                accessible={true}
                accessibilityLabel={'Selected output method'}
                onClick={avatarOutput ? goToAvatarSettings : ''}>
                <Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri:
                      speechOutput == true
                        ? Platform.OS == 'web'
                          ? darkModeAvailable
                            ? RecordIconDark
                            : RecordIcon
                          : darkModeAvailable
                          ? 'iconinputdark'
                          : 'iconinput'
                        : textOutput == true
                        ? Platform.OS == 'web'
                          ? darkModeAvailable
                            ? TextIconDark
                            : TextIcon
                          : darkModeAvailable
                          ? 'icontextdark'
                          : 'icontext'
                        : Platform.OS == 'web'
                        ? darkModeAvailable
                          ? AvatarIconDark
                          : AvatarIcon
                        : darkModeAvailable
                        ? 'iconavatardark'
                        : 'iconavatar',
                  }}
                  style={
                    languageOutput == ''
                      ? textOutput == true
                        ? darkModeAvailable
                          ? [
                              styles.settingsIconStyleTypeTextDark,
                              styles.settingsIconCustomStyle,
                            ]
                          : [
                              styles.settingsIconStyleType,
                              styles.settingsIconCustomStyle,
                            ]
                        : speechOutput == true
                        ? darkModeAvailable
                          ? [
                              styles.settingsIconStyleTypeAudioDark,
                              styles.settingsIconCustomStyle,
                            ]
                          : [
                              styles.settingsIconStyleTypeThree,
                              styles.settingsIconCustomStyle,
                            ]
                        : darkModeAvailable
                        ? [
                            styles.settingsIconStyleTypeVideoDark,
                            styles.settingsIconCustomStyle,
                          ]
                        : [
                            styles.settingsIconStyleTypeTwo,
                            styles.settingsIconCustomStyle,
                          ]
                      : textOutput == true
                      ? darkModeAvailable
                        ? [
                            styles.settingsIconStyleTypeTextDark,
                            styles.settingsIconCustomStyleActive,
                          ]
                        : [
                            styles.settingsIconStyleType,
                            styles.settingsIconCustomStyleActive,
                          ]
                      : speechOutput == true
                      ? darkModeAvailable
                        ? [
                            styles.settingsIconStyleTypeAudioDark,
                            styles.settingsIconCustomStyleActive,
                          ]
                        : [
                            styles.settingsIconStyleTypeThree,
                            styles.settingsIconCustomStyleActive,
                          ]
                      : darkModeAvailable
                      ? [
                          styles.settingsIconStyleTypeVideoDark,
                          styles.settingsIconCustomStyleActive,
                        ]
                      : [
                          styles.settingsIconStyleTypeTwo,
                          styles.settingsIconCustomStyleActive,
                        ]
                  }
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={activeTabTwo}
              accessible={true}
              accessibilityLabel={'Tab two - Output'}>
              <View
                style={styles.textTabImage}
                accessible={true}
                accessibilityLabel={'Selected language output'}>
                <Image
                  width={Platform.OS == 'web' ? '25px' : 25}
                  height={Platform.OS == 'web' ? '25px' : 25}
                  source={{
                    uri:
                      languageOutput == LANGUAGE_GERMAN
                        ? Platform.OS == 'web'
                          ? FlagGermanIcon
                          : 'flaggerman'
                        : languageOutput == LANGUAGE_GREEK
                        ? Platform.OS == 'web'
                          ? FlagGreeceIcon
                          : 'flaggreece'
                        : languageOutput == LANGUAGE_FRENCH
                        ? Platform.OS == 'web'
                          ? FlagFrenchIcon
                          : 'flagfrench'
                        : languageOutput == LANGUAGE_ITALIAN
                        ? Platform.OS == 'web'
                          ? FlagItalianIcon
                          : 'flagitaly'
                        : languageOutput == LANGUAGE_LUXEMBOURG_FRENCH ||
                          languageOutput == LANGUAGE_LUXEMBOURG_GERMAN
                        ? Platform.OS == 'web'
                          ? FlagLuxembourgIcon
                          : 'flagluxembourg'
                        : languageOutput == LANGUAGE_DUTCH
                        ? Platform.OS == 'web'
                          ? FlagDutchIcon
                          : 'flagdutch'
                        : languageOutput == LANGUAGE_SUIZZE_FRENCH ||
                          languageOutput == LANGUAGE_SUIZZE_ITALIAN ||
                          languageOutput == LANGUAGE_SUIZZE_GERMAN
                        ? Platform.OS == 'web'
                          ? FlagSuizzeIcon
                          : 'flagsuizze'
                        : languageOutput == LANGUAGE_BRITISH
                        ? Platform.OS == 'web'
                          ? FlagGreatBritianIcon
                          : 'flaggreatbritain'
                        : Platform.OS == 'web'
                        ? LanguageIcon
                        : 'iconlanguage',
                  }}
                  style={
                    languageOutput == ''
                      ? [
                          styles.settingsIconStyleFlag,
                          styles.settingsIconCustomStyle,
                        ]
                      : [
                          styles.settingsIconStyleFlag,
                          styles.settingsIconCustomStyleActive,
                        ]
                  }
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>
          </View>
          <View
            style={
              tabThree
                ? darkModeAvailable
                  ? styles.textTabThreeActiveDark
                  : styles.textTabThreeActive
                : darkModeAvailable
                ? styles.textTabThreeDark
                : styles.textTabThree
            }
            accessible={true}>
            <TouchableOpacity
              onPress={activeTabThree}
              accessible={true}
              accessibilityLabel={'Tab three - Log'}>
              <View
                accessible={true}
                accessibilityLabel={'Translation log method'}>
                <Image
                  width={Platform.OS == 'web' ? '30px' : 30}
                  height={Platform.OS == 'web' ? '30px' : 30}
                  source={{
                    uri:
                      Platform.OS == 'web'
                        ? darkModeAvailable
                          ? SettingsIconDark
                          : SettingsIcon
                        : darkModeAvailable
                        ? 'illustrationheadersettingsdark'
                        : 'illustrationheadersettings',
                  }}
                  style={
                    darkModeAvailable
                      ? [
                          styles.settingsIconStyleTypeTextDark,
                          styles.settingsIconLogStyle,
                          styles.settingsIconStyleTypeLogDark,
                        ]
                      : [
                          styles.settingsIconStyleType,
                          styles.settingsIconLogStyle,
                        ]
                  }
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>
          </View>
        </View>
        <View
          style={
            darkModeAvailable
              ? styles.textInputFieldMainDark
              : styles.textInputFieldMain
          }
          accessible={true}
          accessibilityLabel={'Input and output content'}>
          {tabOne ? (
            speech ? (
              <View>
                <Video
                  style={{flex: 1}}
                  source={{uri: audioVideoInput}}
                  useNativeControls
                  resizeMode="contain"
                  shouldPlay
                />
                {/* Old - Source: recordedFile */}
                {/*<TouchableOpacity
                      onPress={() =>
                        recordedFile[recordedFile.length - 1].sound.replayAsync()
                      }
                      accessible={true}
                      accessibilityLabel={'Audio play text and function'}>
                      <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                        {t('Audio_Play')}
                      </Text>
                    </TouchableOpacity>*/}
              </View>
            ) : text ? (
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {textInput}
              </Text>
            ) : (
              <View>
                <Video
                  style={{flex: 1}}
                  source={{uri: audioVideoInput}}
                  useNativeControls
                  resizeMode="contain"
                  shouldPlay
                />
                {/* Old - Source: recordedFile */}
              </View>
            )
          ) : (
            <></>
          )}
          {tabTwo ? (
            speechOutput ? (
              <View style={styles.videoAudioOutput}>
                <Video
                  style={{flex: 1}}
                  source={{uri: audioVideoOutput}}
                  useNativeControls
                  resizeMode="contain"
                  shouldPlay
                />
              </View>
            ) : textOutput ? (
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {/*t('Text_Output')*/}
                {textOutputMessage}
              </Text>
            ) : avatarOutput ? (
              <View style={styles.videoAudioOutput}>
                <Video
                  style={{flex: 1}}
                  source={{uri: audioVideoOutput}}
                  useNativeControls
                  resizeMode="contain"
                  shouldPlay
                />
              </View>
            ) : (
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Avatar_Language_Unavailable')}
              </Text>
            )
          ) : (
            <></>
          )}
          {tabThree ? (
            <ScrollView
              style={[styles.videoAudioOutput, styles.logTranslationVisible]}>
              {/*<Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        styles.boldTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        styles.boldTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Affect_Fusion')}
              </Text>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        styles.dataTextHeadline,
                        styles.beautifyJson,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        styles.dataTextHeadline,
                        styles.beautifyJson,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {logAffectFusion !== undefined
                  ? logAffectFusion
                  : t('No_data_available')}
              </Text>*/}
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        styles.boldTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        styles.boldTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Translation')} ID
              </Text>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        styles.dataTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        styles.dataTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {archiveID}
              </Text>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        styles.boldTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        styles.boldTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Intermediate_translation')}
              </Text>
              <View
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        styles.dataTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        styles.dataTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {text && textOutput ? (
                  <>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThirteenTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThirteen}
                      </Text>
                    </View>
                  </>
                ) : (
                  <></>
                )}
                {text && speechOutput ? (
                  <>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThirteenTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThirteen}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logElevenTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logEleven}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                  </>
                ) : (
                  <></>
                )}
                {text && avatarOutput ? (
                  <>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourteenTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourteen}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThree}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logTenTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logTen}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                  </>
                ) : (
                  <></>
                )}
                {speech && textOutput ? (
                  <>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourZeroZeroTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logFourZeroZero}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeZeroThreeTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logThreeZeroThree}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFour}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logTwoTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logTwo}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThree}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFiveTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFive}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSixTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSix}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThirteenTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThirteen}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeZeroFourTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeZeroFour}
                      </Text>
                    </View>
                  </>
                ) : (
                  <></>
                )}
                {speech && speechOutput ? (
                  <>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourZeroZeroTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logFourZeroZero}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeZeroThreeTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logThreeZeroThree}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFour}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logTwoTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logTwo}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThree}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFiveTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFive}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSixTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSix}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logElevenTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logEleven}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                  </>
                ) : (
                  <></>
                )}
                {speech && avatarOutput ? (
                  <>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourZeroZeroTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logFourZeroZero}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeZeroThreeTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logThreeZeroThree}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logTwoTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logTwo}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourZeroTwoTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logFourZeroTwo}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFour}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThree}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFiveTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFive}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSixTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSix}
                      </Text>
                    </View>
                    {/*<View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSevenTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSeven}
                      </Text>
                    </View>*/}
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logTenTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logTen}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                  </>
                ) : (
                  <></>
                )}
                {camera && textOutput ? (
                  <>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourZeroTwoTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logFourZeroTwo}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourZeroOneTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logFourZeroOne}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourTitle}
                      </Text>
                    </View>
                    <View>
                      <View
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFour}
                      </View>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logOneTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logOne}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSixTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSix}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSevenTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSeven}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeZeroFourTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logThreeZeroFour}
                      </Text>
                    </View>
                  </>
                ) : (
                  <></>
                )}
                {camera && speechOutput ? (
                  <>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourZeroTwoTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logFourZeroTwo}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourZeroOneTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logFourZeroOne}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourTitle}
                      </Text>
                    </View>
                    <View>
                      <View
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFour}
                      </View>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logOneTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logOne}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSixTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSix}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSevenTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSeven}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logElevenTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logEleven}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                  </>
                ) : (
                  <></>
                )}
                {camera && avatarOutput ? (
                  <>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourZeroTwoTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logFourZeroTwo}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourZeroOneTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logFourZeroOne}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFourTitle}
                      </Text>
                    </View>
                    <View>
                      <View
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logFour}
                      </View>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logOneTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logOne}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSixTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.beautifyJson,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSix}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSevenTitle}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logSeven}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.textDark,
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                '',
                                styles.dataTextHeadline,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {logTenTitle}
                      </Text>
                    </View>
                    <View>
                      <Video
                        style={{flex: 1}}
                        source={{uri: logTen}}
                        useNativeControls
                        resizeMode="contain"
                      />
                    </View>
                  </>
                ) : (
                  <></>
                )}
              </View>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        styles.boldTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        styles.boldTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {text
                  ? t('Translation_input_from_text')
                  : speech
                  ? t('Translation_input_from_speech')
                  : t('Translation_input_from_sign_video')}
              </Text>
              {speech ? (
                <View
                  style={[styles.dataTextHeadline, styles.videoAudioOutputLog]}>
                  <Video
                    style={{flex: 1}}
                    source={{uri: logStart}}
                    useNativeControls
                    resizeMode="contain"
                  />
                </View>
              ) : text ? (
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textDark,
                          styles.dataTextHeadline,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          '',
                          styles.dataTextHeadline,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {logStart}
                </Text>
              ) : (
                <View
                  style={[styles.dataTextHeadline, styles.videoAudioOutputLog]}>
                  <Video
                    style={{flex: 1}}
                    source={{uri: logStart}}
                    useNativeControls
                    resizeMode="contain"
                  />
                </View>
              )}
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        styles.boldTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        styles.boldTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {textOutput
                  ? t('Translation_output_to_text')
                  : speechOutput
                  ? t('Translation_output_to_speech')
                  : t('Translation_output_to_avatar')}
              </Text>
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.textDark,
                        styles.dataTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        '',
                        styles.dataTextHeadline,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {speechOutput ? (
                  <View
                    style={[
                      styles.dataTextHeadline,
                      styles.videoAudioOutputLog,
                    ]}>
                    <Video
                      style={{flex: 1}}
                      source={{uri: logEnd}}
                      useNativeControls
                      resizeMode="contain"
                    />
                  </View>
                ) : textOutput ? (
                  <Text
                    style={
                      darkModeAvailable
                        ? [
                            styles.textDark,
                            styles.dataTextHeadline,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                        : [
                            '',
                            styles.dataTextHeadline,
                            contrastSizing === SMALLTEXT
                              ? styles.smallTextAll
                              : contrastSizing === NORMALTEXT
                              ? styles.normalTextAll
                              : contrastSizing === BIGTEXT
                              ? styles.bigTextAll
                              : styles.normalTextAll,
                          ]
                    }>
                    {(logEnd !== null || logEnd !== '' || logEnd.length > 0) &&
                    !textOutput
                      ? logEnd
                      : textOutput
                      ? textOutputMessage
                      : t('No_data_available')}
                  </Text>
                ) : (
                  <View
                    style={[
                      styles.dataTextHeadline,
                      styles.videoAudioOutputLog,
                    ]}>
                    <Video
                      style={{flex: 1}}
                      source={{uri: logEnd}}
                      useNativeControls
                      resizeMode="contain"
                    />
                  </View>
                )}
              </Text>
            </ScrollView>
          ) : (
            <></>
          )}
        </View>
        {!modalAvailable ? (
          <>
            <View style={styles.textButtons} accessible={true}>
              <Pressable
                style={styles.textButtonsElement}
                onPress={deleteArchiveModal}
                accessible={true}
                accessibilityLabel={t('Delete')}>
                <View
                  style={
                    darkModeAvailable
                      ? styles.textButtonsElementBackgroundDark
                      : styles.textButtonsElementBackground
                  }
                  accessible={true}>
                  <View
                    style={
                      darkModeAvailable
                        ? styles.textButtonsElementIconMainDark
                        : styles.textButtonsElementIconMain
                    }
                    accessible={true}
                    accessibilityLabel={'Delete with icon'}>
                    <Image
                      width={Platform.OS == 'web' ? '25px' : 25}
                      height={Platform.OS == 'web' ? '25px' : 25}
                      source={{
                        uri:
                          Platform.OS == 'web'
                            ? darkModeAvailable
                              ? DeleteIconDark
                              : DeleteIcon
                            : darkModeAvailable
                            ? 'icondeletedark'
                            : 'icondelete',
                      }}
                      style={styles.textButtonsElementIcon}
                      resizeMode="contain"
                    />
                  </View>
                </View>
                <Text
                  style={
                    darkModeAvailable
                      ? [
                          styles.textButtonsTitleCustomDark,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                      : [
                          styles.textButtonsTitleCustom,
                          contrastSizing === SMALLTEXT
                            ? styles.smallTextAll
                            : contrastSizing === NORMALTEXT
                            ? styles.normalTextAll
                            : contrastSizing === BIGTEXT
                            ? styles.bigTextAll
                            : styles.normalTextAll,
                        ]
                  }>
                  {t('Delete')}
                </Text>
              </Pressable>
            </View>
            <TouchableOpacity
              style={styles.buttonMain}
              onPress={() => navigation.navigate('Archive')}
              accessible={true}
              accessibilityLabel={t('Back')}
              accessibilityHint="Back to archive overview">
              <Text
                style={
                  darkModeAvailable
                    ? [
                        styles.buttonDark,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                    : [
                        styles.button,
                        contrastSizing === SMALLTEXT
                          ? styles.smallTextAll
                          : contrastSizing === NORMALTEXT
                          ? styles.normalTextAll
                          : contrastSizing === BIGTEXT
                          ? styles.bigTextAll
                          : styles.normalTextAll,
                      ]
                }>
                {t('Back')}
              </Text>
            </TouchableOpacity>
          </>
        ) : (
          <>
            <View
              style={
                darkModeAvailable
                  ? styles.modalContainerDark
                  : styles.modalContainer
              }>
              <View style={styles.modalContainerInner}>
                <Text
                  style={[
                    darkModeAvailable
                      ? styles.modalHeadlineDark
                      : styles.modalHeadline,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]}>
                  {t('Delete_one_archive')}
                </Text>
                <View style={styles.textButtonDelete} accessible={true}>
                  <Pressable
                    style={
                      darkModeAvailable
                        ? [
                            styles.menuElementContainerInnerSmallModalDark,
                            styles.menuElementContainerInnerMiddleDarkDelete,
                          ]
                        : [
                            styles.menuElementContainerInnerSmallModal,
                            styles.menuElementContainerInnerMiddleDelete,
                          ]
                    }
                    onPress={closeModal}
                    accessible={true}
                    accessibilityLabel={t('NO')}>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.menuElementMiddle,
                                styles.menuElementAdditionalDark,
                                styles.modalButtonText,
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                styles.menuElementMiddle,
                                styles.menuElementAdditional,
                                styles.modalButtonText,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {t('NO')}
                      </Text>
                    </View>
                  </Pressable>
                </View>
                <View style={styles.textButtonDelete} accessible={true}>
                  <Pressable
                    style={
                      darkModeAvailable
                        ? [
                            styles.menuElementContainerInnerSmallModalDark,
                            styles.menuElementContainerInnerMiddleDarkDelete,
                          ]
                        : [
                            styles.menuElementContainerInnerSmallModal,
                            styles.menuElementContainerInnerMiddleDelete,
                          ]
                    }
                    onPress={() => deleteArchive(archiveID)}
                    accessible={true}
                    accessibilityLabel={t('YES')}>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.menuElementMiddle,
                                styles.menuElementAdditionalDark,
                                styles.modalButtonText,
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                styles.menuElementMiddle,
                                styles.menuElementAdditional,
                                styles.modalButtonText,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {t('YES')}
                      </Text>
                    </View>
                  </Pressable>
                </View>
              </View>
            </View>
          </>
        )}
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
  },
  mainMarginWeb: {
    marginTop: Platform.OS === 'web' ? '20px' : 20,
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsIconStyleType: {
    width: Platform.OS === 'web' ? '45px' : 45,
    height: Platform.OS === 'web' ? '45px' : 45,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-10px' : -10,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-10px' : -10,
  },
  settingsIconStyleTypeTextDark: {
    width: Platform.OS === 'web' ? '45px' : 45,
    height: Platform.OS === 'web' ? '45px' : 45,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-0.5px' : -0.5,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-12.5px' : -12.5,
  },
  settingsIconStyleTypeTwo: {
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '35px' : 35,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-5px' : -5,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-5px' : -5,
  },
  settingsIconStyleTypeAudioDark: {
    width: Platform.OS === 'web' ? '32px' : 32,
    height: Platform.OS === 'web' ? '32px' : 32,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-1px' : -1,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-5.5px' : -5.5,
  },
  settingsIconStyleTypeThree: {
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '35px' : 35,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '-5px' : -5,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-5px' : -5,
  },
  settingsIconStyleTypeVideoDark: {
    width: Platform.OS === 'web' ? '32px' : 32,
    height: Platform.OS === 'web' ? '32px' : 32,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '0px' : 0,
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginTop: Platform.OS === 'web' ? '-6px' : -6,
  },
  settingsIconStyleTypeLogDark: {
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '35px' : 35,
    //resizeMode: 'center',
    alignItems: 'center',
    marginLeft: Platform.OS === 'web' ? '9.5px' : 9.5,
    marginTop: Platform.OS === 'web' ? '-5px' : -5,
    backgroundColor: '#000',
  },
  settingsIconStyleFlag: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsIconCustomStyle: {
    marginLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  settingsIconLogStyle: {
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  textTab: {
    flexDirection: 'row',
    //flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  textTabMain: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#BEBEBE',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  textTabMainDark: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#000',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderColor: '#ecb853',
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderTopWidth: 2,
  },
  textTabMainActive: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#EDB854',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  textTabMainActiveDark: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#ecb853',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  textTabSecond: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#BEBEBE',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '130px' : 130,
  },
  textTabSecondDark: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#000',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '130px' : 130,
    borderColor: '#ecb853',
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderTopWidth: 2,
  },
  textTabSecondActive: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#EDB854',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '130px' : 130,
  },
  textTabSecondActiveDark: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#ecb853',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '130px' : 130,
  },
  textTabThree: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#BEBEBE',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '-177.5px' : -177.5,
  },
  textTabThreeDark: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#000',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '-177.5px' : -177.5,
    borderColor: '#ecb853',
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderTopWidth: 2,
  },
  textTabThreeActive: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#EDB854',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '-177.5px' : -177.5,
  },
  textTabThreeActiveDark: {
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '75px' : 75,
    backgroundColor: '#ecb853',
    borderTopLeftRadius: Platform.OS === 'web' ? '10px' : 10,
    borderTopRightRadius: Platform.OS === 'web' ? '10px' : 10,
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: Platform.OS === 'web' ? '-177.5px' : -177.5,
  },
  textTabImage: {
    width: Platform.OS === 'web' ? '25px' : 25,
  },
  textTabImageTextDark: {
    width: Platform.OS === 'web' ? '30px' : 30,
    height: Platform.OS === 'web' ? '22px' : 22,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    backgroundColor: '#000',
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginTop: Platform.OS === 'web' ? '2px' : 2,
    marginLeft: Platform.OS === 'web' ? '-5px' : -5,
  },
  textTabImageAudioDark: {
    width: Platform.OS === 'web' ? '27px' : 27,
    height: Platform.OS === 'web' ? '22px' : 22,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    backgroundColor: '#000',
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginTop: Platform.OS === 'web' ? '2px' : 2,
    marginLeft: Platform.OS === 'web' ? '-5px' : -5,
  },
  textTabImageVideoDark: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '22px' : 22,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
    backgroundColor: '#000',
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginTop: Platform.OS === 'web' ? '2px' : 2,
    marginLeft: Platform.OS === 'web' ? '-5px' : -5,
  },
  textHeadlineMain: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  textHeadline: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    alignItems: 'left',
    textAlign: 'left',
  },
  textHeadlineDark: {
    paddingLeft: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    color: '#ecb853',
    alignItems: 'left',
    textAlign: 'left',
  },
  textHeadlineTwo: {
    /*paddingRight: Platform.OS === 'web' ? '30px' : 30,*/
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? '90px' : 90,
    alignItems: 'right',
    textAlign: 'right',
  },
  textHeadlineTwoDark: {
    /*paddingRight: Platform.OS === 'web' ? '30px' : 30,*/
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? '90px' : 90,
    color: '#ecb853',
    alignItems: 'right',
    textAlign: 'right',
  },
  textHeadlineThree: {
    /*paddingRight: Platform.OS === 'web' ? '30px' : 30,*/
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? '100px' : 100,
    alignItems: 'center',
    textAlign: 'center',
    position: 'absolute',
  },
  textHeadlineThreeDark: {
    /*paddingRight: Platform.OS === 'web' ? '30px' : 30,*/
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? '100px' : 100,
    color: '#ecb853',
    alignItems: 'center',
    textAlign: 'center',
    position: 'absolute',
  },
  textInputField: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    padding: Platform.OS === 'web' ? '10px' : 10,
  },
  textInputFieldMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#EDB854',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldMainDark: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    height: Platform.OS === 'web' ? '300px' : 300,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '2px' : 2,
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
  },
  textInputFieldLength: {
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textInputFieldLengthColor: {
    color: '#BEBEBE',
  },
  textButtons: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  textButtonsElement: {
    // Before - Width
    // width: Platform.OS === 'web' ? '150px' : 150,
    // After - Width
    width: Platform.OS === 'web' ? '300px' : 300,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  textButtonsElementBackground: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    backgroundColor: '#BEBEBE',
  },
  textButtonsElementBackgroundDark: {
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    borderColor: '#ecb853',
    borderWidth: 2,
    backgroundColor: '#000000',
  },
  textButtonsElementIcon: {
    width: Platform.OS === 'web' ? '25px' : 25,
    height: Platform.OS === 'web' ? '25px' : 25,
  },
  textButtonsElementIconMain: {
    paddingTop: Platform.OS === 'web' ? '7px' : 7,
    paddingLeft: Platform.OS === 'web' ? '8px' : 8,
  },
  textButtonsElementIconMainDark: {
    paddingTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '6px' : 6,
  },
  textButtonsElementIconModal: {
    width: Platform.OS === 'web' ? '80px' : 80,
    height: Platform.OS === 'web' ? '80px' : 80,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  textButtonsTitle: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '8px' : 8,
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleDark: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '8px' : 8,
    color: '#ecb853',
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleCustom: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '6px' : 6,
    alignItems: 'center',
    textAlign: 'center',
  },
  textButtonsTitleCustomDark: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '5px' : 5,
    paddingLeft: Platform.OS === 'web' ? '6px' : 6,
    color: '#ecb853',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMain: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonMainDisabled: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    padding: Platform.OS === 'web' ? '10px' : 10,
    paddingLeft: Platform.OS === 'web' ? '0px' : 0,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '50px' : 50,
    pointerEvents: 'none',
    opacity: 0.4,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  button: {
    backgroundColor: '#D05B5B',
    color: '#fff',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  buttonDark: {
    backgroundColor: '#000000',
    color: '#ecb853',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    padding: Platform.OS === 'web' ? '10px' : 10,
    width: Platform.OS === 'web' ? '300px' : 300,
    //borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderRadius: 20,
    //resizeMode: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderColor: '#ecb853',
    borderWidth: 2,
  },
  modalContainer: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '150px' : 150,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
  },
  modalContainerDark: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '150px' : 150,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
  },
  modalContainerInner: {
    width: Platform.OS === 'web' ? '250px' : 250,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  modalHeadline: {
    flexDirection: 'row',
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  modalHeadlineDark: {
    flexDirection: 'row',
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
  },
  modalButtonText: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '150px' : 150,
    textAlign: 'center',
  },
  menuElementContainerInnerSmallModal: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '150px' : 150,
    backgroundColor: '#BEBEBE',
    color: '#ecb853',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    paddingTop: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  menuElementContainerInnerSmallModalDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '150px' : 150,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    paddingTop: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  videoAudioOutput: {
    height: Platform.OS === 'web' ? '280px' : 280,
  },
  videoAudioOutputLog: {
    height: Platform.OS === 'web' ? '280px' : 280,
    width: Platform.OS === 'web' ? '270px' : 270,
  },
  textDark: {
    color: '#ecb853',
  },
  boldTextHeadline: {
    fontWeight: 'bold',
  },
  dataTextHeadline: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  beautifyJson: {
    whiteSpace: 'pre',
  },
  logTranslationVisible: {
    overflow: 'auto',
  },
});

export default ArchiveDetail;
