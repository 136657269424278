/* eslint-disable */
import React, { Component, useRef, useEffect } from 'react';



import 'video.js/dist/video-js.css';
import videojs from 'video.js';

import 'webrtc-adapter';
import RecordRTC from 'recordrtc';

/*
// Required imports when recording audio-only using the videojs-wavesurfer plugin
import WaveSurfer from 'wavesurfer.js';
import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
WaveSurfer.microphone = MicrophonePlugin;

// Register videojs-wavesurfer plugin
import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';
*/

// register videojs-record plugin with this import
import 'videojs-record/dist/css/videojs.record.css';
import Record from 'videojs-record/dist/videojs.record.js';

const videoJsOptions = {
    controls: true,
    bigPlayButton: false,
    width: 295,
    height: 300,
    fluid: false,
    autoplay:true,
    plugins: {
        record: {
            audio: true,
            video: true,
            maxLength: 60,
            debug: false,
        }
    }
};

 const VideoRecoder = ({stopRecordingWeb, startRecordingWeb}) => {

    const videoRef = useRef(null);
    const playerRef = useRef(null);
    useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
          const videoElement = videoRef.current;

          if (!videoElement) return;

          const player = playerRef.current = videojs(videoElement, videoJsOptions, () => {
            videojs.log('player is ready');
          });
          player.on('deviceReady', () => {
            console.log('device is ready!');
        });

        player.on('startRecord', () => {
            console.log('started recording!');
            startRecordingWeb()
        });

        // user completed recording and stream is available
        player.on('finishRecord', () => {
            // recordedData is a blob object containing the recorded data that
            // can be downloaded by the user, stored on server etc.
            console.log('finished recording: ',player.recordedData);

            /*const blobToBase64 = async blob => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              return new Promise(resolve => {
                reader.onloadend = () => {
                  resolve(reader.result);
                };
              });
            };

            const convertAudioFile = blobToBase64(player.recordedData)
              .then((res) => {
                return res;
              })

            async function urlToFile(url, filename, mimeType){
              return (fetch(url)
                  .then(function(res){return res.arrayBuffer();})
                  .then(function(buf){return new File([buf], filename,{type:mimeType});})
              );
            }
            const getAudioFile = urlToFile(convertAudioFile, 'newRecordVideo.mp4','video/mp4')
              .then(function(file){
                //localStorage.setItem("videoFile", JSON.stringify(file));
                console.log(file)
                return file;
              });

            getAudioFile();*/

            //localStorage.setItem("videoFile", videoRef);
            const newBlobConverter = new Blob([player.recordedData], { type: "video/mp4" });

            stopRecordingWeb(newBlobConverter);
        });

        // End session - Reset
        player.on('ended', () => {
          //player.initChildren();
          player.reset();
        });

        // error handling
        player.on('error', (element, error) => {
            console.warn(error);
        });

        player.on('deviceError', () => {
            console.error('device error:', this.player.deviceErrorCode);
        });


        // You could update an existing player in the `else` block here
        // on prop change, for example:
        } else {
          // const player = playerRef.current;

          // player.autoplay(options.autoplay);
          // player.src(options.sources);
        }
      }, [videoRef]);

    // destroy player on unmount
    useEffect(() => {
        const player = playerRef.current;

        return () => {
          if (player) {
            player.dispose();
            playerRef.current = null;
          }
        };
      },[playerRef]);


        return (
        <div data-vjs-player>
            <video autoplay id="myVideo" ref={videoRef} className="video-js vjs-default-skin" playsInline></video>
        </div>
        );

}

export default VideoRecoder;
