import React, {useEffect, useState, Node} from 'react';
import './index.css';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  BIGTEXT,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  DEFAULT_OUTPUT_TYPE,
  DEFAULT_VALUE_TYPE,
  NORMALTEXT,
  SELECT_INPUT_TYPE,
  SELECT_INPUT_LANGUAGE_TYPE,
  SELECT_OUTPUT_TYPE,
  SELECT_OUTPUT_LANGUAGE_TYPE,
  SMALLTEXT,
  USERTOKEN_KEY,
  VIDEO,
  CAMERA,
  TEXT,
  AUDIO,
  SPEECH,
  AVATAR,
  LANGUAGE_FRENCH,
  LANGUAGE_GERMAN,
  LANGUAGE_GREEK,
  LANGUAGE_ITALIAN,
  LANGUAGE_LUXEMBOURG_FRENCH,
  LANGUAGE_LUXEMBOURG_GERMAN,
  LANGUAGE_DUTCH,
  LANGUAGE_SUIZZE_FRENCH,
  LANGUAGE_SUIZZE_ITALIAN,
  LANGUAGE_SUIZZE_GERMAN,
  LANGUAGE_BRITISH,
} from '../../../../constants/constants';
import {useIsFocused} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';
import axios from 'axios';

const VideoUploadComponent = props => {
  const {
    width,
    height,
    dataSourceURI,
    contrastSizing,
    darkModeAvailable,
    inputLanguage,
    outputLanguage,
    outputMethod,
  } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const inputRef = React.useRef();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [source, setSource] = React.useState();
  /*const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();*/
  const [languageInput, setLanguageInput] = useState('DGS');
  const [languageOutput, setLanguageOutput] = useState('DE');
  const [outputMethodType, setOutputMethodType] = useState('text');
  const [videoRecordData, setVideoRecordData] = useState('');
  const [newDataUpload, setNewDataUpload] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    inputLanguage(languageInput);
    outputLanguage(languageOutput);
    outputMethod(outputMethodType);
    /*dataSourceURI();
    setSource();*/
  });
  const checkSourceData = async () => {
    const sourceData = await AsyncStorage.getItem('sourceData');
    if (sourceData === 'new') {
      setNewDataUpload(false);
    }
  };
  useEffect(() => {
    checkSourceData();
  }, []);
  const handleFileChange = event => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setSource(url);
    dataSourceURI(file);
    setNewDataUpload(true);
  };

  const handleChoose = event => {
    inputRef.current.click();
  };

  const handleSelectInputLanguage = event => {
    setLanguageInput(event.target.value);
    inputLanguage(event.target.value);
    // eslint-disable-next-line no-undef
    localStorage.setItem('inputLanguageVideoUpload', event.target.value);
  };

  const handleSelectOutputLanguage = event => {
    setLanguageOutput(event.target.value);
    outputLanguage(event.target.value);
    // eslint-disable-next-line no-undef
    localStorage.setItem('outputLanguageVideoUpload', event.target.value);
  };

  const handleSelectOutputMethod = event => {
    setOutputMethodType(event.target.value);
    outputMethod(event.target.value);
    // eslint-disable-next-line no-undef
    localStorage.setItem('outputMethodTypeVideoUpload', event.target.value);
    if (event.target.value === 'video' && languageOutput === 'EN') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('BSL');
      // eslint-disable-next-line no-undef
      outputLanguage('BSL');
    }
    if (event.target.value !== 'video' && languageOutput === 'BSL') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('EN');
      // eslint-disable-next-line no-undef
      outputLanguage('EN');
    }
    if (event.target.value === 'video' && languageOutput === 'DE') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('DGS');
      // eslint-disable-next-line no-undef
      outputLanguage('DGS');
    }
    if (event.target.value !== 'video' && languageOutput === 'DGS') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('DE');
      // eslint-disable-next-line no-undef
      outputLanguage('DE');
    }
    if (event.target.value === 'video' && languageOutput === 'FR') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('LSF');
      // eslint-disable-next-line no-undef
      outputLanguage('LSF');
    }
    if (event.target.value !== 'video' && languageOutput === 'LSF') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('FR');
      // eslint-disable-next-line no-undef
      outputLanguage('FR');
    }
    if (event.target.value === 'video' && languageOutput === 'IT') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('LIS');
      // eslint-disable-next-line no-undef
      outputLanguage('LIS');
    }
    if (event.target.value !== 'video' && languageOutput === 'LIS') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('IT');
      // eslint-disable-next-line no-undef
      outputLanguage('IT');
    }
    if (event.target.value === 'video' && languageOutput === 'EL') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('GSL');
      // eslint-disable-next-line no-undef
      outputLanguage('GSL');
    }
    if (event.target.value !== 'video' && languageOutput === 'GSL') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('EL');
      // eslint-disable-next-line no-undef
      outputLanguage('EL');
    }
    if (event.target.value === 'video' && languageOutput === 'NL') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('NGT');
      // eslint-disable-next-line no-undef
      outputLanguage('NGT');
    }
    if (event.target.value !== 'video' && languageOutput === 'NGT') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('NL');
      // eslint-disable-next-line no-undef
      outputLanguage('NL');
    }
    if (event.target.value === 'video' && languageOutput === 'CH') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('DSGS');
      // eslint-disable-next-line no-undef
      outputLanguage('DSGS');
    }
    if (event.target.value !== 'video' && languageOutput === 'DSGS') {
      // eslint-disable-next-line no-undef
      setLanguageOutput('CH');
      // eslint-disable-next-line no-undef
      outputLanguage('CH');
    }
  };

  return (
    <>
      <div className="VideoInputButton">
        <input
          ref={inputRef}
          className="VideoInput_input"
          type="file"
          onChange={handleFileChange}
          accept="video/mp4"
        />
        {darkModeAvailable ? (
          <>
            <button
              className={
                contrastSizing === SMALLTEXT
                  ? 'button-style-dark smallTextAll'
                  : contrastSizing === NORMALTEXT
                  ? 'button-style-dark normalTextAll'
                  : contrastSizing === BIGTEXT
                  ? 'button-style-dark bigTextAll'
                  : 'button-style-dark smallTextAll'
              }
              onClick={handleChoose}>
              {t('Choose')}
            </button>
          </>
        ) : (
          <>
            <button
              className={
                contrastSizing === SMALLTEXT
                  ? 'button-style smallTextAll'
                  : contrastSizing === NORMALTEXT
                  ? 'button-style normalTextAll'
                  : contrastSizing === BIGTEXT
                  ? 'button-style bigTextAll'
                  : 'button-style smallTextAll'
              }
              onClick={handleChoose}>
              {t('Choose')}
            </button>
          </>
        )}
      </div>
      <div className={darkModeAvailable ? 'VideoInputDark' : 'VideoInput'}>
        {source && (
          <>
            <video
              className="VideoInput_video"
              width={width}
              height={height}
              controls
              src={source}
            />
          </>
        )}
        {!source && (
          <div
            className={
              darkModeAvailable ? 'VideoInputBeforeDark' : 'VideoInputBefore'
            }>
            {t('Preview_Video_Upload')}
          </div>
        )}
        {/*<div className="VideoInput_footer">{source || 'Nothing selected'}</div>*/}
      </div>
      {source !== undefined && (
        <div className="VideoContainerSelectOption">
          <div
            className={
              darkModeAvailable
                ? 'VideoContainerElementsDark'
                : 'VideoContainerElements'
            }>
            {t('Select_Input_Language')}
          </div>
          <div
            className={
              darkModeAvailable
                ? 'VideoContainerElementsDark'
                : 'VideoContainerElements'
            }>
            <select value={languageInput} onChange={handleSelectInputLanguage}>
              <option value="BSL">{t('English')} - BSL</option>
              <option value="DGS">{t('German')} - DGS</option>
              <option value="LSF">{t('French')} - LSF</option>
              <option value="LIS">{t('Italian')} - LIS</option>
              <option value="GSL">{t('Greek')} - GSL</option>
              <option value="NGT">{t('Dutch')} - NGT</option>
              <option value="DSGS">{t('Suizze_German')} - DSGS</option>
            </select>
          </div>
          <div
            className={
              darkModeAvailable
                ? 'VideoContainerElementsDark'
                : 'VideoContainerElements'
            }>
            {t('Select_Output_Method')}
          </div>
          <div
            className={
              darkModeAvailable
                ? 'VideoContainerElementsDark'
                : 'VideoContainerElements'
            }>
            <select
              value={outputMethodType}
              onChange={handleSelectOutputMethod}>
              <option value="audio">{t('Speech')}</option>
              <option value="text">{t('Text')}</option>
              <option value="video">{t('Avatar')}</option>
            </select>
          </div>
          <div
            className={
              darkModeAvailable
                ? 'VideoContainerElementsDark'
                : 'VideoContainerElements'
            }>
            {t('Select_Output_Language')}
          </div>
          <div
            className={
              darkModeAvailable
                ? 'VideoContainerElementsDark'
                : 'VideoContainerElements'
            }>
            <select
              value={languageOutput}
              onChange={handleSelectOutputLanguage}>
              <option value={outputMethodType === 'video' ? 'BSL' : 'EN'}>
                {t('English')} - {outputMethodType === 'video' ? 'BSL' : 'EN'}
              </option>
              <option value={outputMethodType === 'video' ? 'DGS' : 'DE'}>
                {t('German')} - {outputMethodType === 'video' ? 'DGS' : 'DE'}
              </option>
              <option value={outputMethodType === 'video' ? 'LSF' : 'FR'}>
                {t('French')} - {outputMethodType === 'video' ? 'LSF' : 'FR'}
              </option>
              <option value={outputMethodType === 'video' ? 'LIS' : 'IT'}>
                {t('Italian')} - {outputMethodType === 'video' ? 'LIS' : 'IT'}
              </option>
              <option value={outputMethodType === 'video' ? 'GSL' : 'EL'}>
                {t('Greek')} - {outputMethodType === 'video' ? 'GSL' : 'EL'}
              </option>
              <option value={outputMethodType === 'video' ? 'NGT' : 'NL'}>
                {t('Dutch')} - {outputMethodType === 'video' ? 'NGT' : 'NL'}
              </option>
              <option value={outputMethodType === 'video' ? 'DSGS' : 'CH'}>
                {t('Suizze_German')} -{' '}
                {outputMethodType === 'video' ? 'DSGS' : 'CH'}
              </option>
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoUploadComponent;
