import React, {Component} from 'react';

class App extends Component {
  render() {
    return (
      <div data-vjs-player>
        <video
          src={'../../../../assets/videos/LSF5.mp4'}
          width="300"
          height="300"
          controls
        />
      </div>
    );
  }
}
export default App;
