/**
 * Reset password
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  TextInput,
  useColorScheme,
  View,
  Button,
  Image,
  Platform,
  Pressable,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import {useIsFocused} from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Logo from './../../assets/easierlogowithtext.png';
import LogoDark from './../../assets/easierlogowithtextdark.png';
import {
  EMAIL_USER,
  RESET_EMAIL,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  DEVELOPMENT,
  TEST,
} from '../../constants/constants';
import {useTranslation} from 'react-i18next';
import axios from 'axios';

const Reset = ({navigation}): Node => {
  const [email, onChangeEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  const resetData = async userEmail => {
    let emailValidation =
      /[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]+/;
    if (userEmail.match(emailValidation)) {
      axios
        .post(TEST + 'user/request-password-reset', {
          email: userEmail,
        })
        .then(async response => {
          if (response.status === 200) {
            console.log(response.data.message);
            await AsyncStorage.setItem(RESET_EMAIL, userEmail);
            navigation.navigate('New Password');
            onChangeEmail('');
            setEmailError(false);
          }
        })
        .catch(e => {
          console.log(e);
          setEmailError(true);
        });
    } else {
      setEmailError(true);
    }
  };
  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={styles.mainMarginLogo}
          accessible={true}
          accessibilityLabel="Logo of EASIER">
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? LogoDark
                    : Logo
                  : darkModeAvailable
                  ? 'easierlogowithtextdark'
                  : 'easierlogowithtext',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View
          style={styles.formInput}
          accessible={true}
          accessibilityLabel={t('E-Mail')}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.labelTextDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.labelText,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('E-Mail')}
          </Text>
          <TextInput
            style={darkModeAvailable ? styles.inputDark : styles.input}
            onChangeText={onChangeEmail}
            value={email}
            keyboardType="email-address"
            accessibilityLabel="Input email"
          />
          <Text
            nativeID="EmailError"
            style={
              emailError
                ? darkModeAvailable
                  ? [
                      styles.errorTextDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.errorText,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                : [
                    styles.errorTextHide,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }
            accessible={emailError}>
            {t('Email_is_not_correct')}
          </Text>
          {/*errors.email && touched.email && (
            <Text style={styles.errorText}>{errors.email}</Text>
          )*/}
        </View>
        {/*<Button
          title={t('Reset_password')}
          color={'#D05B5B'}
          onPress={() => resetData(email)}
          style={styles.button}
        />*/}
        <Pressable
          style={darkModeAvailable ? styles.buttonDark : styles.button}
          onPress={() => resetData(email)}
          accessible={true}
          accessibilityLabel={t('Reset_password')}
          accessibilityHint="Reset password validation">
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.text,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Reset_password')}
          </Text>
        </Pressable>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  button: {
    backgroundColor: '#D05B5B',
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  text: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  textDark: {
    color: '#ecb853',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  formInput: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    marginTop: Platform.OS === 'web' ? '15px' : 15,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  labelText: {
    position: 'absolute',
    top: Platform.OS === 'web' ? '-13px' : -13,
    left: Platform.OS === 'web' ? '20px' : 20,
    padding: Platform.OS === 'web' ? '5px' : 5,
    backgroundColor: Platform.OS === 'web' ? '#FFFFFF' : 'white',
    zIndex: 10,
    color: '#707070',
  },
  labelTextDark: {
    position: 'absolute',
    top: Platform.OS === 'web' ? '-13px' : -13,
    left: Platform.OS === 'web' ? '20px' : 20,
    padding: Platform.OS === 'web' ? '5px' : 5,
    backgroundColor: '#000',
    zIndex: 10,
    color: '#ecb853',
  },
  input: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    borderWidth: 1,
    padding: 15,
    borderColor: '#C5C5C5',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  inputDark: {
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    borderWidth: 1,
    padding: 15,
    borderColor: '#ecb853',
    color: '#ecb853',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  errorText: {
    marginLeft: Platform.OS === 'web' ? '25px' : 25,
    color: '#ff0000',
    opacity: Platform.OS === 'web' ? '1.0' : 1.0,
  },
  errorTextDark: {
    marginLeft: Platform.OS === 'web' ? '25px' : 25,
    color: '#ecb853',
    opacity: Platform.OS === 'web' ? '1.0' : 1.0,
  },
  errorTextHide: {
    opacity: Platform.OS === 'web' ? '0.0' : 0.0,
  },
});

export default Reset;
