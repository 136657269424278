/**
 * Avatar
 */

import React, {useState, useEffect} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Switch,
  TouchableOpacity,
  Platform,
  Pressable,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {useIsFocused} from '@react-navigation/native';
import {
  AVATAR_CLOTHES_TYPE,
  AVATAR_GENDER_TYPE,
  AVATAR_BACKGROUND_TYPE,
  AVATAR_SKIN_TYPE,
  AVATAR_CONTRAST_TYPE,
  AVATAR_BACKGROUND_BLUE,
  AVATAR_BACKGROUND_GREY_LIGHT,
  AVATAR_BACKGROUND_WHITE,
  AVATAR_CLOTHES_GREEN,
  AVATAR_CLOTHES_PURPLE,
  AVATAR_CLOTHES_GREY,
  AVATAR_GENDER_MALE,
  AVATAR_GENDER_FEMALE,
  AVATAR_GENDER_DIVERS,
  AVATAR_SKIN_LIGHT,
  AVATAR_SKIN_LIGHT_DARK,
  AVATAR_SKIN_NORMAL,
  AVATAR_SKIN_DARK_LIGHT,
  AVATAR_SKIN_DARK,
  AVATAR_CONTRAST_RED,
  AVATAR_CONTRAST_GREEN,
  AVATAR_CONTRAST_BLUE,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
} from '../../../constants/constants';
// import RadioButton from './../../../components/radioButton/radioButton';
import {useTranslation} from 'react-i18next';
/* Icons */
import Logo from './../../../assets/easierlogowithtext.png';
import IconArrow from './../../../assets/iconarrow.png';
import GenderDiversIcon from './../../../assets/icongenderdivers.png';
import GenderFemaleIcon from './../../../assets/icongenderfemale.png';
import GenderMaleIcon from './../../../assets/icongendermale.png';
import AvatarIcon from './../../../assets/avatar.png';

/*const PROP = [
  {
    key: 'business',
    text: 'Business',
  },
  {
    key: 'freestyle',
    text: 'Freestyle',
  },
];*/

const RadioButton = ({onPress, selected, children}) => {
  return (
    <View style={styles.radioButtonContainer} accessible={true}>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation"
        style={selected ? styles.radioButtonActive : styles.radioButton}>
        {selected ? <View style={styles.radioButtonIcon} /> : null}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation">
        <Text style={styles.radioButtonText}>{children}</Text>
      </TouchableOpacity>
    </View>
  );
};

const RadioButtonDark = ({onPress, selected, children}) => {
  return (
    <View style={styles.radioButtonContainer} accessible={true}>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation"
        style={selected ? styles.radioButtonActiveDark : styles.radioButton}>
        {selected ? <View style={styles.radioButtonIconDark} /> : null}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation">
        <Text style={styles.radioButtonText}>{children}</Text>
      </TouchableOpacity>
    </View>
  );
};

const RadioButtonVertical = ({onPress, selected, children}) => {
  return (
    <View
      style={[styles.radioButtonContainer, styles.skinColorBlock]}
      accessible={true}>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation"
        style={selected ? styles.radioButtonActive : styles.radioButton}>
        {selected ? <View style={styles.radioButtonIcon} /> : null}
      </TouchableOpacity>
      <TouchableOpacity
        onPress={onPress}
        accessible={true}
        accessibilityLabel={'Checkbox'}
        accessibilityHint="Checkbox validation">
        <Text style={styles.radioButtonText}>{children}</Text>
      </TouchableOpacity>
    </View>
  );
};

const RadioButtonVerticalNew = ({
  onPress,
  selected,
  contrastSizing,
  children,
}) => {
  return (
    <View
      style={[styles.radioButtonContainerNew, styles.contrastColorBlock]}
      accessible={true}>
      <View accessible={true}>
        <TouchableOpacity
          onPress={onPress}
          accessible={true}
          accessibilityLabel={'Checkbox'}
          accessibilityHint="Checkbox validation">
          <Text
            style={[
              styles.radioButtonText,
              contrastSizing === SMALLTEXT
                ? styles.smallTextAll
                : contrastSizing === NORMALTEXT
                ? styles.normalTextAll
                : contrastSizing === BIGTEXT
                ? styles.bigTextAll
                : styles.normalTextAll,
            ]}>
            {children}
          </Text>
        </TouchableOpacity>
      </View>
      <View accessible={true}>
        <TouchableOpacity
          onPress={onPress}
          accessible={true}
          accessibilityLabel={'Checkbox'}
          accessibilityHint="Checkbox validation"
          style={selected ? styles.radioButtonActive : styles.radioButton}>
          {selected ? <View style={styles.radioButtonIcon} /> : null}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const RadioButtonVerticalNewDark = ({
  onPress,
  selected,
  contrastSizing,
  children,
}) => {
  return (
    <View
      style={[styles.radioButtonContainerNew, styles.contrastColorBlock]}
      accessible={true}>
      <View accessible={true}>
        <TouchableOpacity
          onPress={onPress}
          accessible={true}
          accessibilityLabel={'Checkbox'}
          accessibilityHint="Checkbox validation">
          <Text
            style={[
              styles.radioButtonText,
              contrastSizing === SMALLTEXT
                ? styles.smallTextAll
                : contrastSizing === NORMALTEXT
                ? styles.normalTextAll
                : contrastSizing === BIGTEXT
                ? styles.bigTextAll
                : styles.normalTextAll,
            ]}>
            {children}
          </Text>
        </TouchableOpacity>
      </View>
      <View accessible={true}>
        <TouchableOpacity
          onPress={onPress}
          accessible={true}
          accessibilityLabel={'Checkbox'}
          accessibilityHint="Checkbox validation"
          style={selected ? styles.radioButtonActiveDark : styles.radioButton}>
          {selected ? <View style={styles.radioButtonIconDark} /> : null}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const Avatar = ({navigation}): Node => {
  const [isEnabled, setIsEnabled] = useState(false);
  const {t} = useTranslation();
  const [clothes, setClothes] = useState([
    {
      id: 1,
      value: AVATAR_CLOTHES_GREEN,
      name: <View style={styles.settingsBackgroundColorGreen}></View>,
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_CLOTHES_PURPLE,
      name: <View style={styles.settingsBackgroundColorPurple}></View>,
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_CLOTHES_GREY,
      name: <View style={styles.settingsBackgroundColorGrey}></View>,
      selected: false,
    },
  ]);
  const [gender, setGender] = useState([
    {
      id: 1,
      value: AVATAR_GENDER_MALE,
      name: (
        <View style={styles.settingsBackgroundColorIcon}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri: Platform.OS == 'web' ? GenderMaleIcon : 'icongendermale',
            }}
            style={[styles.settingsIconStyle, styles.settingsGenderIconStyle]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_GENDER_FEMALE,
      name: (
        <View style={styles.settingsBackgroundColorIcon}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri: Platform.OS == 'web' ? GenderFemaleIcon : 'icongenderfemale',
            }}
            style={[styles.settingsIconStyle, styles.settingsGenderIconStyle]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_GENDER_DIVERS,
      name: (
        <View style={styles.settingsBackgroundColorIcon}>
          <Image
            width={Platform.OS == 'web' ? '25px' : 25}
            height={Platform.OS == 'web' ? '25px' : 25}
            source={{
              uri: Platform.OS == 'web' ? GenderDiversIcon : 'icongenderdivers',
            }}
            style={[styles.settingsIconStyle, styles.settingsGenderIconStyle]}
            resizeMode="contain"
          />
        </View>
      ),
      selected: false,
    },
  ]);
  const [background, setBackground] = useState([
    {
      id: 1,
      value: AVATAR_BACKGROUND_BLUE,
      name: <View style={styles.settingsBackgroundColorBlue}></View>,
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_BACKGROUND_GREY_LIGHT,
      name: <View style={styles.settingsBackgroundColorGreyLight}></View>,
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_BACKGROUND_WHITE,
      name: <View style={styles.settingsBackgroundColorWhite}></View>,
      selected: false,
    },
  ]);
  const [skins, setSkins] = useState([
    {
      id: 1,
      value: AVATAR_SKIN_LIGHT,
      name: <View style={styles.settingsBackgroundColorSkinOne}></View>,
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_SKIN_LIGHT_DARK,
      name: <View style={styles.settingsBackgroundColorSkinTwo}></View>,
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_SKIN_NORMAL,
      name: <View style={styles.settingsBackgroundColorSkinThree}></View>,
      selected: false,
    },
    {
      id: 4,
      value: AVATAR_SKIN_DARK_LIGHT,
      name: <View style={styles.settingsBackgroundColorSkinFour}></View>,
      selected: false,
    },
    {
      id: 5,
      value: AVATAR_SKIN_DARK,
      name: <View style={styles.settingsBackgroundColorSkinFive}></View>,
      selected: false,
    },
  ]);
  const [contrast, setContrast] = useState([
    {
      id: 1,
      value: AVATAR_CONTRAST_RED,
      name: <View style={styles.settingsBackgroundColorContrastOne}></View>,
      selected: true,
    },
    {
      id: 2,
      value: AVATAR_CONTRAST_GREEN,
      name: <View style={styles.settingsBackgroundColorContrastTwo}></View>,
      selected: false,
    },
    {
      id: 3,
      value: AVATAR_CONTRAST_BLUE,
      name: <View style={styles.settingsBackgroundColorContrastThree}></View>,
      selected: false,
    },
  ]);
  const [selectedClothes, setSelectedClothes] = useState(AVATAR_CLOTHES_GREEN);
  const [selectedGender, setSelectedGender] = useState(AVATAR_GENDER_MALE);
  const [selectedBackground, setSelectedBackground] = useState(
    AVATAR_BACKGROUND_BLUE,
  );
  const [selectedSkin, setSelectedSkin] = useState(AVATAR_SKIN_LIGHT);
  const [selectedContrast, setSelectedContrast] = useState(AVATAR_CONTRAST_RED);
  const onRadioBtnClick = async item => {
    let updatedState = clothes.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setClothes(updatedState);
    setSelectedClothes(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );

    await AsyncStorage.setItem(
      AVATAR_CLOTHES_TYPE,
      updatedState[0].selected == true
        ? AVATAR_CLOTHES_GREEN
        : updatedState[1].selected == true
        ? AVATAR_CLOTHES_PURPLE
        : AVATAR_CLOTHES_GREY,
    );
  };
  const onRadioBtnClickGender = async item => {
    let updatedState = gender.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setGender(updatedState);
    setSelectedGender(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );

    await AsyncStorage.setItem(
      AVATAR_GENDER_TYPE,
      updatedState[0].selected == true
        ? AVATAR_GENDER_MALE
        : updatedState[1].selected == true
        ? AVATAR_GENDER_FEMALE
        : AVATAR_GENDER_DIVERS,
    );
  };
  const onRadioBtnClickBackground = async item => {
    let updatedState = background.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setBackground(updatedState);
    setSelectedBackground(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );

    await AsyncStorage.setItem(
      AVATAR_BACKGROUND_TYPE,
      updatedState[0].selected == true
        ? AVATAR_BACKGROUND_BLUE
        : updatedState[1].selected == true
        ? AVATAR_BACKGROUND_GREY_LIGHT
        : AVATAR_BACKGROUND_WHITE,
    );
  };
  const onRadioBtnClickSkins = async item => {
    let updatedState = skins.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setSkins(updatedState);
    setSelectedSkin(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].selected == true
        ? updatedState[2].value
        : updatedState[3].selected == true
        ? updatedState[3].value
        : updatedState[4].value,
    );

    await AsyncStorage.setItem(
      AVATAR_SKIN_TYPE,
      updatedState[0].selected == true
        ? AVATAR_SKIN_LIGHT
        : updatedState[1].selected == true
        ? AVATAR_SKIN_LIGHT_DARK
        : updatedState[2].selected == true
        ? AVATAR_SKIN_NORMAL
        : updatedState[3].selected == true
        ? AVATAR_SKIN_DARK_LIGHT
        : AVATAR_SKIN_DARK,
    );
  };
  const onRadioBtnClickContrast = async item => {
    let updatedState = contrast.map(isItem =>
      isItem.id === item.id
        ? {...isItem, selected: true}
        : {...isItem, selected: false},
    );
    setContrast(updatedState);
    setSelectedContrast(
      updatedState[0].selected == true
        ? updatedState[0].value
        : updatedState[1].selected == true
        ? updatedState[1].value
        : updatedState[2].value,
    );

    await AsyncStorage.setItem(
      AVATAR_CONTRAST_TYPE,
      updatedState[0].selected == true
        ? AVATAR_CONTRAST_RED
        : updatedState[1].selected == true
        ? AVATAR_CONTRAST_GREEN
        : AVATAR_CONTRAST_BLUE,
    );
  };
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const isFocused = useIsFocused();

  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);

  useEffect(() => {
    const avatarSettings = async () => {
      if ((await AsyncStorage.getItem(AVATAR_CLOTHES_TYPE)) == null) {
        await AsyncStorage.setItem(AVATAR_CLOTHES_TYPE, AVATAR_CLOTHES_GREEN);
      }
      if ((await AsyncStorage.getItem(AVATAR_GENDER_TYPE)) == null) {
        await AsyncStorage.setItem(AVATAR_GENDER_TYPE, AVATAR_GENDER_MALE);
      }
      if ((await AsyncStorage.getItem(AVATAR_BACKGROUND_TYPE)) == null) {
        await AsyncStorage.setItem(
          AVATAR_BACKGROUND_TYPE,
          AVATAR_BACKGROUND_BLUE,
        );
      }
      if ((await AsyncStorage.getItem(AVATAR_SKIN_TYPE)) == null) {
        await AsyncStorage.setItem(AVATAR_SKIN_TYPE, AVATAR_SKIN_LIGHT);
      }
      if ((await AsyncStorage.getItem(AVATAR_CONTRAST_TYPE)) == null) {
        await AsyncStorage.setItem(AVATAR_CONTRAST_TYPE, AVATAR_CONTRAST_RED);
      }
    };

    avatarSettings();
  });

  useEffect(() => {
    const avatarTypeDefault = async () => {
      const defaultAvatarClothesType = await AsyncStorage.getItem(
        AVATAR_CLOTHES_TYPE,
      );
      defaultAvatarClothesType === AVATAR_CLOTHES_GREEN
        ? setSelectedClothes(
            ((clothes[0].selected = true),
            (clothes[1].selected = false),
            (clothes[2].selected = false)),
          )
        : defaultAvatarClothesType === AVATAR_CLOTHES_PURPLE
        ? setSelectedClothes(
            ((clothes[0].selected = false),
            (clothes[1].selected = true),
            (clothes[2].selected = false)),
          )
        : defaultAvatarClothesType === AVATAR_CLOTHES_GREY
        ? setSelectedClothes(
            ((clothes[0].selected = false),
            (clothes[1].selected = false),
            (clothes[2].selected = true)),
          )
        : setSelectedClothes(
            ((clothes[0].selected = true),
            (clothes[1].selected = false),
            (clothes[2].selected = false)),
          );

      const defaultAvatarGenderType = await AsyncStorage.getItem(
        AVATAR_GENDER_TYPE,
      );
      defaultAvatarGenderType === AVATAR_GENDER_MALE
        ? setSelectedGender(
            ((gender[0].selected = true),
            (gender[1].selected = false),
            (gender[2].selected = false)),
          )
        : defaultAvatarGenderType === AVATAR_GENDER_FEMALE
        ? setSelectedGender(
            ((gender[0].selected = false),
            (gender[1].selected = true),
            (gender[2].selected = false)),
          )
        : defaultAvatarGenderType === AVATAR_GENDER_DIVERS
        ? setSelectedGender(
            ((gender[0].selected = false),
            (gender[1].selected = false),
            (gender[2].selected = true)),
          )
        : setSelectedGender(
            ((gender[0].selected = true),
            (gender[1].selected = false),
            (gender[2].selected = false)),
          );

      const defaultAvatarBackgroundType = await AsyncStorage.getItem(
        AVATAR_BACKGROUND_TYPE,
      );
      defaultAvatarBackgroundType === AVATAR_BACKGROUND_BLUE
        ? setSelectedBackground(
            ((background[0].selected = true),
            (background[1].selected = false),
            (background[2].selected = false)),
          )
        : defaultAvatarBackgroundType === AVATAR_BACKGROUND_GREY_LIGHT
        ? setSelectedBackground(
            ((background[0].selected = false),
            (background[1].selected = true),
            (background[2].selected = false)),
          )
        : defaultAvatarBackgroundType === AVATAR_BACKGROUND_WHITE
        ? setSelectedBackground(
            ((background[0].selected = false),
            (background[1].selected = false),
            (background[2].selected = true)),
          )
        : setSelectedBackground(
            ((background[0].selected = true),
            (background[1].selected = false),
            (background[2].selected = false)),
          );

      const defaultAvatarSkinType = await AsyncStorage.getItem(
        AVATAR_SKIN_TYPE,
      );
      defaultAvatarSkinType === AVATAR_SKIN_LIGHT
        ? setSelectedSkin(
            ((skins[0].selected = true),
            (skins[1].selected = false),
            (skins[2].selected = false),
            (skins[3].selected = false),
            (skins[4].selected = false)),
          )
        : defaultAvatarSkinType === AVATAR_SKIN_LIGHT_DARK
        ? setSelectedSkin(
            ((skins[0].selected = false),
            (skins[1].selected = true),
            (skins[2].selected = false),
            (skins[3].selected = false),
            (skins[4].selected = false)),
          )
        : defaultAvatarSkinType === AVATAR_SKIN_NORMAL
        ? setSelectedSkin(
            ((skins[0].selected = false),
            (skins[1].selected = false),
            (skins[2].selected = true),
            (skins[3].selected = false),
            (skins[4].selected = false)),
          )
        : defaultAvatarSkinType === AVATAR_SKIN_DARK_LIGHT
        ? setSelectedSkin(
            ((skins[0].selected = false),
            (skins[1].selected = false),
            (skins[2].selected = false),
            (skins[3].selected = true),
            (skins[4].selected = false)),
          )
        : defaultAvatarSkinType === AVATAR_SKIN_DARK
        ? setSelectedSkin(
            ((skins[0].selected = false),
            (skins[1].selected = false),
            (skins[2].selected = false),
            (skins[3].selected = false),
            (skins[4].selected = true)),
          )
        : setSelectedSkin(
            ((skins[0].selected = true),
            (skins[1].selected = false),
            (skins[2].selected = false),
            (skins[3].selected = false),
            (skins[4].selected = false)),
          );

      const defaultAvatarContrastType = await AsyncStorage.getItem(
        AVATAR_CONTRAST_TYPE,
      );
      defaultAvatarContrastType === AVATAR_CONTRAST_RED
        ? setSelectedContrast(
            ((contrast[0].selected = true),
            (contrast[1].selected = false),
            (contrast[2].selected = false)),
          )
        : defaultAvatarContrastType === AVATAR_CONTRAST_GREEN
        ? setSelectedContrast(
            ((contrast[0].selected = false),
            (contrast[1].selected = true),
            (contrast[2].selected = false)),
          )
        : defaultAvatarContrastType === AVATAR_CONTRAST_BLUE
        ? setSelectedContrast(
            ((contrast[0].selected = false),
            (contrast[1].selected = false),
            (contrast[2].selected = true)),
          )
        : setSelectedContrast(
            ((contrast[0].selected = true),
            (contrast[1].selected = false),
            (contrast[2].selected = false)),
          );
    };
    avatarTypeDefault();
  });
  const saveData = () => {
    navigation.navigate('Settings');
  };

  const toggleSwitch = () => setIsEnabled(previousState => !previousState);

  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}>
        <View
          style={styles.additionalOption}
          accessible={true}
          accessibilityLabel="Avatar">
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri: Platform.OS == 'web' ? AvatarIcon : 'avatar',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View
          style={[styles.additionalOptionStyles, styles.borderDown]}
          accessible={true}>
          <View style={styles.backgroundText} accessible={true}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.categoryTitleDarkMode,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.categoryTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }
              accessible={true}>
              {t('Background')}
            </Text>
            <View accessible={true}>
              {darkModeAvailable
                ? background.map(item => (
                    <RadioButtonDark
                      onPress={() => onRadioBtnClickBackground(item)}
                      selected={item.selected}
                      key={item.id}>
                      {item.name}
                    </RadioButtonDark>
                  ))
                : background.map(item => (
                    <RadioButton
                      onPress={() => onRadioBtnClickBackground(item)}
                      selected={item.selected}
                      key={item.id}>
                      {item.name}
                    </RadioButton>
                  ))}
            </View>
          </View>
          <View style={styles.clothesText} accessible={true}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.categoryTitleDarkMode,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.categoryTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }
              accessible={true}>
              {t('Clothing')}
            </Text>
            <View>
              {darkModeAvailable
                ? clothes.map(item => (
                    <RadioButtonDark
                      onPress={() => onRadioBtnClick(item)}
                      selected={item.selected}
                      key={item.id}>
                      {item.name}
                    </RadioButtonDark>
                  ))
                : clothes.map(item => (
                    <RadioButton
                      onPress={() => onRadioBtnClick(item)}
                      selected={item.selected}
                      key={item.id}>
                      {item.name}
                    </RadioButton>
                  ))}
            </View>
          </View>
          <View style={styles.genderBlock} accessible={true}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.categoryTitleDarkMode,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.categoryTitle,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }
              accessible={true}>
              {t('Gender')}
            </Text>
            <View accessible={true}>
              {darkModeAvailable
                ? gender.map(item => (
                    <RadioButtonDark
                      onPress={() => onRadioBtnClickGender(item)}
                      selected={item.selected}
                      key={item.id}>
                      {item.name}
                    </RadioButtonDark>
                  ))
                : gender.map(item => (
                    <RadioButton
                      onPress={() => onRadioBtnClickGender(item)}
                      selected={item.selected}
                      key={item.id}>
                      {item.name}
                    </RadioButton>
                  ))}
            </View>
          </View>
        </View>
        <View style={styles.additionalOptionSecondStyles} accessible={true}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.categoryTitleSecondDarkMode,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.categoryTitleSecond,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }
            accessible={true}>
            {t('Contrast')}
          </Text>
        </View>
        <View style={styles.contrastBlock} accessible={true}>
          <View style={styles.contrastRow} accessible={true}>
            {darkModeAvailable
              ? contrast.map(item => (
                  <RadioButtonVerticalNewDark
                    onPress={() => onRadioBtnClickContrast(item)}
                    selected={item.selected}
                    contrastSizing={contrastSizing}
                    key={item.id}>
                    {item.name}
                  </RadioButtonVerticalNewDark>
                ))
              : contrast.map(item => (
                  <RadioButtonVerticalNew
                    onPress={() => onRadioBtnClickContrast(item)}
                    selected={item.selected}
                    contrastSizing={contrastSizing}
                    key={item.id}>
                    {item.name}
                  </RadioButtonVerticalNew>
                ))}
          </View>
        </View>
        {/*<View style={styles.additionalOptionSecondStyles}>
          <Text style={styles.categoryTitleSecond}>Skin colour</Text>
        </View>
        <View style={styles.skinBlock}>
          <View style={styles.skinsRow}>
            {skins.map(item => (
              <RadioButtonVertical
                onPress={() => onRadioBtnClickSkins(item)}
                selected={item.selected}
                key={item.id}>
                {item.name}
              </RadioButtonVertical>
            ))}
          </View>
        </View>*/}
        <Pressable
          style={darkModeAvailable ? styles.buttonDark : styles.button}
          accessible={true}
          accessibilityLabel={t('Save')}
          accessibilityHint="Save avatar validation"
          onPress={saveData}>
          <Text
            style={
              darkModeAvailable
                ? [
                    styles.textDark,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
                : [
                    styles.textButton,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]
            }>
            {t('Save')}
          </Text>
        </Pressable>
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  switchEnableBorder: {
    borderColor: '#6fa6d3',
    borderWidth: 1,
  },
  switchDisableBorder: {
    borderColor: '#f2f2f2',
    borderWidth: 1,
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  borderDown: {
    borderBottomWidth: 2,
    borderBottomColor: '#ccc',
  },
  additionalOption: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '200px' : 200,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  additionalOptionTitle: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '35px' : 35,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '20px' : 20,
    marginTop: Platform.OS === 'web' ? '20px' : 20,
  },
  additionalOptionStyles: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
  },
  additionalOptionSecondStyles: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
    paddingTop: Platform.OS === 'web' ? '20px' : 20,
  },
  headlineTitle: {
    width: Platform.OS === 'web' ? '300px' : 300,
    alignItems: 'left',
    //resizeMode: 'center',
  },
  headlineTitleMiddle: {
    width: Platform.OS === 'web' ? '300px' : 300,
    alignItems: 'center',
    //resizeMode: 'center',
  },
  categoryTitle: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  categoryTitleSecond: {
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  categoryTitleDarkMode: {
    marginBottom: Platform.OS === 'web' ? '15px' : 15,
    //resizeMode: 'center',
    alignItems: 'center',
    color: '#ecb853',
  },
  categoryTitleSecondDarkMode: {
    marginBottom: Platform.OS === 'web' ? '-10px' : -10,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    color: '#ecb853',
  },
  switchText: {
    width: Platform.OS === 'web' ? '250px' : 250,
    marginRight: Platform.OS === 'web' ? '5px' : 5,
  },
  clothesText: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  backgroundText: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderRightColor: '#ccc',
  },
  emptyText: {
    width: Platform.OS === 'web' ? '100px' : 100,
    height: Platform.OS === 'web' ? 'auto' : 'auto',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  genderBlock: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  skinBlock: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  skinColorBlock: {
    transform: [{rotate: '-90deg'}],
    width: Platform.OS === 'web' ? '50px' : 50,
  },
  skinsRow: {
    width: Platform.OS === 'web' ? '300px' : 300,
    flexDirection: 'row',
  },
  contrastBlock: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  contrastColorBlock: {
    //transform: [{rotate: '-90deg'}],
    width: Platform.OS === 'web' ? '50px' : 50,
  },
  contrastRow: {
    width: Platform.OS === 'web' ? '200px' : 200,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    flexDirection: 'row',
  },
  switchSlider: {
    width: Platform.OS === 'web' ? '50px' : 50,
    marginLeft: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsBackgroundColor: {
    backgroundColor: '#ECECEC',
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    flex: 1,
    margin: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingLeft: Platform.OS === 'web' ? '20px' : 20,
  },
  settingsText: {
    fontSize: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '5px' : 5,
  },
  settingsTextAdditional: {
    width: Platform.OS === 'web' ? '100px' : 100,
    marginLeft: Platform.OS === 'web' ? '12px' : 12,
  },
  settingsIconStyle: {
    width: Platform.OS === 'web' ? '35px' : 35,
    height: Platform.OS === 'web' ? '35px' : 35,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  settingsGenderIconStyle: {
    marginTop: Platform.OS === 'web' ? '3px' : 3,
  },
  settingsBackgroundColorIcon: {
    backgroundColor: '#BEBEBE',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorGreen: {
    backgroundColor: '#639F42',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorPurple: {
    backgroundColor: '#8E417A',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorGrey: {
    backgroundColor: '#5C5C5C',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorBlue: {
    backgroundColor: '#8BB0C2',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorWhite: {
    backgroundColor: '#ffffff',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    borderWidth: 1,
    borderColor: '#000',
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorGreyLight: {
    backgroundColor: '#BEBEBE',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorSkinOne: {
    backgroundColor: '#F7D7C4',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorSkinTwo: {
    backgroundColor: '#D8B094',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorSkinThree: {
    backgroundColor: '#BB9167',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorSkinFour: {
    backgroundColor: '#8E562E',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorSkinFive: {
    backgroundColor: '#613D30',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorContrastOne: {
    backgroundColor: '#ff0000',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorContrastTwo: {
    backgroundColor: '#639F42',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  settingsBackgroundColorContrastThree: {
    backgroundColor: '#8BB0C2',
    width: Platform.OS === 'web' ? '40px' : 40,
    height: Platform.OS === 'web' ? '40px' : 40,
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '20px' : 20,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
    //resizeMode: 'center',
    alignItems: 'center',
  },
  radioButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  radioButtonContainerNew: {
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
  },
  radioButton: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#000',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonActive: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#D05B5B',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonActiveDark: {
    height: 20,
    width: 20,
    backgroundColor: '#000',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#ecb853',
    alignItems: 'center',
    justifyContent: 'center',
  },
  radioButtonIcon: {
    height: 14,
    width: 14,
    borderRadius: 7,
    backgroundColor: '#D05B5B',
  },
  radioButtonIconDark: {
    height: 14,
    width: 14,
    borderRadius: 7,
    backgroundColor: '#ecb853',
  },
  radioButtonText: {
    fontSize: 16,
    marginLeft: 16,
  },
  text: {
    lineHeight: 30,
    fontSize: 20,
    marginVertical: 5,
  },
  button: {
    backgroundColor: '#D05B5B',
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textButton: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  textDark: {
    color: '#ecb853',
  },
});

export default Avatar;
