/**
 * Profile
 */

import React, {useEffect, useState} from 'react';
import type {Node} from 'react';
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  useColorScheme,
  View,
  Image,
  Button,
  Platform,
  Pressable,
  TextInput,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Logo from './../../assets/easierlogowithtext.png';
import LogoDark from './../../assets/easierlogowithtextdark.png';
import IconArrow from './../../assets/iconarrow.png';
import {useIsFocused} from '@react-navigation/native';
import {useTranslation} from 'react-i18next';
import {
  USERNAME,
  EMAIL_USER,
  USERID,
  CONTRAST_MODE_TYPE,
  CONTRAST_SIZE_TYPE,
  SMALLTEXT,
  NORMALTEXT,
  BIGTEXT,
  USERTOKEN_KEY,
  DEVELOPMENT,
  TEST,
  TOKEN_TIMER,
  USERREFRESHTOKEN_KEY,
} from '../../constants/constants';
import axios from 'axios';

const Profile = ({navigation}): Node => {
  const [darkModeAvailable, setDarkModeAvailable] = useState(false);
  const [modalAvailable, setModalAvailable] = useState(false);
  const [contrastSizing, setContrastSizing] = useState(NORMALTEXT);
  const [userDataSession, setUserDataSession] = useState([]);
  const isFocused = useIsFocused();
  const {t} = useTranslation();
  useEffect(() => {
    const checkDarkModeDefault = async () => {
      const defaultDarkModeType = await AsyncStorage.getItem(
        CONTRAST_MODE_TYPE,
      );
      defaultDarkModeType === 'true'
        ? setDarkModeAvailable(true)
        : setDarkModeAvailable(false);
    };
    const checkSizeDefault = async () => {
      const defaultTextSizingType = await AsyncStorage.getItem(
        CONTRAST_SIZE_TYPE,
      );

      defaultTextSizingType === SMALLTEXT
        ? setContrastSizing(SMALLTEXT)
        : defaultTextSizingType === NORMALTEXT
        ? setContrastSizing(NORMALTEXT)
        : defaultTextSizingType === BIGTEXT
        ? setContrastSizing(BIGTEXT)
        : setContrastSizing(NORMALTEXT);
    };
    checkDarkModeDefault();
    checkSizeDefault();
  }, [isFocused]);
  useEffect(() => {
    const userData = async () => {
      const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

      axios
        .get(TEST + 'oauth2/user/session', {
          headers: {
            //...form.getHeaders(),
            Authorization: `Bearer ${userKeyToken}`,
          },
        })
        .then(response => {
          if (response.status === 200) {
            console.log(response.data);
            setUserDataSession(response.data);
          }
        })
        .catch(e => {
          console.log(e);
        });
    };
    userData();
  }, [isFocused]);
  const deleteUserModal = () => {
    setModalAvailable(true);
  };
  const closeModal = () => {
    setModalAvailable(false);
  };
  const deleteUser = async userID => {
    const userKeyToken = await AsyncStorage.getItem(USERTOKEN_KEY);

    axios
      .delete(TEST + 'user/' + userID, {
        headers: {
          //...form.getHeaders(),
          Authorization: `Bearer ${userKeyToken}`,
        },
      })
      .then(async res => {
        if (res.status === 200) {
          setModalAvailable(false);
          await AsyncStorage.removeItem(TOKEN_TIMER);
          await AsyncStorage.removeItem(USERTOKEN_KEY);
          await AsyncStorage.removeItem(USERREFRESHTOKEN_KEY);
          setTimeout(async () => {
            await navigation.navigate('Log In');
          }, 2000);
        } else {
          console.log('Error');
        }
      })
      .catch(e => {
        console.log(e);
        console.log('Failed');
      });
  };
  return (
    <ScrollView
      style={
        darkModeAvailable
          ? styles.mainBackgroundColorDark
          : styles.mainBackgroundColor
      }
      accessible={true}>
      <View
        style={Platform.OS === 'web' ? styles.mainMarginWeb : styles.mainMargin}
        accessible={true}
        accessibilityLabel="Logo of EASIER">
        <View style={styles.mainMarginLogo}>
          <Image
            width={Platform.OS == 'web' ? '200px' : 200}
            height={Platform.OS == 'web' ? '200px' : 200}
            source={{
              uri:
                Platform.OS == 'web'
                  ? darkModeAvailable
                    ? LogoDark
                    : Logo
                  : darkModeAvailable
                  ? 'easierlogowithtextdark'
                  : 'easierlogowithtext',
            }}
            style={styles.logo}
            resizeMode="contain"
          />
        </View>
        <View style={styles.userDataContainer} accessible={true}>
          <View
            style={styles.userDataInfo}
            accessible={true}
            accessibilityLabel={t('Name') + ' - ' + USERNAME}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.userDataTextDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.userDataText,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Name')}
            </Text>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.userDataTextMoreDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.userDataTextMore,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {userDataSession.username}
            </Text>
          </View>
          <View
            style={styles.userDataInfo}
            accessible={true}
            accessibilityLabel={t('E-Mail') + ' - ' + EMAIL_USER}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.userDataTextDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.userDataText,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('E-Mail')}
            </Text>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.userDataTextMoreDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.userDataTextMore,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {userDataSession.email}
            </Text>
          </View>
          <View
            style={styles.userDataInfo}
            accessible={true}
            accessibilityLabel={t('ID') + ' - ' + USERID}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.userDataTextDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.userDataText,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('ID')}
            </Text>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.userDataTextMoreDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.userDataTextMore,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {'USER' + userDataSession.user_id}
            </Text>
          </View>
        </View>
        {/*<Button
          title={t('Delete_account')}
          color={'#D05B5B'}
          style={darkModeAvailable ? styles.buttonDark : styles.button}
        />*/}
        {!modalAvailable ? (
          <Pressable
            style={
              darkModeAvailable
                ? [styles.buttonDark, styles.buttonSize]
                : [styles.button, styles.buttonSize]
            }
            accessible={true}
            accessibilityLabel={t('Delete_account')}
            accessibilityHint="Delete account validation"
            onPress={deleteUserModal}>
            <Text
              style={
                darkModeAvailable
                  ? [
                      styles.textDark,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
                  : [
                      styles.text,
                      contrastSizing === SMALLTEXT
                        ? styles.smallTextAll
                        : contrastSizing === NORMALTEXT
                        ? styles.normalTextAll
                        : contrastSizing === BIGTEXT
                        ? styles.bigTextAll
                        : styles.normalTextAll,
                    ]
              }>
              {t('Delete_account')}
            </Text>
          </Pressable>
        ) : (
          <>
            <View
              style={
                darkModeAvailable
                  ? styles.modalContainerDark
                  : styles.modalContainer
              }>
              <View style={styles.modalContainerInner}>
                <Text
                  style={[
                    darkModeAvailable
                      ? styles.modalHeadlineDark
                      : styles.modalHeadline,
                    contrastSizing === SMALLTEXT
                      ? styles.smallTextAll
                      : contrastSizing === NORMALTEXT
                      ? styles.normalTextAll
                      : contrastSizing === BIGTEXT
                      ? styles.bigTextAll
                      : styles.normalTextAll,
                  ]}>
                  {t('Delete_user_account')}
                </Text>
                <View style={styles.textButtonDelete} accessible={true}>
                  <Pressable
                    style={
                      darkModeAvailable
                        ? [
                            styles.menuElementContainerInnerSmallModalDark,
                            styles.menuElementContainerInnerMiddleDarkDelete,
                          ]
                        : [
                            styles.menuElementContainerInnerSmallModal,
                            styles.menuElementContainerInnerMiddleDelete,
                          ]
                    }
                    onPress={closeModal}
                    accessible={true}
                    accessibilityLabel={t('NO')}>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.menuElementMiddle,
                                styles.menuElementAdditionalDark,
                                styles.modalButtonText,
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                styles.menuElementMiddle,
                                styles.menuElementAdditional,
                                styles.modalButtonText,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {t('NO')}
                      </Text>
                    </View>
                  </Pressable>
                </View>
                <View style={styles.textButtonDelete} accessible={true}>
                  <Pressable
                    style={
                      darkModeAvailable
                        ? [
                            styles.menuElementContainerInnerSmallModalDark,
                            styles.menuElementContainerInnerMiddleDarkDelete,
                          ]
                        : [
                            styles.menuElementContainerInnerSmallModal,
                            styles.menuElementContainerInnerMiddleDelete,
                          ]
                    }
                    onPress={() => deleteUser(userDataSession.user_id)}
                    accessible={true}
                    accessibilityLabel={t('YES')}>
                    <View>
                      <Text
                        style={
                          darkModeAvailable
                            ? [
                                styles.menuElementMiddle,
                                styles.menuElementAdditionalDark,
                                styles.modalButtonText,
                                styles.textDark,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                            : [
                                styles.menuElementMiddle,
                                styles.menuElementAdditional,
                                styles.modalButtonText,
                                contrastSizing === SMALLTEXT
                                  ? styles.smallTextAll
                                  : contrastSizing === NORMALTEXT
                                  ? styles.normalTextAll
                                  : contrastSizing === BIGTEXT
                                  ? styles.bigTextAll
                                  : styles.normalTextAll,
                              ]
                        }>
                        {t('YES')}
                      </Text>
                    </View>
                  </Pressable>
                </View>
              </View>
            </View>
          </>
        )}
      </View>
    </ScrollView>
  );
};

const {ids, styles} = StyleSheet.create({
  smallTextAll: {
    fontSize: Platform.OS === 'web' ? '12px' : 12,
  },
  normalTextAll: {
    fontSize: Platform.OS === 'web' ? '14px' : 14,
  },
  bigTextAll: {
    fontSize: Platform.OS === 'web' ? '16px' : 16,
  },
  mainBackgroundColor: {
    backgroundColor: '#fff',
    flex: 1,
  },
  mainBackgroundColorDark: {
    backgroundColor: '#000',
    flex: 1,
  },
  mainMargin: {
    margin: Platform.OS === 'web' ? '10px' : 10,
  },
  mainMarginWeb: {
    margin: Platform.OS === 'web' ? '15px' : 15,
    height: 'calc(100vh)',
  },
  mainMarginLogo: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '-20px' : -20,
  },
  userDataContainer: {
    borderColor: '#000',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    marginBottom: Platform.OS === 'web' ? '30px' : 30,
    marginTop: Platform.OS === 'web' ? '-30px' : -30,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    paddingBottom: Platform.OS === 'web' ? '20px' : 20,
    paddingTop: Platform.OS === 'web' ? '20px' : 20,
    width: Platform.OS === 'web' ? '300px' : 300,
  },
  userDataInfo: {
    marginLeft: Platform.OS === 'web' ? '10px' : 10,
    marginRight: Platform.OS === 'web' ? '10px' : 10,
    marginBottom: Platform.OS === 'web' ? '30px' : 30,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  userDataText: {
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    color: '#666666',
  },
  userDataTextMore: {
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    color: '#666666',
    fontSize: Platform.OS === 'web' ? '16px' : 16,
    fontWeight: '600',
  },
  userDataTextDark: {
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    color: '#ecb853',
  },
  userDataTextMoreDark: {
    marginBottom: Platform.OS === 'web' ? '5px' : 5,
    color: '#ecb853',
    fontSize: Platform.OS === 'web' ? '16px' : 16,
    fontWeight: '600',
  },
  equalLine: {
    flexDirection: 'row',
    opacity: Platform.OS === 'web' ? '1' : 1,
  },
  equalLineHidden: {
    flexDirection: 'row',
    opacity: Platform.OS === 'web' ? '0' : 0,
  },
  logo: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    //resizeMode: 'center',
    alignItems: 'center',
    //textAlign: 'center',
  },
  backArrow: {
    width: Platform.OS === 'web' ? '20px' : 20,
    height: Platform.OS === 'web' ? '20px' : 20,
    transform: [{rotate: '180deg'}],
  },
  backText: {},
  button: {
    backgroundColor: '#D05B5B',
    textAlign: 'center',
    padding: 15,
    borderRadius: 20,
  },
  text: {
    color: '#ffffff',
  },
  buttonDark: {
    backgroundColor: '#000000',
    textAlign: 'center',
    padding: 15,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: 20,
  },
  textDark: {
    color: '#ecb853',
  },
  menuMainUser: {
    width: Platform.OS === 'web' ? '200px' : 200,
    height: Platform.OS === 'web' ? '200px' : 200,
    top: Platform.OS === 'web' ? '0px' : 0,
    right: Platform.OS === 'web' ? '20px' : 20,
    position: 'absolute',
    backgroundColor: '#ffffff',
    boxShadow: '0px 0px 3px 0px #000',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5,
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,
    elevation: 11,
    border: '1px solid #ccc',
    padding: Platform.OS === 'web' ? '20px' : 20,
  },
  buttonSize: {
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
  },
  modalContainer: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '150px' : 150,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
  },
  modalContainerDark: {
    flexDirection: 'row',
    height: Platform.OS === 'web' ? '150px' : 150,
    width: Platform.OS === 'web' ? '300px' : 300,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    borderColor: '#ecb853',
    borderWidth: 2,
    borderRadius: Platform.OS === 'web' ? '10px' : 10,
  },
  modalContainerInner: {
    width: Platform.OS === 'web' ? '250px' : 250,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  modalHeadline: {
    flexDirection: 'row',
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
  },
  modalHeadlineDark: {
    flexDirection: 'row',
    textAlign: 'center',
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    marginTop: Platform.OS === 'web' ? '10px' : 10,
    color: '#ecb853',
  },
  modalButtonText: {
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    width: Platform.OS === 'web' ? '150px' : 150,
    textAlign: 'center',
  },
  menuElementContainerInnerSmallModal: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '150px' : 150,
    backgroundColor: '#BEBEBE',
    color: '#ecb853',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    paddingTop: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
  },
  menuElementContainerInnerSmallModalDark: {
    flexDirection: 'row',
    width: Platform.OS === 'web' ? '150px' : 150,
    backgroundColor: '#000',
    borderRadius: Platform.OS === 'web' ? '5px' : 5,
    borderColor: '#ecb853',
    borderStyle: 'solid',
    borderWidth: Platform.OS === 'web' ? '1px' : 1,
    marginLeft: Platform.OS === 'web' ? 'auto' : 'auto',
    marginRight: Platform.OS === 'web' ? 'auto' : 'auto',
    marginBottom: Platform.OS === 'web' ? '10px' : 10,
    paddingTop: Platform.OS === 'web' ? '10px' : 10,
    paddingBottom: Platform.OS === 'web' ? '10px' : 10,
  },
});

export default Profile;
